import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Validators } from 'ngx-editor';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-coach-details',
  templateUrl: './coach-details.component.html',
  styleUrls: ['./coach-details.component.css'],
})
export class CoachDetailsComponent implements OnInit {
  @ViewChild('closepaymentModal') closepaymentModal: ElementRef | any;
  coachlist: any;
  listpayment: any = [];
  routeparam: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  eventlist: any = [];
  withdrawForm: FormGroup | any;
  submitted: Boolean = false;
  nameamount: any;
  userrole: any;
  schoolid: any;
  coachPayment: any;
  widrawpayment: any = [];

  dropdownSettings: any = {};

  constructor(
    private route: ActivatedRoute,
    private Apiservice: ApiService,
    private Authservice: AuthService,
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userrole = this.Authservice.userrole;
    this.pagecounts = this.Apiservice.pageCounts;
    this.routeparam = this.route.params;

    if (this.userrole == 'SUPERADMIN') {
      this.Apiservice.getApi(endpoints.viewschoolcoach.path + '?id=' + this.routeparam._value.id).subscribe(
        (data: any) => {
          this.coachlist = data.coach;
          this.schoolid = this.coachlist.school.id;
          this.Apiservice.getApi(endpoints.viewschool.path + '?school_id=' + this.schoolid).subscribe(
            (data: any) => {
              this.eventlist = data.events.filter((s: any) => s.coach.id == this.routeparam._value.id);
            }
          );
        }
      );
    } else {
      this.coachlist = this.route.snapshot.data?.message?.data;
      this.Apiservice.getApi(endpoints.eventlist.path).subscribe((data: any) => {
        let activeevent = data.results.filter((s: any) => s.coach_payment_status == 'false');
        this.eventlist = activeevent.filter((s: any) => s.coach.id == this.routeparam._value.id);
        this.eventlist.map((s: any) => {
          (s.name = s.name + '-' + s.coach_fee), (s.id = s.id);
        });
      });
    }

    this.Apiservice.getApi(endpoints.coachpayment.path + '?coach=' + this.routeparam._value.id).subscribe(
      (data: any) => {
        this.coachPayment = data;
      }
    );

    this.WidrawList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.withdrawForm = this.formBuilder.group({
      event: [[], Validators.required],
    });
  }
  get f() {
    return this.withdrawForm.controls;
  }
  WidrawList() {
    this.Apiservice.getApi(endpoints.withdrawpaymenlist.path + '?coach=' + this.routeparam._value.id).subscribe(
      (data: any) => {
        this.widrawpayment = data.paid;
      }
    );
  }
  onSubmit() {
    this.submitted = true;
    if (this.withdrawForm.invalid) {
      return;
    }
    // let evntval= this.withdrawForm.value.event.map((s:any)=>s.id)
    // const body={
    //   "event":evntval
    // }
    this.Apiservice.postApi(endpoints.withdrawpayment.path, this.withdrawForm.value).subscribe(
      (data: any) => {
        this.toaster.success(data.message);
        this.closepaymentModal.nativeElement.click();
        this.router.navigate(['/admin/coach/view-coach/' + this.routeparam._value.id]);
        this.WidrawList();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
