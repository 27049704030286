import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { endpoints } from '../../../../../core/config/endpoints';
import { AuthService } from '../../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-video-section',
  templateUrl: './add-video-section.component.html',
  styleUrls: ['./add-video-section.component.css'],
})
export class AddVideoSectionComponent implements OnInit {
  classForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  userid: any;
  routerid: any;
  settings: any = [];
  position: any = [];
  videoLibrary: any = [];
  @Input() eventId: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    public modalService: NgbActiveModal
  ) {}

  ngOnInit(): void {
    console.log('add eventId--- ', this.eventId);
    this.settings = this.AuthService.setting;
    this.position = this.settings.data.filter((t: any) => t.group == 'staffs-position');

    this.userid = this.route.params;
    this.routerid = this.userid._value.id;

    if (!this.routerid) {
      this.classForm = this.formBuilder.group({
        section: ['', Validators.required],
        assign_status: [0],
        event_id: [this.eventId],
        // default_section:[0]
      });
    } else {
      this.data = this.route.snapshot.data['message'];
      this.classForm = this.formBuilder.group({
        section: [this.data.section, Validators.required],
        assign_status: [''],
        event_id: [this.data.event_id],
        // default_section:['']
      });
      if (this.data.assign_status == '0') {
        this.classForm.controls['assign_status'].setValue(false);
      } else if (this.data.assign_status == '1') {
        this.classForm.controls['assign_status'].setValue(true);
      }
      // if(this.data.default_section=='0'){
      //   this.classForm.controls['default_section'].setValue(false)
      // }else if(this.data.default_section=='1'){
      //   this.classForm.controls['default_section'].setValue(true)
      // }
    }
  }
  get f() {
    return this.classForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.classForm.invalid) {
      return;
    }
    if (this.classForm.value.assign_status == true) {
      this.classForm.controls['assign_status'].setValue(1);
    } else if (this.classForm.value.assign_status == false) {
      this.classForm.controls['assign_status'].setValue(0);
    }
    // if(this.classForm.value.default_section==true){
    //   this.classForm.controls['default_section'].setValue(1)
    // }else if(this.classForm.value.default_section==false){
    //   this.classForm.controls['default_section'].setValue(0)
    // }
    this.Apiservice.postApi(endpoints.createVideoSection.path, this.classForm.value).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Video Section added Successfully'));
        this.modalService.close(data);
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  videoevent() {
    this.Apiservice.getApi(endpoints.listVideoUpload.path).subscribe(
      (data: any) => {
        this.videoLibrary = data.videoList;
      },
      (err: any) => {}
    );
  }
  Close() {
    this.modalService.close();
  }
}
