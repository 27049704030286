<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Add Message</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <a class="btn-sm auth-button " [routerLink]="['/superadmin/messages']" translate><i
                                class="fas fa-bars mr-1"></i>All</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="positionsForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label class="mt-1" translate>School<span class="text-danger">*</span></label>
                            <ng-select placeholder="{{'Select School' | translate}}" formControlName="school"
                                [ngClass]="{ 'is-invalid': submitted && f.school.errors }">
                                <ng-option *ngFor="let status of school" [value]="status.id">{{status.name}}
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.school.errors" class="invalid-feedback">
                                <div *ngIf="f.school.errors.required" translate>School is
                                    required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate>Description</label>
                            <textarea class="form-control" formControlName="description"></textarea>
                        </div>
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4"
                            translate>Submit</button>
                        <a [routerLink]="['/superadmin/messages']"
                            class="btn btn-dark float-right mr-2 mt-4 text-white text-white" translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>