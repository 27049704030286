<main class="e-add-student-page">
  <div id="main-content">
    <!-- Page header section  -->
    <div class="e-add-student-page__header">
      <div class="e-add-student-page__content-box">
        <div class="e-add-student-page__content-box__content">
          <div class="col-12" style="margin: auto">
            <form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
              <div class="c-page-header__divisor">
                <div>
                  <div class="c-page-header__divisor__title">
                    <span>{{
                      routerid
                        ? ('ADD_STUDENT_PAGE.EDIT_TITLE' | translate)
                        : ('ADD_STUDENT_PAGE.TITLE' | translate)
                    }}</span>
                  </div>
                  <div class="c-page-header__divisor__subtitle">
                    <span>{{
                      routerid
                        ? ('ADD_STUDENT_PAGE.EDIT_SUBTITLE' | translate)
                        : ('ADD_STUDENT_PAGE.SUBTITLE' | translate)
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="c-field u-display--flex u-flex-direction--column u-align-items--center">
                    <label class="c-field__label">
                      {{ 'Profile Picture' | translate }}
                    </label>
                    <div>
                      <label for="profileImage" class="c-field__profile-picture-container">
                        <img
                          src="{{ data?.photo_url || 'assets/images/empty-profile.jpg' }}"
                          class="c-field__profile-picture"
                          id="profilePicPreview"
                          onerror="this.onerror=null;this.src='assets/images/img_avatar.png';"
                          alt="" />
                        <div class="c-field__profile-picture-icon">
                          <ion-icon name="pencil-outline"></ion-icon>
                        </div>
                      </label>
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      id="profileImage"
                      name="photo"
                      style="display: none"
                      (change)="onProfilePicSelected($event)" />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-12">
                  <app-field-input
                    formControlName="name"
                    type="text"
                    minlength="2"
                    maxlength="200"
                    class="mt-3"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'Name' | translate"
                    [placeholder]="'Name' | translate">
                  </app-field-input>
                </div>

                <div class="col-lg-6 col-12">
                  <app-field-input
                    formControlName="email"
                    type="text"
                    maxlength="64"
                    class="mt-3"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'Email' | translate"
                    [placeholder]="'Email' | translate">
                  </app-field-input>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <div class="c-field">
                    <label class="c-field__label">{{ 'Phone Number' | translate }} <span>*</span></label>
                  </div>
                  <div class="input-group">
                    <div class="input-group-prepend c-form-phone">
                      <ng-select
                        formControlName="phone_code"
                        class="c-form-phone c-form-phone__code"
                        (change)="conutyphone($event)">
                        <ng-option *ngFor="let status of countryCodeList" [value]="status">
                          {{ status.code }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <input
                      type="text"
                      class="form-control c-form-phone"
                      [placeholder]="minDigit"
                      mask=" {{ minDigit }} || {{ maxDigit }}"
                      [validation]="false"
                      formControlName="phone_number"
                      [ngClass]="{
                        'is-invalid c-field__input--invalid': f.phone_number.touched && f.phone_number.errors
                      }" />
                    <div *ngIf="f.phone_number.touched && f.phone_number.errors" class="invalid-feedback mt-2">
                      <div *ngIf="f.phone_number.errors.required" translate>Phone Number is required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-datepicker
                    labelClass="c-field__label"
                    inputClass="c-field__input e-class-search__input u-margin-bottom--none"
                    variant="date"
                    [startDate]="startDate"
                    [endDate]="endDate"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [isRequired]="true"
                    [calendarLabel]="'ADD_STUDENT_PAGE.DOB_LABEL' | translate"
                    [calendarPlaceholder]="'ADD_STUDENT_PAGE.DOB_PLACEHOLDER' | translate"
                    [showDropdowns]="true"
                    (OnDate)="onDateChange($event)"></app-datepicker>
                  <!--   <app-field-input
                    formControlName="dob"
                    type="text"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    mask="XXXX-XX-XX"
                    [label]="'Date of Birth (YYYY-MM-DD)' | translate"
                    [placeholder]="'YYYY-MM-DD' | translate"></app-field-input> -->
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-select
                    formControlName="gender"
                    [label]="'Gender' | translate"
                    [options]="[
                      { id: 'male', text: 'Male' },
                      { id: 'female', text: 'Female' },
                      { id: 'other', text: 'Other' }
                    ]"
                    [placeholder]="'Select Gender' | translate"
                    [isRequired]="true">
                  </app-field-select>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-select
                    *ngIf="beltOptions.length > 0"
                    formControlName="belt_id"
                    [label]="'Belt' | translate"
                    [options]="beltOptions"
                    [placeholder]="'Select Belt Color' | translate"
                    [isRequired]="true">
                  </app-field-select>
                </div>
              </div>

              <app-country-state-city (CountryChanged)="countryChange($event)" [countryForm]="studentForm">
              </app-country-state-city>

              <app-field-input
                formControlName="address"
                type="textarea"
                class="mt-3"
                [isRequired]="true"
                [clearButton]="true"
                [label]="'Address' | translate"
                [placeholder]="'Address' | translate"
                (focus)="clearAddressErrors()">
              </app-field-input>

              <div *ngIf="addressErrorVisible" class="invalid-feedback d-block">
                {{ addressErrorMessage }}
              </div>

              <div class="row" *ngIf="!this.routerid">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-input
                    formControlName="password"
                    type="password"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.PASSWORD_LABEL' | translate"
                    [placeholder]="'REGISTER.PASSWORD_PLACEHOLDER' | translate"></app-field-input>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                  <app-field-input
                    formControlName="password_confirmation"
                    type="password"
                    maxlength="150"
                    [isRequired]="true"
                    [clearButton]="true"
                    [label]="'REGISTER.PASSWORD_CONFIRMATION_LABEL' | translate"
                    [placeholder]="'REGISTER.PASSWORD_CONFIRMATION_PLACEHOLDER' | translate"></app-field-input>
                </div>
              </div>

              <div class="d-flex justify-content-end mt-5">
                <app-button
                  variant="secondary"
                  class="mr-3"
                  [isSubmit]="true"
                  [disabledStyleOnly]="true"
                  (click)="goBack()"
                  translate
                  >Voltar</app-button
                >
                <app-button
                  icon="checkmark"
                  [iconAfterLabel]="true"
                  variant="primary"
                  [isSubmit]="true"
                  [loading]="loading"
                  [disabled]="loading || studentForm.invalid"
                  [disabledStyleOnly]="true"
                  >{{
                    routerid ? ('ADMIN_ACTIONS.SAVE' | translate) : ('ADMIN_ACTIONS.SUBMIT' | translate)
                  }}</app-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
