<div class="c-fab" [class.c-fab--close]="variant === 'close'">
  <span *ngIf="!!label" class="c-fab__label">
    {{ label }}
  </span>
  <button
    class="c-fab__button"
    [class.c-fab__button--close]="variant === 'close'"
    [class.c-fab__button--toggled]="variant === 'toggled'"
    [class.c-fab__button--untoggled]="variant === 'untoggled'">
    <ion-icon class="c-fab__button__icon" *ngIf="!!icon" [name]="icon"></ion-icon>
  </button>
</div>
