import { Component, OnInit, ViewChild } from '@angular/core';

import { NgxCaptureService } from 'ngx-capture';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css'],
})
export class AttendanceComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
   
  }

  
}
