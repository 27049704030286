<div class="e-class-search" *ngIf="showFilter || !isMobile" [class.show]="showFilter" @showHide>
  <span class="e-class-search__backdrop" (click)="onToggleShow.emit()"></span>

  <div class="e-class-search_container">
    <!-- search header -->
    <header class="e-class-search_header">
      <h3 class="e-class-search__title">{{ 'EVENTS_PAGE.HEADER_TITLE_SEARCH' | translate }}</h3>
      <button class="e-class-search__action" [class.active]="showFilter" (click)="onToggleShow.emit()">
        <ion-icon class="e-mobile" name="close-circle-outline"></ion-icon>
        <ion-icon class="e-desktop" [name]="showFilter ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
      </button>
    </header>

    <!-- Search body -->
    <form
      [formGroup]="filterForm"
      class="e-class-search_form e-fieldset"
      *ngIf="showFilter"
      @showHide
      [class.show]="showFilter">
      <div class="c-field e-class-search__field">
        <app-datepicker
          labelClass="c-field__label"
          inputClass="c-field__input e-class-search__input u-margin-bottom--none"
          [calendarLabel]="'EVENTS_PAGE.TIME_PERIOD_FIELD_LABEL' | translate"
          [calendarPlaceholder]="'EVENTS_PAGE.TIME_PERIOD_FIELD_PLACEHOLDER' | translate"
          [startDate]="filterForm.value?.startDate"
          [endDate]="filterForm.value?.endDate"
          variant="range"
          [clearDate]="true"
          (OnDate)="onDateChange($event)"
          (onClear)="onDateClear()"></app-datepicker>
      </div>

      <app-field-select
        formControlName="program"
        class="e-class-search__field"
        [options]="programsList"
        [label]="'EVENTS_PAGE.PROGRAM_FIELD' | translate"
        [placeholder]="'EVENTS_PAGE.PROGRAM_FIELD_PLACEHOLDER' | translate"></app-field-select>

      <app-field-select
        formControlName="coach"
        class="e-class-search__field"
        [options]="coachList"
        [label]="'EVENTS_PAGE.COACH_FIELD' | translate"
        [placeholder]="'EVENTS_PAGE.COACH_FIELD_PLACEHOLDER' | translate"></app-field-select>

      <app-field-input
        formControlName="name"
        type="text"
        class="u-flex--full"
        [clearButton]="true"
        [label]="'EVENTS_PAGE.NAME_FIELD' | translate"
        [placeholder]="'EVENTS_PAGE.NAME_FIELD_PLACEHOLDER' | translate"
        (keyup)="onKeyup($event)"></app-field-input>

      <footer class="e-class-search_footer">
        <app-button class="u-width--100" variant="secondary" (click)="clearFilters()">
          {{ 'Clear' | translate }}
        </app-button>
        <app-button class="u-width--100 e-mobile" variant="primary" icon="search" (click)="onMobileFilterButton()">
          {{ 'Search' | translate }}
        </app-button>
      </footer>
    </form>
  </div>
</div>
