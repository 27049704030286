import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';

@Component({
  selector: 'app-add-staffpositions',
  templateUrl: './add-staffpositions.component.html',
  styleUrls: ['./add-staffpositions.component.css'],
})
export class AddStaffpositionsComponent implements OnInit {
  positionsForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot.data['message'];
    if (this.routerid) {
      this.positionsForm = this.formBuilder.group({
        position: [this.data.positions, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      });
    } else {
      this.positionsForm = this.formBuilder.group({
        position: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      });
    }
  }
  get f() {
    return this.positionsForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.positionsForm.invalid) {
      return;
    }
    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.addposition.path, this.positionsForm.value).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Position added Successfully'));
          this.router.navigate(['/superadmin/positions']);
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updateposition.path + '?id=' + this.routerid,
        this.positionsForm.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Position updated successfully'));
          this.router.navigate(['/superadmin/positions']);
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    }
  }
}
