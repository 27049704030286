import { Component, EventEmitter, Input, OnInit, Output, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { RadioGroupItem } from './radio-group.helper';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnInit, ControlValueAccessor {
  @Input() label: string | undefined;

  @Input() actionLabel: string | undefined;

  @Input() actionIcon: string = 'add';

  @Input() helperText: string | undefined;

  @Input() isRequired: boolean = false;

  @Input() isMultiple: boolean = false;

  @Input() value: string | string[] | undefined;

  @Input() options: RadioGroupItem[] = [];

  @Output() onSelect: EventEmitter<string | string[]> = new EventEmitter();

  @Output() onAction = new EventEmitter();

  onChange: (_: any) => void = () => {};
  onTouched: () => void = () => {};

  get isDisabled() {
    return this.ngControl.control?.disabled;
  }

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  writeValue(value: string): void {
    this.value = value;
    this.handleCheckedOptions();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateChanges() {
    this.onChange(this.value);
  }

  ngOnInit(): void {
    const { control } = this.ngControl;

    if (!control) return;

    let validators = this.getValidators();
    validators = control?.validator ? [control.validator, ...validators] : this.getValidators();

    control.setValidators(validators);
    control.updateValueAndValidity();

    this.handleCheckedOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    // update list and selected items
    if (changes.options?.currentValue || changes.selected?.currentValue !== changes.selected?.previousValue) {
      this.handleCheckedOptions();
    }

    // convert selected prop to string od array
    if (changes.isMultiple?.currentValue) {
      if (typeof this.value === 'string') {
        this.value = [this.value] as string[];
      }
    } else {
      if (typeof this.value === 'object') {
        this.value.toString();
      }
    }
  }

  handleCheckedOptions() {
    if (!this.options?.length) return;

    this.options = this.options.map((item) => {
      if (this.isMultiple) {
        if (this.value?.includes(item.id)) {
          return { ...item, checked: true };
        }
        return item;
      } else {
        return { ...item, checked: item.id === this.value };
      }
    });
  }

  onOptionSelect(selectedOption: RadioGroupItem) {
    if (this.isDisabled) return;

    if (this.isMultiple) {
      // Multiple answers accepted
      let selectedArray = (this.value as string[]) || [];
      const hasId = this.value?.includes(selectedOption.id) || false;

      if (!selectedArray?.length || !hasId) {
        selectedArray = [...selectedArray, selectedOption.id];
        selectedOption.checked = true;
      } else {
        selectedArray = selectedArray?.filter((el: string) => el !== selectedOption.id);
        selectedOption.checked = false;
      }
      this.value = selectedArray;
    } else {
      // Only one answer accepted
      if (selectedOption.id === this.value) {
        this.value = undefined;
        selectedOption.checked = false;
      } else {
        this.value = selectedOption.id;
        selectedOption.checked = true;
      }
      this.handleCheckedOptions();
    }

    this.markAsTouchedAndDirty();
    this.updateChanges();
  }

  markAsTouchedAndDirty() {
    this.ngControl.control?.markAsTouched();
    this.ngControl.control?.markAsDirty();
  }

  private getValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    return validators;
  }
}
