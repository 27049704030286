export const endpoints = {
  /*
   Registration endpoints
   */

  logIn: { path: 'login', method: 'POST' },
  forgotPassword: { path: 'forgotpassword', method: 'POST' },
  resetPassword: { path: ' reset', method: 'POST' },
  setting: { path: 'settings/list', method: 'GET' },
  changePassword: { path: 'change', method: 'POST' },
  verifiedSchool: { path: 'school/add_accountDetails', method: 'POST' },

  register: { path: 'admin/auth/user', method: 'POST' },

  accounttoken: { path: 'payment/createAccount', method: 'POST' },

  // Admin module

  recurrence: { path: 'admin/events/create', methog: 'POST' },

  dashboard: { path: 'admin/dashboard/income', method: 'GET' },
  editprofile: { path: 'admin/profile/edit', method: 'GET' },
  updateprofile: { path: 'admin/profile/update', method: 'POST' },

  stafflist: { path: 'admin/staff/all', method: 'GET' },
  addstaff: { path: 'admin/staff/create', method: 'POST' },
  editstaff: { path: 'admin/staff/edit', method: 'GET' },
  updatestaff: { path: 'admin/staff/update', method: 'POST' },
  deletestaff: { path: 'admin/staff/delete', method: 'GET' },
  activestaff: { path: 'admin/staff/activate', method: 'GET' },

  editschool: { path: 'admin/school/edit', method: 'GET' },
  updateschool: { path: 'admin/school/update', method: 'POST' },
  autoRenew: { path: 'admin/school/auto-renew', method: 'POST' },

  classlist: { path: 'admin/class/all', method: 'GET' },
  addclass: { path: 'admin/class/create', method: 'POST' },
  editclass: { path: 'admin/class/edit', method: 'GET' },
  updateclass: { path: 'admin/class/update', method: 'POST' },
  deleteclass: { path: 'admin/class/delete', method: 'GET' },
  activeclass: { path: 'admin/class/activate', method: 'GET' },
  listassignclass: { path: 'admin/class/listtoassignclasses', method: 'GET' },
  addassignclass: { path: 'admin/class/assignclasses', method: 'POST' },
  assignclass: { path: 'assigned-class/list', method: 'GET' },

  coachlist: { path: 'admin/coach/all', method: 'GET' },
  addcoach: { path: 'admin/coach/create', method: 'POST' },
  editcoach: { path: 'admin/coach/edit', method: 'GET' },
  updatecoach: { path: 'admin/coach/update', method: 'POST' },
  deletecoach: { path: 'admin/coach/delete', method: 'GET' },
  activecoach: { path: 'admin/coach/activate', method: 'GET' },
  coachview: { path: 'admin/coach/coach', method: 'GET' },

  studentlist: { path: 'admin/student/all', method: 'GET' },
  addstudent: { path: 'admin/student/create', method: 'POST' },
  editstudent: { path: 'admin/student/edit', method: 'GET' },
  updatestudent: { path: 'admin/student/update', method: 'POST' },
  deletestudent: { path: 'admin/student/delete', method: 'GET' },
  activestudent: { path: 'admin/student/activate', method: 'GET' },
  studenview: { path: 'admin/student/student', method: 'GET' },
  pauseMembership: { path: 'admin/student/pause-membership', method: 'POST' },
  resumeMembership: { path: 'admin/student/resume-membership', method: 'POST' },
  patchStudent: { path: 'admin/student', method: 'PATCH' },

  eventlist: { path: 'admin/events/all', method: 'GET' },
  addevent: { path: 'admin/events/create', method: 'POST' },
  editevent: { path: 'admin/events/edit', method: 'GET' },
  updateevent: { path: 'admin/events/update', method: 'POST' },
  deleteevent: { path: 'admin/events/delete', method: 'GET' },
  activeevent: { path: 'admin/events/activate', method: 'GET' },
  eventdetails: { path: 'kisok/details', method: 'GET' },

  addadminpackages: { path: 'admin/package/create', mettod: 'POST' },
  packagesadminlist: { path: 'admin/package/all', method: 'GET' },
  editadminpackages: { path: 'admin/package/edit', method: 'GET' },
  updateadminpackages: { path: 'admin/package/update', method: 'POST' },
  deleteadminpackages: { path: 'admin/package/delete', method: 'GET' },
  activateadminpackages: { path: 'admin/package/activate', method: 'GET' },

  belthistory: { path: 'admin/belt/belt-history', method: 'GET' },
  addbelt: { path: 'admin/belt/create', mettod: 'POST' },
  beltlist: { path: 'admin/belt/all', method: 'GET' },
  editbelt: { path: 'admin/belt/edit', method: 'GET' },
  updatebelt: { path: 'admin/belt/update', method: 'POST' },
  deletebelt: { path: 'admin/belt/delete', method: 'GET' },
  activatebelt: { path: 'admin/belt/activate', method: 'GET' },

  withdrawpayment: { path: 'payment/withdraw', method: 'POST' },
  withdrawpaymenlist: { path: 'payment/coachpaymentlist', method: 'GET' },

  //SUPER ADMIN

  superadmindashbnoard: { path: 'superadmin/dashboard', method: 'GET' },

  superadminprofile: { path: 'superadmin/profile/edit', method: 'GET' },
  updatesuperadminprofile: { path: 'superadmin/profile/update', method: 'POST' },

  listuserprofile: { path: 'superadmin/user/all', method: 'GET' },
  adduserprofile: { path: 'superadmin/user/create', method: 'POST' },
  edituserprofile: { path: 'superadmin/user/edit', method: 'GET' },
  updateuserprofile: { path: 'superadmin/user/update', method: 'POST' },
  deleteuserprofile: { path: 'superadmin/user/delete', method: 'GET' },
  activateuserprofile: { path: 'superadmin/user/activate', method: 'GET' },

  editseo: { path: 'superadmin/seo/edit', method: 'GET' },
  updateseo: { path: 'superadmin/seo/update', method: 'POST' },

  schoolList: { path: 'superadmin/school/all', method: 'GET' },
  addSchool: { path: 'superadmin/school/create', mettod: 'POST' },
  editSchool: { path: 'superadmin/school/edit', method: 'GET' },
  updateSchool: { path: 'superadmin/school/update', method: 'POST' },
  deleteSchool: { path: 'superadmin/school/delete', method: 'GET' },
  activateSchool: { path: 'superadmin/school/activate', method: 'GET' },
  viewschool: { path: 'superadmin/school/view', method: 'GET' },
  viewschoolcoach: { path: 'superadmin/school/coach', method: 'GET' },

  staffpositionList: { path: 'superadmin/staff-position/all', method: 'GET' },
  addposition: { path: 'superadmin/staff-position/create', mettod: 'POST' },
  editposition: { path: 'superadmin/staff-position/edit', method: 'GET' },
  updateposition: { path: 'superadmin/staff-position/update', method: 'POST' },
  deleteposition: { path: 'superadmin/staff-position/delete', method: 'GET' },

  rolesList: { path: 'superadmin/role/all', method: 'GET' },
  addroles: { path: 'superadmin/role/create', mettod: 'POST' },
  editroles: { path: 'superadmin/role/edit', method: 'GET' },
  updateroles: { path: 'superadmin/role/update', method: 'POST' },
  deleteroles: { path: 'superadmin/role/delete', method: 'GET' },

  settingclasslist: { path: 'superadmin/class/all', method: 'GET' },
  addsettingclass: { path: 'superadmin/class/create', mettod: 'POST' },
  editsettingclass: { path: 'superadmin/class/edit', method: 'GET' },
  updatesettingclasss: { path: 'superadmin/class/update', method: 'POST' },
  deletesettingclass: { path: 'superadmin/class/delete', method: 'GET' },
  activatesettingclass: { path: 'superadmin/class/activate', method: 'GET' },

  packageslist: { path: 'superadmin/package/all', method: 'GET' },
  addpackages: { path: 'superadmin/package/create', mettod: 'POST' },
  editpackages: { path: 'superadmin/package/edit', method: 'GET' },
  updatepackages: { path: 'superadmin/package/update', method: 'POST' },
  deletepackages: { path: 'superadmin/package/delete', method: 'GET' },
  activatepackages: { path: 'superadmin/package/activate', method: 'GET' },

  //student module

  studentdashboard: { path: 'student/dashboard', method: 'GET' },

  eventfilter: { path: 'student/class/filter', mettod: 'GET' },
  editstudentprofile: { path: 'student/profile/edit', method: 'GET' },
  updatestudentprofile: { path: 'student/profile/update', method: 'POST' },
  listbelt: { path: 'student/beltlist', method: 'GET' },
  listpackage: { path: 'student/packages', method: 'GET' },

  studenteventlist: { path: 'student/eventlist', method: 'GET' },
  studenteventdetails: { path: 'student/details', method: 'GET' },
  eventregistration: { path: 'student/registration', method: 'POST' },
  eventcheckedin: { path: 'student/checkin', method: 'POST' },
  listnotes: { path: 'list', method: 'GET' },

  studentbelt: { path: 'student/beltlist', method: 'GET' },
  studentbelthistory: { path: 'student/profile/belt-list', method: 'GET' },

  //Coach module

  coachdashboard: { path: 'coach/dashboard', method: 'GET' },

  editcoachprofile: { path: 'coach/profile/edit', method: 'GET' },
  updatecoachprofile: { path: 'coach/profile/update', method: 'POST' },
  coacheventdetails: { path: 'coach/details', method: 'GET' },
  coacheventlist: { path: 'coach/eventlist', method: 'GET' },

  createnotes: { path: 'createnotes', method: 'POST' },

  coachbelt: { path: 'coach/list', method: 'GET' },

  coachpayment: { path: 'payment/listPayment', method: 'GET' },

  //Message
  listusers: { path: 'users', mettod: 'GET' },
  createmessage: { path: 'create', mettod: 'POST' },
  msglist: { path: 'all', mettod: 'GET' },
  rlymsglist: { path: 'allReply', mettod: 'GET' },
  rlymessage: { path: 'reply', mettod: 'POST' },

  //payment
  listcard: { path: 'payment/listcard', mettod: 'GET' },
  addcard: { path: 'payment/addcard', methos: 'POST' },
  addpayment: { path: 'payment/createpayment', methos: 'POST' },
  listpayment: { path: 'payment/paymentlist', mettod: 'GET' },
  regaddCard: { path: 'school-payment/addcard', methos: 'POST' },
  regaddpayment: { path: 'school-payment/createpayment', methos: 'POST' },
  reglistcard: { path: 'school-payment/cardList', mettod: 'GET' },
  deleteCard: { path: 'payment/deletecard', methos: 'POST' },

  //Attendance
  attendance: { path: 'student/attendance/attendance', method: 'GET' },
  coachattendance: { path: 'coach/attendance/attendance', method: 'GET' },

  //Notification
  listnotify: { path: 'notification/list', mettod: 'GET' },
  deletenotify: { path: 'notification/notify-delete', mettod: 'DELETE' },
  latestnotify: { path: 'notification/latest', mettod: 'GET' },
  unreadnotify: { path: 'notification/unreadlist', mettod: 'GET' },
  latestMessage: { path: 'latest-message', mettod: 'GET' },

  //VideoSection
  listVideoSection: { path: 'videoSection/all', mettod: 'GET' },
  createVideoSection: { path: 'videoSection/create', method: 'POST' },
  editVideoSection: { path: 'videoSection/edit', mettod: 'GET' },
  updateVideoSection: { path: 'videoSection/update', method: 'POST' },
  deleteVideoSection: { path: 'videoSection/delete', mettod: 'GET' },
  activeVideoSection: { path: 'videoSection/activate', mettod: 'GET' },

  //VideoUpload
  listVideoUpload: { path: 'videoLibrary/all', mettod: 'GET' },
  createVideoUpload: { path: 'videoLibrary/create', method: 'POST' },
  editVideoUpload: { path: 'videoLibrary/edit', mettod: 'GET' },
  removeVideoCategory: { path: 'videoSection/remove-category', method: 'GET' },
  removeVideo: { path: 'videoSection/remove-video', mettod: 'GET' },
  singleVideoUpload: { path: 'videoLibrary/single-upload', method: 'POST' },
  singleVideoUpdate: { path: 'videoLibrary/video-update', method: 'POST' },

  superAdminCategory: { path: 'videoSection/superadmin-category', mettod: 'GET' },
  AdminCategory: { path: 'videoSection/admin-category', mettod: 'GET' },

  //Stripe connect
  stripeConnectOnboarding: { path: 'stripe-account-connect', method: 'POST' },
  stripeExpressDashboard: { path: 'payment/stripe-express-dashboard', method: 'POST' },
};
