import { RouterModule, Routes } from '@angular/router';

import { AssignClassResolver } from 'src/app/shared/services/assignclass.resolver';
import { AttendanceComponent } from './attendance/attendance.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CoachAuthguard } from '../../shared/services/coach-authguard.guard';
import { CoachProfileResolver } from 'src/app/shared/services/coachprofile.resolver';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MessagesComponent } from './message/message.component';
import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { SettingResolver } from 'src/app/shared/services/setting.resolver';
import { SettingsComponent } from './settings/settings.component';
import { ViewScheduleComponent } from './calendar/view-schedule/view-schedule.component';
import { CoachViewScheduleResolver } from 'src/app/shared/services/coachviewschedule.resolver';
import { VideoLibraryComponent } from './video-library/video-library.component';

const routes: Routes = [
  {
    path: 'coach',
    resolve: { message: SettingResolver, class: AssignClassResolver },
    canActivate: [CoachAuthguard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
      {
        path: 'schedule',
        component: CalendarComponent,
      },
      {
        path: 'schedule/view/:id',
        component: ViewScheduleComponent,
        resolve: { message: CoachViewScheduleResolver },
      },
      {
        path: 'video-library',
        component: VideoLibraryComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        resolve: { message: CoachProfileResolver },
      },
      {
        path: 'attendance',
        component: AttendanceComponent,
      },
      {
        path: 'payments',
        component: PaymentsComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'report',
        component: ReportComponent,
      },
      {
        path: 'message',
        component: MessagesComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoachRoutingModule {
  static components = [DashboardComponent];
}
