import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StudentService } from '../student/state/student.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent | any;

  rangeForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  student: any = [];
  listpayment: any = [];
  currentUser: any;
  currency: string = 'USD';

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService,
    private studentService: StudentService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.studentService.getStudents({ active: true, per_page: 100 }).subscribe((students) => {
      this.student = students.data;
    });
    // this.paymentList()
    this.rangeForm = this.formBuilder.group({
      start_time: [''],
      end_time: [''],
      student: [null],
    });
  }
  get f() {
    return this.rangeForm.controls;
  }
  paymentList() {
    this.Apiservice.getApi(endpoints.listpayment.path).subscribe((data: any) => {
      this.listpayment = data.response.payments;
    });
  }
  private convert(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
  OnSelect(event: any) {
    if (event?.startDate) {
      let startdate = this.convert(event.startDate._d);
      let enddate = this.convert(event.endDate._d);
      this.rangeForm.controls['start_time'].setValue(startdate);
      this.rangeForm.controls['end_time'].setValue(enddate);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (
      (this.rangeForm.value.start_time == '' && this.rangeForm.value.student == null) ||
      (this.rangeForm.value.start_time == null && this.rangeForm.value.student == null)
    ) {
      this.toaster.error(this.translate.instant('Select a from and to date or student to submit'));
      return;
    }
    if (this.rangeForm.value.student == null) {
      this.rangeForm.controls['student'].setValue('');
    }
    this.Apiservice.getApi(
      endpoints.listpayment.path +
        '?start_time=' +
        this.rangeForm.value.start_time +
        '&end_time=' +
        this.rangeForm.value.end_time +
        '&user_id=' +
        this.rangeForm.value.student
    ).subscribe(
      (data: any) => {
        const userDataString = localStorage.getItem('currentUser');
        if (userDataString) {
          this.currentUser = JSON.parse(userDataString);
          this.currency = this.currentUser.school.default_currency.toUpperCase();
        }

        this.listpayment = data.response.payments;
      },
      (err: any) => {}
    );
  }
  clear() {
    this.daterangepicker.clear();
    this.rangeForm.reset();
    this.ngSelectComponent.handleClearClick();
    this.paymentList();
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
