<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 *ngIf="!this.routerid" translate>Add Staff</h2>
                    <h2 *ngIf="this.routerid" translate>Edit Staff</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <a class="btn-sm auth-button " [routerLink]="['/admin/staff']" translate><i
                                class="fas fa-bars mr-1"></i>All</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="staffForm" (ngSubmit)="onSubmit()">
                        <div class="body text-center">
                            <label for="profileImage">
                                <img src="assets/images/group.svg" class="rounded-circle"
                                    id="profilePicPreview"
                                    onerror="this.onerror=null;this.src='assets/images/img_avatar.png';"
                                    alt="" />
                            </label>
                            <input type="file" accept="image/*" id="profileImage" name="photo"
                                style="display: none" (change)="onFileSelected($event)">
                        </div>
                        
                        <div class="form-group ">
                            <label class="mt-1" translate>First Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'First Name'| translate}}"
                                formControlName="first_name" minlength="2" maxlength="64"
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required" translate>First Name is required</div>
                                <div *ngIf="f.first_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Middle Name</label>
                            <input type="text" class="form-control" placeholder="{{'Middle Name'| translate}}"
                                formControlName="middle_name" maxlength="64"
                                [ngClass]="{ 'is-invalid': submitted && f. middle_name.errors }" />
                            <div *ngIf="submitted && f.middle_name.errors" class="invalid-feedback">
                                <div *ngIf="f.middle_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="mt-1" translate>Last Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Last Name'| translate}}"
                                formControlName="last_name" maxlength="64"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required" translate>Last Name is required</div>
                                <div *ngIf="f.last_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate>Gender<span class="text-danger">*</span></label>
                            <ng-select placeholder="{{'Select Gender' | translate}}" formControlName="gender"
                                [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                <ng-option value="male">Male
                                </ng-option>
                                <ng-option value="female">Female
                                </ng-option>
                                <ng-option value="others">Others
                                </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                <div *ngIf="f.gender.errors.required" translate>Gender is required</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Email<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Email'| translate}}" maxlength="64"
                                formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required" translate>Email is required</div>
                                <div *ngIf="f.email.errors.pattern" translate>Enter valid email</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1 texinput" translate>Phone Number<span
                                    class="text-danger">*</span></label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <ng-select formControlName="phone" (change)="conutyphone($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                        <ng-option *ngFor="let status of countryCodeList" [value]="status">
                                            {{status.code}}
                                        </ng-option>
                                    </ng-select>
                                </div>
                                <input type="text" placeholder="(00) 00000-0000"  mask=" {{minDigit}} || {{maxDigit}}" [validation]="false" class="form-control"
                                    formControlName="phonenumber"
                                    [ngClass]="{'is-invalid': submitted && f.phonenumber.errors}"
                                    >
                                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phonenumber.errors.required" translate>
                                        Phone Number is required
                                    </div>
                                    <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile should be 10 character long
                                    </div>
                                    <div *ngIf="f.phonenumber.errors.pattern">Only Numbers are allowed</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label translate>Position<span class="text-danger">*</span></label>
                            <ng-select placeholder="{{'Select Position'| translate}}" formControlName="position"
                                [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
                                <ng-option *ngFor="let status of position" [value]="status.id">
                                    {{status.positions}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                                <div *ngIf="f.position.errors.required" translate>Position is required</div>
                            </div>
                        </div>
                        <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="staffForm">
                        </app-country-state>
                        <div class="form-group ">
                            <label class="mt-1" translate>Address<span class="text-danger">*</span></label>
                            <textarea type="textarea" class="form-control" placeholder="{{'Address'| translate}}" maxlength="250"
                                formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                cols="30" rows="10"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required" translate>Address is required</div>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="!this.routerid"
                            translate>Save</button>
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="this.routerid"
                            translate>Update</button>
                        <a [routerLink]="['/admin/staff']" class="btn btn-dark float-right mr-2 mt-4 text-white"
                            translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>