<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>SEO Settings</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <!-- <a class="btn-sm auth-button " [routerLink]="['/superadmin/school']"><i
                                class="fas fa-bars mr-1"></i>All</a> -->
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="schoolForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="form-group ">
                                        <label translate>Logo</label>
                                        <input type="file" name="logo" class="form-control " accept="image/png,image/jpeg"
                                            (change)="LogoSelected($event)" />
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <img src="{{this.data.logo}}" id="imagePreview" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="form-group ">
                                        <label translate>Favicon</label>
                                        <input type="file" name="fav_icon" class="form-control " accept="image/png,image/jpeg"
                                            (change)="faviconSelected($event)" />
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <img src="{{this.data.fav_icon}}" id="imagePreview" />
                                </div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Title<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Title'| translate}}"
                                formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                            <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                <div *ngIf="f.title.errors.required" translate>Title is required</div>
                                <div *ngIf="f.title.errors.pattern" translate>Only
                                    Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Meta Tag<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Meta Data'| translate}}"
                                formControlName="meta_tag"
                                [ngClass]="{ 'is-invalid': submitted && f.meta_tag.errors }" />
                            <div *ngIf="submitted && f.meta_tag.errors" class="invalid-feedback">
                                <div *ngIf="f.meta_tag.errors.required" translate>Meta tag is required</div>
                                <div *ngIf="f.meta_tag.errors.pattern" translate>Only
                                    Letters are allowed</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate>Description</label>
                            <textarea class="form-control" formControlName="description"></textarea>
                        </div>
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4 mr-2"
                            translate>Update</button>
                        <a [routerLink]="['/superadmin/seosettings']"
                            class="btn btn-dark float-right mr-2 mt-4 text-white" translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>