<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <ul class="nav nav-pills" id="nav-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                    aria-controls="pills-home" aria-selected="true" translate><i
                        class="fas fa-calendar mr-2"></i>Events</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                    aria-controls="pills-profile" aria-selected="false" translate><i
                        class="fas fa-money-check-alt mr-2"></i>Payments</a>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
                    <div class="row for-calender">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                            <app-datepicker></app-datepicker>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-3 col-4">
                            <button class="btn-sm auth-button submit-newbtn new-btnclass" translate>Submit</button>
                        </div>
                    </div>
                </form>
                <div class="block-header">
                    <div class="row clearfix">
                        <div class="card">
                            <div class="col-md-12" id="content" #content>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <span class="d-flex">{{ 'Show' | translate}}
                                                <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                                                    aria-controls="selection-datatable"
                                                    class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                    <option *ngFor="let pageCountObj of pagecounts"
                                                        [value]="pageCountObj">
                                                        {{pageCountObj}}</option>
                                                </select> {{ 'entries' | translate}}
                                            </span>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="input-group">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'Search'|translate}}" [(ngModel)]="searchText"
                                                    name="searchText">
                                                <div class="input-group-append" *ngIf="searchText">
                                                    <span class="input-group-text" id="search-mail"
                                                        (click)="resetsearch()" translate>Clear</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th translate>Name</th>
                                                <th translate>Coach Name</th>
                                                <th translate>Start Time</th>
                                                <th translate>End Time</th>
                                                <th translate>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <form [formGroup]="studentform" (ngSubmit)="onstudentSubmit()">
                    <div class="row for-calender">
                        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                            <app-datepicker></app-datepicker>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-8">
                            <label translate>Student<span class="text-danger">*</span></label>
                            <ng-select placeholder="{{'Select Student'| translate}}" formControlName="studentname"
                                [ngClass]="{ 'is-invalid': submitte && ff.studentname.errors }">
                                <ng-option *ngFor="let status of studentname" [value]="status.id">
                                    {{status.first_name}} {{status.middle_name}} {{status.last_name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitte && ff.studentname.errors" class="invalid-feedback">
                                <div *ngIf="ff.studentname.errors.required" translate>Student is required</div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-3 col-4">
                            <button class="btn-sm auth-button submit-newbtn new-btnclass" translate>Submit</button>
                        </div>
                    </div>
                </form>
                <div class="block-header">
                    <div class="row clearfix">
                        <div class="card">
                            <div class="col-md-12" id="content" #content>
                                <div class="body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <span class="d-flex">{{ 'Show' | translate}}
                                                <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                                                    aria-controls="selection-datatable"
                                                    class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                    <option *ngFor="let pageCountObj of pagecounts"
                                                        [value]="pageCountObj">
                                                        {{pageCountObj}}</option>
                                                </select> {{ 'entries' | translate}}
                                            </span>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="input-group">
                                                <input type="text" class="form-control" [(ngModel)]="searchText"
                                                    name="searchText">
                                                <div class="input-group-append" *ngIf="searchText">
                                                    <span class="input-group-text" id="search-mail"
                                                        (click)="resetsearch()" translate>Clear</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th translate>Amount</th>
                                                <th translate>Date</th>
                                                <th translate>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>School</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>