import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SecureInnerPagesGuard } from './../../shared/guard/SecureInnerPagesGuard.guard';
import { SchoolRegisterComponent } from './school-register/school-register.component';

const routes: Routes = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Log In' },
      },
      {
        path: 'forgotPassword',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' },
      },
      {
        path: '',
        component: LoginComponent,
        data: { title: 'login' },
      },
      {
        path: 'resetPassword',
        component: ResetPasswordComponent,
        data: { title: 'Reset Password' },
      },
      {
        path: 'school-register',
        component:SchoolRegisterComponent,
        data: { title: 'School Register'}
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {
  static components = [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ];
}
