import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  rangeForm: FormGroup | any;
  studentform: FormGroup | any;
  messageForm: FormGroup | any;
  isTouchUIActivated = false;
  submitted = false;
  submitte = false;
  submitt = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  selectedFile: File | any = null;
  name: any;
  securityForm: FormGroup | any;
  userForm: FormGroup | any;
  settings: any;
  events: any;
  coaches: any;
  studentname: any;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    this.Apiservice.getApi(endpoints.studentlist.path).subscribe((data: any) => {
      this.studentname = data.filter((s: any) => s.status == 1);
    });

    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
    });
    this.studentform = this.formBuilder.group({
      start: [''],
      end: [''],
    });
    this.messageForm = this.formBuilder.group({
      start: [''],
      end: [''],
      studentname: [null, Validators.required],
      coach: [null, Validators.required],
    });

    this.data = this.route.snapshot.data?.message?.data;
  }

  get f() {
    return this.rangeForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('photo', this.rangeForm.get('photo').value);
    let formfile = ['name', 'email', 'phone', 'address'];
    formfile.forEach((name: string) => {
      formData.append(name, this.rangeForm.get(name).value);
    });
    this.Apiservice.postApi(endpoints.updatesuperadminprofile.path, formData).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Superadmin profile updated Successfully'));
        this.router.navigate(['/superadmin/dashboard']);
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Profile'));
      }
    );
  }
  get ff() {
    return this.studentform.controls;
  }
  onstudentSubmit() {
    this.submitte = true;
    if (this.studentform.invalid) {
      return;
    }
  }
  get fff() {
    return this.messageForm.controls;
  }

  onmessageSubmit() {
    this.submitt = true;
    if (this.messageForm.invalid) {
      return;
    }
  }

  resetsearch() {
    this.searchText = '';
  }

  pageChange(event: any) {
    this.page = event;
  }
}
