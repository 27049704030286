import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;

    return items.filter((item) => {
      return Object.keys(item).some((key) => {
        if (
          item.name &&
          item.email &&
          item.phone &&
          item.position &&
          item.address
        ) {
          return (
            String(item[key])
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase()) ||
            item.phone.toLowerCase().includes(searchText.toLowerCase()) ||
            item.position.positions
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item.address.toLowerCase().includes(searchText.toLowerCase())
          );
        } else if (
          item.age &&
          item.name &&
          item.email &&
          item.phone &&
          item.address
        ) {
          return (
            (String(item[key])
              .toLowerCase()
              .includes(searchText.toLowerCase()) &&
              item.age.toLowerCase().includes(searchText.toLowerCase())) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            item.email.toLowerCase().includes(searchText.toLowerCase()) ||
            item.phone.toLowerCase().includes(searchText.toLowerCase()) ||
            item.address.toLowerCase().includes(searchText.toLowerCase())
          );
        } else {
          return String(item[key])
            .toLowerCase()
            .includes(searchText.toLowerCase());
        }
      });
    });
  }
}
