import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { Belt } from '../../admin.interface';

@Component({
  selector: 'app-add-belt',
  templateUrl: './add-belt.component.html',
  styleUrls: ['./add-belt.component.scss'],
})
export class AddBeltComponent implements OnInit {
  packageform: FormGroup | any;
  loading = false;
  data: any;
  beltid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private adminService: AdminService,
    public toaster: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.beltid = this.route.params;
    this.routerid = this.beltid._value.id;
    this.data = this.route.snapshot.data?.message?.data;

    if (this.routerid && this.data) {
      this.packageform = this.formBuilder.group({
        name: [this.data.name, [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        minimum_attendance: [this.data.minimum_attendance, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        order: [this.data.order, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      });
    } else {
      this.packageform = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern("^[a-zA-Z -']+")]],
        minimum_attendance: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
        order: [null, [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      });
    }
  }

  onSubmit() {
    if (this.packageform.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      for (const key in this.packageform.controls) {
        this.packageform.controls[key].markAsDirty();
        this.packageform.controls[key].markAsTouched();
      }
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    // only submit data that was changed by the user
    const requestData: Partial<Belt> = {};
    for (const control in this.packageform.controls) {
      if (this.routerid && !this.packageform.controls[control].touched) {
        continue;
      }
      requestData[control as keyof Belt] = this.packageform.controls[control].value;
    }

    if (!this.routerid) {
      this.adminService.postBelt(requestData).subscribe({
        next: () => {
          this.loading = false;
          this.packageform.reset();
          this.toaster.success(this.translate.instant('Belt added Successfully'));
          this.router.navigate(['/admin/belt']);
        },
        error: (err: any) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    } else {
      this.adminService.patchBelt(this.routerid, requestData).subscribe({
        next: () => {
          this.loading = false;
          this.packageform.reset();
          this.toaster.success(this.translate.instant('Belt updated successfully'));
          this.router.navigate(['/admin/belt']);
        },
        error: (err: any) => {
          this.loading = false;
          this.toaster.error(err.error.message || err.error);
        },
      });
    }
  }
}
