<div id="left-sidebar" class="sidebar light_active">
  <button type="button" (click)="cToggoleMenu()" class="btn-toggle-offcanvas btn btn-sm float-right p-3">
    <i class="fas fa-times"></i>
  </button>
  <div class="navbar-brand">
    <a><img src="../../../../assets/img/tatami-logo.png" width="50" alt="" class="img-fluid logo" /> </a>
  </div>
  <div class="user-account profilepointer" [routerLink]="['/student/profile']">
    <div class="user_div">
      <img src="assets/images/user.png" class="user-photo" alt="User Profile Picture" />
    </div>
    <div class="dropdown">
      <span translate>SIDE_MENU.PROFILE</span>
      <a href="javascript:void(0);" class="user-name"><strong translate>SIDE_MENU.STUDENT</strong></a>
    </div>
  </div>
  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul id="main-menu" class="metismenu animation-li-delay">
      <li>
        <a [routerLink]="['/student/dashboard']" [routerLinkActive]="'active'"
          ><i class="fas fa-desktop"></i><span translate>SIDE_MENU.DASHBOARD</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/schedule']" [routerLinkActive]="'active'"
          ><i class="fas fa-calendar"></i><span translate>SIDE_MENU.CLASS</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/video-library']" [routerLinkActive]="'active'"
          ><i class="fas fa-video"></i><span translate>SIDE_MENU.VIDEO_LIBRARY</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/attendance']" [routerLinkActive]="'active'"
          ><i class="fas fa-digital-tachograph"></i><span translate>SIDE_MENU.ATTENDANCE</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/payment']" [routerLinkActive]="'active'"
          ><i class="fas fa-credit-card"></i><span translate>SIDE_MENU.PAYMENTS</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/messages']" [routerLinkActive]="'active'"
          ><i class="fas fa-comment-alt"></i><span translate>SIDE_MENU.MESSAGES</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/notification']" [routerLinkActive]="'active'"
          ><i class="fas fa-bell"></i><span translate>SIDE_MENU.NOTIFICATION</span></a
        >
      </li>
      <li>
        <a [routerLink]="['/student/settings']" [routerLinkActive]="'active'"
          ><i class="fas fa-cog"></i><span translate>SIDE_MENU.SETTINGS</span></a
        >
      </li>
    </ul>
  </nav>
</div>
