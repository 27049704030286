<button
  [type]="isSubmit ? 'submit' : 'button'"
  class="c-btn c-btn--{{ variant }}"
  [class.c-btn--lg]="size === 'lg'"
  [class.c-btn--sm]="size === 'sm'"
  [class.c-btn--icon-only]="iconOnly"
  [class.c-btn--disabled]="disabled"
  [disabled]="disabled && !disabledStyleOnly">
  <ion-icon
    [class.c-btn__icon--desktop-only]="hideIconOnMobile"
    class="c-btn__icon"
    *ngIf="!loading && icon && !iconAfterLabel"
    [name]="icon"></ion-icon>

  <span>
    <ng-content></ng-content>
  </span>

  <ion-icon
    class="c-btn__icon"
    [class.c-btn__icon--desktop-only]="hideIconOnMobile"
    *ngIf="!loading && icon && iconAfterLabel"
    [name]="icon"></ion-icon>
  <app-spinner *ngIf="loading" class="c-btn__loading c-btn__loading--{{ variant }}"></app-spinner>
</button>
