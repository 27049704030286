<div class="c-field">
  <header class="u-margin-bottom--sm">
    <div class="u-display--flex u-flex-justify--space-between u-flex-align--center">
      <label class="c-field__label u-margin-bottom--none" [class.c-field__label--disabled]="isDisabled">
        {{ label }}
        <span *ngIf="isRequired">*</span>
      </label>

      <!-- Action button -->
      <app-button
        *ngIf="actionLabel"
        variant="secondary"
        [icon]="actionIcon"
        [iconAfterLabel]="true"
        [disabled]="isDisabled"
        size="sm"
        (click)="onAction.emit()"
        >{{ actionLabel }}</app-button
      >
    </div>
    <span class="e-radio-group__helper">{{ helperText }}</span>
  </header>
  <div class="e-radio-group" *ngIf="options?.length; else emptyOptions">
    <app-choice-chip
      class="u-flex--unset"
      *ngFor="let option of options"
      [value]="option.id"
      [isRadioButton]="true"
      [selected]="option.checked"
      [separated]="true"
      [disabled]="option.disabled || isDisabled"
      [label]="option.label"
      (chipSelectionChanged)="onOptionSelect(option)"></app-choice-chip>
  </div>

  <div
    class="c-field__icon__helper u-display--flex u-flex-direction--column"
    *ngIf="ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.errors">
    <span *ngIf="ngControl.control?.errors?.required" class="u-color--orange-300">{{
      'FORM_ERRORS.FIELD_REQUIRED' | translate
    }}</span>
  </div>
</div>

<ng-template #emptyOptions>
  <p class="e-empty-options">No options available</p>
</ng-template>
