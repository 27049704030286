import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/shared/services/api.services';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.css'],
})
export class AddPackagesComponent implements OnInit {
  packageform: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot.data?.message?.data;
    if (this.routerid) {
      this.packageform = this.formBuilder.group({
        price: [this.data.price, [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)(\.\d+)?$/)]],
        plan_name: [this.data.plan_name, Validators.required],
        valid_date: [this.data.valid_date, Validators.required],
        description: [this.data.description],
      });
    } else {
      this.packageform = this.formBuilder.group({
        price: ['', [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)(\.\d+)?$/)]],
        plan_name: ['', Validators.required],
        valid_date: ['', Validators.required],
        description: [''],
      });
    }
  }
  get f() {
    return this.packageform.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.packageform.invalid) {
      return;
    }
    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.addadminpackages.path, this.packageform.value).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Packages added Successfully'));
          this.router.navigate(['/admin/packages']);
        },
        (err: any) => {
          this.toaster.error(err.error.message || err.error);
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updateadminpackages.path + '?id=' + this.routerid,
        this.packageform.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Packages updated successfully'));
          this.router.navigate(['/admin/packages']);
        },
        (err: any) => {
          this.toaster.error(err.error.message || err.error);
        }
      );
    }
  }
}
