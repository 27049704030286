import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';

@Injectable({
  providedIn: 'root',
})
export class AdminCoachResolver implements Resolve<any> {
  constructor(private Apiservice: ApiService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let userId = route.paramMap.get('id');
      this.Apiservice.getApi(endpoints.editcoach.path + '?id=' + userId).subscribe((data) => {
        resolve(data);
      });
    });
  }
}
