<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title head-color-blue" id="exampleModalLabel" translate>{{ section.section }}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div class="row" *ngIf="link == ''">
      <mat-spinner strokeWidth="3" [diameter]="50" class="m-auto"></mat-spinner>
    </div> -->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 m-auto pb-3">
        <p class="mb-0">
          <b>{{ link.title }}</b>
        </p>
      </div>
    </div>
    <div class="row clearfix my-3">
      <div class="col-lg-11 col-md-11 col-sm-11 m-auto">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 m-auto">
            <!-- <iframe [src]="link.link.replace('watch?v=','embed/') | safe"
              width="100%"
              height="450vh"
              frameborder="0"
              allowfullscreen
              webkitallowfullscreen
              mozallowfullscreen
            ></iframe> -->
            <youtube-player
              [videoId]="link.video_id ? link.video_id : 'Invalid Video Id'"
              suggestedQuality="highres"
              [height]="500"
              [width]="">
            </youtube-player>
          </div>
        </div>
      </div>
    </div>
    <div class="row pb-3">
      <div class="col-lg-12 col-md-12 col-sm-12 m-auto pb-3">
        <p class="mb-0">{{ link.description }}</p>
      </div>
    </div>
  </div>
</div>
