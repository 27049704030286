<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Staff Positions</h2>
                </div>
                <div class="col-md-12">
                    <div class="float-right">
                        <a class="btn-sm auth-button" [routerLink]="['/superadmin/positions/add-positions']"
                            translate><i class="fas fa-plus mr-1"></i>New</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12" id="content" #content>
                                        <div class="body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <span class="d-flex">{{ 'Show' | translate}}
                                                        <select [(ngModel)]="itemPerPage"
                                                            name="selection-datatable_length"
                                                            aria-controls="selection-datatable"
                                                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                            <option *ngFor="let pageCountObj of pagecounts"
                                                                [value]="pageCountObj">
                                                                {{pageCountObj}}</option>
                                                        </select> {{ 'entries' | translate}}
                                                    </span>
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="{{'Search' | translate}}"
                                                            [(ngModel)]="searchText" name="searchText">
                                                        <div class="input-group-append" *ngIf="searchText">
                                                            <span class="input-group-text" id="search-mail"
                                                                (click)="resetsearch()" translate>Clear</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <!-- <table datatable [dtOptions]="dtOptions" class="row-border hover"> -->
                                            <table class="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th translate>Id</th>
                                                        <th translate>Position</th>
                                                        <th translate>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let list of liststaff | paginate: { itemsPerPage: itemPerPage, currentPage: page } | filter: searchText; let i = index">
                                                        <td>{{list.id}}</td>
                                                        <td class="multi_lines_text">{{list.positions}}</td>
                                                        <td>
                                                            <a [routerLink]="['/superadmin/positions/edit-positions/'+list.id]"
                                                                class="btn btn-default btn-sm"><i
                                                                    class="fas fa-pencil-alt"></i></a>
                                                            <a (click)="deleteActiveRow(i, list)"
                                                                class="btn btn-default btn-sm ml-1" title="{{ 'Delete' | translate }}"><i
                                                                    class="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-2">
                                <pagination-controls 
                                    (pageChange)="pageChange($event)"
                                    [previousLabel]="'Previous' | translate"
                                    [nextLabel]="'Next' | translate"
                                ></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>