<div class="block-header">
  <div class="row clearfix">
    <div class="col-xl-12 col-md-12 col-sm-12">
      <h2 translate>Add Payment</h2>
    </div>
    <div class="col-md-12">
      <div class="float-right">
        <a class="btn-sm auth-button pay-button-bg" data-toggle="modal" data-target="#exampleModal" translate><i
            class="fas fa-credit-card mr-1"></i>Add Card</a>
        <a class="btn-sm auth-button pay-button-bg ml-2" [routerLink]="['/admin/school']" translate *ngIf="userrole=='ADMIN'"><i
            class="fas fa-bars mr-1"></i>Payment</a>
        <a class="btn-sm auth-button pay-button-bg ml-2" [routerLink]="['/superadmin/payments']" translate
          *ngIf="userrole=='SUPERADMIN'"><i class="fas fa-bars mr-1"></i>Payment</a>
      </div>
    </div>
  </div>

  <div class="row clearfix mt-3" *ngIf="status === 'expired'">
    <div class="col-12">
    <div class="alert alert-warning" role="alert" translate>
      Your membership is expired. Please renew your membership.
      </div>
    </div>
  </div>

  <form [formGroup]="paymentForm">
    <div class="row clearfix mt-4">
      <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12" *ngIf="userrole=='SUPERADMIN' && !router_id">
          <h6 class="head-color" translate>Select School</h6>
          <ng-select placeholder="{{'Select School' | translate}}" (change)="selectUser($event)">
            <ng-option *ngFor="let status of school" [value]="status.id">{{status.name}}
            </ng-option>
          </ng-select>

        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
          <h6 class="head-color" translate>Packages</h6>
          <div class="" id="ngxpackdisable-btn">
            <div slickContainer #slickidController="slick" [slickConfig]="config">
              <ng-container *ngIf="slickidController.initialize">
                <div slickItem class="ngxslider-width" *ngFor="let plan of packageList">
                  <div class="ngxmaincontent" [ngClass]="{activemenu:plan.id==selectplan}" (click)="planList(plan)">
                    <div class="ngxname-text">
                      <p>{{plan.plan_name}}</p>
                      <p class="datecolor">{{plan.valid_date}}</p>
                    </div>
                    <div class="ngxprice-text">
                      <p>$ {{plan.price}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>


        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" *ngIf="listcard.length>0">
          <h6 translate>Existing Card</h6>
          <div class="" id="ngxexdisable-btn">
            <div slickContainer #cardidController="slick" [slickConfig]="config">
              <ng-container *ngIf="cardidController?.initialize">
                <div slickItem class="ngxslider-width" *ngFor="let card of listcard">
                  <div *ngIf="!card.hidden" class="ngxmaincontent" [ngClass]="{activemenu:card.id==card_id}" (click)="Paymentcard(card)">
                    <div class="ngxname-text">
                      <p>{{card.holder_name}}</p>
                      <p class="datecolor">{{card.last4}}</p>
                    </div>
                    <div class="ngxprice-text">
                      <p>{{card.exp_month}}/{{card.exp_year}}</p>
                    </div>
                  </div>
                    <!-- Add the "Remove Card" button here -->
                    <div *ngIf="!card.hidden" class="text-center">
                      <button class="btn btn-danger btn-sm" (click)="confirmRemoveCard(card.id); $event.stopPropagation()" translate>Remove Card</button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" *ngIf="this.paymentForm.value.card_id=='' && listcard.length==0 || listcard.length==0 ">
            <div class="custom-control custom-checkbox" *ngIf="addcard!='addcard' ">
              <input type="checkbox" class="custom-control-input" id="customCheck1"  (change)="existingAddress($event)">
              <label class="custom-control-label" for="customCheck1" translate>To Use existing address</label>
            </div>
          </div>

      <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" *ngIf="this.paymentForm.value.card_id=='' && listcard.length==0 || listcard.length==0 ">
        <div *ngIf="addcard!='addcard'">
          <h6 class="head-color" translate>Billing Address</h6>

          <form [formGroup]="addCardForm">
            <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="addCardForm"></app-country-state>
            <div class="form-group">
              <label class="mt-2" translate>Address<span
                      class="text-danger">*</span></label>
              <textarea placeholder="{{'Address' | translate}}" type="text"
                  class="form-control" formControlName="address"
                  maxlength="250"
                  [ngClass]="{'is-invalid': submitted && f.address.errors}"></textarea>
              <div *ngIf="submitted && f.address.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.address.errors.required" translate>
                      Address is required
                  </div>
              </div>
            </div>
          </form>
        </div>

      </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-12"
          *ngIf="this.paymentForm.value.card_id=='' && listcard.length==0 || listcard.length==0">
          <ng-payment-card (formSaved)="addCard($event)" *ngIf="addcard!='addcard'"></ng-payment-card>
        </div>

        <button (click)="initializePayment()" class="btn float-right auth-button mt-4">
          <p class="my-0"><span translate>Payment of</span> R$ {{ planeName || 0}}</p>
        </button>
      </div>
    </div>
  </form>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title payment-text-color" id="exampleModalLabel" translate>Add Card</h5>
        <button type="button" class="close" #closeCardModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 mt-4" >
          <h6 class="head-color" translate>Billing Address</h6>
          <form [formGroup]="addCardForm">
            <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="addCardForm"></app-country-state>
            <div class="form-group">
              <label class="mt-2" translate>Address<span
                      class="text-danger">*</span></label>
              <textarea placeholder="{{'Address' | translate}}" type="text"
                  class="form-control" formControlName="address"
                  maxlength="250"
                  [ngClass]="{'is-invalid': submitted && f.address.errors}"></textarea>
              <div *ngIf="submitted && f.address.errors"
                  class="invalid-feedback">
                  <div *ngIf="f.address.errors.required" translate>
                      Address is required
                  </div>
              </div>
            </div>
          </form>
        </div>
        <ng-payment-card (formSaved)="addCard($event)" #paymentcard></ng-payment-card>
      </div>

    </div>
  </div>
</div>
