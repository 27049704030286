import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, finalize } from 'rxjs';
import { SelectOption } from 'src/app/shared/components/field-select/field-select.interfaces';
import { CountryService } from 'src/app/shared/services/countrycitystate';
import { BeltEntity, CoachEntity, NewCoachResquest } from '../../state/events.interface';
import { EventsService } from '../../state/events.service';

@Component({
  selector: 'app-add-coach-modal',
  templateUrl: './add-coach-modal.component.html',
  styleUrls: ['./add-coach-modal.component.scss'],
})
export class AddCoachModal {
  @Output() clickevent = new EventEmitter<CoachEntity>();

  form: FormGroup;
  isLoading: boolean = false;
  isMobile: boolean = false;
  maxMobileSize: number = 576;
  countryOptions: SelectOption[] = [];
  stateOptions: SelectOption[] = [];
  cityOptions: SelectOption[] = [];
  beltOptions: SelectOption[] = [];
  maxDate: moment.Moment = moment();

  beltSub$: Subscription | undefined;
  countrySub$: Subscription | undefined;
  stateSub$: Subscription | undefined;

  countryCodeList: any = [];

  maxDigit: any;
  minDigit: any;

  constructor(
    private eventsService: EventsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private countryService: CountryService,
    public activeModal: NgbActiveModal,
    private element: ElementRef,
    private _httpClient: HttpClient
  ) {
    this.isMobile = window.innerWidth < this.maxMobileSize;

    this.beltSub$ = this.eventsService.getBelts().subscribe((belts) => {
      this.beltOptions = belts.map((belt: BeltEntity) => ({ id: belt.belt_id, text: belt.name }));
    });

    this.form = new FormGroup({
      name: new FormControl(''),
      gender: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_code: new FormControl('+1', [Validators.required]),
      phone_number: new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]),
      dob: new FormControl('', [Validators.required]),
      belt: new FormControl('', Validators.required),
      zip_code: new FormControl('', [Validators.required, Validators.pattern(/^[0-9.+\\-]*$/)]),
      country: new FormControl('', Validators.required),
      country_code: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      state_code: new FormControl({ value: '', disabled: true }, Validators.required),
      city: new FormControl({ value: '', disabled: true }, Validators.required),
      address: new FormControl('', Validators.required),
    });

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });

    this.countryOptions = this.countryService
      .getCountries()
      .map((country) => ({ id: country.isoCode, text: country.name }));

    this.countrySub$ = this.form.get('country_code')?.valueChanges.subscribe((value) => {
      this.countryChanged(value);
    });

    this.stateSub$ = this.form.get('state_code')?.valueChanges.subscribe((value) => {
      this.stateChanged(value);
    });
  }

  ngOnInit() {
    if (this.form.controls['country_code'].value) {
      this.stateOptions = this.countryService
        .getStatesByCountry(this.form.controls['country_code'].value)
        .map((state) => ({ id: state.isoCode, text: state.name }));
    }
    if (this.form.controls['state_code'].value) {
      this.cityOptions = this.countryService
        .getCitiesByState(this.form.controls['country_code'].value, this.form.controls['state_code'].value)
        .map((city) => ({ id: city.name, text: city.name }));
    }

    if (this.form.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.form.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  buildPayload(): NewCoachResquest {
    const coach = this.form.value;
    const payload: NewCoachResquest = {
      name: coach.name,
      email: coach.email,
      password: '',
      dob: coach.dob,
      belt_id: coach.belt,
      gender: coach.gender,
      address: coach.address,
      city: coach.city,
      state: coach.state,
      state_code: coach.state_code,
      country: coach.country,
      country_code: coach.country_code,
      zip_code: coach.zip_code,
      phone_code: coach.phone_code,
      phone_number: coach.phone_number,
    };

    return payload;
  }

  submit() {
    if (this.form.invalid || this.isLoading) return;

    this.isLoading = true;
    this.eventsService
      .postCoach(this.buildPayload())
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (coach: CoachEntity) => {
          this.toastr.success(this.translate.instant('ADD_NEW_COACH_MODAL.TOAST_MESSAGES.SUCCESS'));
          this.clickevent.emit(coach);
          this.form.reset();
          this.activeModal.close();
        },
        error: (err) => {
          console.error(err);
          this.toastr.error(
            err.error?.message ?? this.translate.instant('ADD_NEW_COACH_MODAL.TOAST_MESSAGES.ERROR')
          );
        },
      });
  }

  countryChanged(value: string) {
    this.form.controls['state_code'].reset();
    this.stateOptions = [];
    this.cityOptions = [];

    if (!value) {
      this.form.controls['country'].reset();
      this.form.controls['state_code'].disable();
      return;
    }

    this.form.controls['state_code'].enable();
    this.form.controls['country'].setValue(this.countryOptions.find((x) => x.id === value)?.text);

    this.stateOptions = this.countryService
      .getStatesByCountry(this.form.controls['country_code'].value)
      .map((state) => ({ id: state.isoCode, text: state.name }));
  }

  stateChanged(value: string) {
    this.form.controls['city'].reset();
    this.cityOptions = [];

    if (!value) {
      this.form.controls['state'].reset();
      this.form.controls['city'].disable();
      return;
    }

    this.form.controls['city'].enable();
    this.form.controls['state'].setValue(this.stateOptions.find((x) => x.id === value)?.text);

    this.cityOptions = this.countryService
      .getCitiesByState(this.form.controls['country_code'].value, this.form.controls['state_code'].value)
      .map((city) => ({ id: city.name, text: city.name }));
  }

  handleGenderChipSelected(value: string | null): void {
    this.form.controls['gender'].setValue(value);
  }

  onDateChange(event: any) {
    this.form.controls['dob'].setValue(event?.startDate ? moment(event.startDate).format('YYYY-MM-DD') : null);
  }

  ngOnDestroy() {
    this.beltSub$?.unsubscribe();
    this.countrySub$?.unsubscribe();
    this.stateSub$?.unsubscribe();
  }

  conutyphone(event: any) {
    this.form.controls['phone_code'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';

    if (this.form.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.form.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }

    const nextInput = this.element.nativeElement.querySelector('input[formControlName="phone_number"]');
    if (nextInput) {
      nextInput.focus();
    }
  }
}
