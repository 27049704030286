import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  show: false | undefined;
  title = 'Tatami';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private ngxLoader: NgxUiLoaderService,
    private translate: TranslateService
  ) {
    if (environment.production) {
      window.console.log = () => {};
    }

    const language = localStorage.getItem('language') || 'pt-br';
    translate.addLangs(['en', 'es', 'pt-br']);
    translate.setDefaultLang(language);
    translate.use(language);
  }

  ngOnInit(): void {}
}
