<label class="{{ labelClass }}">
  {{
    calendarLabel ??
      (variant === 'range'
        ? ('CALENDAR.RANGE_FIELD_LABEL' | translate)
        : ('CALENDAR.DATE_FIELD_LABEL' | translate))
  }}
</label>

<div class="u-position--relative" [class.e-single-calendar]="variant === 'range'">
  <input
    type="text"
    name="daterange"
    ngxDaterangepickerMd
    [ngModel]="selectedRange"
    (ngModelChange)="selectedDate($event)"
    [singleDatePicker]="variant === 'date'"
    [showDropdowns]="showDropdowns"
    [customRangeDirection]="true"
    [showCustomRangeLabel]="true"
    [alwaysShowCalendars]="true"
    [closeOnAutoApply]="true"
    [linkedCalendars]="false"
    [ranges]="ranges"
    [autoApply]="true"
    [isCustomDate]="isCustomDate"
    [showClearButton]="false"
    [locale]="locale"
    [placeholder]="
      calendarPlaceholder ??
      (variant === 'range'
        ? ('CALENDAR.RANGE_FIELD_PLACEHOLDER' | translate)
        : ('CALENDAR.DATE_FIELD_PLACEHOLDER' | translate))
    "
    [minDate]="minDate"
    [maxDate]="maxDate"
    class="{{ inputClass }}"
    autocomplete="off"
    (input)="onInput($event.target.value)"
    (blur)="onBlur()" />

  <ion-icon
    *ngIf="selectedRange?.startDate && clearDate"
    name="close"
    class="c-field__icon-delete"
    (click)="clear()"></ion-icon>
  <ion-icon
    *ngIf="clearDate ? !selectedRange?.startDate : true"
    class="c-field__icon-delete u-color--gray-500 ngx-daterangepicker-action"
    name="calendar"
    (click)="openDatepicker()"></ion-icon>
</div>

<span *ngIf="helperText" class="c-field__icon__helper">{{ helperText }}</span>
<div *ngIf="isRequired" class="c-field__icon__helper u-display--flex u-flex-direction--column">
  <span
    *ngIf="(!selectedRange?.startDate || !selectedRange?.endDate) && touched && isRequired"
    class="u-color--orange-300"
    >{{ 'FORM_ERRORS.FIELD_REQUIRED' | translate }}</span
  >

  <span *ngIf="invalidFormat && inputValue !== ''" class="u-color--orange-300">{{
    'FORM_ERRORS.INVALID_FORMAT' | translate
  }}</span>
</div>
