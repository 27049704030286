import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './../../../shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { HttpClient } from '@angular/common/http';
import { Moment } from 'moment';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;

  filterform: FormGroup | any;
  submitted = false;
  showMsg: boolean = false;
  isTouchUIActivated = false;
  searchText: any;
  searchview: boolean = false;
  settings: any = [];
  eventsdetails:any=[]
  classes:any=[]
  studentlist:any=[]
  eventid:any
  userid:any
  userstudent:any
  listevents:any=[]
  eventregister:any=[]
  regstudent:any
  noteslist:any=[]
  eventnotes:any=[]
  filterData:any=[]
  startdate:any
  enddate:any
  selectdate: any;
  activedata:any
  schoolname:any

  ranges: any;
  calendarPlaceholder: string | any;
  selectedRange: null |any;
  minDate: Moment | any;
  maxDate: Moment | any;

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public toaster: ToastrService,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}


  ngOnInit(): void {
    this.schoolname=this.AuthService.schoolname
    this.userid=this.AuthService.userid

    this.filterform = this.formBuilder.group({
      start_time: [''],
      end_time: [''],
    });
    this.eventlist()

  }
  eventlist(){
    this.Apiservice.getApi(endpoints.studenteventlist.path).subscribe(
      (data: any) => {
        this.filterData=data.results
        this.activedata=this.filterData[0]
        // this.enentdata(this.activedata)

      },(err:any)=>{
        this.toaster.error(err.error.message)

      })

  }

  formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  resetsearch() {
    this.searchText = '';
  }
  mToggoleMenu() {
    document
      .getElementsByTagName('body')[0]
      .classList.toggle('offcanvas-active');
  }
  get f() {
    return this.filterform.controls;
  }
  private convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  OnSelect(event:any){
    if(event?.startDate){
      this.startdate=this.convert(event.startDate._d)
      this.enddate=this.convert(event.endDate._d)
      this.filterform.controls['start_time'].setValue(this.startdate)
      this.filterform.controls['end_time'].setValue(this.enddate)
    }
  }
  onSubmit() {
    this.submitted = true;
    if(this.filterform.value.start_time=='' || this.filterform.value.start_time==null){
      this.toaster.error(this.translate.instant('Select date to submit'))
      return
    }
    if(this.filterform.value.start_time!=''){
      this.selectdate=this.startdate+ ' to ' + this.enddate
    }
    this.Apiservice.getApi(endpoints.studenteventlist.path+"?start_time="+this.filterform.value.start_time+"&end_time="+this.filterform.value.end_time).subscribe(
      (data: any) => {
       this.toaster.success(this.translate.instant('Successfully'))
        this.filterData=data.results
        // this.activedata=this.filterData[0]
        this.enentdata(this.activedata)
        if(this.filterform.value.start_time!=''){
          this.selectdate=this.startdate+ ' to ' + this.enddate
        }
      },(err:any)=>{
        this.toaster.error(err.error.message)

      })
  }
  clear(){
    this.daterangepicker.clear()
    this.filterform.reset()

    this.selectdate=''
    // this.eventlist()
  }
  enentdata(event: any) {
    this.eventid=event.id
    this.Apiservice.getApi(endpoints.studenteventdetails.path+"?id="+this.eventid).subscribe(
      (data: any) => {
        this.eventregister = data.eventDetails.event_register;
        this.regstudent=this.eventregister.find((s:any)=>s.student_id.id==this.AuthService.userid);

        let studentname:any=[]
        this.eventsdetails = data.eventDetails.eventclass;
        this.eventnotes= data.eventDetails.notes;
        this.eventsdetails.forEach((s:any)=>{
          s.students.forEach((x:any)=>{
            studentname.push(x)
          })
        })
        this.studentlist=studentname

        this.userstudent=this.studentlist.filter((s:any)=>s.id==this.AuthService.userid)

      }
    );


  }
  Registration(){
    const body={
      event_id:this.eventid,
      student_id:this.AuthService.userid
    }
    Swal.fire({
      title: this.translate.instant('Register?'),
      text: this.translate.instant("Do you want to Register?"),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.postApi(endpoints.eventregistration.path,body).subscribe(
          (data: any) => {
            if(data.status==200){
              Swal.fire({
                title: this.translate.instant('Registration Successfully'),
                icon: 'success',
              } )
              this.eventlist()
            }else{
              Swal.fire({
                title: data.message,
                icon: 'error',
              } )
            }

          },(err)=>{
            Swal.fire({
              title: err,
              icon: 'error',
            } )
          })
      }
    })
  }
  CheckedIn(studentlist:any){
    const body={
      event_id:this.eventid,
      student_id:studentlist.id,
      id:26
    }
    Swal.fire({
      title: this.translate.instant('Checked In?'),
      text: this.translate.instant("Do you want to check in?"),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.postApi(endpoints.eventcheckedin.path,body).subscribe(
          (data: any) => {
            Swal.fire({
              title: this.translate.instant('Succesfully Checked In'),
              icon: 'success',
            } )
          },(err)=>{
            Swal.fire({
              title: this.translate.instant('Invalid Checked In'),
              icon: 'error',
            } )
          })
      }
    })
  }

}
