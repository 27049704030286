import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fab[icon]',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent implements OnInit {
  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() variant: 'normal' | 'toggled' | 'untoggled' | 'close' = 'normal';

  constructor() {}

  ngOnInit(): void {}
}
