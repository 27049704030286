<div id="main-content">
  <div class="container-fluid">
    <div class="row mt-4" *ngIf="readnotify == '' || unreadnotify == ''">
      <div class="card p-4">
        <h5 translate>No data available</h5>
      </div>
    </div>
    <div
      class="alert alert-success alert-dismissible fade show mt-4"
      role="alert"
      *ngFor="let read of readnotify; let i = index">
      <strong>{{ read.title }}</strong> {{ read.notification_message }}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="deletenotify(i, read.id)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div
      class="alert alert-warning alert-dismissible fade show mt-4"
      role="alert"
      *ngFor="let unread of unreadnotify; let i = index">
      <strong>{{ unread.title }}</strong> {{ unread.notification_message }}
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
        (click)="deletenotify(i, unread.id)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</div>
