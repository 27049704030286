<app-header></app-header>

<div id="main-content" ngxUiLoaderBlurred>
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Dashboard</h2>
                </div>
            </div>
            <div class="row clearfix mt-3" id="onboardingAlert" *ngIf="!hasBankAccount && showOnboardingAlert">
              <div class="col-12">
                <div class="alert alert-warning" role="alert" translate>
                  According to our records, you haven't completed the onboarding process
                  yet. To receive payments into your bank account, please complete the
                  onboarding process and provide your banking information. Click the
                  button below to proceed.
                  <div class="text-center mt-2">
                    <button type="button" id="stripeConnectBtn"  class="btn btn-dark auth-button"
                    (click)="completeOnboarding()" translate [disabled]="loading">
                      <span *ngIf="!loading">Complete Onboarding</span>
                      <span *ngIf="loading">Loading...</span>
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="card">
                        <div class="body">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="card dash-body" style="background: #ff5c33;">
                                        <div class="body">
                                            <div class="row text-white align-items-center">
                                                <div class="col-md-6 text-center border-right">
                                                    <i class="far fa-flag"></i>
                                                    <p translate>Total Income / Month</p>
                                                </div>
                                                <div class="col-md-6 text-center">
                                                    <h4>{{dashboardcount?.income?.total_amount || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="card  dash-body" style="background: #4da6ff;">
                                        <div class="body">
                                            <div class="row text-white align-items-center">
                                                <div class="col-md-6 text-center border-right">
                                                    <i class="far fa-folder"></i>
                                                    <p translate>Total Expense / Month</p>
                                                </div>
                                                <div class="col-md-6 text-center">
                                                    <h4 >{{dashboardcount?.expenses?.total_amount || 0}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="header">
                        <h2 class="dashboard-h2 mb-2" translate>Cash Flow Overview</h2>
                    </div>
                    <div class="card">
                        <div class="body">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="piechartsize">
                                        <div class="chart" *ngIf="pieChartData.datasets.length">
                                            <canvas baseChart [data]="pieChartData" [type]="pieChartType"
                                                [options]="pieChartOptions" [plugins]="pieChartPlugins">
                                            </canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="header">
                        <h2 class="dashboard-h2 mb-2" translate>Latest Registered Students</h2>
                    </div>
                    <div class="card-body bgwhite">
                        <div class="box-border p-4">
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th translate>ID</th>
                                            <th translate>Name</th>
                                            <th translate>ADM Date</th>
                                            <th translate>Class</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let student of registerlist">
                                            <td>{{student.id}}</td>
                                            <td>{{student.first_name}}</td>
                                            <td>{{student.created_at | date:'YYYY-MM-dd'}}</td>
                                            <td >
                                                <div *ngFor="let class of student.studentclass">{{class.name}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="header">
                        <h2 class="dashboard-h2 mb-2" translate>Latest Payments</h2>
                    </div>
                    <div class="card-body bgwhite">
                        <div class="box-border p-4">
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th translate>Transaction Id</th>
                                            <th translate>Transaction Date</th>
                                            <th translate>Packages</th>
                                            <th translate>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let payment of paymentlist">
                                            <td>{{payment.transaction_id}}</td>
                                            <td>{{payment.transaction_date  }}</td>
                                            <td *ngIf="payment.package">{{payment.package.plan_name}}</td>
                                            <td>R$ {{payment.amount}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
