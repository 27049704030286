<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header">
      <div class="row clearfix mt-4">
        <div class="col-xxl-10 col-12 p-5 auth-form" style="margin: auto">
          <form *ngIf="schoolForm" [formGroup]="schoolForm">
            <div class="e-schedule-form-line mb-3">
              <div class="e-schedule-form_input-container">
                <div class="e-schedule-form_input-container__title">
                  <span>{{ 'SCHOOL_PAGE.TITLE' | translate }}</span>
                </div>
                <div class="e-schedule-form_input-container__subtitle">
                  <span>{{ 'SCHOOL_PAGE.SUBTITLE' | translate }}</span>
                </div>
              </div>
            </div>
            <!--  <div class="body text-center">
              <label for="profileImage">
                <img
                  [src]="data.photo"
                  class="rounded-circle"
                  id="profilePicPreview"
                  (error)="onImageError($event)"
                  alt="" />
              </label>
              <input
                type="file"
                accept="image/*"
                id="profileImage"
                name="photo"
                style="display: none"
                (change)="onFileSelected($event)" />
            </div> -->
            <!--   <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                <div class="c-field">
                  <label class="c-field__label">
                    {{ 'School Logo' | translate }}
                  </label>
                </div>
                <label for="profileImage">
                  <img
                    [src]="data?.photo || 'assets/images/image-placeholder.jpeg'"
                    class="e-logo-preview add-circle"
                    id="profilePicPreview"
                    (error)="onImageError($event)"
                    alt="" />
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="profileImage"
                  name="photo"
                  style="display: none"
                  (change)="onFileSelected($event)" />
              </div>
            </div> -->

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                <app-field-input
                  formControlName="name"
                  type="text"
                  minlength="2"
                  maxlength="200"
                  [isRequired]="true"
                  [clearButton]="true"
                  [label]="'School Name' | translate"
                  [placeholder]="'School Name' | translate"></app-field-input>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                <app-field-input
                  formControlName="responsible"
                  type="text"
                  minlength="2"
                  maxlength="200"
                  [isRequired]="true"
                  [clearButton]="true"
                  [label]="'Responsible' | translate"
                  [placeholder]="'Responsible' | translate"></app-field-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 mt-3">
                <div class="c-field">
                  <label class="c-field__label"> {{ 'Phone Number' | translate }} <span>*</span> </label>
                </div>

                <div class="input-group">
                  <div class="input-group-prepend e-form-phone">
                    <ng-select
                      formControlName="phone_code"
                      class="e-form-phone e-form-phone__code"
                      (change)="conutyphone($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.phone_code.errors }">
                      <ng-option *ngFor="let status of countryCodeList" [value]="status">
                        {{ status.code }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <input
                    type="text"
                    class="form-control e-form-phone"
                    [placeholder]="minDigit"
                    mask=" {{ minDigit }} || {{ maxDigit }}"
                    [validation]="false"
                    formControlName="phone_number"
                    [disabled]="f.phone_number.disabled"
                    [ngClass]="{
                      'is-invalid c-field__input--invalid': f.phone_number.touched && f.phone_number.errors
                    }" />
                  <div *ngIf="f.phone_number.touched && f.phone_number.errors" class="invalid-feedback mt-2">
                    <div *ngIf="f.phone_number.errors.required" translate>Phone Number is required</div>
                    <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile should be 10 character long
                          </div>
                          <div *ngIf="f.phonenumber.errors.pattern">Only Numbers are allowed</div> -->
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <app-field-input
                  formControlName="email"
                  type="text"
                  maxlength="64"
                  class="mt-3"
                  [isRequired]="true"
                  [clearButton]="true"
                  [label]="'Email' | translate"
                  [placeholder]="'Email' | translate"></app-field-input>
              </div>
            </div>

            <app-country-state-city (CountryChanged)="countryChange($event)" [countryForm]="schoolForm">
            </app-country-state-city>

            <app-field-input
              formControlName="address"
              type="textarea"
              class="mt-3"
              [isRequired]="true"
              [clearButton]="true"
              [label]="'Address' | translate"
              [placeholder]="'Address' | translate"></app-field-input>

            <app-field-select
              class="u-flex--full mt-3"
              formControlName="timezone"
              [label]="'TIMEZONE' | translate"
              [options]="timezones"
              [placeholder]="'SELECT_TIMEZONE' | translate"
              [allowSearch]="true"
              [isRequired]="true"></app-field-select>

            <div class="d-flex justify-content-end mt-5">
              <app-button
                icon="checkmark"
                [iconAfterLabel]="true"
                variant="primary"
                [isSubmit]="true"
                [loading]="submitting"
                [disabled]="submitting || schoolForm.invalid"
                [disabledStyleOnly]="true"
                (click)="onSubmit()"
                >{{ 'ADMIN_ACTIONS.SAVE' | translate }}</app-button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
