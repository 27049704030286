import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ProgramColor } from '../../admin.interface';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrls: ['./add-program.component.scss'],
})
export class AddProgramComponent implements OnInit {
  classForm: FormGroup = new FormGroup({});
  loading = false;
  data: any;
  routerid: any;

  colors: { foreground: string; background: string; label: string; value: ProgramColor }[] = [
    { foreground: '#7A6019', background: '#F2F8C9', label: 'Y', value: ProgramColor.YELLOW },
    { foreground: '#256014', background: '#DCFBCC', label: 'G', value: ProgramColor.GREEN },
    { foreground: '#154A64', background: '#DBF8FF', label: 'B', value: ProgramColor.BLUE },
    { foreground: '#592D76', background: '#EADCF8', label: 'P', value: ProgramColor.PURPLE },
    { foreground: '#722938', background: '#F4D6E1', label: 'R', value: ProgramColor.RED },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.routerid = this.route.snapshot.params['id'];
    this.data = this.route.snapshot.data?.message;

    if (this.routerid && this.data) {
      this.classForm = this.formBuilder.group({
        name: [this.data.name, [Validators.required]],
        color: [this.data.color, [Validators.required]],
      });
    } else {
      this.classForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        color: [this.colors[0].value, [Validators.required]],
      });
    }
  }

  onSubmit() {
    if (this.classForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      for (const key in this.classForm.controls) {
        this.classForm.controls[key].markAsDirty();
        this.classForm.controls[key].markAsTouched();
      }
      return;
    }

    if (this.loading) {
      return;
    }
    this.loading = true;

    const color = this.colors.find((color) => color.value === this.classForm.get('color')?.value);

    const requestData = {
      ...this.classForm.value,
      color: color?.value,
    };

    const request$ = this.routerid
      ? this.adminService.patchProgram(this.routerid, requestData)
      : this.adminService.postProgram(requestData);

    request$.subscribe({
      next: () => {
        this.loading = false;
        this.classForm.reset();
        this.toaster.success(
          this.translate.instant(this.routerid ? 'Class updated Successfully' : 'Class added Successfully')
        );
        this.router.navigate(['/admin/programs']);
      },
      error: (err: any) => {
        this.loading = false;
        this.toaster.error(err.error.message || err.error);
      },
    });
  }

  setColor(color: any) {
    this.classForm.get('color')?.setValue(color.value);
  }
}
