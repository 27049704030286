<!-- Empty list -->
<ng-container *ngIf="!hasFilters; else emptySearch">
  <article class="e-events-empty">
    <div class="e-events-empty__img-container">
      <img class="e-events-empty__img-container__img" src="assets/images/empty-class-list.svg" />
    </div>
    <span class="e-events-empty__text">There are currently no {{ status }} classes</span>
  </article>
</ng-container>

<!-- Empty list on search -->
<ng-template #emptySearch>
  <article class="e-events-empty">
    <div class="e-events-empty__img-container">
      <img class="e-events-empty__img-container__img" src="assets/images/empty-matches.svg" />
    </div>
    <span class="e-events-empty__text">Sorry, there are no matches for your search</span>
  </article>
</ng-template>
