
    <div class="row mt-4">
        <div class="card">
            <form [formGroup]="securityForm">
                <div class="card-body">
                    <div class="row mt-4 justify-content-center">
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label class="mt-2" translate>Old Password<span
                                                class="text-danger">*</span></label>
                                        <input type="password" class="form-control" placeholder="{{'Old Password' |translate }}"
                                            formControlName="current_password"
                                            [ngClass]="{'is-invalid': submit && ff.current_password.errors }" />
                                        <div *ngIf="submit && ff.current_password.errors" class="invalid-feedback">
                                            <div *ngIf="ff.current_password.errors.required" translate>
                                                Old Password is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="mt-2" translate>New Password<span
                                                class="text-danger">*</span></label>
                                        <input type="password" class="form-control" placeholder="{{'New Password' | translate}}"
                                            formControlName="password"
                                            [ngClass]="{'is-invalid': submit && ff.password.errors }" />
                                        <div *ngIf="submit && ff.password.errors" class="invalid-feedback">
                                            <div *ngIf="ff.password.errors.required" translate>
                                                Password is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="mt-2" translate>Confirm Password<span
                                                class="text-danger">*</span></label>
                                        <input type="password" class="form-control" placeholder="{{'Confirm Password'| translate}}"
                                            formControlName="password_confirmation"
                                            [ngClass]="{'is-invalid': submit && ff.password_confirmation.errors  }" />
                                        <div *ngIf="submit && ff.password_confirmation.errors" class="invalid-feedback">
                                            <div *ngIf="ff.password_confirmation.errors.required" translate>
                                                Confirm Password is required
                                            </div>
                                        </div>
                                    </div>
                                    <button (click)="onPassSubmit()"
                                        class=" btn float-right auth-button  mb-4 " translate>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
