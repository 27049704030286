import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { RadioGroupItem } from 'src/app/shared/components/radio-group/radio-group.helper';
import { ProgramEntity } from '../../state/events.interface';
import { EventsRepository } from '../../state/events.repository';
import { EventsService } from '../../state/events.service';

@Component({
  selector: 'app-add-program-modal',
  templateUrl: './add-program-modal.component.html',
  styleUrls: ['./add-program-modal.component.scss'],
})
export class AddProgramModal {
  @Input() allPrograms: RadioGroupItem[] = [];
  @Output() clickevent = new EventEmitter<ProgramEntity>();

  form: FormGroup;
  isLoading: boolean = false;
  isMobile: boolean = false;
  maxMobileSize: number = 576;

  constructor(
    private eventsService: EventsService,
    private eventsRepo: EventsRepository,
    private toastr: ToastrService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    this.isMobile = window.innerWidth < this.maxMobileSize;

    this.form = new FormGroup({
      name: new FormControl(null),
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  submit() {
    if (this.isLoading) return;
    if (this.form.invalid) {
      this.validateForm();
      return;
    }

    this.isLoading = true;

    const isDuplicate = this.allPrograms.some((el) => el.label === this.form.value.name);
    if (isDuplicate) {
      this.toastr.error(this.translate.instant('PROGRAM_DUPLICATE'));
      this.isLoading = false;
      return;
    }

    this.eventsService
      .postProgram(this.form.value.name)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (program: ProgramEntity) => {
          this.toastr.success(this.translate.instant('PROGRAM_ADDED_SUCCESSFULLY'));
          this.clickevent.emit(program);
          this.form.reset();
          this.activeModal.close();
        },
        error: (err) => {
          console.error(err);
          this.toastr.error(err.error?.message ?? this.translate.instant('FAILED_TO_ADD_PROGRAM'));
        },
      });
  }

  validateForm() {
    Object.keys(this.form.controls).forEach((field) => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      }
    });
  }
}
