<div class="e-event-form" [formGroup]="eventForm">
  <div class="e-event-form_container">
    <app-radio-group
      formControlName="program"
      [label]="'Program' | translate"
      [isMultiple]="false"
      [isRequired]="true"
      [options]="programsList"
      [actionLabel]="'ADD' | translate"
      actionIcon="add"
      (onAction)="onAddProgram()"></app-radio-group>
  </div>

  <div class="e-event-form_container">
    <app-field-input
      formControlName="name"
      type="text"
      class="u-flex--full"
      [isRequired]="true"
      [clearButton]="true"
      [label]="'Name' | translate"
      [placeholder]="'Class Name' | translate"></app-field-input>
  </div>

  <div class="e-event-form_container">
    <app-radio-group
      formControlName="coach"
      [label]="'Coach' | translate"
      [isMultiple]="false"
      [isRequired]="true"
      [options]="coachList"
      [actionLabel]="'ADD' | translate"
      actionIcon="add"
      (onAction)="onAddCoach()"></app-radio-group>
  </div>

  <div class="e-event-form_container">
    <app-field-number
      formControlName="max_registration"
      type="number"
      class="u-flex--full"
      [isRequired]="true"
      [label]="'CAPACITY' | translate"
      [placeholder]="'MAX_REGISTRATION' | translate"></app-field-number>
  </div>

  <div class="e-event-form_container">
    <app-field-input
      formControlName="description"
      class="u-flex--full"
      [label]="'Description' | translate"
      [placeholder]="'DESCRIPTION_PLACEHOLDER' | translate"></app-field-input>
  </div>

  <div class="e-event-form-line">
    <div class="e-event-form_container u-display--block">
      <div class="e-event-form_container__title u-color--gray-600">
        <span>{{ 'EARLY_CHECK_IN' | translate }}*</span>
      </div>
      <div class="u-display--flex u-flex-gap--sm u-flex-justify--start u-flex-align--center u-margin-block--sm">
        <app-field-number
          formControlName="early_checkin_minutes"
          type="number"
          class="e-event-form__input"
          [centerContent]="true"
          [isRequired]="true"
          placeholder="15"></app-field-number>
        <span class="t-text--md u-color--black">{{ 'MINUTES_BEFORE_CLASS' | translate }}</span>
      </div>
      <small class="t-text--sm u-color--gray-600">
        {{ 'EARLY_CHECK_HELPER_TEXT' | translate }}
      </small>
    </div>

    <div class="e-event-form_container u-display--block">
      <div class="e-event-form_container__title u-color--gray-600">
        <span>{{ 'LATE_CHECK_IN' | translate }}*</span>
      </div>
      <div class="u-display--flex u-flex-gap--sm u-flex-justify--start u-flex-align--center u-margin-block--sm">
        <app-field-number
          formControlName="late_checkin_minutes"
          type="number"
          class="e-event-form__input"
          [centerContent]="true"
          [isRequired]="true"
          placeholder="15"></app-field-number>
        <span class="t-text--md u-color--black">{{ 'MINUTES_BEFORE_CLASS' | translate }}</span>
      </div>
      <small class="t-text--sm u-color--gray-600">
        {{ 'LATE_CHECK_HELPER_TEXT' | translate }}
      </small>
    </div>
  </div>
</div>
