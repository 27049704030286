import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './../../../shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { ToastrService } from 'ngx-toastr';
import { emit } from 'process';
import { endpoints } from 'src/app/core/config/endpoints'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  @ViewChild('closemsgModal') closemsgModal: ElementRef | any;
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;
  isTouchUIActivated = false;
  rangeForm: FormGroup | any;
  messageForm: FormGroup | any;
  notesForm:FormGroup | any
  submitted: boolean = false;
  submit: boolean = false;
  searchText: any;
  searchview: boolean = false;
  settings: any = [];
  classData: any = [];
  studentList: any = [];
  coach: any = [];
  eventsdetails: any = [];
  classes: any = [];
  studentlist: any = [];
  activedata: any=[];
  student: any = [];
  listevents: any;
  eventnotes:any=[]
  filterData:any=[]
  startdate:any
  enddate:any
  selectdate: any;
  schoolname:any

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    public cd:ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolname=this.AuthService.schoolname
    this.eventlist()

    this.Apiservice.getApi(endpoints.assignclass.path).subscribe((data: any) => {
      this.classData = data.data
    })
    this.rangeForm = this.formBuilder.group({
      start_time: [''],
      end_time: [''],
      class: [null],
    });
    this.messageForm = this.formBuilder.group({
      event_id: ['', Validators.required],
      to_id: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.notesForm = this.formBuilder.group({
      event_id: ['', Validators.required],
      message: ['', Validators.required],
    });

  }
  get f() {
    return this.rangeForm.controls;
  }
  get ff() {
    return this.messageForm.controls;
  }
  resetsearch() {
    this.searchText = '';
  }
  mToggoleMenu() {
    document
      .getElementsByTagName('body')[0]
      .classList.toggle('offcanvas-active');
  }
  matClickPopup(e: Event) {
    alert(e.target);
  }
  enentdata(event: any) {
    this.AuthService.eventid=event.id

    this.Apiservice.getApi(
      endpoints.coacheventdetails.path + '?id=' + event.id
    ).subscribe((data: any) => {
      this.eventsdetails = data.eventDetails.event_register;
      this.eventnotes= data.eventDetails.notes;
      // this.eventsdetails.forEach((s: any) => {
      //   s.students.forEach((x: any) => {
      //     studentname.push(x);
      //   });
      // });
    });
  }
eventlist(){
  this.Apiservice.getApi(endpoints.coacheventlist.path).subscribe(
    (data: any) => {
      this.filterData=data.results

      this.activedata=this.filterData[0]
      this.enentdata(this.activedata)

    },(err:any)=>{
      this.toaster.error(err.error.message)

    })
}

  onSubmit() {
    this.submitted = true;

    if(this.rangeForm.value.start_time==''){
      this.selectdate=''
    }else{
      this.selectdate=this.rangeForm.value.start_time+ ' to ' + this.rangeForm.value.start_time
    }
    if(this.rangeForm.value.start_time=='' && this.rangeForm.value.class==null || this.rangeForm.value.start_time==null && this.rangeForm.value.class==null){
      this.toaster.error(this.translate.instant('Select date or class to submit'))
      return
    }
    if(this.rangeForm.value.class==null){
      this.rangeForm.controls['class'].setValue('')
    }
    this.Apiservice.getApi(endpoints.coacheventlist.path+"?start_time="+this.rangeForm.value.start_time+"&end_time="+this.rangeForm.value.end_time+"&class="+this.rangeForm.value.class).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Successfully'))

        this.filterData=data.results
        this.activedata=this.filterData[0]
        this.enentdata(this.activedata)

      },(err:any)=>{
        this.toaster.error(err.error.message)
      })


  }
  clear(){
    this.daterangepicker.clear()
    this.rangeForm.reset()
    this.selectdate=''
    this.eventlist()
  }
  Studentmessage(event:any){

    this.messageForm.controls['event_id'].setValue(this.AuthService.eventid)
    this.messageForm.controls['to_id'].setValue(event.student_id.id)
  }
  messageSubmit() {

    this.submit = true;
    if (this.messageForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.createmessage.path,
      this.messageForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Message sent Successfully'));
        this.closemsgModal.nativeElement.click();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  private convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  OnSelect(event:any){
    if(event?.startDate){
      this.startdate=this.convert(event.startDate._d)
      this.enddate=this.convert(event.endDate._d)
      this.rangeForm.controls['start_time'].setValue(this.startdate)
      this.rangeForm.controls['end_time'].setValue(this.enddate)
    }
  }

}
