import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup | any;
  submitted: any = false;
  success: boolean = false;
  error = '';
  isLoading: boolean = false;
  deviceInfo: any;
  profile: any;

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public api: ApiService,
    public toaster: ToastrService,
    public auth: AuthService,
    public http: HttpClient,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$')]),
      ],
      password: ['', Validators.required],
      deviceId: this.deviceInfo.browser_version,
      devices: this.deviceInfo.deviceType,
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    let loginemail = this.loginForm.value.email.toLowerCase();
    this.loginForm.controls['email'].setValue(loginemail);
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.submitted = false;

    this.auth
      .login(
        this.loginForm.get('email').value,
        this.loginForm.get('password').value,
        this.loginForm.get('deviceId').value,
        this.loginForm.get('devices').value
      )
      .subscribe(
        (data: any) => {
          if (data.user_role_name) {
            this.isLoading = false;
            this.toaster.clear();
            this.toaster.success(this.translate.instant('Connected successfully!'));

            let response = data;
            if (response.user_role_name.toLowerCase() == 'superadmin') {
              this.api.getApi(endpoints.superadminprofile.path).subscribe((data: any) => {
                if (data.data.r_password != '') {
                  this.auth.firstpassword = true;
                  this.toaster.warning(this.translate.instant('Please Change your password'));
                  this.router.navigate(['/superadmin/profile']);
                } else {
                  this.router.navigate(['/superadmin/dashboard']);
                }
              });
            } else if (response.user_role_name.toLowerCase() == 'admin') {
              this.api.getApi(endpoints.editprofile.path).subscribe((data: any) => {
                let value = data.data.school.bankAccount;
                if (data.data.r_password != '') {
                  this.auth.firstpassword = true;
                  this.toaster.warning(this.translate.instant('Please Change your password'));
                  localStorage.setItem('userBankAccount', value);
                  this.router.navigate(['/admin/profile']);
                } else {
                  localStorage.setItem('userBankAccount', value);
                  this.router.navigate(['/admin/dashboard']);
                }
              });
            } else if (response.user_role_name.toLowerCase() == 'coach') {
              this.api.getApi(endpoints.editcoachprofile.path).subscribe((data: any) => {
                if (data.data.r_password != '') {
                  this.toaster.warning(this.translate.instant('Please Change your password'));
                  this.router.navigate(['/coach/settings']);
                } else {
                  this.router.navigate(['/coach/dashboard']);
                }
              });
            } else if (response.user_role_name.toLowerCase() == 'student') {
              this.api.getApi(endpoints.editstudentprofile.path).subscribe((data: any) => {
                if (data.data.r_password != '') {
                  this.toaster.warning(this.translate.instant('Please Change your password'));
                  this.router.navigate(['/student/settings']);
                } else {
                  this.router.navigate(['/student/dashboard']);
                }
              });
            }
          } else {
            this.isLoading = false;
            this.toaster.error(data.error_message);
          }
        },
        (err) => {
          this.isLoading = false;
          this.toaster.error(err.error.message || err.message);
        }
      );
  }
}
