<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Coach</h2>
        </div>
        <div class="col-md-12">
          <div class="float-right">
            <a class="btn-sm auth-button" [routerLink]="['/admin/coach/add-coach']" translate
              ><i class="fas fa-plus mr-1"></i>New</a
            >
          </div>
        </div>
      </div>
      <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
          </div>
          <div class="col-lg-2 col-md-3 col-sm-3 col-4">
            <button type="submit" class="btn-sm auth-button submit-newbtn" translate>Search</button>
            <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>Clear</button>
          </div>
        </div>
      </form>
      <div class="row mt-4 table">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="pb-3">
            <ul class="nav nav-pills" id="nav-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  translate
                  ><i class="fas fa-lock-open"></i> Active</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  translate
                  ><i class="fas fa-lock"></i> In-active</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tab-content mt-0" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab">
              <div class="row clearfix">
                <div class="card">
                  <div class="body">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="d-flex">{{ 'Show' | translate}}
                          <select
                            [(ngModel)]="itemPerPage"
                            name="selection-datatable_length"
                            aria-controls="selection-datatable"
                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                            <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                              {{ pageCountObj }}
                            </option>
                          </select>
                          {{ 'entries' | translate}}
                        </span>
                      </div>
                      <div class="col-md-9">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'Search' | translate }}"
                            [(ngModel)]="searchText"
                            name="searchText" />
                          <div class="input-group-append" *ngIf="searchText">
                            <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                              >Clear</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th translate>Name</th>
                          <th translate>Email</th>
                          <th translate>Phone</th>
                          <th translate>Gender</th>
                          <th translate>Address</th>
                          <th translate class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let coach of coachlist
                              | filter: searchText
                              | paginate: { itemsPerPage: itemPerPage, currentPage: page, id: 'foo' };
                            let i = index
                          ">
                          <td>
                            {{ coach.first_name }} {{ coach.middle_name }}
                            {{ coach.last_name }}
                          </td>
                          <td>{{ coach.email }}</td>
                          <td>
                            {{ coach.phone }} {{ coach.phonenumber | slice: 0 : 3 }}-{{
                              coach.phonenumber | slice: 3 : 6
                            }}-{{ coach.phonenumber | slice: 6 }}
                          </td>
                          <td class="text-capitalize">{{ coach.gender }}</td>
                          <td class="multi_lines_text">{{ coach.address }}</td>
                          <td class="text-center">
                            <a
                              class="btn btn-default btn-sm"
                              title="{{ 'View' | translate }}"
                              [routerLink]="['/admin/coach/view-coach/' + coach.id]">
                              <i class="fas fa-eye"></i
                            ></a>
                            <a
                              class="btn btn-default btn-sm ml-1"
                              title="{{ 'Edit' | translate }}"
                              [routerLink]="['/admin/coach/edit-coach/' + coach.id]">
                              <i class="fas fa-pencil-alt"></i
                            ></a>
                            <a (click)="deleteRow(i, coach)" class="btn btn-default btn-sm ml-1" title="{{ 'Delete' | translate }}">
                              <i class="fas fa-trash-alt"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination-controls 
                  id="foo" 
                  (pageChange)="pageChange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="row clearfix">
                <div class="card">
                  <div class="body">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="d-flex">{{ 'Show' | translate}}
                          <select
                            [(ngModel)]="itemPerPage"
                            name="selection-datatable_length"
                            aria-controls="selection-datatable"
                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                            <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                              {{ pageCountObj }}
                            </option>
                          </select>
                          {{ 'entries' | translate}}
                        </span>
                      </div>
                      <div class="col-md-9">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'Search' | translate }}"
                            [(ngModel)]="searchText"
                            name="searchText" />
                          <div class="input-group-append" *ngIf="searchText">
                            <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                              >Clear</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th translate>Name</th>
                          <th translate>Email</th>
                          <th translate>Phone</th>
                          <th translate>Gender</th>
                          <th translate>Address</th>
                          <th translate class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let coach of inacticelist
                              | paginate: { itemsPerPage: itemPerPage, currentPage: pagenext, id: 'inactive' }
                              | filter: searchText;
                            let i = index
                          ">
                          <td>
                            {{ coach.first_name }} {{ coach.middle_name }}
                            {{ coach.last_name }}
                          </td>
                          <td>{{ coach.email }}</td>
                          <td>
                            {{ coach.phone }} {{ coach.phonenumber | slice: 0 : 3 }}-{{
                              coach.phonenumber | slice: 3 : 6
                            }}-{{ coach.phonenumber | slice: 6 }}
                          </td>
                          <td class="text-capitalize">{{ coach.gender }}</td>
                          <td class="multi_lines_text">{{ coach.address }}</td>
                          <td>
                            <a
                              class="btn btn-default btn-sm"
                              title="{{ 'View' | translate }}"
                              [routerLink]="['/admin/coach/view-coach/' + coach.id]">
                              <i class="fas fa-eye"></i
                            ></a>
                            <a
                              class="btn btn-default btn-sm"
                              title="{{ 'Edit' | translate }}"
                              [routerLink]="['/admin/coach/edit-coach/' + coach.id]">
                              <i class="fas fa-pencil-alt"></i
                            ></a>
                            <a (click)="InactiveRow(i, coach)" class="btn btn-default btn-sm ml-1" title="{{ 'Delete' | translate }}">
                              <i class="fas fa-trash-alt"></i
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination-controls 
                  id="inactive" 
                  (pageChange)="pagechange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
