import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
  Router,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/core/config/endpoints';

@Injectable({
  providedIn: 'root',
})
export class EventAccessResolver implements Resolve<any> {
  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let userId = route.paramMap.get('id');
      this.Apiservice.getApi(endpoints.studenteventdetails.path + '?id=' + userId).subscribe((data:any) => {
        resolve(data);
        if(data != ''){
          return resolve(data);
        }else{
          return this.router.navigate(['/student/schedule']);
        }
      });
    });
  }
}
