import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClassesRoutingModule } from './classes-routing.module';
import { AddCoachModal } from './components/add-coach-modal/add-coach-modal.component';
import { AddProgramModal } from './components/add-program-modal/add-program-modal.component';
import { AddScheduleModal } from './components/add-schedule-modal/add-schedule-modal.component';
import { AddVideoSectionComponent } from './components/add-video-section/add-video-section.component';
import { EmptyEventsComponent } from './components/empty-events/empty-events.component';
import { EventFormComponent } from './components/event-form/event-form.component';
import { EventsListComponent } from './components/events-list/events-list.component';
import { ScheduleFormComponent } from './components/schedule-form/schedule-form.component';
import { SummaryCardComponent } from './components/summary-card/summary-card.component';
import { ClassesPage } from './pages/classes/classes.page';
import { SingleEventPage } from './pages/single-event/single-event.page';

@NgModule({
  declarations: [
    ClassesPage,
    AddVideoSectionComponent,
    EventsListComponent,
    EmptyEventsComponent,
    SingleEventPage,
    EventFormComponent,
    ScheduleFormComponent,
    SummaryCardComponent,
    AddScheduleModal,
    AddProgramModal,
    AddCoachModal,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CollapseModule,
    ClassesRoutingModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
  ],
  exports: [AddVideoSectionComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ClassesModule {}
