<div class="container-fluid p-0" style="
    background-color: #ffffff">
    <div class="row">
        <!-- image -->

        <!-- sign up form -->

        <div class="col-md-6 d-flex align-items-center justify-content-center">



            <div class="form-group d-flex justify-content-center">
                <a class="navbar-brand" href="#">
                    <!-- <img src="/assets/images/404.jpg" style="
                        margin-left: 550px;
                    " width="900" class="" alt=""> -->
                </a>
            </div>

            <div class="col-md-8 p-5">


                <!-- <img src="../../../../assets/img/school-manage.png" width="350" class="" alt=""> -->
                <h1 style=" color: #601de2; margin-left: 111px;"> 404 </h1>



            </div>



        </div>
    </div>