import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  messages:any=[]
  dashboardcount:object | any;
  membershipExpired: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private Authservice:AuthService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.Apiservice.getApi(endpoints.studentdashboard.path).subscribe(
      (data: any) => {
        console.log(data)
        //Redirect to payment if membership expired
        this.membershipExpired = data.membership_expired;
        if (this.membershipExpired) {
          this.router.navigate(['/student/payment/add-payment'], { queryParams: { status: 'expired' } });
        }

        this.dashboardcount=data
        this.messages=data.messages
      },

      (err: any) => {
      }
    );
  }
}

