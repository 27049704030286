import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicAuthguardGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (
      !!localStorage.getItem('currentUser') &&
      !!JSON.parse(localStorage.getItem('currentUser') || '')['token']
    ) {
      let roleType = JSON.parse(localStorage.getItem('currentUser') || '');
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      return true;
    }
  }
}
