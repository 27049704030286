import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-video-category',
  templateUrl: './video-category.component.html',
  styleUrls: ['./video-category.component.css']
})
export class VideoCategoryComponent implements OnInit {

  rangeForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagenext:any
  itemPerPage = 50;
  classlist: any = [];
  Inactivelist: any = [];
  searchText: any;
  pagecounts: any;
  assignclass: any = [];
  ClassForm: FormGroup | any;
  submitted: boolean = false;
  submit: boolean = false;
  settings: any = [];
  class: any = [];
  checkvalue: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;

    this.getClass();
    this.Apiservice.getApi(endpoints.listassignclass.path).subscribe(
      (data: any) => {
        let superadmin = data.super_admin_class;
        let admin = data.admin_class;
        this.assignclass = superadmin.concat(admin);
        this.assignclass.forEach((s: any, x: any) => {
          let adds = this.ClassForm.get('data') as FormArray;
          adds.push(this.assignClassFormGroup(s.id, x.assigned));
        });

        this.showMsg = true;
      },
      (err: any) => {

      }
    );

    this.ClassForm = this.formBuilder.group({
      data: this.formBuilder.array([]),
    });
    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
    });
  }
  private assignClassFormGroup(id: any, status: any): FormGroup {
    if (status == 1) {
      this.checkvalue = true;
    } else {
      this.checkvalue = false;
    }

    return this.formBuilder.group({
      id: id,
      status: this.checkvalue,
    });
  }
  getClass() {
    this.Apiservice.getApi(endpoints.AdminCategory.path).subscribe((response: any) => {
      this.classlist = response.section.filter((s: any) => s.assign_status == 1);
      this.Inactivelist = response.section.filter((s: any) => s.assign_status == 0);
      this.showMsg = true;
    },
    (err: any) => {

    });
  }
  deleteRow(i: any, staff: any) {
    Swal.fire({
      title: this.translate.instant('Inactive'),
      text: this.translate.instant('Do you want to Inactive ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deleteVideoSection.path + '?id=' + staff.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: this.translate.instant('Status Changed Succesfully'),
              icon: 'success',
            });
            this.getClass();
          },
          (err) => {
            Swal.fire({
              title: this.translate.instant('Invalid Checked In'),
              icon: 'error',
            });
          }
        );
      }
    });
  }
  deleteInActiveRow(i: number, staff: any) {
    Swal.fire({
      title: this.translate.instant('Active'),
      text: this.translate.instant('Do you want to Active ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.activeVideoSection.path + '?id=' + staff.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: this.translate.instant('Status Changed Succesfully'),
              icon: 'success',
            });
            this.getClass();
          },
          (err) => {
            Swal.fire({
              title: this.translate.instant('Status Changed Succesfully'),
              icon: 'error',
            });
          }
        );
      }
    });
  }
  get f() {
    return this.rangeForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.ClassForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.addassignclass.path,
      this.ClassForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.AuthService.assignclass=data;
        this.toaster.success(this.translate.instant('Assign class Added Successfully'));
        //this.getClass();
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Class'));
      }
    );
  }
  onFilterSubmit() {
    this.submit = true;

    if (this.rangeForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.addassignclass.path,
      this.rangeForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Assign class Added Successfully'));
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Class'));
      }
    );
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  pagechange(event: any) {
    this.pagenext = event;
  }

}
