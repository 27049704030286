import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './../../../../shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { ToastrService } from 'ngx-toastr';
import { emit } from 'process';
import { endpoints } from 'src/app/core/config/endpoints'
import { ActivatedRoute } from '@angular/router';
import { IframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-schedule',
  templateUrl: './view-schedule.component.html',
  styleUrls: ['./view-schedule.component.css']
})
export class ViewScheduleComponent implements OnInit {

  @ViewChild('closemsgModal') closemsgModal: ElementRef | any;
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;
  isTouchUIActivated = false;
  rangeForm: FormGroup | any;
  messageForm: FormGroup | any;
  submit: boolean = false;
  searchText: any;
  eventsdetails: any = [];
  eventnotes:any=[];
  activedata: any=[];
  filterData:any=[];
  data:any;
  eventVideos:any=[];
  step = 0;
  schoolname:any;
  userid:any;
  categoryName:any;

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    public cd:ChangeDetectorRef,
    private route: ActivatedRoute,
    public modalService: NgbModal,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.schoolname=this.AuthService.schoolname;
    this.userid=this.AuthService.userid

    this.data = this.route.snapshot.data['message'];
    this.categoryName = this.data.eventDetails.name;
    this.eventsdetails = this.data.eventDetails.event_register;
    this.eventnotes= this.data.eventDetails.notes;
    this.eventVideos= this.data.videoData;

    this.messageForm = this.formBuilder.group({
      event_id: ['', Validators.required],
      to_id: ['', Validators.required],
      message: ['', Validators.required],
    });

  }
  get ff() {
    return this.messageForm.controls;
  }
  setStep(index: number) {
    this.step = index;
  }
  openDialog(section:any,list:any,index:any) {
    const modalRef = this.modalService.open(IframeDialogComponent, {
      size: 'lg',
      backdrop : 'static',
      keyboard : false
    });
    modalRef.componentInstance.link = list;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.name = this.categoryName;
    modalRef.componentInstance.section = section;
  }
  enentdata(event: any) {
    this.AuthService.eventid=event.id

    this.Apiservice.getApi(
      endpoints.coacheventdetails.path + '?id=' + event.id
    ).subscribe((data: any) => {
      this.eventsdetails = data.eventDetails.event_register;
      this.eventnotes= data.eventDetails.notes;
      // this.eventsdetails.forEach((s: any) => {
      //   s.students.forEach((x: any) => {
      //     studentname.push(x);
      //   });
      // });
    });
  }
  eventlist(){
    this.Apiservice.getApi(endpoints.coacheventlist.path).subscribe(
      (data: any) => {
        this.filterData=data.results

        this.activedata=this.filterData[0]
        this.enentdata(this.activedata)

      },(err:any)=>{
        this.toaster.error(err.error.message)

      })
  }
  Studentmessage(event:any){
    this.messageForm.controls['event_id'].setValue(this.AuthService.eventid);
    this.messageForm.controls['to_id'].setValue(event.student_id.id);
  }
  messageSubmit() {
    this.submit = true;
    if (this.messageForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.createmessage.path,
      this.messageForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Message sent Successfully'));
        this.closemsgModal.nativeElement.click();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }

  resetsearch() {
    this.searchText = '';
  }

}
