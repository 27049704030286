import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { UnsavedChangesGuard } from 'src/app/shared/guard/unsaved.guard';
import { AdminBeltResolver } from 'src/app/shared/services/adminbelt.resolver';
import { AdminClassResolver } from 'src/app/shared/services/adminclass.resolver';
import { AdminCoachResolver } from 'src/app/shared/services/admincoach.resolver';
import { AdminEditVideoCategoryResolver } from 'src/app/shared/services/admineditvideocategory.resolver';
import { EditadminpackagesResolver } from 'src/app/shared/services/adminpackage.resolver';
import { AdminProfileResolver } from 'src/app/shared/services/adminprofile.resolver';
import { AdminStaffResolver } from 'src/app/shared/services/adminstaff.resolver';
import { AdminStudentResolver } from 'src/app/shared/services/adminstudent.resolver';
import { AdminViewCoachResolver } from 'src/app/shared/services/adminviewcoach.resolver';
import { AdminViewStudentResolver } from 'src/app/shared/services/adminviewstudent.resolver';
import { AssignClassResolver } from 'src/app/shared/services/assignclass.resolver';
import { SettingResolver } from 'src/app/shared/services/setting.resolver';
import { AdminAuthguardGuard } from '../../shared/services/admin-authguard.guard';
import { AdminComponent } from './admin.component';
import { AddBeltComponent } from './belt/add-belt/add-belt.component';
import { BeltComponent } from './belt/belt.component';
import { ClassesModule } from './classes/classes.module';
import { AddCoachComponent } from './coach/add-coach/add-coach.component';
import { CoachComponent } from './coach/coach.component';
import { ViewCoachComponent } from './coach/view-coach/view-coach.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddMessagesComponent } from './messages/add-messages/add-messages.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { PackagesComponent } from './packages/packages.component';
import { AddPaymentComponent } from './payments/add-payment/add-payment.component';
import { PaymentsComponent } from './payments/payments.component';
import { MyPaymentComponent } from './profile/my-payment/my-payment.component';
import { ProfileComponent } from './profile/profile.component';
import { AddProgramComponent } from './programs/add-program/add-program.component';
import { ProgramsComponent } from './programs/programs.component';
import { ReportComponent } from './report/report.component';
import { SchoolComponent } from './school/school.component';
import { AddStaffComponent } from './staff/add-staff/add-staff.component';
import { StaffComponent } from './staff/staff.component';
import { AddStudentComponent } from './student/add-student/add-student.component';
import { StudentComponent } from './student/student.component';
import { ViewStudentComponent } from './student/view-student/view-student.component';
import { AddVideoComponent } from './video-category/add-video/add-video.component';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { VideoLibraryComponent } from './video-library/video-library.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    resolve: { message: SettingResolver, class: AssignClassResolver },
    canActivate: [AdminAuthguardGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
      {
        path: 'classes',
        loadChildren: () => import('../admin/classes/classes.module').then((m) => m.ClassesModule),
      },
      {
        path: 'staff',
        component: StaffComponent,
      },
      {
        path: 'staff/add-staff',
        component: AddStaffComponent,
      },
      {
        path: 'staff/edit-staff/:id',
        component: AddStaffComponent,
        resolve: { message: AdminStaffResolver },
      },
      {
        path: 'school',
        component: SchoolComponent,
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'programs',
        component: ProgramsComponent,
      },
      {
        path: 'programs/add-program',
        component: AddProgramComponent,
      },
      {
        path: 'programs/edit-program/:id',
        component: AddProgramComponent,
        resolve: { message: AdminClassResolver },
      },
      {
        path: 'video-category',
        component: VideoCategoryComponent,
      },
      {
        path: 'video-category/add-video-category',
        component: AddVideoComponent,
      },
      {
        path: 'video-category/edit-video-category/:id',
        component: AddVideoComponent,
        resolve: { message: AdminEditVideoCategoryResolver },
      },
      {
        path: 'coach',
        component: CoachComponent,
      },
      {
        path: 'coach/add-coach',
        component: AddCoachComponent,
      },
      {
        path: 'coach/edit-coach/:id',
        component: AddCoachComponent,
        resolve: { message: AdminCoachResolver },
      },
      {
        path: 'coach/view-coach/:id',
        component: ViewCoachComponent,
        resolve: { message: AdminViewCoachResolver },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        resolve: { message: AdminProfileResolver },
      },
      {
        path: 'student',
        component: StudentComponent,
      },
      {
        path: 'student/add-student',
        component: AddStudentComponent,
        resolve: { message: AdminStudentResolver },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'student/edit-student/:id',
        component: AddStudentComponent,
        resolve: { message: AdminStudentResolver },
        canDeactivate: [UnsavedChangesGuard],
      },
      {
        path: 'student/view-student/:id',
        component: ViewStudentComponent,
        resolve: { message: AdminViewStudentResolver },
      },
      {
        path: 'payment',
        component: PaymentsComponent,
      },
      {
        path: 'payment/my-payment',
        component: MyPaymentComponent,
      },
      {
        path: 'payment/add-payment',
        component: AddPaymentComponent,
      },
      {
        path: 'payment/add-payment/:id',
        component: AddPaymentComponent,
      },
      {
        path: 'packages',
        component: PackagesComponent,
      },
      {
        path: 'packages/add-packages',
        component: AddPackagesComponent,
      },
      {
        path: 'packages/edit-packages/:id',
        component: AddPackagesComponent,
        resolve: { message: EditadminpackagesResolver },
      },
      {
        path: 'belt',
        component: BeltComponent,
      },
      {
        path: 'belt/add-belt',
        component: AddBeltComponent,
      },
      {
        path: 'belt/edit-belt/:id',
        component: AddBeltComponent,
        resolve: { message: AdminBeltResolver },
      },
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'messages/add-messages',
        component: AddMessagesComponent,
      },
      {
        path: 'messages/edit-messages/:id',
        component: AddMessagesComponent,
      },
      {
        path: 'messages/edit-message/:id',
        component: AddMessagesComponent,
      },
      {
        path: 'report',
        component: ReportComponent,
      },
      {
        path: 'video-library',
        component: VideoLibraryComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ClassesModule],
  exports: [RouterModule],
})
export class AdminRoutingModule {
  static components = [DashboardComponent];
}
