import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  pageTitle$: BehaviorSubject<string> = new BehaviorSubject('TatamiApp');
  redirectBack$: BehaviorSubject<string> = new BehaviorSubject('');
  displayGoBack$ = new BehaviorSubject(false);

  constructor() {}
}
