import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ConvertToRadioOption, RadioGroupItem } from 'src/app/shared/components/radio-group/radio-group.helper';
import { CoachEntity, ProgramEntity } from '../../state/events.interface';
import { EventsRepository } from '../../state/events.repository';
import { AddCoachModal } from '../add-coach-modal/add-coach-modal.component';
import { AddProgramModal } from '../add-program-modal/add-program-modal.component';

export interface EventsFormType {
  program: FormControlTyped<string>;
  name: FormControlTyped<string>;
  coach: FormControlTyped<string>;
  max_registration: FormControlTyped<number>;
  description: FormControlTyped<string>;
  early_checkin_minutes: FormControlTyped<number>;
  late_checkin_minutes: FormControlTyped<number>;
}

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss'],
})
export class EventFormComponent implements OnInit {
  @Input() groupName!: string;

  showProgramModal: boolean = false;
  showCoachModal: boolean = false;
  eventForm: FormGroup = new FormGroup({});
  programsList: RadioGroupItem[] = [];
  coachList: RadioGroupItem[] = [];

  coachesSub$: Subscription | undefined;
  programsSub$: Subscription | undefined;
  eventFormSub$: Subscription | undefined;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private eventsRepo: EventsRepository,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.eventForm = this.rootFormGroup.control.get(this.groupName) as FormGroupTyped<EventsFormType>;
    this.eventForm.controls['program'].setValidators(this.validateProgram.bind(this));
    this.eventForm.controls['coach'].setValidators(this.validateCoach.bind(this));

    this.fetchRadioInfo();
  }

  fetchRadioInfo() {
    this.programsSub$ = this.eventsRepo.programs$.subscribe((programs) => {
      this.programsList = ConvertToRadioOption(programs, 'program_id', 'name') || [];
    });

    this.coachesSub$ = this.eventsRepo.coaches$.subscribe((coaches) => {
      this.coachList = ConvertToRadioOption(coaches, 'user_id', 'name') || [];
    });
  }

  onAddProgram() {
    const modalRef = this.modalService.open(AddProgramModal, {
      centered: true,
      modalDialogClass: 'c-modal_custom',
    });
    modalRef.componentInstance.allPrograms = this.programsList;

    modalRef.componentInstance.clickevent.subscribe((program: ProgramEntity) => {
      this.eventForm.controls['program'].setValue(program.program_id);
    });
  }

  onAddCoach() {
    const modalRef = this.modalService.open(AddCoachModal, {
      centered: true,
      modalDialogClass: 'c-modal_custom',
    });

    modalRef.componentInstance.clickevent.subscribe((coach: CoachEntity) => {
      this.eventForm.controls['coach'].setValue(coach.user_id);
    });
  }

  ngOnDestroy() {
    this.eventFormSub$?.unsubscribe();
    this.coachesSub$?.unsubscribe();
    this.programsSub$?.unsubscribe();
  }

  validateProgram(control: AbstractControl): ValidationErrors | null {
    const programValue = control.value;
    const isValid = this.programsList.some((program) => program.id === programValue);
    return isValid ? null : { invalidProgram: true };
  }

  validateCoach(control: AbstractControl): ValidationErrors | null {
    const coachValue = control.value;
    const isValid = this.coachList.some((coach) => coach.id === coachValue);
    return isValid ? null : { invalidCoach: true };
  }
}
