import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;

  rangeForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  listPayment:any

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.paymentList()
    this.rangeForm = this.formBuilder.group({
      start_time: [''],
      end_time: [''],
    });
  }
  get f() {
    return this.rangeForm.controls;
  }
    paymentList(){
      this.Apiservice.getApi(endpoints.coachpayment.path+"?coach="+this.AuthService.userid).subscribe((data: any) => {
        this.listPayment=data
        
      });
    }
    private convert(str:any) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
    OnSelect(event:any){
      if(event?.startDate){
        let startdate=this.convert(event.startDate._d)
        let enddate=this.convert(event.endDate._d)
        this.rangeForm.controls['start_time'].setValue(startdate)
        this.rangeForm.controls['end_time'].setValue(enddate)
      }
    }

    onSubmit() {
      this.submitted = true;
      if(this.rangeForm.invalid){
        this.toaster.error(this.translate.instant('Select a from and to date or student to submit'))
        return
      }
      if (this.rangeForm.invalid) {
        return;
      }
      this.Apiservice.getApi(
        endpoints.coachpayment.path+"?start_time="+this.rangeForm.value.start_time+"&end_time="+this.rangeForm.value.end_time+"&user_id="+this.AuthService.userid,
      ).subscribe(
        (data: any) => {
          this.listPayment=data
        },
        (err: any) => {
        }
      );
    }
    clear(){
      this.daterangepicker.clear()
      this.rangeForm.reset()
      this.paymentList()
    }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
