<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 *ngIf="!routerid" translate>Add Program</h2>
          <h2 *ngIf="routerid" translate>Edit Program</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="float-right mb-4">
            <a class="btn-sm auth-button" [routerLink]="['/superadmin/class']" translate
              ><i class="fas fa-bars mr-1"></i>All</a
            >
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto">
          <form [formGroup]="classForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label class="mt-1" translate>Program<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Program' | translate }}"
                maxlength="50"
                formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required" translate>Program is required</div>
              </div>
            </div>
            <button type="submit" *ngIf="!routerid" class="btn btn-dark float-right auth-button mt-4" translate>
              Save
            </button>
            <button type="submit" *ngIf="routerid" class="btn btn-dark float-right auth-button mt-4" translate>
              Update
            </button>
            <a [routerLink]="['/superadmin/class']" class="btn btn-dark float-right mr-2 mt-4 text-white" translate
              >Cancel</a
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
