import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ChoiceChipComponent } from '../choice-chip/choice-chip.component';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements AfterContentInit, OnDestroy {
  @Input() separateItems: boolean = false;
  @ContentChildren(ChoiceChipComponent) chips!: QueryList<ChoiceChipComponent>;
  @Output() chipSelected = new EventEmitter<string>();
  tooltip: string = '';

  private subscriptions: Subscription[] = [];

  constructor() {}

  ngAfterContentInit() {
    this.chips.forEach((chip) => {
      this.subscriptions.push(
        chip.chipSelectionChanged.subscribe((output) => {
          this.chipSelected.emit(output.value);
          this.tooltip = output.tooltip;
          this.unselectDifferentChips(output.value);
        })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  unselectDifferentChips(value: string) {
    this.chips.forEach((chip) => {
      if (chip.value !== value) {
        chip.selected = false;
      }
    });
  }
}
