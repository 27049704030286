<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2 translate>Attendance</h2>
                </div>
            </div>
            <div class="auth-form">
                <app-student-attendance></app-student-attendance>
            </div>
        </div>
    </div>
    
</div>
