import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @Output() CountryValue: EventEmitter<any> = new EventEmitter();
  submitted = false;
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagenext: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  selectedFile: File | any = null;
  name: any;
  generalForm: FormGroup | any;
  securityForm: FormGroup | any;
  userForm: FormGroup | any;
  userlist: any = [];
  Inactiveuser: any = [];
  userdata: any;
  routerid: any;
  countryCodeList: any = [];
  password: any;
  maxDigit: any;
  minDigit: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.password = this.AuthService.firstpassword;
    setTimeout(() => {
      this.AuthService.firstpassword = false;
    }, 3000);
    this.pagecounts = this.Apiservice.pageCounts;
    this.getUser();
    this.data = this.route.snapshot.data?.message?.data;

    this.generalForm = this.formBuilder.group({
      photo: [''],
      first_name: [this.data.first_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      last_name: [this.data.last_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      email: [
        this.data.email,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
        ]),
      ],
      phone: [this.data.phone, Validators.required],
      phonenumber: [this.data.phonenumber, [Validators.required, Validators.pattern('[- +()0-9]+')]],
      dob: [this.data.dob, Validators.required],
      address: [this.data.address, Validators.required],
      countryCode: [this.data.country_code, Validators.required],
      stateCode: [this.data.state_code, Validators.required],
      city: [this.data.city, Validators.required],
      zip_code: [this.data.zip_code, Validators.required],
      gender: [this.data.gender, Validators.required],
    });
    this.securityForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$')]],
    });
    if (this.data.middle_name != null) {
      this.generalForm.controls['middle_name'].setValue(this.data.middle_name);
    }

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    if (this.generalForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.generalForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }

  get f() {
    return this.generalForm.controls;
  }
  get ff() {
    return this.securityForm.controls;
  }

  getUser() {
    this.Apiservice.getApi(endpoints.listuserprofile.path).subscribe(
      (data: any) => {
        this.userlist = data.data.filter((s: any) => s.status == 1);
        this.Inactiveuser = data.data.filter((s: any) => s.status == 0);
        this.showMsg = true;
      },
      (err: any) => {}
    );
  }
  onSubmit() {
    this.submitted = true;

    if (this.generalForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('photo', this.generalForm.get('photo').value);
    let formfile = [
      'first_name',
      'middle_name',
      'last_name',
      'email',
      'phone',
      'phonenumber',
      'dob',
      'address',
      'countryCode',
      'stateCode',
      'city',
      'zip_code',
      'gender',
    ];
    formfile.forEach((name: string) => {
      formData.append(name, this.generalForm.get(name).value);
    });
    this.Apiservice.postApi(endpoints.updatesuperadminprofile.path, formData).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Superadmin profile updated Successfully'));
        this.router.navigate(['/superadmin/dashboard']);
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Profile'));
      }
    );
  }
  onPassSubmit() {
    this.submit = true;

    if (this.securityForm.invalid) {
      return;
    }
    // if(this.securityForm.value.password!=this.securityForm.value.password_confirmation){
    //   this.toaster.error(this.translate.instant('The password confirmation and password must match.'))
    //   return
    // }
    this.Apiservice.postApi(endpoints.changePassword.path, this.securityForm.value).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(data.message);
        this.AuthService.logOut();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.generalForm.patchValue({
          photo: file,
        });
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  deleteRow(i: any, user: any) {
    Swal.fire({
      title: this.translate.instant('Inactive'),
      text: this.translate.instant('Do you want to Inactive ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(endpoints.deleteuserprofile.path + '?id=' + user.id).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.getUser();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  deleteActiveRow(i: any, user: any) {
    Swal.fire({
      title: this.translate.instant('Active'),
      text: this.translate.instant('Do you want to Active ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(endpoints.activateuserprofile.path + '?id=' + user.id).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.getUser();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }

  resetsearch() {
    this.searchText = '';
  }

  pageChange(event: any) {
    this.page = event;
  }
  pagechange(event: any) {
    this.pagenext = event;
  }
  Countrychange(event: any) {
    this.generalForm.controls['countryCode'].setValue(event.countryCode);
    this.generalForm.controls['stateCode'].setValue(event.stateCode);
    this.generalForm.controls['city'].setValue(event.city);
    this.generalForm.controls['zip_code'].setValue(event.zip_code);
  }
  conutyphone(event: any) {
    this.generalForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }
}
