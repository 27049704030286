import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest, finalize, switchMap } from 'rxjs';
import { CancelModal } from 'src/app/shared/components/cancel-modal/cancel-modal.component';
import { HeaderService } from '../../../header/state/header.service';
import { NewClassSchedule, NewClassesResquest, Schedules, SummaryDetails } from '../../state/events.interface';
import { EventsRepository } from '../../state/events.repository';
import { EventsService } from '../../state/events.service';

@Component({
  selector: 'app-single-event',
  templateUrl: './single-event.page.html',
  styleUrls: ['./single-event.page.scss'],
})
export class SingleEventPage implements OnInit {
  form: FormGroup;
  headerTitle: 'CREATE_CLASS' | 'EDIT_CLASS' = 'CREATE_CLASS';
  loadingPage: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  classId: string | undefined = undefined;
  activeStep: number = 1;
  currentClassName: string = '';
  summaryDetails: SummaryDetails = {
    programName: '',
    coachName: '',
    coachImage: undefined,
    className: '',
  };

  urlSub$: Subscription | undefined = undefined;
  eventFormSub$: Subscription | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private eventsRepo: EventsRepository,
    private eventsService: EventsService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private headerService: HeaderService,
    private modalService: NgbModal
  ) {
    this.form = this.fb.group({
      event: this.fb.group({
        program: ['', Validators.required],
        name: this.fb.control('', {
          updateOn: 'blur',
          validators: [
            Validators.minLength(3),
            Validators.required,
            Validators.pattern(/^(?=(?:\s*\S){3,})[\s\S]*$/), // This regex will ensure that the string contains at least one non-space character and allows for spaces in between words.
          ],
        }),
        coach: ['', Validators.required],
        max_registration: [null, [Validators.required, Validators.min(0)]],
        description: '',
        early_checkin_minutes: [15, [Validators.required, Validators.min(0)]],
        late_checkin_minutes: [15, [Validators.required, Validators.min(0)]],
      }),
      schedule: this.fb.group({
        schedules: [[], Validators.required],
        start_date: [moment().format('YYYY-MM-DD'), Validators.required],
        end_date: null,
      }),
    });
  }

  get canProceed(): boolean {
    return this.form.get('event')?.valid || false;
  }

  ngOnInit(): void {
    this.getClassFromUrl();
    this.headerService.pageTitle$.next(this.translate.instant(this.headerTitle));
    this.headerService.redirectBack$.next('/admin/events');
    this.headerService.displayGoBack$.next(true);

    this.getEventFormInfo();

    if (this.isEdit) {
      this.loadForm();
    }
  }

  getClassFromUrl() {
    this.urlSub$ = this.route.url.subscribe((urlSegments) => {
      if (urlSegments[0].path === 'edit') {
        this.headerTitle = 'EDIT_CLASS';
        this.isEdit = true;
        this.classId = urlSegments[1].path;
      }
    });
  }

  loadForm() {
    if (!this.classId) {
      this.toastr.error(this.translate.instant('MESSAGE_TRY_AGAIN_SOON'));
      this.router.navigate(['/admin/events']);
      return;
    }

    this.loadingPage = true;

    this.eventsService
      .getClassById(this.classId)
      .pipe(finalize(() => (this.loadingPage = false)))
      .subscribe({
        next: (response) => {
          this.form.patchValue(
            {
              event: {
                program: response.program.program_id,
                name: response.name,
                coach: response.coach.user_id,
                max_registration: response.max_registration,
                description: response.description,
                early_checkin_minutes: response.early_checkin_minutes,
                late_checkin_minutes: response.late_checkin_minutes,
              },
              schedule: {
                schedules: this.formatApiScheduleData(response.schedules),
                start_date: response.start_date,
                end_date: response.end_date,
              },
            },
            { emitEvent: true }
          );
        },
        error: (err) => {
          this.toastr.error(err.error.message);
          this.router.navigate(['/admin/events']);
        },
      });
  }

  getEventFormInfo() {
    this.eventFormSub$ = this.form
      .get('event')
      ?.valueChanges.pipe(
        switchMap((value) => {
          this.currentClassName = value.name;
          return combineLatest([
            this.eventsRepo.getProgramById(value.program),
            this.eventsRepo.getCoachById(value.coach),
          ]);
        })
      )
      .subscribe(([program, coach]) => {
        this.summaryDetails = {
          programName: program?.name ?? '',
          coachName: coach?.name ?? '',
          coachImage: coach?.photo ?? undefined,
          className: this.currentClassName,
        };
      });
  }

  slideTo(step: number) {
    if (this.canProceed) this.activeStep = step;
    return;
    // el.scrollIntoView({ behavior: 'smooth' });
  }

  submitClass() {
    if (this.form.invalid || !this.canProceed || this.isLoading) return;
    const body = this.buildPayload();
    this.isLoading = true;

    if (this.isEdit) {
      this.updateClass(body);
    } else {
      this.createClass(body);
    }
  }

  private createClass(body: NewClassesResquest) {
    this.eventsService
      .createClass(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastr.success(this.translate.instant('MESSAGE_CLASS_CREATE'));
          this.router.navigate(['/admin/events']);
        },
        error: (err) => {
          this.toastr.error(err.error.message);
        },
      });
  }

  private updateClass(body: NewClassesResquest) {
    if (!this.classId) {
      this.getClassFromUrl();
      this.toastr.error(this.translate.instant('MESSAGE_TRY_AGAIN_SOON'));
      return;
    }

    this.eventsService
      .updateClass(this.classId, body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.toastr.success(this.translate.instant('MESSAGE_CLASS_UPDATE'));
          this.router.navigate(['/admin/events']);
        },
        error: (err) => {
          this.toastr.error(err.error.message);
        },
      });
  }

  private buildPayload(): NewClassesResquest {
    const { event, schedule } = this.form.value;
    const payload: NewClassesResquest = {
      name: event.name,
      description: event.description,
      program_id: event.program,
      coach_id: event.coach,
      start_date: schedule.start_date,
      end_date: schedule.end_date,
      max_registration: event.max_registration,
      early_checkin_minutes: event.early_checkin_minutes,
      late_checkin_minutes: event.late_checkin_minutes,
      schedules: [],
    };

    schedule.schedules.forEach((item: any) => {
      payload.schedules.push({
        recurrence: item.recurrence,
        sequence: item.sequence,
        day: item.day,
        start_time: item.start_time,
        end_time: item.end_time,
      });
    });

    return payload;
  }

  private formatApiScheduleData(apiSchedule: Schedules[]): NewClassSchedule[] {
    if (!apiSchedule.length) return [];

    let newSchedule: NewClassSchedule[] = [];
    apiSchedule.forEach((item) => {
      const convertedSchedule: NewClassSchedule = {
        recurrence: item?.recurrence_value,
        sequence: item?.sequence_value || null,
        day: item?.day_value,
        start_time: item.start_time,
        end_time: item.end_time,
      };
      newSchedule.push(convertedSchedule);
    });
    return newSchedule;
  }

  goToEventList() {
    const modalRef = this.modalService.open(CancelModal, {
      centered: true,
      modalDialogClass: 'c-modal_custom c-modal_custom--sm',
    });
    modalRef.componentInstance.confirmTitle = this.translate.instant(
      this.isEdit ? 'CONFIRM_ABORT_UPDATE_TITLE' : 'CONFIRM_ABORT_CREATION_TITLE'
    );
    modalRef.componentInstance.confirmText = this.translate.instant('CONFIRM_ABORT_CREATION_TEXT');

    modalRef.componentInstance.clickevent.subscribe((action: string) => {
      if (action === 'confirm') this.router.navigate(['/admin/events']);
    });
  }

  ngOnDestroy() {
    this.eventFormSub$?.unsubscribe();
    this.urlSub$?.unsubscribe();
  }
}
