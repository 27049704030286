<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Dashboard</h2>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-lg-4 col-md-4">
                  <div class="card dash-body" style="background: #66ff33">
                    <div class="body">
                      <div class="row text-white align-items-center">
                        <div class="col-md-6 text-center border-right">
                          <i class="far fa-edit"></i>
                          <p translate>No. of Schools</p>
                        </div>
                        <div class="col-md-6 text-center">
                          <h4>{{ dashboardcount?.schools }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="card dash-body" style="background: #ff5c33">
                    <div class="body">
                      <div class="row text-white align-items-center">
                        <div class="col-md-6 text-center border-right">
                          <i class="far fa-flag"></i>
                          <p translate>Total Income / Month</p>
                        </div>
                        <div class="col-md-6 text-center">
                          <h4>{{ dashboardcount?.income.total_amount || 0 }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4">
                  <div class="card dash-body" style="background: #4da6ff">
                    <div class="body">
                      <div class="row text-white align-items-center">
                        <div class="col-md-6 text-center border-right">
                          <i class="far fa-folder"></i>
                          <p translate>Total Expense / Month</p>
                        </div>
                        <div class="col-md-6 text-center">
                          <h4>{{ dashboardcount?.expenses.total_amount || 0 }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="header">
            <h2 class="dashboard-h2 mb-2" translate>Cash Flow Overview</h2>
          </div>
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="piechartsize">
                    <div class="chart">
                      <canvas
                        baseChart
                        [data]="pieChartData"
                        [type]="pieChartType"
                        [options]="pieChartOptions"
                        [plugins]="pieChartPlugins">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="header">
            <h2 class="dashboard-h2 mb-2" translate>Latest Registered Schools</h2>
          </div>
          <div class="card-body bgwhite">
            <div class="box-border p-4">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th translate>Reg.No</th>
                      <th translate>Name</th>
                      <th translate>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let school of schoollist">
                      <td>{{ school.id }}</td>
                      <td>{{ school.name }}</td>
                      <td>{{ school.created_at | date: 'dd-MM-YYYY' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="header">
            <h2 class="dashboard-h2 mb-2" translate>Latest Payments</h2>
          </div>
          <div class="card-body bgwhite">
            <div class="box-border p-4">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th translate>School Name</th>
                      <th translate>Transaction Id</th>
                      <th translate>Transaction Date</th>
                      <th translate>Packages</th>
                      <th translate>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let payment of paymentlist">
                      <td>{{ payment.school.name }}</td>
                      <td>{{ payment.transaction_id }}</td>
                      <td>{{ payment.transaction_date }}</td>
                      <td *ngIf="payment.package">{{ payment.package.plan_name }}</td>
                      <td>R$ {{ payment.amount }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
