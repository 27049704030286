import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-divisor',
  templateUrl: './divisor.component.html',
  styleUrls: ['./divisor.component.scss'],
})
export class DivisorComponent implements OnInit {
  @Input() label: string = '';
  @Input() backgroundColor: string = '';
  @Input() fontColor: string = '';

  constructor() {}

  ngOnInit(): void {}
}
