<article
  class="c-card c-class-card"
  [class.c-card--blue]="status === 'upcoming'"
  [class.c-card--disabled]="isClassClosed()">
  <span
    *ngIf="status !== 'open'"
    class="c-card__text u-font-weight--semibold"
    [class.u-color--blue-700]="status === 'upcoming'"
    [class.u-color--orange-300]="status === 'open_with_expiration'"
    [class.u-color--gray-700]="isClassClosed()"
    >{{ handleStatusMessage() }}</span
  >
  <div class="c-card__text u-position--relative" [class.c-card__text--disabled]="isClassClosed()">
    <div *ngFor="let date of visibleDates">
      <span>{{ getFormatedDays(date.days) }}</span>
      <span> {{ 'EVENTS_PAGE.FROM' | translate }} </span>
      <span class="u-font-weight--semibold">{{ formatTime(date.start_time) }}</span>
      <span> {{ 'EVENTS_PAGE.TO' | translate }} </span>
      <span class="u-font-weight--semibold">{{ formatTime(date.end_time) }}</span>
    </div>
    <span
      *ngIf="hiddenDatesQuantity > 0"
      class="c-card__text--link u-cursor--clickable"
      [class.c-card__text--link-disabled]="isClassClosed"
      (mouseover)="toggleDatesList(true, $event)"
      (mouseleave)="toggleDatesList(false, $event)"
      (click)="toggleDatesList(true, $event)"
      >{{ 'AND' | translate }} {{ hiddenDatesQuantity }} {{ 'OTHERS' | translate }}...</span
    >

    <ng-container [ngTemplateOutlet]="isMobile ? mobileDates : desktopDates"></ng-container>
  </div>

  <div class="c-card_content">
    <ng-container [ngTemplateOutlet]="coachInfo"></ng-container>
  </div>

  <div class="c-card_footer">
    <div class="c-card_footer_tag-container c-card__text">
      <span class="c-card_footer__tag" [class.c-card_footer__tag--disabled]="isClassClosed()"
        >{{ class?.program?.name }}
      </span>
      <span class="c-card_footer__tag" [class.c-card_footer__tag--disabled]="isClassClosed()"
        >{{ class?.max_registration }}
        <ion-icon name="person"></ion-icon>
      </span>
    </div>

    <div class="c-card_footer_actions">
      <app-button
        class="c-class-card__menu-button"
        icon="ellipsis-horizontal"
        [iconOnly]="true"
        [variant]="isClassClosed() ? 'gray' : 'secondary'"
        (click)="showMenu = !showMenu"></app-button>
      <app-button
        class="c-class-card__action-button"
        icon="chatbox-ellipses"
        [iconOnly]="true"
        [variant]="isClassClosed() ? 'gray' : 'secondary'"
        (click)="onActionClick('add-note')"></app-button>
      <app-button
        class="c-class-card__action-button"
        icon="pencil"
        [iconOnly]="true"
        [variant]="isClassClosed() ? 'gray' : 'secondary'"
        (click)="onActionClick('edit-class')"></app-button>
      <app-button
        *ngIf="isClassOpen()"
        class="c-class-card__action-button"
        icon="lock-open"
        [iconOnly]="true"
        variant="untoggled"
        (click)="onActionClick('close-class')"></app-button>
      <app-button
        *ngIf="!isClassOpen()"
        class="c-class-card__action-button"
        icon="lock-closed"
        [iconOnly]="true"
        variant="primary"
        (click)="onActionClick('open-class')"></app-button>
      <div *ngIf="showMenu">
        <app-fab-list>
          <app-fab
            [label]="'EVENTS_PAGE.ADD_NOTE' | translate"
            icon="chatbox-ellipses"
            (click)="onActionClick('add-note')"></app-fab>
          <app-fab
            [label]="'EVENTS_PAGE.EDIT_CLASS' | translate"
            icon="pencil"
            (click)="onActionClick('edit-class')"></app-fab>
          <app-fab
            *ngIf="!isClassClosed()"
            [label]="'EVENTS_PAGE.CLOSE_CLASS' | translate"
            icon="lock-open"
            variant="untoggled"
            (click)="onActionClick('close-class')"></app-fab>
          <app-fab
            *ngIf="isClassClosed()"
            [label]="'EVENTS_PAGE.OPEN_CLASS' | translate"
            icon="lock-closed"
            variant="toggled"
            (click)="onActionClick('open-class')"></app-fab>
          <app-fab icon="close" variant="close" (click)="closeMenu()"></app-fab>
        </app-fab-list>
      </div>
    </div>
  </div>
</article>

<!-- Coach info -->
<ng-template #coachInfo>
  <div class="c-card_content_info">
    <img
      class="c-card_content_info__img"
      [class.c-card_content_info__img--disabled]="isClassClosed()"
      [src]="class?.coach?.photo ?? 'assets/images/placeholder-class.png'"
      alt="coach picture" />
    <div class="c-card__text u-display--flex u-flex-direction--column">
      <span class="c-card__text--title" [class.c-card__text--disabled]="isClassClosed()">{{ class?.name }}</span>
      <span class="c-card__text--subtitle" [class.c-card__text--disabled]="isClassClosed()">
        {{ class?.coach?.name }}
      </span>
    </div>
  </div>
</ng-template>

<!-- Desktop dates tooltip -->
<ng-template #desktopDates>
  <ul class="e-card__tooltip" [class.show]="showHiddenDates">
    <li *ngFor="let date of hiddenDates">
      <span>{{ getFormatedDays(date.days) }}</span>
      <span> {{ 'EVENTS_PAGE.FROM' | translate }} </span>
      <span>{{ formatTime(date.start_time) }}</span>
      <span> {{ 'EVENTS_PAGE.TO' | translate }} </span>
      <span>{{ formatTime(date.end_time) }}</span>
    </li>
  </ul>
</ng-template>

<!-- Mobile dates Modal -->
<ng-template #mobileDates>
  <app-modal *ngIf="showHiddenDates" [(show)]="showHiddenDates" [showCloseButton]="true">
    <ng-container slot="header" [ngTemplateOutlet]="coachInfo"></ng-container>

    <ul class="e-dates-modal-content">
      <li *ngFor="let date of allDates">
        <span>{{ getFormatedDays(date.days) }}</span>
        <span> {{ 'EVENTS_PAGE.FROM' | translate }} </span>
        <span class="u-font-weight--semibold">{{ formatTime(date.start_time) }}</span>
        <span> {{ 'EVENTS_PAGE.TO' | translate }} </span>
        <span class="u-font-weight--semibold">{{ formatTime(date.end_time) }}</span>
      </li>
    </ul>

    <ng-container slot="footer">
      <app-button icon="pencil" variant="secondary">{{ 'EDIT' | translate }}</app-button>
    </ng-container>
  </app-modal>
</ng-template>
