import { Component, HostListener, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';
import { endpoints } from '../../../core/config/endpoints';
import { HeaderService } from './state/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isFullScreen: boolean | any;
  msglist: any = [];
  language: any;
  readnotify: any = [];
  userBankAccount: any = '0';
  userval: any;
  isMobile: boolean = false;
  maxMobileSize: number = 576;

  closePage: string | undefined;
  closePageSub$: Subscription;
  readonly pageTitle$: BehaviorSubject<string>;
  readonly displayGoBack$: BehaviorSubject<boolean>;

  constructor(
    private authservice: AuthService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private Apiservice: ApiService,
    private translate: TranslateService,
    private router: Router,
    private service: HeaderService,
    public modalService: NgbModal
  ) {
    // this.userBankAccount = this.authservice.userBankAccount;
    // console.log("this.userBankAccount", this.userBankAccount);
    this.language = localStorage.getItem('language') || 'pt-br';
    translate.setDefaultLang(this.language);
    translate.addLangs(['en', 'es', 'pt-br']);
    this.isMobile = window.innerWidth < this.maxMobileSize;

    // header config
    this.pageTitle$ = this.service.pageTitle$;
    this.displayGoBack$ = this.service.displayGoBack$;
    this.closePageSub$ = this.service.redirectBack$.subscribe((url) => (this.closePage = url));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  ngOnInit(): void {
    let value: any = localStorage.getItem('currentUser');

    let filterValue = JSON.parse(value);
    this.userBankAccount = filterValue.school.bankAccount;
    this.userval = filterValue.user_id;
    // console.log('this.userBankAccount', this.userval);
    let limit = 5;
    this.Apiservice.getApi(endpoints.latestMessage.path + '?limit=' + limit).subscribe((data: any) => {
      this.msglist = data.list;
    });
    // this.Apiservice.getApi(endpoints.msglist.path).subscribe((data: any) => {
    //   let response = data.list;
    //   let newData = response.filter((e: any, i: number) =>response.findIndex((s: any) => e.message_id === s.message_id) === i,);
    //   let myData:any=[] = newData.filter((item: any) => item.from_id == this.authservice.userid || item.to_id == this.authservice.userid,);
    //   if(myData.length>0){
    //     let newDataList = myData.map((i: any) =>i.from_user.id == this.authservice.userid? {...i,label: i.user?.first_name + ' ' + i.user?.last_name,}: {...i,label: i.from_user?.first_name + ' ' + i.from_user?.last_name,},);
    //     this.msglist=newDataList.slice(0, 5)
    //   }
    // })
    this.Apiservice.getApi(endpoints.latestnotify.path).subscribe((data: any) => {
      this.readnotify = data.latest;
    });
  }

  goBack() {
    if (!this.closePage) return;

    this.router.navigateByUrl(this.closePage);
  }

  logOut() {
    Swal.fire({
      title: this.translate.instant('Log Out'),
      text: this.translate.instant('Do you want to Log off ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: 'rgb(0, 64, 128)',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.authservice.logOut();
      }
    });
  }

  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle('offcanvas-active');
    // document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }

  openfullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen || elem.requestFullscreen;
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem);
    }
    this.isFullScreen = true;
  }

  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) {
      /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) {
      /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  useLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translate.use(language);
  }

  addbank() {
    const body = {
      user_id: this.userval,
    };
    this.Apiservice.postApi(endpoints.accounttoken.path + '?user_id=' + this.userval, body).subscribe(
      (data: any) => {
        if (data.response.redirect == true) {
          window.open(data.response.data.verification, '_blank');
        }
      }
    );
    // payment/createAccount?user_id=3
    // const modalRef = this.modalService.open(AddaccountComponent, {
    //   size: 'lg',
    // });
    // modalRef.result.then((result:any) => {
    // });
  }

  ngOnDestroy() {
    this.closePageSub$.unsubscribe();
  }
}
