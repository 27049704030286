<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <ul class="nav nav-pills" id="nav-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          [ngClass]="{ active: password != true }"
          id="pills-home-tab"
          data-toggle="pill"
          href="#pills-home"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          translate
          ><i class="fas fa-home mr-2"></i>General</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          [ngClass]="{ active: password == true }"
          id="pills-profile-tab"
          data-toggle="pill"
          href="#pills-profile"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          translate
          ><i class="fas fa-lock mr-2"></i>Security</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="pills-payment-tab"
          data-toggle="pill"
          href="#pills-payment"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          translate
          ><i class="fas fa-money-check-alt mr-2"></i>My Payments</a
        >
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{ active: password != true }"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab">
        <div class="block-header">
          <div class="row clearfix">
            <div class="card">
              <form [formGroup]="generalForm">
                <div class="card-body">
                  <div class="row mt-4">
                    <div class="col-lg-3">
                      <div class="body text-center">
                        <label for="profileImage">
                          <img
                            src="{{ data.photo || 'assets/images/user.png' }}"
                            class="rounded-circle"
                            id="profilePicPreview"
                            onerror="this.onerror=null;this.src='assets/images/img_avatar.png';"
                            alt="" />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="profileImage"
                          name="photo"
                          style="display: none"
                          (change)="onFileSelected($event)" />
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>First Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'First Name' | translate }}"
                              formControlName="first_name"
                              minlength="2"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                              <div *ngIf="f.first_name.errors.required" translate>First Name is required</div>
                              <div *ngIf="f.first_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>Middle Name</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Middle Name' | translate }}"
                              formControlName="middle_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.middle_name.errors }" />
                            <div *ngIf="submitted && f.middle_name.errors" class="invalid-feedback">
                              <div *ngIf="f.middle_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>Last Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Last Name' | translate }}"
                              formControlName="last_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                              <div *ngIf="f.last_name.errors.required" translate>Last Name is required</div>
                              <div *ngIf="f.last_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>School Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'School Name' | translate }}"
                              formControlName="school"
                              minlength="2"
                              maxlength="200"
                              [ngClass]="{ 'is-invalid': submitted && f.school.errors }" />
                            <div *ngIf="submitted && f.school.errors" class="invalid-feedback">
                              <div *ngIf="f.school.errors.required" translate>School is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Email<span class="text-danger">*</span></label>
                            <input
                              placeholder="{{ 'Email' | translate }}"
                              type="text"
                              class="form-control"
                              formControlName="email"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required" translate>Email is required</div>
                              <div *ngIf="f.email.errors.pattern" translate>Enter valid email</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1 texinput" translate
                              >Phone Number<span class="text-danger">*</span></label
                            >
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <ng-select
                                  formControlName="phone"
                                  (change)="conutyphone($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                  <ng-option *ngFor="let status of countryCodeList" [value]="status">
                                    {{ status.code }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <input
                                placeholder="(00) 00000-0000"
                                mask=" {{ minDigit }} || {{ maxDigit }}"
                                [validation]="false"
                                type="text"
                                class="form-control"
                                formControlName="phonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" />
                              <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required" translate>Phone Number is required</div>
                                <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile
                                                                    should be 10 character long
                                                                </div> -->
                                <!-- <div *ngIf="f.phonenumber.errors.pattern">Only Numbers
                                                                    are allowed</div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>DOB<span class="text-danger">*</span></label>
                            <input
                              type="date"
                              class="form-control"
                              formControlName="dob"
                              max="9999-12-31"
                              [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" />
                            <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                              <div *ngIf="f.dob.errors.required" translate>DOB is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label translate>Gender<span class="text-danger">*</span></label>
                            <ng-select
                              placeholder="{{ 'Select Gender' | translate }}"
                              formControlName="gender"
                              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                              <ng-option value="male">Male </ng-option>
                              <ng-option value="female">Female </ng-option>
                              <ng-option value="others">Others </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                              <div *ngIf="f.gender.errors.required" translate>Gender is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label translate>Address<span class="text-danger">*</span></label>
                            <textarea
                              placeholder="{{ 'Address' | translate }}"
                              type="text"
                              class="form-control"
                              cols="30"
                              rows="10"
                              formControlName="address"
                              maxlength="250"
                              [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required" translate>Address is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <app-country-state
                        (CountryChanged)="Countrychange($event)"
                        [countryForm]="generalForm"></app-country-state>
                    </div>
                  </div>
                </div>
                <button (click)="onSubmit()" class="btn btn-dark float-right auth-button mr-3 mb-4" translate>
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        [ngClass]="{ active: password == true }"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab">
        <div class="block-header">
          <app-change-password></app-change-password>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12 my-4">
          <div class="float-right">
            <a class="btn-sm auth-button" [routerLink]="['/admin/payment/my-payment']" translate
              ><i class="fas fa-plus mr-1"></i>Pay Now</a
            >
          </div>
        </div>
        <form [formGroup]="rangeForm" (ngSubmit)="onDateSubmit()">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-3 col-4">
              <button class="btn-sm auth-button submit-newbtn" translate>Search</button>
              <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>Clear</button>
            </div>
          </div>
        </form>
        <div class="row mt-4 clearfix">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="card">
              <div class="body">
                <div class="row">
                  <div class="col-md-3">
                    <span class="d-flex">{{ 'Show' | translate}}
                      <select
                        [(ngModel)]="itemPerPage"
                        name="selection-datatable_length"
                        aria-controls="selection-datatable"
                        class="custom-select custom-select-sm form-control form-control-sm w-auto">
                        <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                          {{ pageCountObj }}
                        </option>
                      </select>
                      {{ 'entries' | translate}}
                    </span>
                  </div>
                  <div class="col-md-9">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="{{ 'Search' | translate }}"
                        [(ngModel)]="searchText"
                        name="searchText" />
                      <div class="input-group-append" *ngIf="searchText">
                        <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                          >Clear</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th translate>Transaction Id</th>
                      <th translate>Transaction Date</th>
                      <th translate>Amount</th>
                      <th translate>Payment status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let payment of listpayment
                          | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                          | filter: searchText;
                        let i = index
                      ">
                      <td>{{ payment.transaction_id }}</td>
                      <td>{{ payment.transaction_date }}</td>
                      <td>R$ {{ payment.amount }}</td>
                      <td>{{ payment.payment_status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row mx-2">
              <pagination-controls 
                (pageChange)="pageChange($event)"
                [previousLabel]="'Previous' | translate"
                [nextLabel]="'Next' | translate"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
