import { VideoIframeDialogComponent } from './../../../shared/components/video-iframe-dialog/video-iframe-dialog.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Moment } from 'moment';
import Swal from 'sweetalert2';
import { endpoints } from 'src/app/core/config/endpoints';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeDialogComponent } from '../calendar/iframe-dialog/iframe-dialog.component';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.css']
})
export class VideoLibraryComponent implements OnInit {

  searchText: any;
  eventregister:any=[];
  regstudent:any;
  studentlist:any=[];
  activedata:any;
  userid:any;
  eventnotes:any=[];
  schoolname:any;
  filterData:any=[];
  eventid:any;
  eventsdetails:any=[];
  userstudent:any;
  data:any;
  step = 0;
  videoSection:any;
  categoryName:any;
  safeURL:any;
  embedLink:any=[];
  replaceEmbedLink:any=[];
  eventAccess:any;

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public toaster: ToastrService,
    public formBuilder: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public modalService: NgbModal,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.schoolname=this.AuthService.schoolname;
    this.userid=this.AuthService.userid;
    // this.data = this.route.snapshot.data['message'];
    // console.log(this.route.snapshot.data);
    // this.eventAccess = this.data.eventDetails.id;
    // console.log(this.eventAccess);

    this.Apiservice.getApi(endpoints.listVideoSection.path).subscribe((data: any) => {
      this.videoSection = data.data;
    },
    (err:any)=>{ });

    // this.eventregister = this.data.eventDetails.event_register;
    // this.regstudent=this.eventregister.find((s:any)=>s.student_id.id==this.AuthService.userid)

    // let studentname:any=[]
    // this.categoryName = this.data.eventDetails.name;
    // this.eventsdetails = this.data.eventDetails.eventclass;
    // this.eventnotes= this.data.eventDetails.notes;

    // this.videoSection = this.data.videoData;

    // this.videoSection.forEach((s:any) => {
    //   s.section_id.forEach((x:any) => {
    //     this.embedLink = x.link;
    //     this.replaceEmbedLink = this.embedLink.replace('watch?v=','embed/');
    //   })
    // })

    // this.eventsdetails.forEach((s:any)=>{
    //   s.students.forEach((x:any)=>{
    //     studentname.push(x)
    //   })
    // })
    // this.studentlist=studentname

    // this.userstudent=this.studentlist.filter((s:any)=>s.id==this.AuthService.userid)

    // this.eventlist()
  }

  setStep(index: number) {
    this.step = index;
  }
  openDialog(section:any,list:any,index:any) {
    const modalRef = this.modalService.open(VideoIframeDialogComponent, {
      // size: 'lg',
      backdrop : 'static',
      keyboard : false,
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.link = list;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.section = section;
  }
  // openDialog(section:any,list:any,index:any) {
  //   const modalRef = this.modalService.open(IframeDialogComponent, {
  //     size: 'lg',
  //     backdrop : 'static',
  //     keyboard : false
  //   });
  //   modalRef.componentInstance.link = list;
  //   modalRef.componentInstance.index = index;
  //   modalRef.componentInstance.section = section;
  // }

  // eventlist(){
  //   this.Apiservice.getApi(endpoints.studenteventlist.path).subscribe(
  //     (data: any) => {
  //       this.filterData=data.results
  //       this.activedata=this.filterData[0]
  //       console.log('activedata ',this.activedata);
  //       this.enentdata(this.activedata)

  //     },(err:any)=>{
  //       this.toaster.error(err.error.message)

  //     })

  // }
  // enentdata(event: any) {
  //   this.eventid=event.id
  //   this.Apiservice.getApi(endpoints.studenteventdetails.path+"?id="+this.eventid).subscribe(
  //     (data: any) => {
  //       this.eventregister = data.eventDetails.event_register;
  //       this.regstudent=this.eventregister.find((s:any)=>s.student_id.id==this.AuthService.userid)
  //       console.log(this.regstudent);

  //       let studentname:any=[]
  //       this.eventsdetails = data.eventDetails.eventclass;
  //       this.eventnotes= data.eventDetails.notes;
  //       this.eventsdetails.forEach((s:any)=>{
  //         s.students.forEach((x:any)=>{
  //           studentname.push(x)
  //         })
  //       })
  //       this.studentlist=studentname
  //       console.log(this.studentlist);

  //       this.userstudent=this.studentlist.filter((s:any)=>s.id==this.AuthService.userid)

  //     }
  //   );

  // }
  Registration(){
    const body={
      event_id:this.eventid,
      student_id:this.AuthService.userid
    }
    Swal.fire({
      title: this.translate.instant('Register?'),
      text: this.translate.instant("Do you want to Register?"),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.postApi(endpoints.eventregistration.path,body).subscribe(
          (data: any) => {
            if(data.status==200){
              Swal.fire({
                title: this.translate.instant('Registration Successfully'),
                icon: 'success',
              } )
              // this.eventlist()
            }else{
              Swal.fire({
                title: data.message,
                icon: 'error',
              } )
            }

          },(err)=>{
            Swal.fire({
              title: err,
              icon: 'error',
            } )
          })
      }
    })
  }
  CheckedIn(studentlist:any){
    const body={
      event_id:this.eventid,
      student_id:studentlist.id,
      id:26
    }
    Swal.fire({
      title: this.translate.instant('Checked In?'),
      text: this.translate.instant("Do you want to check in?"),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.postApi(endpoints.eventcheckedin.path,body).subscribe(
          (data: any) => {
            Swal.fire({
              title: this.translate.instant('Succesfully Checked In'),
              icon: 'success',
            } )
          },(err)=>{
            Swal.fire({
              title: this.translate.instant('Invalid Checked In'),
              icon: 'error',
            } )
          })
      }
    })
  }
  resetsearch() {
    this.searchText = '';
  }

}
