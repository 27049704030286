import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  data: any;

  constructor(
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {}

  closeMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
  }
}
