import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFocusNext]',
})
export class FocusNextDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown.enter', ['$event'])
  handleEnter(event: KeyboardEvent) {
    event.preventDefault();
    let form = this.el.nativeElement.form;
    let elements = form.querySelectorAll('input, button, select, textarea, [tabindex]');
    let index = Array.prototype.indexOf.call(elements, this.el.nativeElement);

    if (index > -1 && index < elements.length - 1) {
      elements[index + 1].focus();
    }
  }
}
