export interface SelectOption {
  text: string;
  id: string;
  amount?: number | string;
}

export const convertToSelectOption = (
  originalArray: any[],
  toId: string,
  toText: string,
  toAmount?: string
): SelectOption[] => {
  const newArray: SelectOption[] = [];

  originalArray.forEach((item) => {
    const count = toAmount ? String(item[toAmount]) : undefined;
    const newObject = {
      id: item[toId],
      text: item[toText],
      amount: count || undefined,
    };
    newArray.push(newObject);
  });
  return newArray;
};
