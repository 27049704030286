<div class="block-header">
    <div class="row clearfix">
        <div class="col-lg-10 col-md-10 col-sm-8 col-8">
            <h2 class="mainh2" translate>Message</h2>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-4 col-4" *ngIf="userrole!='STUDENT'">
            <a class="btn-sm auth-button float-right" data-toggle="modal"
            data-target="#exampleModal" translate><i
                class="fas fa-plus mr-1"></i>New</a>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="input-group">
                <input type="text" class="form-control"
                    placeholder="{{'Search'| translate}}" autocomplete="off"
                    [(ngModel)]="searchText" name="searchText">
                <div class="input-group-append" *ngIf="searchText">
                    <span class="input-group-text" id="search-mail"
                        (click)="resetsearch()" translate>Clear</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4" *ngIf="msglist==''">
        <div class="card p-4">
            <h5 translate>No data available</h5>
        </div>
    </div>
    <div class="row mt-4" *ngIf="msglist!=''">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <div class="msg-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
                <perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true">
                    <div *ngFor="let msg of msglist | filter: searchText; let i = index ">
                        <div class="message-left-main"  [ngClass]="{'activemenu':msg.id==chatid}"  (click)="Chatuser(msg)">
                            <div class="msg-main-ss">
                                <div class="image-section">
                                    <img class="message-image" src="assets/images/user.png" alt="message-img" />
                                </div>
                                <div class="image-section">
                                    <div class="message-name-date">
                                        <h6 class="message-text-col" *ngIf="msg.user!=null" >{{msg.label}}</h6>
                                        <h6 class="message-text-col2">{{msg.created_at | date:'dd-MM-YYY'}}</h6>
                                        <div >{{msg.message}} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
            <div class="msg-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto">
                <perfect-scrollbar class="scroll-container" fxFlex="auto" [scrollIndicators]="true">
                    <div class="message-background" *ngIf="rlymsglist!=''">
                        <div *ngFor="let rlymsg of rlymsglist; let i=index">
                            <div class="message-right" *ngIf="rlymsg.from_user.id!=userid"  >
                                <div class="custom-message-img">
                                    <div class="custom-message-user">
                                        <img class="custom-user-img" src="{{rlymsg.from_user.photo || 'assets/images/user.png'}}" alt="message-img" />
                                        {{rlymsg.from_user.first_name}} {{rlymsg.from_user.last_name}} 
                                        <span>{{rlymsg.created_at | date: "shortTime"}}</span>
                                    </div>
                                    <div class="custom-message-text mt-2" >
                                        <p >{{rlymsg.message}}</p>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="message-left" *ngIf="rlymsg.from_user.id==userid" >
                                <div class="custom-message-img">
                                    <div class="custom-message-user float-right">
                                       
                                        <span>{{rlymsg.created_at | date: "shortTime"}}</span> (You)
                                        <img class="custom-user-img" src="{{rlymsg.from_user.photo  || 'assets/images/user.png'}}" alt="message-img" />
                                    </div><br>
                                    <div class="custom-message-text">
                                        <p class="mt-2">{{rlymsg.message}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
            <form [formGroup]="replyForm" (ngSubmit)="replySubmit()">
                <div class="msg-send">
                    <div class="formmenu-send">
                        <input type="text" class="form-control" placeholder="Type..." formControlName="message" />
                    </div>
                    <button class="msg-send-btn" type="submit"><i class="fas fa-paper-plane"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" translate>New Message</h5>
                <button type="button" class="close" data-dismiss="modal" #closenewModal aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row clearfix mt-4">
                    <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
                        <form [formGroup]="messageForm" (ngSubmit)="messageSubmit()">
                            <div class="form-group">
                                <label translate>User<span class="text-danger">*</span></label>
                                <ng-select placeholder="{{'Select User' | translate}}" formControlName="to_id"
                                    [ngClass]="{ 'is-invalid': submitted && f.to_id.errors }">
                                    <ng-option *ngFor="let status of userlist" [value]="status.id">
                                        {{status.first_name}}  {{status.middle_name}}  {{status.last_name}}
                                    </ng-option>
                                </ng-select>
                                <div *ngIf="submitted && f.to_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.to_id.errors.required" translate>User is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label translate>Message<span class="text-danger">*</span></label>
                                <textarea class="form-control" formControlName="message"
                                    [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required" translate>Message is required</div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-dark float-right auth-button mt-4 mr-2"
                                translate>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>