import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { endpoints } from '../../../core/config/endpoints';
import { OnboardingStep, PromotableStudent, RecentStudent } from '../admin.interface';
import { AdminService } from '../admin.service';

interface TodoItem {
  id: number;
  title: string;
  description: string;
  cta: string;
  completed: boolean;
  step: string;
}

interface DashboardStatistics {
  students_count: number;
  checkins_this_month_count: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  paymentlist: any = [];
  registerlist: any = [];
  dashboardcount: any;
  show = false;
  fullScreen = true;
  template = ``;
  currentUser: any = [];
  hasBankAccount = true;
  loading = false;
  showOnboardingAlert: boolean = false;
  membershipExpired: boolean = false;
  selectedTodo: TodoItem | null = null;
  todos: TodoItem[] = [];

  loadingState = false;
  statistics: DashboardStatistics | null = null;
  loadingStatistics = false;
  hasIncompleteSteps = false;

  promotableStudents$ = new BehaviorSubject<PromotableStudent[]>([]);
  loadingPromotableStudents = false;
  promotableStudentsError = '';
  pageData$ = new BehaviorSubject<{
    currentPage: number;
    perPage: number;
    total: number;
  } | null>(null);

  recentStudents$ = new BehaviorSubject<RecentStudent[]>([]);

  recentStudents: RecentStudent[] = [];
  loadingRecentStudents = false;
  recentStudentsError = '';
  recentPageData$ = new BehaviorSubject<{
    currentPage: number;
    perPage: number;
    total: number;
  } | null>(null);

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private adminService: AdminService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.userid;
    setTimeout(() => {
      this.showOnboardingAlert = true;
    }, 700);

    this.loadingState = true;

    this.route.queryParams.subscribe((params) => {
      const fromStripe = params['source'] === 'stripe';

      if (fromStripe) {
        this.loadOnboardingSteps();
      }
    });

    this.loadOnboardingSteps();
    this.loadStatistics();
    this.loadPromotableStudents();
    this.loadRecentStudents();
  }

  loadOnboardingSteps(): void {
    this.adminService.getOnboardingSteps().subscribe({
      next: (steps: OnboardingStep[]) => {
        this.loadingState = false;
        this.todos = steps.map((step, index) => ({
          id: index + 1,
          step: step.step,
          completed: step.completed,
          title: this.getStepTitle(step.step),
          description: this.getStepDescription(step.step),
          cta: this.getStepCta(step.step),
        }));

        this.hasIncompleteSteps = this.todos.some((todo) => !todo.completed);

        if (this.hasIncompleteSteps) {
          const firstIncomplete = this.todos.find((todo) => !todo.completed);
          this.selectedTodo = firstIncomplete || this.todos[0];
        }
      },
      error: () => {
        this.toaster.error(this.translate.instant('Error loading dashboard, please refresh the page.'));
        this.loadingState = false;
      },
    });
  }

  selectTodo(todo: TodoItem): void {
    const firstIncomplete = this.todos.find((item) => !item.completed);
    if (todo.id !== firstIncomplete?.id) {
      this.toaster.warning(this.translate.instant('ONBOARDING_STEPS.WARNING_ORDER'));
      return;
    }
    this.selectedTodo = todo;
  }

  private getStepTitle(step: string): string {
    return this.getStepTitleOverride(step);
  }

  private getStepDescription(step: string): string {
    return this.getStepDescriptionOverride(step);
  }

  private getStepCta(step: string): string {
    return this.getStepCtaOverride(step);
  }

  handleTodoCta(todo: TodoItem): void {
    if (todo.completed) return;

    switch (todo.step) {
      case 'user_profile':
        const userDataString = localStorage.getItem('currentUser');

        if (userDataString) {
          this.currentUser = JSON.parse(userDataString);
          const userId = this.currentUser.user_id;

          this.router.navigate([`/admin/student/edit-student/${userId}`], {
            queryParams: { from: 'dashboard' },
          });
        }
        break;
      case 'school_profile':
        this.router.navigate(['/admin/school'], { queryParams: { from: 'dashboard' } });
        break;
      case 'bank_account':
        this.completeOnboarding();
        break;
      case 'first_class':
        this.router.navigate(['/admin/classes/create'], { queryParams: { from: 'dashboard' } });
        break;
      case 'first_student':
        this.router.navigate(['/admin/student/add-student'], { queryParams: { from: 'dashboard' } });
        break;
    }
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {}

  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {}
  // onClickCustomTemplate(){
  //   alert(2)
  //   this.show = true;
  //   this.fullScreen = false;
  //   this.template = `<div class="loader loader-2">
  //           <span></span>
  //           <span></span>
  //           <span></span>
  //       </div>`
  //   setTimeout(() => {
  //       this.show = false
  //   }, 3000);
  // }

  completeOnboarding() {
    this.loading = true;

    const userDataString = localStorage.getItem('currentUser');

    if (userDataString) {
      this.currentUser = JSON.parse(userDataString);
      const userId = this.currentUser.user_id;

      this.Apiservice.postApi(endpoints.stripeConnectOnboarding.path, { user_id: userId }).subscribe(
        (data: any) => {
          //console.log('data', data);
          if (data?.response?.data?.onboarding_link) {
            window.location.href = data.response.data.onboarding_link;
          } else {
            this.toaster.error(this.translate.instant('ONBOARDING_STEPS.ERROR_PAYMENT'));
            this.loading = false;
          }
        },
        (err: any) => {
          this.loading = false;

          this.toaster.error(err.error.message);
        }
      );
    }
  }

  hasNextIncompleteStep(currentTodo: TodoItem): boolean {
    const nextTodos = this.todos.slice(currentTodo.id);
    return nextTodos.some((todo) => !todo.completed);
  }

  getNextIncompleteTodo(currentTodo: TodoItem): TodoItem {
    const nextIncompleteTodo = this.todos.slice(currentTodo.id).find((todo) => !todo.completed);
    return nextIncompleteTodo || currentTodo;
  }

  private loadStatistics(): void {
    this.loadingStatistics = true;
    this.adminService.getDashboardStatistics().subscribe({
      next: (statistics) => {
        this.statistics = statistics;
        this.loadingStatistics = false;
      },
      error: () => {
        this.toaster.error(this.translate.instant('Error loading statistics'));
        this.loadingStatistics = false;
      },
    });
  }

  get currentMonth(): string {
    return new Intl.DateTimeFormat(this.translate.currentLang, { month: 'long' }).format(new Date());
  }

  loadPromotableStudents(page: number = 1): void {
    this.loadingPromotableStudents = true;
    this.promotableStudentsError = '';

    this.adminService.getPromotableStudents(page).subscribe({
      next: (response) => {
        this.promotableStudents$.next(response.data);
        this.pageData$.next({
          currentPage: response.meta.current_page,
          perPage: response.meta.per_page,
          total: response.meta.total,
        });
        this.loadingPromotableStudents = false;
      },
      error: () => {
        this.promotableStudentsError = this.translate.instant('Error loading promotable students');
        this.loadingPromotableStudents = false;
      },
    });
  }

  onPromotableStudentsPageChange(page: number): void {
    this.loadPromotableStudents(page);
  }

  loadRecentStudents(page: number = 1): void {
    this.loadingRecentStudents = true;
    this.recentStudentsError = '';

    this.adminService.getRecentStudents(page).subscribe({
      next: (response) => {
        this.recentStudents$.next(response.data);
        this.recentStudents = response.data;

        this.recentPageData$.next({
          currentPage: response.meta.current_page,
          perPage: response.meta.per_page,
          total: response.meta.total,
        });

        this.loadingRecentStudents = false;
      },
      error: () => {
        this.recentStudentsError = this.translate.instant('Error loading recent students');
        this.loadingRecentStudents = false;
      },
    });
  }

  onRecentStudentsPageChange(page: number): void {
    this.loadRecentStudents(page);
  }

  isStripeRedirect(): boolean {
    return this.route.snapshot.queryParams['source'] === 'stripe';
  }

  getStepTitleOverride(step: string): string {
    if (step === 'bank_account' && this.isStripeRedirect() && !this.selectedTodo?.completed) {
      return this.translate.instant('ONBOARDING_STEPS.TITLES.bank_account_incomplete');
    }
    return this.translate.instant(`ONBOARDING_STEPS.TITLES.${step}`);
  }

  getStepDescriptionOverride(step: string): string {
    if (step === 'bank_account' && this.isStripeRedirect() && !this.selectedTodo?.completed) {
      return this.translate.instant('ONBOARDING_STEPS.DESCRIPTIONS.bank_account_incomplete');
    }
    return this.translate.instant(`ONBOARDING_STEPS.DESCRIPTIONS.${step}`);
  }

  getStepCtaOverride(step: string): string {
    if (step === 'bank_account' && this.isStripeRedirect() && !this.selectedTodo?.completed) {
      return this.translate.instant('ONBOARDING_STEPS.CTA.bank_account_incomplete');
    }
    return this.translate.instant(`ONBOARDING_STEPS.CTA.${step}`);
  }
}
