import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup | any;
  submitted = false;
  success: boolean = false;
  isLoading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    public auth: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.forgotForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
        ]),
      ],
    });
  }

  get f() {
    return this.forgotForm.controls;
  }

  onSubmit() {
    let loginemail = this.forgotForm.value.email.toLowerCase();
    this.forgotForm.controls['email'].setValue(loginemail);

    if (this.forgotForm.valid) {
      this.isLoading = true;
      this.submitted = false;
      this.Apiservice.postApi(endpoints.forgotPassword.path, {
        email: this.forgotForm.get('email').value,
      }).subscribe(
        (data) => {
          this.isLoading = false;
          this.toaster.success(
            'We have sent a reset code to your email address'
          );
          this.router.navigate(['auth/resetPassword']);
        },
        (err) => {
          this.isLoading = false;
          this.toaster.error(this.translate.instant('Email address not found'));
        }
      );
    } else {
      this.isLoading = false;
      this.submitted = true;
    }
  }
}
