import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  paymentlist:any=[]
  registerlist:any=[]
  dashboardcount:any
  show = false;
  fullScreen = true;
  template = ``;
  currentUser:any=[];
  hasBankAccount = false;
  loading = false;
  showOnboardingAlert: boolean = false;
  membershipExpired: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService

  ) {

  }
  ngOnInit(): void {
    setTimeout(() => {
      this.showOnboardingAlert = true;
    }, 700);

    this.Apiservice.getApi(endpoints.dashboard.path).subscribe(
      (data: any) => {
        //Redirect to payment if membership expired
        this.membershipExpired = data.membership_expired;
        if (this.membershipExpired) {
          this.router.navigate(['/admin/payment/my-payment'], { queryParams: { status: 'expired' } });
        }

        this.hasBankAccount = data.has_bank_account;
        this.dashboardcount=data
        this.paymentlist=data.payments
        this.registerlist=data.students
        this.pieChartData.datasets=[
          {
            data:[ this.dashboardcount?.currentMonth.count, this.dashboardcount?.currentYear.count, this.dashboardcount?.lastMonth.count, this.dashboardcount?.lastYear.count]
          }
        ]
      },

      (err: any) => {
      }
    );
  }

  // Pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          padding: 15,
          usePointStyle: true,
          boxWidth: 10,
        }
      },
      datalabels: {
        font: {
          weight: 'bold',
          size: 14
        }
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [this.translate.instant('Current Month'), this.translate.instant('Current Year'), this.translate.instant('Last Month'), this.translate.instant('Last Year')],

    datasets: [ ],
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [DatalabelsPlugin];

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
  }

  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
  }
  // onClickCustomTemplate(){
  //   alert(2)
  //   this.show = true;
  //   this.fullScreen = false;
  //   this.template = `<div class="loader loader-2">
  //           <span></span>
  //           <span></span>
  //           <span></span>
  //       </div>`
  //   setTimeout(() => {
  //       this.show = false
  //   }, 3000);
  // }

  completeOnboarding() {
    this.loading = true;

    const userDataString = localStorage.getItem('currentUser');

    if (userDataString) {
      this.currentUser = JSON.parse(userDataString);
      const userId = this.currentUser.user_id;

      this.Apiservice.postApi(endpoints.stripeConnectOnboarding.path, {user_id: userId}).subscribe(
        (data: any) => {
          //console.log('data', data);
          window.location.href = data.response.data.onboarding_link;
        },
        (err: any) => {
          this.loading = false;

          this.toaster.error(err.error.message);
        }
      );
    }
  }
}
