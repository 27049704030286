import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss'],
})
export class LeftmenuComponent implements OnInit {
  SalesCollapsed = true;

  constructor(
    private router: Router,
    @Inject(AppComponent) private app: AppComponent,
    private authservice: AuthService
  ) {
    if (this.router.url.includes('seosettings')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('class')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('positions')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('roles')) {
      this.SalesCollapsed = false;
    }
    if (this.router.url.includes('video-category')) {
      this.SalesCollapsed = false;
    }
  }

  ngOnInit(): void {}
  logOut() {
    this.authservice.logOut();
  }
  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
    } else {
      body.classList.add('toggle_menu_active');
    }
  }
  showDropDown() {
    //document.getElementById('drp').classList.toggle("ShowDiv");
  }
  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove('offcanvas-active');
    //document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }
}
