import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.css'],
})
export class AddRolesComponent implements OnInit {
  rolesForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot.data['message'];
    if (this.routerid) {
      this.rolesForm = this.formBuilder.group({
        role: [this.data.role, Validators.required],
      });
    } else {
      this.rolesForm = this.formBuilder.group({
        role: ['', Validators.required],
      });
    }
  }
  get f() {
    return this.rolesForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.rolesForm.invalid) {
      return;
    }

    if (!this.routerid) {
      this.Apiservice.postApi(
        endpoints.addroles.path,
        this.rolesForm.value
      ).subscribe(
        (data: any) => {
          
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Role added Successfully'));
          this.router.navigate(['/superadmin/roles']);
        },
        (err: any) => {
          
          this.toaster.error(this.translate.instant('Error while loading Role'));
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updateroles.path + '?id=' + this.routerid,
        this.rolesForm.value
      ).subscribe(
        (data: any) => {
          
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Role updated successfully'));
          this.router.navigate(['/superadmin/roles']);
        },
        (err: any) => {
          
          this.toaster.error(this.translate.instant('Error while loading Role'));
        }
      );
    }
  }
}
