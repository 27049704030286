import { Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-field-number',
  templateUrl: './field-number.component.html',
  styleUrls: ['./field-number.component.scss'],
})
export class FieldNumberComponent implements OnInit, ControlValueAccessor {
  @Input() type: 'number' | 'tel' | 'zip' = 'number';
  @Input() label: string | undefined = undefined;
  @Input() placeholder: string | undefined = undefined;
  @Input() helperText: string | undefined = undefined;
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() centerContent: boolean = false;
  @Input() clearButton: boolean = false;
  @Input() min: number = 0;
  @Input() max: number | undefined = undefined;

  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();

  isFocused: boolean = false;
  numericArrows: boolean = true;
  value: string | undefined = undefined;

  onChange: (_: any) => void = () => {};
  onTouched: () => void = () => {};

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    if (this.type !== 'number') {
      this.numericArrows = false;
    }

    const { control } = this.ngControl;
    control?.updateValueAndValidity();
  }

  setValue(event: any) {
    if (this.isDisabled) return;

    this.value = event.target.value;
  }

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocus(): void {
    this.isFocused = true;
    this.focus.emit();
  }

  onBlur() {
    this.isFocused = false;
    this.blur.emit();
    // transform negative numbers
    if (this.value?.charAt(0) === '-') {
      this.value = this.value.substring(1);
      this.setValue({ target: { value: this.value } });
      this.updateChanges();
    }
  }

  handleArrows(ev: any) {
    if (this.numericArrows) return;
    if (['ArrowDown', 'ArrowUp'].includes(ev.key)) {
      ev.preventDefault();
    }
  }

  clearInput(event: Event) {
    event.stopPropagation();
    this.setValue({ target: { value: null } });
    this.value = '';
    this.updateChanges();
  }
}
