<ng-container *ngIf="classes?.length; else emptyState">
  <div class="e-events-list" *ngFor="let program of programs; let idx = index">
    <app-divisor
      *ngIf="program.classes_count && hasClassesFromProgram(program)"
      [label]="program.name"
      [backgroundColor]="program.colors.bg"
      [fontColor]="program.colors.font"></app-divisor>

    <ng-container *ngFor="let class of classes">
      <app-class-card
        *ngIf="class.program?.program_id === program.program_id"
        [class]="class"
        (actionClicked)="performAction($event)"></app-class-card>
    </ng-container>
  </div>
</ng-container>

<!-- Empty list -->
<ng-template #emptyState>
  <app-empty-events [hasFilters]="hasFilters" [status]="status"></app-empty-events>
</ng-template>
