import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-school-register',
  templateUrl: './school-register.component.html',
  styleUrls: ['./school-register.component.scss'],
})
export class SchoolRegisterComponent implements OnInit {
  @ViewChild('closeCardModal') closeCardModal: ElementRef | any;

  submitting = false;
  loading = false;
  registerForm: FormGroup = this.formBuilder.group({
    school_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
    name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    password_confirmation: ['', Validators.required],
    phone_code: ['+1', Validators.required],
    phone_number: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
  });

  countryCodeList: any = [];

  maxDigit: any;
  minDigit: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private element: ElementRef,
    private translate: TranslateService,
    private _httpClient: HttpClient,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.registerForm.get('password')?.setValidators([Validators.required, this.passwordValidator.bind(this)]);

    this.registerForm
      .get('password_confirmation')
      ?.setValidators([Validators.required, this.passwordMatchValidator.bind(this)]);

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });

    if (this.registerForm.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.registerForm.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }
  }

  conutyphone(event: any) {
    this.registerForm.controls['phone_code'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';

    if (this.registerForm.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.registerForm.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }

    const nextInput = this.element.nativeElement.querySelector('input[formControlName="phone_number"]');
    if (nextInput) {
      nextInput.focus();
    }
  }

  get fff() {
    return this.registerForm.controls;
  }

  onSubmit() {
    if (this.registerForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));

      for (const key in this.registerForm.controls) {
        this.registerForm.controls[key].markAsDirty();
        this.registerForm.controls[key].markAsTouched();
      }
      return;
    }

    if (this.submitting) {
      return;
    }
    this.submitting = true;

    const formData = this.registerForm.getRawValue();

    formData.email = formData.email.toLowerCase();

    this.authService.register(formData).subscribe({
      next: (response: any) => {
        this.toaster.success(this.translate.instant('SCHOOL_ADDED_SUCCESSFULLY'));

        this.submitting = false;

        if (response.user_role_name.toLowerCase() == 'superadmin') {
          this.router.navigate(['/superadmin/dashboard']);
        } else if (response.user_role_name.toLowerCase() == 'admin') {
          this.router.navigate(['/admin/dashboard']);
        } else if (response.user_role_name.toLowerCase() == 'coach') {
          this.router.navigate(['/coach/dashboard']);
        } else if (response.user_role_name.toLowerCase() == 'student') {
          this.router.navigate(['/student/dashboard']);
        }
      },
      error: (err: any) => {
        this.toaster.error(err.error?.message ?? this.translate.instant('SCHOOL_ADD_FAILED'));
        this.submitting = false;
      },
    });
  }
  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
          this.registerForm.patchValue({
            photo: file,
          });
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  Countrychange(event: any) {
    this.registerForm.controls['countryCode'].setValue(event.countryCode);
    this.registerForm.controls['stateCode'].setValue(event.stateCode);
    this.registerForm.controls['city'].setValue(event.city);
    this.registerForm.controls['zip_code'].setValue(event.zip_code);
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/image-placeholder.jpeg';
  }

  // Custom validator function
  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    if (!this.registerForm) {
      return null;
    }
    const password = this.registerForm.get('password');
    const confirmPassword = control;

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    }

    return null;
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasMinLength = value.length >= 8;
    const hasLetters = /[a-zA-Z]/.test(value);
    const hasMixedCase = /[a-z]/.test(value) && /[A-Z]/.test(value);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasNumbers = /[0-9]/.test(value);

    const passwordValid = hasMinLength && hasLetters && hasMixedCase && hasSymbols && hasNumbers;

    return !passwordValid ? { weakPassword: true } : null;
  }
}
