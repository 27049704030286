<form [formGroup]="countryForm" (ngSubmit)="(countryForm.form.valid)">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group">
        <label translate>Country<span class="text-danger">*</span></label>
        <ng-select
          placeholder="{{ 'Select Country' | translate }}"
          formControlName="countryCode"
          (change)="selectCountry($event)"
          [ngClass]="{ 'is-invalid': f.countryCode.errors }">
          <ng-option *ngFor="let status of countries" [value]="status.isoCode">{{ status.name }}</ng-option>
        </ng-select>
        <div *ngIf="f.countryCode.errors" class="invalid-feedback">
          <div *ngIf="f.countryCode.errors.required" translate>Country is required</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="states != ''">
      <div class="form-group">
        <label translate>State<span class="text-danger">*</span></label>
        <ng-select
          placeholder="{{ 'Select State' | translate }}"
          formControlName="stateCode"
          (change)="selectState($event)"
          [ngClass]="{ 'is-invalid': f.stateCode.errors }">
          <ng-option *ngFor="let status of states" [value]="status.isoCode">{{ status.name }}</ng-option>
        </ng-select>
        <div *ngIf="f.stateCode.errors" class="invalid-feedback">
          <div *ngIf="f.stateCode.errors.required" translate>State is required</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="states == ''">
      <div class="form-group">
        <label translate>State<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          formControlName="stateCode"
          placeholder="Enter State"
          pattern="^[A-Za-zÀ-ÖØ-öø-ÿ _-]+$"
          [ngClass]="{ 'is-invalid': f.stateCode.errors }" />
        <div *ngIf="f.stateCode.errors" class="invalid-feedback">
          <div *ngIf="f.stateCode.errors.required" translate>State is required</div>
          <div *ngIf="f.stateCode.errors.pattern" translate>Enter valid state</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="(states != '' && cities == '') || states == ''">
      <div class="form-group">
        <label translate>City<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          formControlName="city"
          placeholder="Enter City"
          pattern="^[A-Za-zÀ-ÖØ-öø-ÿ _-]+$"
          [ngClass]="{ 'is-invalid': f.city.errors }" />
        <div *ngIf="f.city.errors" class="invalid-feedback">
          <div *ngIf="f.city.errors.required" translate>City is required</div>
          <div *ngIf="f.city.errors.pattern" translate>Enter valid city</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="states != '' && cities != ''">
      <div class="form-group">
        <label translate>City<span class="text-danger">*</span></label>
        <ng-select
          placeholder="{{ 'Select City' | translate }}"
          formControlName="city"
          (change)="selectCity($event)"
          [ngClass]="{ 'is-invalid': f.city.errors }">
          <ng-option *ngFor="let status of cities" [value]="status.name">{{ status.name }}</ng-option>
        </ng-select>
        <div *ngIf="f.city.errors" class="invalid-feedback">
          <div *ngIf="f.city.errors.required" translate>City is required</div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <div class="form-group">
        <label translate>Zip Code<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'Zip Code' | translate }}"
          formControlName="zip_code"
          maxlength="10"
          [ngClass]="{ 'is-invalid': f.zip_code.errors }" />
        <div *ngIf="f.zip_code.errors" class="invalid-feedback">
          <div *ngIf="f.zip_code.errors.required" translate>Zipcode is required</div>
        </div>
      </div>
    </div>
  </div>
</form>
