<app-header></app-header>
<div class="e-student-page">
  <section id="main-content">
    <div class="e-student-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title">{{ 'STUDENT_PAGE.TITLE' | translate }}</h2>
        <app-button icon="add" [routerLink]="['/admin/student/add-student']">
          {{ 'STUDENT_PAGE.ADD_STUDENT_BTN' | translate }}
        </app-button>
      </header>
    </div>

    <div class="e-student-page__content-box">
      <div class="e-student-page__content-box__content">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row clearfix">
              <div class="col-12">
                <div class="card mb-4">
                  <div class="mb-4 e-student-page__content-header">
                    <app-field-input
                      [formControl]="searchText"
                      type="text"
                      class="e-student-page__search-input"
                      [clearButton]="true"
                      [placeholder]="'Search' | translate"></app-field-input>
                    <div class="d-flex">
                      <app-chip-list
                        class="u-animation-fade-in e-student-page__chip-list"
                        (chipSelected)="setFilter($event)">
                        <app-choice-chip
                          value="active"
                          [disabled]="isSearchActive"
                          [selected]="true"
                          [counter]="isSearchActive ? null : (numActive$ | async)"
                          [label]="'STUDENT_PAGE.ACTIVE' | translate"
                          [mobileLabel]="'STUDENT_PAGE.ACTIVE' | translate"></app-choice-chip>
                        <app-choice-chip
                          [disabled]="isSearchActive"
                          value="archived"
                          [counter]="isSearchActive ? null : (numArchived$ | async)"
                          [label]="'STUDENT_PAGE.ARCHIVED' | translate"
                          [mobileLabel]="'STUDENT_PAGE.ARCHIVED' | translate"></app-choice-chip>
                      </app-chip-list>
                    </div>
                  </div>
                  <div class="table-responsive e-student-page__table">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th class="w-25" translate>STUDENT_PAGE.NAME</th>
                          <th class="w-25" translate>STUDENT_PAGE.EMAIL</th>
                          <th translate>STUDENT_PAGE.DOB</th>
                          <th translate>STUDENT_PAGE.ACCOUNT_CREATION_DATE</th>
                          <th translate class="text-center">STUDENT_PAGE.ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngIf="isLoading">
                          <td colspan="5" class="text-center pb-3 pt-3">
                            <div class="spinner-border" role="status">
                              <span class="sr-only" translate>Loading</span>
                            </div>
                          </td>
                        </tr>
                        <ng-container *ngIf="errorMessage; else content">
                          <tr>
                            <td colspan="5" class="text-center pb-3 pt-3">
                              <ion-icon name="alert-circle-outline"></ion-icon>
                              {{ 'STUDENT_PAGE.ERROR_MESSAGE' | translate }}
                            </td>
                          </tr>
                        </ng-container>
                        <ng-template #content>
                          <ng-container *ngIf="!errorMessage && (students$ | async) as students">
                            <tr
                              (click)="navigateToUser(student.user_id)"
                              class="e-student-page__table-item"
                              *ngFor="
                                let student of students
                                  | paginate
                                    : {
                                        id: 'active',
                                        itemsPerPage: (pageData$ | async)?.perPage,
                                        currentPage: (pageData$ | async)?.currentPage,
                                        totalItems: (pageData$ | async)?.total
                                      };
                                let i = index
                              ">
                              <td>{{ student.name }}</td>
                              <td>{{ student.email }}</td>

                              <td>{{ student.dob }}</td>
                              <td>{{ student.created_at | date: 'yyyy-MM-dd' }}</td>

                              <td>
                                <div class="d-flex w-100 justify-content-center">
                                  <app-button
                                    class="c-class-card__action-button mr-2"
                                    icon="pencil"
                                    [iconOnly]="true"
                                    variant="untoggled"
                                    (click)="navigateToEdit($event, student.user_id)"></app-button>

                                  <app-button
                                    class="c-class-card__action-button"
                                    [icon]="student.active ? 'archive-outline' : 'power-outline'"
                                    [iconOnly]="true"
                                    [loading]="performingActionOnId === student.user_id"
                                    variant="untoggled"
                                    (click)="toggleActive($event, student)"></app-button>
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="!isLoading && (students$ | async)?.length === 0">
                            <tr>
                              <td colspan="5" class="text-center" translate>STUDENT_PAGE.NO_STUDENTS</td>
                            </tr>
                          </ng-container>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>
                <pagination-controls
                  id="active"
                  class="text-end mt-4"
                  (pageChange)="pageChange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
