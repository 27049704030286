import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import Swal from 'sweetalert2';
import { Program } from '../admin.interface';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  searchText: string = '';
  viewMode: 'active' | 'archived' = 'active';

  activeProgramList: Program[] = [];
  inactiveProgramList: Program[] = [];
  displayProgramList: Program[] = [];

  isLoading = true;
  failedToLoad = false;

  togglingMap: Record<string, boolean> = {};

  constructor(
    private toaster: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.loadProgramList();
  }

  loadProgramList() {
    this.isLoading = true;
    this.displayProgramList = [];
    this.adminService
      .getPrograms()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.activeProgramList = data.filter((p: Program) => p.active === true);
          this.inactiveProgramList = data.filter((p: Program) => p.active === false);
          this.updateDisplayList();
        },
        error: () => {
          this.failedToLoad = true;
        },
      });
  }

  toggleActive(ev: Event, program: Program) {
    ev.preventDefault();
    if (this.togglingMap[program.program_id]) return;

    ev.stopPropagation();

    const title = program.active
      ? this.translate.instant('PROGRAM_PAGE.ARCHIVE_TITLE')
      : this.translate.instant('PROGRAM_PAGE.UNARCHIVE_TITLE');
    const text = program.active
      ? this.translate.instant('PROGRAM_PAGE.ARCHIVE_TEXT')
      : this.translate.instant('PROGRAM_PAGE.UNARCHIVE_TEXT');

    const confirmButtonText = program.active
      ? this.translate.instant('PROGRAM_PAGE.SET_ARCHIVED_BTN')
      : this.translate.instant('PROGRAM_PAGE.SET_ACTIVE_BTN');

    Swal.fire({
      title,
      text,
      icon: 'info',
      customClass: 'c-swal-modal',
      showCancelButton: true,
      confirmButtonColor: '#2F69B0',
      cancelButtonColor: '#F1F8FF',
      reverseButtons: true,
      confirmButtonText,
      cancelButtonText: this.translate.instant('PROGRAM_PAGE.CANCEL_BTN'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.togglingMap[program.program_id] = true;
        this.adminService.patchProgram(program.program_id, { active: !program.active }).subscribe({
          next: () => {
            this.togglingMap[program.program_id] = false;
            const message = program.active
              ? this.translate.instant('PROGRAM_PAGE.SET_ARCHIVED_SUCCESS')
              : this.translate.instant('PROGRAM_PAGE.SET_ACTIVE_SUCCESS');
            this.toaster.success(message);
            this.loadProgramList();
          },
          error: () => {
            this.togglingMap[program.program_id] = false;
            const message = program.active
              ? this.translate.instant('PROGRAM_PAGE.SET_ARCHIVED_ERROR')
              : this.translate.instant('PROGRAM_PAGE.SET_ACTIVE_ERROR');
            this.toaster.error(message);
          },
        });
      }
    });
  }

  editProgram(programId: string) {
    this.router.navigate(['/admin/programs/edit-program/' + programId]);
  }

  setFilter(event: string) {
    this.viewMode = event as 'active' | 'archived';
    this.updateDisplayList();
  }

  updateDisplayList() {
    this.displayProgramList = this.viewMode === 'active' ? this.activeProgramList : this.inactiveProgramList;
  }
}
