import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModal implements OnInit {
  @Input() confirmTitle: string | undefined = undefined;
  @Input() confirmText: string | undefined = undefined;
  @Input() showFooterDivisor: boolean = false;
  @Output() clickevent = new EventEmitter<'confirm' | 'dismiss'>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.clickevent.emit('dismiss');
    this.activeModal.dismiss();
  }
  confirm() {
    this.clickevent.emit('confirm');
    this.activeModal.close();
  }
}
