import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {

  rangeForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  schoolList: any = [];
  ActiveStatus: any = [];
  InActiveStatus: any = [];
  listview: any;
  listviewstudent: any;
  listviewcoach: any;
  listviewevent: any;
  listviewpayment: any;
  settings: any = [];
  studentname: any = [];
  eventname: any = [];
  submitted: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    public formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    this.studentname = this.settings.data.filter(
      (t: any) => t.group == 'student'
    );
    this.eventname = this.settings.data.filter((t: any) => t.group == 'event');
    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
      studentname: [''],
    });
  }

  

  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      return;
    }
  }

  resetsearch() {
    this.searchText = '';
  }

  pageChange(event: any) {
    this.page = event;
  }

}
