import { ScheduleInterface } from 'src/app/shared/interfaces/schedule.interface';

export interface ClassesItem {
  availability: ClassAvailability;
  classes_count: number;
  programs: ClassPrograms[];
}

export interface ClassEntity {
  availability: ClassAvailability;
  program: {
    name: string;
    program_id: string;
  };
  class_id: string;
  name: string;
  description: string | null;
  start_date: string; // yyyy-mm-dd
  end_date: string; // yyyy-mm-dd
  max_registration: number;
  early_checkin_minutes: number;
  late_checkin_minutes: number;
  manually_closed_at: string | null; // yyyy-mm-dd
  coach: ClassCoach;
  schedules: Schedules[];
}

export interface ClassPrograms {
  program_id: string;
  name: string;
  classes: ClassEntity[];
}

export interface ProgramEntity {
  program_id: string;
  name: string;
  classes_count: number;
  colors: {
    bg: string;
    font: string;
  };
}

export interface BeltEntity {
  belt_id: string;
  name: string;
  color: string;
  range: string;
  time_line: string;
  users_count: number;
}

export interface ClassCoach {
  user_id: string;
  name: string;
  photo: string | null;
}

export interface CoachEntity {
  user_id: string;
  name: string;
  email: string;
  dob: string;
  age: number;
  role: string;
  gender: string;
  belt: BeltEntity;
  photo: null;
  address: string;
  city: string;
  state: string;
  state_code: string;
  country: string;
  country_code: string;
  zip_code: string;
  phone_code: string;
  phone_number: string;
  expiry_date: null;
  classes_count: number;
}

export interface NewCoachResquest {
  name: string;
  email: string;
  password: string;
  dob: string; // yyyy-mm-dd
  belt_id: string;
  gender: string;
  address: string;
  city: string;
  state: string;
  state_code: string;
  country: string;
  country_code: string;
  zip_code: string;
  phone_code: string;
  phone_number: string;
}

export interface Schedules {
  schedule_id: string;
  recurrence: 'Weekly' | 'Monthly';
  recurrence_value: RecurrenceType;
  sequence: null;
  sequence_value: null;
  day: DaysOfWeek;
  day_value: number;
  start_time: string; // hh:mm:ss
  end_time: string; // hh:mm:ss
}

export interface NewClassesResquest {
  name: string;
  description: string;
  program_id: string;
  coach_id: string;
  start_date: string; // yyyy-mm-dd
  end_date: string; // yyyy-mm-dd
  max_registration: number;
  early_checkin_minutes: number;
  late_checkin_minutes: number;
  schedules: NewClassSchedule[];
}

export interface NewClassSchedule extends ScheduleInterface {}

export interface ClassScheduleMap {
  days: number[];
  start_time: string; // hh:mm:ss
  end_time: string; // hh:mm:ss
}

export interface EventsFilter {
  coach_id: string | undefined;
  name: string | undefined;
  startDate: string | undefined; //date
  endDate: string | undefined; //date
  // from: string | undefined; //date
  // to: string | undefined; //date
  program_id: string | undefined;
}

export type ClassAvailability = 'open' | 'upcoming' | 'closed';

export type DaysOfWeek = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

export type RecurrenceType = 'weekly' | 'wonthly';

export enum WeekDaysEnum {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export interface GenericItem {
  id: string;
  value: string;
}

export interface StatusId {
  open: string[];
  upcoming: string[];
  closed: string[];
}

export interface SummaryDetails {
  className: string;
  coachName: string;
  programName: string;
  coachImage?: string;
}
