import { Component, OnInit } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
})
export class RolesComponent implements OnInit {
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  schoolList: any = [];
  listroles: any = [];

  constructor(
    private Apiservice: ApiService,
    private _httpClient: HttpClient,
    public toaster: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.rolesList();
  }
  rolesList() {
    this.Apiservice.getApi(endpoints.rolesList.path).subscribe(
      (data: any) => {
        this.listroles = data;
        this.showMsg = true;
      },
      (err: any) => {
      }
    );
  }
  deleteActiveRow(i: number, school: any) {
    var delBtn = confirm(' Do you want to Delete ?');
    if (delBtn == true) {
      this.Apiservice.getApi(
        endpoints.deleteroles.path + '?id=' + school.id
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.listroles.splice(i, 1);
          this.toaster.success(this.translate.instant('Roles deleted Successfully'));
          this.rolesList();
        },
        (err: any) => {
        }
      );
    }
  }

  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
