import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;

  name: any;
  securityForm: FormGroup | any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.securityForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'
          ),
        ]),
      ],
    });
  }

  get ff() {
    return this.securityForm.controls;
  }

  onPassSubmit() {
    this.submit = true;

    if (this.securityForm.invalid) {
      return;
    }
    if(this.securityForm.value.password!=this.securityForm.value.password_confirmation){
      this.toaster.error(this.translate.instant('The password confirmation and password must match.'))
      return
    }
    this.Apiservice.postApi(
      endpoints.changePassword.path,
      this.securityForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(data.message);
        this.AuthService.logOut()
      },
      (err: any) => {
        this.toaster.error(this.translate.instant('Error while loading Password'));
      }
    );
  }
}
