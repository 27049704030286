<app-modal-root size="sm">
  <ng-container slot="header">
    <span class="e-cancel-icon">
      <ion-icon name="warning"></ion-icon>
    </span>
  </ng-container>

  <div class="u-text-align--center">
    <p class="c-modal_header__title u-margin-bottom--none">{{ confirmTitle }}</p>
    <p class="c-modal_header__title u-margin-bottom--none">{{ confirmText }}</p>
  </div>

  <ng-container slot="footer">
    <app-button class="u-flex--full" variant="secondary" (click)="dismiss()">
      {{ 'NO' | translate }}
    </app-button>
    <app-button class="u-flex--full" variant="orange" (click)="confirm()">
      {{ 'IM_SURE' | translate }}
    </app-button>
  </ng-container>
</app-modal-root>
