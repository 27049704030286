<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            
            <app-admin-payment></app-admin-payment>
        </div>
    </div>
</div>