<app-header></app-header>
<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Membership Packages</h2>
                </div>
                <div class="col-md-12">
                    <div class="float-right">
                        <a class="btn-sm auth-button" [routerLink]="['/admin/packages/add-packages']" translate><i
                                class="fas fa-plus mr-1"></i>New</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12" id="content" #content>
                            <div class="body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <span class="d-flex">{{ 'Show' | translate}}
                                            <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                                                aria-controls="selection-datatable"
                                                class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                                    {{pageCountObj}}</option>
                                            </select> {{ 'entries' | translate}}
                                        </span>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="{{'Search'|translate}}"
                                                [(ngModel)]="searchText" name="searchText">
                                            <div class="input-group-append" *ngIf="searchText">
                                                <span class="input-group-text" id="search-mail" (click)="resetsearch()"
                                                    translate>Clear</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th translate>Amount</th>
                                            <th translate>Plan Name</th>
                                            <th translate>Valid Days</th>
                                            <th translate>Description</th>
                                            <th translate>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let list of ActiveStatus | paginate: { itemsPerPage: itemPerPage, currentPage: page } | filter: searchText; let i = index">
                                            <td>{{currency}} {{list.price}}</td>
                                            <td>{{list.plan_name}}</td>
                                            <td>{{list.valid_date}}</td>
                                            <td>{{list.description}}</td>
                                            <td>
                                                <a [routerLink]="['/admin/packages/edit-packages/'+list.id]"
                                                    class="btn btn-default btn-sm"><i class="fas fa-pencil-alt"></i></a>
                                                <a (click)="deleteActiveRow(i, list)"
                                                    class="btn btn-default btn-sm ml-1" title="{{ 'Delete' | translate }}"><i
                                                        class="fas fa-trash-alt"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-2">
                    <pagination-controls
                        (pageChange)="pageChange($event)"
                        [previousLabel]="'Previous' | translate"
                        [nextLabel]="'Next' | translate"
                    ></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
