import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  @ViewChild('closenewModal') closenewModal: ElementRef | any;
  messageForm:FormGroup | any
  replyForm:FormGroup | any
  submitted = false;
  submit=false
  loading = false;
  userlist:any=[]
  userrole:any
  msglist:any=[]
  rlymsglist:any=[]
  userid:any
  searchText: any;
  chatid:any

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
    
  ) { }

  ngOnInit(): void {
    this.userrole=this.AuthService.userrole
    this.userid=this.AuthService.userid
    this.Apiservice.getApi(endpoints.listusers.path).subscribe((data: any) => {
      if(this.AuthService.userrole=='SUPERADMIN'){
        this.userlist = data.data.admin;
      }else{
        this.userlist = data.data ;
      }
      
    },(err:any)=>{
      this.toaster.error(err.error.message);
    });
    this.messagelist()
    this.messageForm = this.formBuilder.group({
      to_id:['', Validators.required],
      message:['', Validators.required]
    })
    this.replyForm = this.formBuilder.group({
      to_user:['', Validators.required],
      message_id:['', Validators.required],
      message:['', Validators.required]
    })
  }
  get f() {
    return this.messageForm.controls;
  }
  messagelist(){
    this.Apiservice.getApi(endpoints.msglist.path).subscribe((data: any) => {      
     
      let response = data.list;
      let newData = response.filter((e: any, i: number) =>response.findIndex((s: any) => e.message_id === s.message_id) === i,);
      
      let myData = newData.filter((item: any) => item.from_id == this.AuthService.userid || item.to_id == this.AuthService.userid,);
      
      let newDataList = myData.map((i: any) =>i.from_user.id == this.AuthService.userid? {...i,label: i.user.first_name + ' ' + i.user.last_name,}: {...i,label: i.from_user.first_name + ' ' + i.from_user.last_name,},);
      this.msglist=newDataList
      let singlemsg =newDataList[0]
      
      if(singlemsg){
        this.replyForm.controls['to_user'].setValue(singlemsg.user.id)
        this.replyForm.controls['message_id'].setValue(singlemsg.message_id)
        this.Apiservice.getApi(endpoints.rlymsglist.path+"?message_id="+singlemsg.message_id).subscribe((data: any) => {
          this.rlymsglist = data.list;  
                
        },(err:any)=>{
          this.toaster.error(err.error.message);
        });
      }
     
    },(err:any)=>{
      this.toaster.error(err.error.message);
    });
  }
  messageSubmit(){
    this.submitted = true;
    if (this.messageForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.createmessage.path,
      this.messageForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Message sent Successfully'));
        this.closenewModal.nativeElement.click();
        this.messageForm.reset()
        this.messagelist()
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  Chatuser(msg:any){
    this.chatid=msg.id
    this.replyForm.controls['to_user'].setValue(msg.user.id)
    this.replyForm.controls['message_id'].setValue(msg.message_id)
    
    this.Apiservice.getApi(endpoints.rlymsglist.path+"?message_id="+msg.message_id).subscribe((data: any) => {
      this.rlymsglist = data.list;
    },(err:any)=>{
      this.toaster.error(err.error.message);
    });
  }
  replySubmit(){
    
    this.submit = true;
    if (this.replyForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.rlymessage.path,
      this.replyForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Message sent Successfully'));
        this.replyForm.reset()
        this.messagelist()
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  resetsearch() {
    this.searchText = '';
  }
}
