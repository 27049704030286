import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { endpoints } from '../../../core/config/endpoints';
import Swal from 'sweetalert2';
import { VideoIframeDialogComponent } from 'src/app/shared/components/video-iframe-dialog/video-iframe-dialog.component';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.css']
})
export class VideoLibraryComponent implements OnInit {

  videoSectionForm: FormGroup | any;
  schoolForm: FormGroup | any;
  searchText: any;
  eventdetails:any;
  activedata:any=[];
  filterData: any = [];
  inactiveevents:any=[];
  videoLibrary:any=[];
  step = 0;
  video:any;
  data:any;
  eventVideoSection:any=[];
  eventSectionId:any=[];
  showMsg: boolean = false;
  videoSectionId:any=[];
  section_id:any;
  userid:any;
  schoolname:any;
  allSchool:any=[];
  toSelect:any;
  selectId:any;
  selectedSchool:any;
  selectedId:any;

  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    public modalService: NgbModal,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.schoolname=this.AuthService.schoolname;
    this.userid=this.AuthService.userid;

    this.schoolForm = this.fb.group({
      school:[null],
    });

    this.Apiservice.getApi(endpoints.schoolList.path).subscribe((data: any) => {
      this.allSchool = data.school;
      this.toSelect = this.allSchool.filter((c:any) => c.name);
      this.selectedId = this.schoolForm.controls['school'].setValue(this.allSchool[0].id);
      this.schoolForm.value.school=this.allSchool[0].id;
      if(this.allSchool && this.allSchool.length>0){
        this.Apiservice.getApi(endpoints.listVideoSection.path+'?school='+this.allSchool[0].id).subscribe((data: any) => {
          this.eventdetails = data.data;
          this.eventVideoSection= data.data;
          this.schoolForm.controls['school'].value = this.allSchool[0].id;
          this.selectedId = this.schoolForm.controls['school'].value;
          if(this.eventVideoSection && this.eventVideoSection.length>0)
            {
              this.eventVideoSection.forEach((x:any,row:number) => {

                  let adds = this.videoSectionForm.get('section') as FormArray;
                  adds.push(this.sectionIdGroup(x));
                  let videos =  adds.at(row).get('videos') as FormArray;
                  if(x.section_id && x.section_id.length>0)
                  {

                    x.section_id.forEach((v:any,vrow:number)=> {
                    videos.push(this.itemsGroup(v));
                    })
                  }

              })
              let nestArr:any=<FormArray>this.videoSectionForm.get('section');

            }
          },
          (err: any) => {
          });

        // this.eventdetails= this.data.eventDetails;
        this.schoolForm.value.school=this.allSchool[0].id;
        this.selectedId = this.allSchool[0].id;
        this.videoSectionForm = this.fb.group({
          school: [this.selectedId],
          section: this.fb.array([]),
          section_id: [''],
        });

      }

    },
    (err: any) => { });

  }
  selectionChange(event:any){
    this.Apiservice.getApi(endpoints.listVideoSection.path+'?school='+event).subscribe((data: any) => {
      this.eventVideoSection= data.data;
      if(this.eventVideoSection && this.eventVideoSection.length>0)
        {
          this.eventVideoSection.forEach((x:any,row:number) => {

              let adds = this.videoSectionForm.get('section') as FormArray;
              adds.push(this.sectionIdGroup(x));
              let videos =  adds.at(row).get('videos') as FormArray;
              if(x.section_id && x.section_id.length>0)
              {

                x.section_id.forEach((v:any,vrow:number)=> {
                videos.push(this.itemsGroup(v));
                })
              }

          })
          let nestArr:any=<FormArray>this.videoSectionForm.get('section');

        }
    },
    (err: any) => {
    });
    this.videoSectionForm = this.fb.group({
      school: [event],
      section: this.fb.array([]),
      section_id: [''],
    });
  }

  private sectionIdGroup(item:any): FormGroup {
    let videos:any=[];
    return this.fb.group({
      id:[item.id?item.id:'',{validators:[Validators.required]}],
      section:[item.section?item.section:''],
      videos: new FormArray([]),
    });
  }
  private itemsGroup(section:any): FormGroup {
    return this.fb.group({
      id: [section.id?section.id:'',{validators:[Validators.required]}],
      title: [section.title?section.title:'',{validators:[Validators.required]}],
      description: section.description?section.description:'',
      link: [section.link?section.link:'',{validators:[Validators.required]}],
      video_id:[section.video_id?section.video_id:''],
    });
  }

  addNewRow(row:any) {
    let value = this.fb.group({
      id:[''],
      title: [ '', Validators.required],
      description: [ ''],
      link: [ '', Validators.required],
    });
    (<FormArray>this.videoSectionForm.get('section').at(row).get('videos')).push(value);
  }
  deleteRow(row:number,index: number,link:any) {
    const addValue:any= this.videoSectionForm.get('section').at(row).get('videos') as FormArray;
    Swal.fire({
      title: this.translate.instant('Delete '+link.value.title),
      text: this.translate.instant('Do you want to Delete ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
    addValue.removeAt(index);
    this.toaster.success(this.translate.instant('Deleted Successfully!'));
    // });
    if(link && link.value && link.value.id!='' && link.value.id>0)
    {
    this.Apiservice.getApi(endpoints.removeVideo.path+'?id='+link.value.id).subscribe((data: any) => {
      this.showMsg = true;
      this.eventVideoSection.splice(index, 1 );

      const addValue = this.videoSectionForm.get('video') as FormArray;
      addValue.removeAt(index);
    }, (err: any) => {

      this.toaster.error(this.translate.instant('Something went wrong!'));
    });
    }
    }
  })
  }

  setStep(index: number) {
    this.step = index;
  }
  videoUpload(vindex:number,index:number,section:any,link:any){

    let formValue:any={
      school: this.videoSectionForm.value.school,
      section_id: section.value.id,
      // event_id: this.eventdetails.id,
      link:link.value.link,
      description:link.value.description,
      title:link.value.title,
      id:link.value.id,
      video_id:link.value.video_id,
    }

    let apiPath:string=endpoints.singleVideoUpload.path;
    if(link.value.id>0)
    apiPath=endpoints.singleVideoUpdate.path
    this.Apiservice.postApi(
      apiPath,
      formValue
    ).subscribe((data: any) => {
        this.showMsg = true;
        this.toaster.success(data.message);
        link.value.id = data.video.id;
        link.value.video_id = data.video.video_id;
      },
      (err: any) => {
        if(err.error.message){
          this.toaster.error(err.error.message);
        }else{
          this.toaster.error(err.error.message);
        }
      }
    );
  }
  openVideo(vindex:number,index:number,section:any,list:any){
    const modalRef = this.modalService.open(VideoIframeDialogComponent, {
      // size: 'xl',
      backdrop : 'static',
      keyboard : false,
      windowClass: 'myCustomModalClass'
    });
    modalRef.componentInstance.link = list.value;
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.section = section.value;
  }

  resetsearch() {
    this.searchText = '';
  }

}
