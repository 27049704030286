import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from '../../../../core/config/endpoints';

@Component({
  selector: 'app-staff-positions',
  templateUrl: './staff-positions.component.html',
  styleUrls: ['./staff-positions.component.css'],
})
export class StaffPositionsComponent implements OnInit {
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  liststaff: any = [];
  ActiveStatus: any = [];
  InActiveStatus: any = [];
  settings: any = [];
  position: any = [];
  submitted: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    this.position = this.settings.data.filter(
      (t: any) => t.group == 'staffs-position'
    );
    this.staffpositionList();
  }

  staffpositionList() {
    this.Apiservice.getApi(endpoints.staffpositionList.path).subscribe(
      (data: any) => {
        this.liststaff = data;
      },
      (err: any) => {
      }
    );
  }
  deleteActiveRow(i: number, school: any) {
    Swal.fire({
      title: this.translate.instant('Delete'),
      text: this.translate.instant('Do you want to Delete ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deleteposition.path + '?id=' + school.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title:  this.translate.instant('Deleted Succesfully'),
              icon: 'success',
            });
            this.staffpositionList();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
