import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Belt, EditableSchoolDetails, Program, SchoolDetails } from './admin.interface';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private http: HttpClient) {}

  patchSchool(body: EditableSchoolDetails) {
    return this.http.patch(`${environment.BACKEND_BASE_URL}admin/school`, body);
  }

  getSchoolDetails(): Observable<SchoolDetails> {
    return this.http.get<SchoolDetails>(`${environment.BACKEND_BASE_URL}admin/school`);
  }

  getBeltList(): Observable<Belt[]> {
    return this.http.get<Belt[]>(`${environment.BACKEND_BASE_URL}admin/belts`);
  }

  patchBelt(belt_id: string, body: Partial<Belt>): Observable<Belt> {
    return this.http.patch<Belt>(`${environment.BACKEND_BASE_URL}admin/belts/${belt_id}`, body);
  }

  postBelt(body: Partial<Belt>): Observable<Belt> {
    return this.http.post<Belt>(`${environment.BACKEND_BASE_URL}admin/belts`, body);
  }

  getPrograms(): Observable<Program[]> {
    return this.http.get<Program[]>(`${environment.BACKEND_BASE_URL}admin/programs`);
  }

  postProgram(body: Partial<Program>): Observable<Program> {
    return this.http.post<Program>(`${environment.BACKEND_BASE_URL}admin/programs`, body);
  }

  patchProgram(program_id: string, body: Partial<Program>): Observable<Program> {
    return this.http.patch<Program>(`${environment.BACKEND_BASE_URL}admin/programs/${program_id}`, body);
  }
}
