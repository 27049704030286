import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../services/auth.service';
import { endpoints } from 'src/app/core/config/endpoints';
import  html2canvas from 'html2canvas';

@Component({
  selector: 'app-admin-attendance',
  templateUrl: './admin-attendance.component.html',
  styleUrls: ['./admin-attendance.component.css']
})
export class AdminAttendanceComponent implements OnInit {
  @ViewChild('screen') screen: ElementRef | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;

  attendance:any
  listattendance:any=[]
  userrole:any
  routevalue:any
  router_id:any
  urlSegments:any

  constructor(
    private Apiservice: ApiService,
    private authservice: AuthService,
    private route:ActivatedRoute,
    private router:Router
  ) { 
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const urlTree = this.router.parseUrl(val.url);
       
        
        this.urlSegments = urlTree.root.children['primary'].segments.map(segment => segment.path);        
      }
    });
  }

  ngOnInit(): void {
    this.routevalue=this.route.params  
    this.router_id=this.routevalue._value.id 
    if(this.urlSegments[2]=='view-student'){
      this.Apiservice.getApi(endpoints.attendance.path+"?user_id="+this.router_id).subscribe(
        (data: any) => {
          this.attendance=data
          this.listattendance= data.date_filter.filter((s:any)=>s.events!=null);
        }
      );
    }else if(this.urlSegments[2]=='view-coach'){
      this.Apiservice.getApi(endpoints.coachattendance.path+"?user_id="+this.router_id).subscribe(
        (data: any) => {
          this.attendance=data
          this.listattendance = data.date_filter.filter((s:any)=>s.events!=null);
        }
      );
    }
   
  }
  dateFilter(event:any){
    if(this.urlSegments[2]=='view-student'){
      this.Apiservice.getApi(endpoints.attendance.path+"?user_id="+this.router_id+"&date="+event.target.value).subscribe(
        (data: any) => {
          this.listattendance= data.date_filter.filter((s:any)=>s.events!=null);;
        }
      );
    }else if(this.urlSegments[2]=='view-coach'){
      this.Apiservice.getApi(endpoints.coachattendance.path+"?user_id="+this.router_id+"&date="+event.target.value).subscribe(
        (data: any) => {
          this.listattendance= data.date_filter.filter((s:any)=>s.events!=null);;
        }
      );
    }
   
    
  }
  Captureimage(){
    html2canvas(this.screen.nativeElement).then((canvas:any) => {
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
    });
  }
}
