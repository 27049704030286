import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators
} from '@angular/forms';

import { CountryService } from 'src/app/shared/services/countrycitystate';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'app-country-state',
  templateUrl: './country-state.component.html',
  styleUrls: ['./country-state.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CountryStateComponent implements OnInit {
  @Output() CountryChanged: EventEmitter<any> = new EventEmitter();
  @Input() countryForm: FormGroup | any;

  submitted = false;
  countries: any;
  states: any;
  cities: any;
  countrycode: any;
  statecode: any;
  citycode: any;
  citydata = 0;
  statedata = 0;

  constructor(
    private service: CountryService,
    public formBuilder: FormBuilder
  ) {
    this.countries = this.service.getCountries();
    
  }

  ngOnInit() {
    if(this.countryForm.value.countryCode){
      this.states = this.service.getStatesByCountry(this.countryForm.value.countryCode); 
      this.cities = this.service.getCitiesByState(
        this.countryForm.value.countryCode,
        this.countryForm.value.stateCode
      ); 
    }
    
   
  }
  get f() {
    return this.countryForm.controls;
  }
  selectCountry(event: any) {
    this.countrycode = event;
    this.states = this.service.getStatesByCountry(this.countrycode);    
    this.countryForm.controls['countryCode'].setValue(this.countrycode);
    this.countryForm.controls['stateCode'].setValue('');
    this.countryForm.controls['city'].setValue('');
    if (this.states !='') {
      this.statedata = 1;
    }
  }
  selectState(event: any) {
    this.statecode = event;
    this.countryForm.controls['city'].setValue('');
    this.cities = this.service.getCitiesByState(
      this.countrycode || this.countryForm.value.countryCode,
      this.statecode
    );
    
    if (this.cities != '') {
      this.citydata = 1;
    }
    this.countryForm.controls['stateCode'].setValue(this.statecode);
  }
  selectCity(event: any) {
    this.citycode = event;
    this.countryForm.controls['city'].setValue(this.citycode);
  }

}
