import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';

import { TranslateService } from '@ngx-translate/core';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  paymentlist: any = [];
  schoollist: any = [];
  dashboardcount: any;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.Apiservice.getApi(endpoints.superadmindashbnoard.path).subscribe(
      (data: any) => {
        this.dashboardcount = data;
        this.paymentlist = data.payments.filter((s: any) => s.school != null);
        this.schoollist = data.latestSchool;

        let chartData = {
          labels: [
            this.translate.instant('Current Month'),
            this.translate.instant('Current Year'),
            this.translate.instant('Last Month'),
            this.translate.instant('Last Year'),
          ],
          datasets: [
            {
              data: [
                this.dashboardcount.currentMonth?.count ?? null,
                this.dashboardcount.currentYear?.count ?? null,
                this.dashboardcount.lastMonth?.count ?? null,
                this.dashboardcount.lastYear?.count ?? null,
              ],
            },
          ],
        };

        this.pieChartData = chartData;
      },

      (err: any) => {}
    );
  }

  // Pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          padding: 15,
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      datalabels: {
        font: {
          weight: 'bold',
          size: 14,
        },
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [],
  };
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [DatalabelsPlugin];

  // events
  public chartClicked({ event, active }: { event: MouseEvent; active: {}[] }): void {}

  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {}
}
