import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-school',
  templateUrl: './view-school.component.html',
  styleUrls: ['./view-school.component.css'],
})
export class ViewSchoolComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;
  eventsForm: FormGroup | any;
  paymentsForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pageclass:any
  pagecounts: any;
  pagecoach:any
  pageevent:any
  pagepayment:any
  itemPerPage = 50;
  schoolList: any = [];
  ActiveStatus: any = [];
  InActiveStatus: any = [];
  listview: any;
  listviewstudent: any;
  listviewcoach: any;
  listviewevent: any;
  listviewpayment: any;
  settings: any = [];
  school: any = [];
  submitted = false;
  studentname: any = [];
  classname: any;
  coachname: any;
  eventsname: any;
  userid:any

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    private route:ActivatedRoute,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {

    this.pagecounts = this.Apiservice.pageCounts;
    this.userid=this.route.params

    this.Liststudent();
    this.eventsForm = this.formBuilder.group({
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
    });
    this.paymentsForm = this.formBuilder.group({
      start: [''],
      end: [''],
    });
  }

  Liststudent() {
    this.Apiservice.getApi(endpoints.viewschool.path+"?school_id="+this.userid._value.schoolid).subscribe(
      (data: any) => {
        this.AuthService.schoolname=data.data.school.name
        this.listview = data.data.student;
        this.listviewstudent = data.data.class;
        this.listviewcoach = data.data.coach;
        this.listviewevent = data.events;
        this.listviewpayment = data.data.payment;
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  onSubmit() {
    this.submitted = true;
 
    if (this.eventsForm.invalid) {
      this.toaster.error(this.translate.instant('Select a from and to date'))
      return;
    }
    this.Apiservice.getApi(endpoints.viewschool.path+"?start_time="+this.eventsForm.value.start_time+"&end_time="+this.eventsForm.value.end_time+"&school_id="+this.userid._value.schoolid).subscribe(
      (data: any) => {
        this.listviewevent = data.events;
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  clear(){
    this.daterangepicker.clear()
    this.eventsForm.reset()
    this.Liststudent()
  }
  private convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  OnSelect(event:any){
    if(event?.startDate){
      let startdate=this.convert(event.startDate._d)
      let enddate=this.convert(event.endDate._d)
      this.eventsForm.controls['start_time'].setValue(startdate)
      this.eventsForm.controls['end_time'].setValue(enddate)
    }
  }
  resetsearch() {
    this.searchText = '';
  }

  pageChange(event: any) {
    this.page = event;
  }
  pageClass(event: any) {
    this.pageclass = event;
  }
  pageCoach(event: any) {
    this.pagecoach = event;
  }
  pageEvent(event: any) {
    this.pageevent = event;
  }
  pagePayment(event: any) {
    this.pagepayment = event;
  }
  
 
}
