<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-10 col-md-10 col-sm-6 col-8">
          <h2 class="mainh2" translate>{{ schoolname }} <span translate>Class</span></h2>
        </div>
      </div>
      <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-4 col-4">
            <label translate>Program</label>
            <ng-select placeholder="{{ 'Select Program' | translate }}" formControlName="class">
              <ng-option *ngFor="let status of classData" [value]="status.id">{{ status.name }} </ng-option>
            </ng-select>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-4">
            <button class="btn-sm auth-button submit-newbtn" type="submit" translate>Search</button>
            <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>Clear</button>
          </div>
        </div>
      </form>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12 m-auto">
          <app-calander-leftmenu (newItemEvent)="enentdata($event)" [date]="selectdate" [dataInput]="filterData">
          </app-calander-leftmenu>
        </div>
        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="row clearfix">
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <ul class="nav nav-pills" id="nav-tab" role="tablist">
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                                                href="#pills-home" role="tab" aria-controls="pills-home"
                                                aria-selected="true" translate>Student List</a>
                                        </li>
                                        <li class="nav-item" role="presentation">
                                            <a class="nav-link" id="pills-note-tab" data-toggle="pill"
                                                href="#pills-note" role="tab" aria-controls="pills-note"
                                                aria-selected="false" translate>Notes</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="tab-content" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                            aria-labelledby="pills-home-tab">
                                            <div class="card">
                                                <div class="row">
                                                    <div class="col-md-12" id="content" >
                                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                            <div class="input-group mb-1 mt-4">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Search" [(ngModel)]="searchText"
                                                                    name="searchText">
                                                                <div class="input-group-append" *ngIf="searchText">
                                                                    <span class="input-group-text" id="search-mail"
                                                                        (click)="resetsearch()" translate>Clear</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="auth-form mt-4 p-1">
                                                            <h5 *ngIf="eventsdetails.length==0 && !activedata" class="text-center" translate>No data available</h5>
                                                            <div class="row clearfix mt-4 mb-4" *ngIf="eventsdetails.length>0">
                                                                <div class="col-lg-3 col-md-4 col-sm-4 col-6"
                                                                    *ngFor="let studentlist of eventsdetails  | filter: searchText ; let i = index">
                                                                    <div class="calendar-list mb-4">
                                                                        <div class="check-uncheck">
                                                                            <img src="assets/images/user.png"
                                                                                class="img-person"
                                                                                alt="User Profile Picture">
                                                                        </div>
                                                                        <button class="btn inputcheck1 mr-2"
                                                                            data-toggle="modal"
                                                                            data-target="#exampleModal" (click)="Studentmessage(studentlist)">
                                                                            <i class="fas fa-envelope"></i>
                                                                        </button>
                                                                        <button *ngIf="studentlist.status==1"
                                                                            class="btn inputcheck1 btngreen">
                                                                            <i class="fas fa-check"></i>
                                                                        </button>
                                                                        <button *ngIf="studentlist.status==0"
                                                                            class="btn inputcheck1 btnred">
                                                                            <i class="fas fa-times"></i>
                                                                        </button>
                                                                        <p>{{studentlist.student_id.first_name}}
                                                                            {{studentlist.student_id.middle_name}}
                                                                            {{studentlist.student_id.last_name}}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-note" role="tabpanel"
                                            aria-labelledby="pills-note-tab">
                                            <div class="card">
                                                <h5 *ngIf="eventnotes.length==0 && !activedata" class="text-center py-4" translate>No data available</h5>
                                                <div class="row clearfix mt-4 p-3" *ngIf="eventnotes.length>0 && activedata">
                                                    <div class="col-lg-10 col-md-10 col-sm-10 m-auto" *ngFor="let notes of eventnotes">
                                                        <div class="box-border mb-2 p-3">
                                                            <div class="row">
                                                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                    <p class="float-right"><strong>{{notes.created_at | date:'dd-MM-YYYY hh:mm'}}</strong></p>
                                                                </div>

                                                            </div>
                                                            <p >{{notes.message}}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-blue-color" id="exampleModalLabel" translate>Message</h5>
        <button type="button" class="close" data-dismiss="modal" #closemsgModal aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row clearfix mt-4">
          <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
            <form [formGroup]="messageForm" (ngSubmit)="messageSubmit()">
              <div class="form-group">
                <label translate>Message<span class="text-danger">*</span></label>
                <textarea
                  class="form-control"
                  formControlName="message"
                  [ngClass]="{ 'is-invalid': submit && ff.message.errors }"></textarea>
                <div *ngIf="submit && ff.message.errors" class="invalid-feedback">
                  <div *ngIf="ff.message.errors.required" translate>Message is required</div>
                </div>
              </div>
              <button type="submit" class="btn btn-dark float-right auth-button mt-4 mr-2" translate>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
