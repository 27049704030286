import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Subscription, debounceTime } from 'rxjs';
import { EventsRepository } from '../../../modules/admin/classes/state/events.repository';
import { SelectOption, convertToSelectOption } from '../field-select/field-select.interfaces';
import { ClassesFilters } from './search-class.interfaces';

@Component({
  selector: 'app-search-class',
  templateUrl: './search-class.component.html',
  styleUrls: ['./search-class.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SearchClassComponent implements OnInit {
  @Input() showFilter: boolean = false;

  @Input() isMobile: boolean = false;

  @Output() onToggleShow = new EventEmitter();

  @Output() filters: EventEmitter<ClassesFilters> = new EventEmitter();

  selectedDate: string = '';

  filterForm: FormGroup;

  programsList: SelectOption[] = [];

  coachList: SelectOption[] = [];

  coachesSub$: Subscription | undefined;
  programsSub$: Subscription | undefined;
  formSub$: Subscription | undefined;

  constructor(private eventsRepo: EventsRepository) {
    this.filterForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      program: new FormControl(null),
      coach: new FormControl(null),
      name: new FormControl(null),
    });
  }

  ngOnInit() {
    if (!this.isMobile) this.updateFormFilters();
    this.fetchSelectInfo();
  }

  fetchSelectInfo() {
    this.coachesSub$ = this.eventsRepo.coaches$.subscribe((coaches) => {
      this.coachList = convertToSelectOption(coaches, 'user_id', 'name', 'classes_count') || [];
    });

    this.programsSub$ = this.eventsRepo.programs$.subscribe((programs) => {
      this.programsList = convertToSelectOption(programs, 'program_id', 'name', 'classes_count') || [];
    });
  }

  updateFormFilters() {
    this.formSub$ = this.filterForm?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (!this.isMobile) this.onUpdateFilters();
    });
  }

  onKeyup(event: KeyboardEvent) {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      this.filterForm.patchValue({ name: '' });
    }
  }

  onDateChange(dateEv: any) {
    if (dateEv?.startDate) {
      const startDate = moment(dateEv?.startDate).format('YYYY-MM-DD');
      const endDate = moment(dateEv?.endDate).format('YYYY-MM-DD');

      this.filterForm.patchValue({ startDate: startDate, endDate: endDate });
    } else {
      this.eventsRepo.updateFilter({ ...this.filterForm.value, startDate: null, endDate: null });
    }

    if (!this.isMobile) {
      this.onUpdateFilters();
    }
  }

  onDateClear() {
    this.filterForm.patchValue({ startDate: null, endDate: null });
    this.eventsRepo.updateFilter(this.filterForm.value);
  }

  clearFilters() {
    this.filterForm.reset();
    this.selectedDate = '';

    this.eventsRepo.resetFilter();
  }

  onUpdateFilters() {
    this.eventsRepo.updateFilter(this.filterForm.value);
  }

  onMobileFilterButton() {
    this.onUpdateFilters();
    this.onToggleShow.emit();
  }

  ngOnDestroy() {
    this.formSub$?.unsubscribe();
    this.coachesSub$?.unsubscribe();
    this.programsSub$?.unsubscribe();
  }
}
