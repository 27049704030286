import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { StudentService } from 'src/app/modules/admin/student/state/student.service';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminStudentResolver implements Resolve<any> {
  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService,
    private studentService: StudentService,
    private adminService: AdminService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      let userId = route.paramMap.get('id');
      if (userId == null) {
        this.adminService.getBeltList().subscribe((data) => {
          resolve({ belts: data });
        });
      } else {
        forkJoin([this.studentService.getStudent(userId), this.adminService.getBeltList()]).subscribe(
          ([student, belts]) => {
            resolve({ ...student, belts });
          }
        );
      }
    });
  }
}
