export interface SchoolDetails extends EditableSchoolDetails {
  school_id: string;
  logo_url: string | null;
}

export interface EditableSchoolDetails {
  name: string;
  responsible: string;
  email: string;
  phone_code: string;
  phone_number: string;
  address: string;
  city: string;
  state: string | null;
  state_code: string;
  country: string | null;
  country_code: string;
  zip_code: string;
  timezone: string;
}

export interface Belt {
  belt_id: string;
  name: string;
  minimum_attendance: number;
  order: number;
  active: boolean;
  users_count: number;
}

export interface Program {
  program_id: string;
  name: string;
  classes_count: number;
  color: string;
  colors: Colors;
  active: boolean;
}

export enum ProgramColor {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  PURPLE = 'purple',
}

interface Colors {
  bg: string;
  font: string;
}
