import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { CountryService } from 'src/app/shared/services/countrycitystate';
import { ZipCodeValidator } from '../../validators/zipcode.validator';
import { SelectOption } from '../field-select/field-select.interfaces';

@Component({
  selector: 'app-country-state-city',
  templateUrl: './country-state-city.component.html',
  styleUrls: ['./country-state-city.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class CountryStateCityComponent implements OnInit {
  @Output() CountryChanged: EventEmitter<any> = new EventEmitter();
  @Input() countryForm: FormGroup | any;

  submitted = false;
  countries: SelectOption[] = [];
  states: SelectOption[] = [];
  cities: any;
  country_code: any;
  state_code: any;
  citycode: any;
  citydata = 0;
  statedata = 0;
  zipCodeErrorVisible = false;
  zipCodeErrorMessage = '';

  subscriptionAdded = false;
  countryInteraction = false;

  constructor(
    private service: CountryService,
    public formBuilder: FormBuilder,
    private translate: TranslateService
  ) {
    this.countries = this.service.getCountries().map((country) => ({
      id: country.isoCode,
      text: country.name,
    }));
  }

  ngOnInit() {
    try {
      this.countryForm.controls['country_code'].valueChanges.subscribe((value: SelectOption) => {
        if (value) {
          this.selectCountry(value);
          this.updateZipCodeValidation();
        }
      });
    } catch (error) {}

    if (this.countryForm.value.country_code) {
      this.states = this.service.getStatesByCountry(this.countryForm.value.country_code).map((state) => ({
        id: state.isoCode,
        text: state.name,
      }));
      this.cities = this.service
        .getCitiesByState(this.countryForm.value.country_code, this.countryForm.value.state_code)
        .map((city) => ({
          id: city.name,
          text: city.name,
        }));
    }

    // Setup initial validation CEP
    this.countryForm.controls['zip_code'].setValidators([ZipCodeValidator.createValidator()]);
    this.countryForm.controls['zip_code'].updateValueAndValidity();
    this.countryForm.get('zip_code').valueChanges.subscribe(() => this.updateZipCodeErrorState());

    // See changing on zip_code field
    this.countryForm.controls['zip_code'].statusChanges.subscribe(() => {
      this.updateZipCodeErrorState();
    });
  }

  get f() {
    return this.countryForm.controls;
  }
  private updateZipCodeValidation(): void {
    this.countryForm.controls['zip_code'].setValidators([Validators.required, ZipCodeValidator.createValidator()]);
    this.countryForm.controls['zip_code'].updateValueAndValidity();
  }

  public updateZipCodeErrorState(): void {
    const zipControl = this.countryForm.get('zip_code');

    this.zipCodeErrorVisible = zipControl?.invalid && (zipControl?.dirty || zipControl?.touched);

    if (this.zipCodeErrorVisible) {
      this.zipCodeErrorMessage = ZipCodeValidator.getErrorMessage(zipControl?.errors, this.translate);
    }
  }
  userInteracted() {
    if (this.subscriptionAdded) {
      return;
    }
    try {
      this.subscriptionAdded = true;

      this.countryForm.controls['state_code'].valueChanges.subscribe((value: SelectOption) => {
        if (value) {
          this.selectState(value);
        }
      });
    } catch (error) {}
  }
  selectCountry(event: any) {
    this.country_code = event;
    this.states = this.service.getStatesByCountry(this.country_code).map((state) => ({
      id: state.isoCode,
      text: state.name,
    }));

    if (this.countryInteraction) {
      this.countryForm.controls['state_code'].setValue('');
      this.countryForm.controls['city'].setValue('');
    }
    if (this.states.length > 0) {
      this.statedata = 1;
    }
  }
  selectState(event: any) {
    this.state_code = event;
    this.countryForm.controls['city'].setValue('');
    this.cities = this.service
      .getCitiesByState(this.country_code || this.countryForm.value.country_code, this.state_code)
      .map((city) => ({
        id: city.name,
        text: city.name,
      }));

    if (this.cities && this.cities.length > 0) {
      this.citydata = 1;
    }
  }
  selectCity(event: any) {
    this.citycode = event;
    this.countryForm.controls['city'].setValue(this.citycode);
  }
}
