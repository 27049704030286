<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Payments</h2>
                </div>
                <div class="col-md-12">
                    <div class="float-right">
                        <a class="btn-sm auth-button" [routerLink]="['/superadmin/payments/add-payment']" translate><i
                                class="fas fa-plus mr-1"></i>Pay Now</a>
                    </div>
                </div>
            </div>
            <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                        <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3 col-8">
                        <label translate>School</label>
                        <ng-select placeholder="{{'Select School' | translate}}"  (change)="selectUser($event)"
                           >
                            <ng-option *ngFor="let status of school" [value]="status.id">{{status.name}}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-3 col-4">
                        <button class="btn-sm auth-button submit-newbtn" translate>Search</button>
                        <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>
                            Clear
                        </button>
                    </div>
                </div>
            </form>
            <div class="row mt-4 clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card">
                        <div class="body">
                            <div class="row">
                                <div class="col-md-3">
                                    <span class="d-flex">{{ 'Show' | translate}}
                                        <select [(ngModel)]="itemPerPage" name="selection-datatable_length"
                                            aria-controls="selection-datatable"
                                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                            <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                                {{pageCountObj}}</option>
                                        </select> {{ 'entries' | translate}}
                                    </span>
                                </div>
                                <div class="col-md-9">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="{{'Search' | translate}}"
                                            [(ngModel)]="searchText" name="searchText">
                                        <div class="input-group-append" *ngIf="searchText">
                                            <span class="input-group-text" id="search-mail" (click)="resetsearch()"
                                                translate>Clear</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th translate>Transaction Id</th>
                                        <th translate>Transaction Date</th>
                                        <th translate>Packages</th>
                                        <th translate>Amount</th>
                                        <th translate>Payment status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payment of listpayment | paginate: { itemsPerPage: itemPerPage, currentPage: page } | filter: searchText; let i = index">
                                        <td>{{payment.transaction_id}}</td>
                                        <td>{{payment.transaction_date  }}</td>
                                        <td *ngIf="payment.package">{{payment.package.plan_name}}</td>
                                        <td>R$ {{payment.amount}}</td>
                                        <td>{{payment.payment_status}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="row mx-2">
                        <pagination-controls 
                            (pageChange)="pageChange($event)"
                            [previousLabel]="'Previous' | translate"
                            [nextLabel]="'Next' | translate"
                        ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>