import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-calander-leftmenu',
  templateUrl: './calander-leftmenu.component.html',
  styleUrls: ['./calander-leftmenu.component.css'],
})
export class CalanderLeftmenuComponent implements OnInit {
  listevents: any=[];
  datePicker: boolean = false;
  settings: any = [];
  classData: any = [];
  coachList: any = [];
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() date: any;
  @Input() dataInput: any;
  classes:any=[]
  listclass:any=[]
  eventsdetails:any=[]
  eventDetails:any
  coachDetails:any
  eventregister:any=[]
  eventactive:any
  myDate:any

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService
  ) {
    this.myDate = new Date();
  }

  ngOnInit(): void {
  }

  cToggoleMenu() {
    const body = document
      .getElementsByTagName('body')[0]
      .classList.remove('offcanvas-active');
  }
  datepickerCalendar() {
    this.datePicker = !this.datePicker;
  }
  eventdetails(event: any) {
    this.eventactive=event.id
    this.newItemEvent.emit(event)

  }
  singleevent(event:any){
    this.eventDetails=event.description
  }
  coachdetails(event:any){
    this.coachDetails=event.coach
  }
}
