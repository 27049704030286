import { RouterModule, Routes } from '@angular/router';

import { AddClassComponent } from './class/add-class/add-class.component';
import { AddMessagesComponent } from './messages/add-messages/add-messages.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { AddPaymentComponent } from './payments/add-payment/add-payment.component';
import { AddRolesComponent } from './settings/roles/add-roles/add-roles.component';
import { AddSchoolComponent } from './school/add-school/add-school.component';
import { AddStaffpositionsComponent } from './settings/staff-positions/add-staffpositions/add-staffpositions.component';
import { AddUserComponent } from './profile/add-user/add-user.component';
import { ClassComponent } from './class/class.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EditSchoolResolver } from 'src/app/shared/services/edit-school.resolver';
import { EditpackagesResolver } from 'src/app/shared/services/superadminpackage.resolver';
import { MessagesComponent } from './messages/messages.component';
import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification/notification.component';
import { PackagesComponent } from './packages/packages.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { RolesComponent } from './settings/roles/roles.component';
import { SchoolComponent } from './school/school.component';
import { SeoSettingsComponent } from './settings/seo-settings/seo-settings.component';
import { SettingResolver } from 'src/app/shared/services/setting.resolver';
import { StaffPositionsComponent } from './settings/staff-positions/staff-positions.component';
import { SuperAdminAuthGuard } from '../../shared/services/superadmin-authguard.guard';
import { SuperAdminClassResolver } from 'src/app/shared/services/superadminclass.resolver';
import { SuperAdminPositionResolver } from 'src/app/shared/services/superadminposition.resolver';
import { SuperAdminProfileResolver } from 'src/app/shared/services/superadminprofile.resolver';
import { SuperAdminRoleResolver } from 'src/app/shared/services/superadminrole.resolver';
import { SuperAdminSEOResolver } from 'src/app/shared/services/superadminseo.resolver';
import { SuperAdminUserProfileResolver } from 'src/app/shared/services/superadminuserprofile.resolver';
import { SuperAdminUserResolver } from 'src/app/shared/services/superadminuser.resolver';
import { ViewCoachComponent } from './school/view-coach/view-coach.component';
import { ViewEventComponent } from './school/view-event/view-event.component';
import { ViewSchoolComponent } from './school/view-school/view-school.component';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { AddVideoCategoryComponent } from './video-category/add-video-category/add-video-category.component';
import { SuperAdminEditVideoCategoryResolver } from 'src/app/shared/services/superadmineditvideocategory.resolver';
import { VideoLibraryComponent } from './video-library/video-library.component';

const routes: Routes = [
  {
    path: 'superadmin',
    resolve: { message: SettingResolver },
    canActivate: [SuperAdminAuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'notification',
        component: NotificationComponent,
      },
      {
        path: 'school',
        component: SchoolComponent,
      },
      {
        path: 'school/add-school',
        component: AddSchoolComponent,
      },
      {
        path: 'school/view-school/:schoolid',
        component: ViewSchoolComponent,
      },
      {
        path: 'school/edit-school/:id',
        component: AddSchoolComponent,
        resolve: { message: EditSchoolResolver },
      },
      {
        path: 'school/view-coach/:id',
        component: ViewCoachComponent,
      },
      {
        path: 'school/view-event/:id',
        component: ViewEventComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        resolve: { message: SuperAdminProfileResolver },
      },
      {
        path: 'profile/add-user',
        component: AddUserComponent,
      },
      {
        path: 'profile/edit-user/:id',
        component: AddUserComponent,
        resolve: { message: SuperAdminUserResolver },
      },
      {
        path: 'class',
        component: ClassComponent,
      },
      {
        path: 'class/add-class',
        component: AddClassComponent,
      },
      {
        path: 'class/edit-class/:id',
        component: AddClassComponent,
        resolve: { message: SuperAdminClassResolver },
      },
      {
        path: 'video-category',
        component: VideoCategoryComponent,
      },
      {
        path: 'video-category/add-video-category',
        component: AddVideoCategoryComponent,
      },
      {
        path: 'video-category/edit-video-category/:id',
        component: AddVideoCategoryComponent,
        resolve: { message: SuperAdminEditVideoCategoryResolver },
      },
      {
        path: 'positions',
        component: StaffPositionsComponent,
      },
      {
        path: 'positions/add-positions',
        component: AddStaffpositionsComponent,
      },
      {
        path: 'positions/edit-positions/:id',
        component: AddStaffpositionsComponent,
        resolve: { message: SuperAdminPositionResolver },
      },
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'roles/add-roles',
        component: AddRolesComponent,
      },
      {
        path: 'roles/edit-roles/:id',
        component: AddRolesComponent,
        resolve: { message: SuperAdminRoleResolver },
      },
      {
        path: 'seosettings',
        component: SeoSettingsComponent,
        resolve: { message: SuperAdminSEOResolver },
      },
      {
        path: 'payments',
        component: PaymentsComponent,
      },
      {
        path: 'payments/add-payment',
        component: AddPaymentComponent,
      },
      {
        path: 'payments/add-payment/:id',
        component: AddPaymentComponent,
      },
      {
        path: 'packages',
        component: PackagesComponent,
      },
      {
        path: 'packages/add-packages',
        component: AddPackagesComponent,
      },
      {
        path: 'packages/edit-packages/:id',
        component: AddPackagesComponent,
        resolve: { message: EditpackagesResolver },
      },
      {
        path: 'messages',
        component: MessagesComponent,
      },
      {
        path: 'messages/add-message',
        component: AddMessagesComponent,
      },
      {
        path: 'messages/edit-message/:id',
        component: AddMessagesComponent,
      },
      {
        path: 'report',
        component: ReportComponent,
      },
      {
        path: 'video-library',
        component: VideoLibraryComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuperAdminRoutingModule {
  static components = [DashboardComponent];
}
