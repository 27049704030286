import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICountry, Timezones } from 'country-state-city/dist/lib/interface';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { finalize } from 'rxjs';
import { SelectOption } from 'src/app/shared/components/field-select/field-select.interfaces';
import { HasUnsavedChanges } from 'src/app/shared/guard/unsaved.guard';
import { AddressValidator } from 'src/app/shared/validators/address.validator';
import { AuthService } from '../../../shared/services/auth.service';
import { CountryService } from '../../../shared/services/countrycitystate';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { EditableSchoolDetails, SchoolDetails } from '../admin.interface';
import { AdminService } from '../admin.service';
import { HeaderService } from '../header/state/header.service';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss'],
})
export class SchoolComponent implements OnInit, HasUnsavedChanges {
  schoolForm = this.formBuilder.group({
    logo: ['', []],
    name: ['', [Validators.required]],
    responsible: ['', [Validators.required]],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phone_code: ['+1', Validators.required],
    phone_number: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
    address: ['', Validators.required],
    country_code: ['', Validators.required],
    state_code: ['', Validators.required],
    city: ['', Validators.required],
    timezone: ['', Validators.required],
    zip_code: ['', Validators.required],
  });

  // New reactive properties (replaces functions in the template)
  addressErrorVisible = false;
  addressErrorMessage = '';

  submitted = false;
  submitting = false;
  submit = false;
  loading = false;
  showMsg: boolean = false;
  data: any;
  settings: any = [];
  countryCodeList: any = [];
  maxDigit: any;
  minDigit: any;
  timezone: any;
  timezones: SelectOption[] = [];
  fromDashboard: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private element: ElementRef,
    private translate: TranslateService,
    private countryService: CountryService,
    private headerService: HeaderService,
    private adminService: AdminService,
    private loader: NgxUiLoaderService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.countryService.getCountries().forEach((element: ICountry) => {
      if (element.timezones) {
        this.timezones = [
          ...this.timezones,
          ...element.timezones.map((timezone: Timezones) => ({
            id: timezone.zoneName,
            text: `${timezone.zoneName} (${timezone.gmtOffsetName})`,
          })),
        ];
      }
    });

    this.schoolForm.get('address')?.statusChanges.subscribe(() => {
      this.updateAddressErrorState();
    });

    this.schoolForm.get('country_code')?.valueChanges.subscribe(() => {
      this.updateAddressErrorState();
    });
  }

  ngOnInit(): void {
    this.headerService.pageTitle$.next('Edit School');
    this.settings = this.AuthService.setting;

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });

    this.updatePhoneMask();

    this.schoolForm
      .get('address')
      ?.setValidators([
        Validators.required,
        AddressValidator.createValidator(() => this.schoolForm?.get('country_code')?.value),
      ]);

    this.loadSchoolData();
    this.fromDashboard = this.route.snapshot.queryParamMap.get('from') === 'dashboard';
  }

  private updateAddressErrorState(): void {
    const addressControl = this.schoolForm.get('address');
    this.addressErrorVisible = addressControl
      ? addressControl.invalid && (addressControl.dirty || addressControl.touched)
      : false;
    this.addressErrorMessage = this.getAddressErrorMessage();
  }

  getAddressErrorMessage(): string {
    const errors = this.schoolForm.get('address')?.errors;
    const countryCode = this.schoolForm.get('country_code')?.value;
    return AddressValidator.getErrorMessage(errors, countryCode, this.translate);
  }

  get f() {
    return this.schoolForm.controls;
  }

  loadSchoolData() {
    this.schoolForm.disable();
    this.adminService
      .getSchoolDetails()
      .pipe(
        finalize(() => {
          this.loader.stop();
          this.schoolForm.enable();
        })
      )
      .subscribe((data: SchoolDetails) => {
        this.data = data;
        this.schoolForm.enable();

        this.schoolForm.setValue({
          name: data.name,
          responsible: data.responsible,
          email: data.email,
          phone_code: data.phone_code || '+1',
          phone_number: data.phone_number,
          address: data.address,
          country_code: data.country_code,
          state_code: data.state_code,
          city: data.city,
          timezone: data.timezone,
          zip_code: data.zip_code,
          logo: null,
        });

        this.updatePhoneMask();

        try {
          const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          const userTimezoneOption = this.timezones.find((tz) => tz.id === timezone);

          if (userTimezoneOption) {
            this.schoolForm.patchValue({
              timezone: userTimezoneOption.id,
            });
          }
        } catch (error) {}
      });
  }

  clearAddressErrors() {
    this.schoolForm.get('address')?.setErrors(null);
    this.schoolForm.get('address')?.markAsUntouched();
  }
  showAddressError(): boolean {
    const addressControl = this.schoolForm.get('address');
    return addressControl ? addressControl.invalid && (addressControl.dirty || addressControl.touched) : false;
  }

  onSubmit() {
    if (this.schoolForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      for (const key in this.schoolForm.controls) {
        this.schoolForm.controls[key].markAsDirty();
        this.schoolForm.controls[key].markAsTouched();
      }
      return;
    }

    if (this.submitting) {
      return;
    }

    this.submitting = true;

    const formData: EditableSchoolDetails = this.schoolForm.getRawValue();

    const country = this.countryService.getCountryByCode(formData.country_code)?.name || null;
    const state = this.countryService.getStateByCode(formData.state_code)?.name || null;

    formData.country = country;
    formData.state = state;

    this.adminService.patchSchool(formData).subscribe({
      next: () => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('SCHOOL_UPDATED_SUCCESSFULLY'));
        this.schoolForm.markAsUntouched();

        this.goBack();
      },
      error: (err: any) => {
        this.toaster.error(err.error?.message ?? this.translate.instant('SCHOOL_UPDATE_FAILED'));
        this.submitting = false;
      },
      complete: () => {
        this.submitting = false;
      },
    });
  }

  countryChange(event: any) {
    this.schoolForm.controls['country_code'].setValue(event.country_code);
    this.schoolForm.controls['state_code'].setValue(event.state_code);
    this.schoolForm.controls['city'].setValue(event.city);
    this.schoolForm.controls['zip_code'].setValue(event.zip_code);

    this.schoolForm.controls['address'].updateValueAndValidity();
  }
  onFileSelected(event: any) {
    const file: File = event.target?.files[0];
    this.fileUploadService.handleImageUpload(file, {
      form: this.schoolForm,
      formControlName: 'logo',
      previewElement: this.element,
      previewElementId: '#profilePicPreview',
      minWidth: 512,
      minHeight: 512,
    });
  }
  conutyphone(event: any) {
    this.schoolForm.controls['phone_code'].setValue(event.code);
    this.updatePhoneMask();
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/group.svg';
  }

  selectTimezone(event: any) {
    this.timezone = event;
    this.schoolForm.controls['timezone'].setValue(this.timezone);
  }

  updatePhoneMask() {
    if (this.schoolForm.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.schoolForm.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }
  }

  hasUnsavedChanges(): boolean {
    return this.schoolForm.touched;
  }

  goBack() {
    const fromDashboard = this.route.snapshot.queryParamMap.get('from') === 'dashboard';
    if (fromDashboard) {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  private addressValidator(control: AbstractControl): ValidationErrors | null {
    const countryCode = this.schoolForm?.get('country_code')?.value;
    return AddressValidator.validate(control.value, countryCode);
  }
}
