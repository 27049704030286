<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="float-right mb-4">
        <a class="btn-sm auth-button" [routerLink]="['/superadmin/school']" translate
          ><i class="fas fa-bars mr-1"></i>All</a
        >
      </div>

      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="ClassListActive">
            <ul class="nav nav-pills d-block" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  translate
                  ><i class="fas fa-user-graduate mr-2"></i>Student</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  href="#pills-profile"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  translate
                  ><i class="fas fa-school mr-2"></i>Program</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-coach-tab"
                  data-toggle="pill"
                  href="#pills-coach"
                  role="tab"
                  aria-controls="pills-coach"
                  aria-selected="false"
                  translate
                  ><i class="fas fa-user mr-2"></i>Coach</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-events-tab"
                  data-toggle="pill"
                  href="#pills-events"
                  role="tab"
                  aria-controls="pills-events"
                  aria-selected="false"
                  translate
                  ><i class="fas fa-calendar-alt mr-2"></i>Class</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-payment-tab"
                  data-toggle="pill"
                  href="#pills-payment"
                  role="tab"
                  aria-controls="pills-payment"
                  aria-selected="false"
                  translate
                  ><i class="fas fa-credit-card mr-2"></i>Payments</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12" id="content">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <!-- <table datatable [dtOptions]="dtOptions" class="row-border hover"> -->
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Student Name</th>
                            <th translate>Phone</th>
                            <th translate>Email</th>
                            <th translate>Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let list of listview
                                | paginate: { itemsPerPage: itemPerPage, currentPage: page, id: 'student' }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ list.first_name }} {{ list.middle_name }} {{ list.last_name }}</td>
                            <td>
                              {{ list.phone }} {{ list.phonenumber | slice: 0 : 3 }}-{{
                                list.phonenumber | slice: 3 : 6
                              }}-{{ list.phonenumber | slice: 6 }}
                            </td>
                            <td>{{ list.email }}</td>
                            <td>{{ list.address }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="student" 
                  (pageChange)="pageChange($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Id</th>
                            <th translate>Program name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let list of listviewstudent
                                | paginate: { itemsPerPage: itemPerPage, currentPage: pageclass, id: 'class' }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ list.id }}</td>
                            <td>{{ list.name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="class" 
                  (pageChange)="pageClass($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-coach" role="tabpanel" aria-labelledby="pills-coach-tab">
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Coach name</th>
                            <th translate>Phone</th>
                            <th translate>Email</th>
                            <th translate>Address</th>
                            <th translate>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let list of listviewcoach
                                | paginate: { itemsPerPage: itemPerPage, currentPage: pagecoach, id: 'coach' }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ list.first_name }} {{ list.middle_name }} {{ list.last_name }}</td>
                            <td>
                              {{ list.phone }} {{ list.phonenumber | slice: 0 : 3 }}-{{
                                list.phonenumber | slice: 3 : 6
                              }}-{{ list.phonenumber | slice: 6 }}
                            </td>
                            <td>{{ list.email }}</td>
                            <td>{{ list.address }}</td>
                            <td>
                              <a
                                class="btn btn-default btn-sm"
                                title="{{ 'View' | translate }}"
                                [routerLink]="['/superadmin/school/view-coach/' + list.id]">
                                <i class="fas fa-eye"></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="coach" 
                  (pageChange)="pageCoach($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-events" role="tabpanel" aria-labelledby="pills-events-tab">
              <form class="for-filterui" [formGroup]="eventsForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-3 col-4">
                    <button type="submit" class="btn-sm auth-button submit-newbtn" translate>Search</button>
                    <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>
                      Clear
                    </button>
                  </div>
                </div>
              </form>
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Class Name</th>
                            <th translate>Coach</th>
                            <th translate>Date & Time</th>
                            <th translate>Program</th>
                            <th translate>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let list of listviewevent
                                | paginate: { itemsPerPage: itemPerPage, currentPage: pageevent, id: 'event' }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ list.name }}</td>
                            <td>
                              {{ list.coach.first_name }} {{ list.coach.middle_name }} {{ list.coach.last_name }}
                            </td>
                            <td>{{ list.start_time | date: 'dd-MM-YYYY hh:mm' }}</td>
                            <td>
                              <span *ngFor="let class of list.eventclass" class="d-block">{{ class.name }}</span>
                            </td>
                            <td>
                              <a
                                class="btn btn-default btn-sm"
                                title="{{ 'View' | translate }}"
                                [routerLink]="['/superadmin/school/view-event/' + list.id]">
                                <i class="fas fa-eye"></i
                              ></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="event" 
                  (pageChange)="pageEvent($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab">
              <form class="for-filterui" [formGroup]="paymentsForm" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-6 col-12">
                    <app-datepicker></app-datepicker>
                  </div>
                  <div class="col-lg-2 col-md-3 col-sm-3 col-4">
                    <button class="btn-sm auth-button submit-newbtn" translate>Search</button>
                  </div>
                </div>
              </form>
              <div class="card">
                <div class="row">
                  <div class="col-md-12">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Transaction Id</th>
                            <th translate>Transaction Date</th>
                            <th translate>Packages</th>
                            <th translate>Amount</th>
                            <th translate>Payment status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let list of listviewpayment
                                | paginate: { itemsPerPage: itemPerPage, currentPage: pagepayment, id: 'payment' }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ list.transaction_id }}</td>
                            <td>{{ list.transaction_date }}</td>
                            <td>{{ list.package.plan_name }}</td>
                            <td>R$ {{ list.amount }}</td>
                            <td>{{ list.payment_status }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mx-2">
                <pagination-controls 
                  id="payment" 
                  (pageChange)="pagePayment($event)"
                  [previousLabel]="'Previous' | translate"
                  [nextLabel]="'Next' | translate"
                ></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
