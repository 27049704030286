import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BeltEntity,
  ClassEntity,
  ClassesItem,
  CoachEntity,
  EventsFilter,
  NewClassesResquest,
  NewCoachResquest,
  ProgramEntity,
} from './events.interface';
import { EventsRepository } from './events.repository';

@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor(
    private http: HttpClient,
    private repository: EventsRepository
  ) {}

  createClass(body: NewClassesResquest): Observable<ClassEntity> {
    return this.http.post<ClassEntity>(`${environment.BACKEND_BASE_URL}admin/classes`, body).pipe(
      tap((response) => {
        this.repository.updateEvent(response);
      })
    );
  }

  updateClass(classId: string, body: NewClassesResquest): Observable<ClassEntity> {
    return this.http.put<ClassEntity>(`${environment.BACKEND_BASE_URL}admin/classes/${classId}`, body).pipe(
      tap((response) => {
        this.repository.updateEvent(response);
      })
    );
  }

  getClasses(filter: EventsFilter): Observable<ClassesItem[]> {
    let params = this.buildFiltersParams(filter);

    return this.http.get<ClassesItem[]>(`${environment.BACKEND_BASE_URL}admin/classes`, { params }).pipe(
      tap((classes) => {
        this.repository.setEvents(classes);
      })
    );
  }

  getClassById(classId: string): Observable<ClassEntity> {
    return this.http.get<ClassEntity>(`${environment.BACKEND_BASE_URL}admin/classes/${classId}`);
  }

  getPrograms(): Observable<ProgramEntity[]> {
    return this.http.get<ProgramEntity[]>(`${environment.BACKEND_BASE_URL}admin/programs`).pipe(
      tap((programs) => {
        this.repository.setPrograms(programs);
      })
    );
  }

  postProgram(name: string): Observable<ProgramEntity> {
    return this.http
      .post<ProgramEntity>(`${environment.BACKEND_BASE_URL}admin/programs`, {
        name,
      })
      .pipe(
        tap((program) => {
          this.repository.updateProgram(program);
        })
      );
  }

  getCoaches(): Observable<CoachEntity[]> {
    return this.http.get<CoachEntity[]>(`${environment.BACKEND_BASE_URL}admin/coaches`).pipe(
      tap((coaches) => {
        this.repository.setCoaches(coaches);
      })
    );
  }

  postCoach(body: NewCoachResquest): Observable<CoachEntity> {
    return this.http.post<CoachEntity>(`${environment.BACKEND_BASE_URL}admin/coaches`, body).pipe(
      tap((coach) => {
        this.repository.updateCoach(coach);
      })
    );
  }

  patchClassStatus(classId: string, newStatus: 'open' | 'close'): Observable<ClassEntity> {
    return this.http
      .patch<ClassEntity>(`${environment.BACKEND_BASE_URL}admin/classes/${classId}/${newStatus}`, null)
      .pipe(
        tap((classEntity) => {
          this.repository.updateEvent(classEntity);
        })
      );
  }

  getBelts(): Observable<BeltEntity[]> {
    return this.http.get<BeltEntity[]>(`${environment.BACKEND_BASE_URL}admin/belts`).pipe(
      tap((belts) => {
        this.repository.setBelts(belts);
      })
    );
  }

  private buildFiltersParams(filter: EventsFilter): HttpParams {
    let params = new HttpParams();

    if (filter.startDate) params.append('from', filter.startDate);
    if (filter.endDate) params.append('to', filter.endDate);
    if (filter.program_id) params.append('program_id', filter.program_id);
    if (filter.coach_id) params.append('coach_id', filter.coach_id);

    return params;
  }
}
