<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header" >
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row clearfixs my-4 no-gutters d-flex justify-content-between align-items-center">
            <p class="mb-0 breadcrumbs-style" translate>All Videos</p>
            <!-- <a class="btn-sm auth-button ml-2 float-right" [routerLink]="['/superadmin//video-library']"><i class="fas fa-angle-left mr-1"></i>Back</a> -->
          </div>
          <div class="row">
            <form [formGroup]="schoolForm" class="w-100">
              <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                <label translate>Select School</label>
                <ng-select placeholder="{{'Select School' | translate}}" formControlName="school" (change)="selectionChange($event)">
                  <ng-option *ngFor="let school of allSchool" [value]="school.id">{{school.name}}</ng-option>
                </ng-select>
              </div>
            </form>
          </div>
          <div class="row clearfix mt-4">
            <div class="col-md-12">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <div class="card">
                    <h5 *ngIf="eventVideoSection.length==0" class="text-center py-4" translate>No data available</h5>
                    <div>
                      <!-- accordion -->
                      <div class="px-3 py-4 pb-4" *ngIf="eventVideoSection.length>0">
                        <form [formGroup]="videoSectionForm">
                          <mat-accordion class="example-headers-align" formArrayName="section">
                            <mat-expansion-panel [expanded]="row === 0" (opened)="setStep(row)" hideToggle *ngFor="let vcat of videoSectionForm.get('section').controls; index as row" [formGroupName]="row">
                              <mat-expansion-panel-header >
                                <mat-panel-title>
                                  <div class="row card no-gutters category-card-style">
                                    <h5 class="mb-0 accord-cate-titles">{{vcat.controls.section.value}}</h5>
                                  </div>
                                  <!-- <i class="fa fa-plus"></i> -->
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div>
                                <div class="mb-2 pt-3">
                                  <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">

                                        <div class="table-responsive">
                                          <table class="table table-hover mb-0">
                                            <thead>
                                              <tr>
                                                <th translate>Title</th>
                                                <th translate>Description</th>
                                                <th translate>Link</th>
                                                <th translate>
                                                  <span translate>Action</span>
                                                  <span>
                                                  <a class="btn btn-default text-white addarray-btns btn-sm ml-2" type="button" (click)="addNewRow(row)" title="{{ 'Update' | translate }}"><i class="fas fa-plus"></i></a>
                                                  </span>
                                                </th>
                                              </tr>
                                            </thead>
                                          <tbody formArrayName="videos">
                                                <tr *ngFor="let video of vcat.get('videos').controls; index as vrow" [formGroupName]="vrow">
                                                  <td translate>
                                                    <div class="form-group mb-0">{{video.title}}
                                                      <input type="text" class="form-control" placeholder="{{'Title' | translate}}" formControlName="title" />
                                                    </div>
                                                  </td>
                                                  <td translate>
                                                    <div class="form-group mb-0">{{video.description}}
                                                      <input type="text" class="form-control" placeholder="{{'Description' | translate}}" formControlName="description" />
                                                    </div>
                                                  </td>
                                                  <td translate>
                                                    <div class="form-group mb-0">{{video.link}}
                                                      <input type="url" class="form-control" placeholder="{{'Link' | translate}}" formControlName="link" />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <a class="btn btn-default btn-sm" type="button" (click)="videoUpload(row,vrow,vcat,video)" title="{{ 'Save' | translate }}"><i class="fas fa-check"></i></a>
                                                    <a class="btn btn-default btn-sm ml-1" title="{{ 'Delete' | translate }}" (click)="deleteRow(row,vrow,video)"><i class="fas fa-trash-alt"></i></a>
                                                    <a class="btn btn-default btn-sm ml-1" type="button" (click)="openVideo(row,vrow,vcat,video)" *ngIf="video.value.id != ''" title="{{ 'View Video' | translate }}"><i class="fas fa-play"></i></a>
                                                  </td>
                                                </tr>
                                            </tbody>
                                          </table>
                                        </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-expansion-panel>
                          </mat-accordion>
                        </form>
                      </div>
                    </div>
                    <!-- accordion -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
