<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header row">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>
            View Coach
            <span>({{ coachlist.first_name }} {{ coachlist.middle_name }} {{ coachlist.last_name }})</span>
          </h2>
        </div>
      </div>
      <div class="col-md-12">
        <div class="float-right mb-4" *ngIf="userrole == 'ADMIN'">
          <a class="btn-sm auth-button" [routerLink]="['/admin/coach']" translate
            ><i class="fas fa-bars mr-1"></i>All</a
          >
        </div>
        <div class="float-right mb-4" *ngIf="userrole != 'ADMIN'">
          <a class="btn-sm auth-button" [routerLink]="['/superadmin/school']" translate
            ><i class="fas fa-bars mr-1"></i>All</a
          >
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="card profile-header">
        <ul class="nav nav-pills py-3 pt-2 pl-4" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-student-tab"
              data-toggle="pill"
              href="#pills-student"
              role="tab"
              aria-controls="pills-student"
              aria-selected="true"
              translate
              ><i class="fas fa-user mr-2"></i>Coach Details</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              translate
              ><i class="fas fa-history mr-2"></i>Attendance History</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              translate
              ><i class="fas fa-user-graduate mr-2"></i>Class</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-payment-tab"
              data-toggle="pill"
              href="#pills-payment"
              role="tab"
              aria-controls="pills-payment"
              aria-selected="false"
              translate
              ><i class="fas fa-credit-card mr-2"></i>Payment Method</a
            >
          </li>
        </ul>
        <div class="tab-content mt-0" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-student"
            role="tabpanel"
            aria-labelledby="pills-student-tab">
            <div class="row" style="padding: 30px 10px">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="body text-center">
                  <img src="{{ coachlist.photo || 'assets/images/user.png' }}" class="rounded-circle" alt="" />
                  <h5 class="mt-3 mb-3">
                    <strong
                      >{{ coachlist.first_name }} {{ coachlist.middle_name }} {{ coachlist.last_name }}</strong
                    >
                  </h5>
                  <p><i class="fas fa-phone-alt">&nbsp;:</i>{{ coachlist.phone }} {{ coachlist.phonenumber }}</p>
                  <p><i class="fas fa-envelope">&nbsp;:</i>{{ coachlist.email }}</p>
                  <p><i class="fas fa-calendar">&nbsp;:</i>{{ coachlist.dob }}</p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 view-studentsection">
                <div class="d-flex pt-2" style="flex-direction: initial">
                  <div class="view-student pr-2 mt-3">
                    <p><span translate>Belt</span></p>
                    <p><span translate>City</span></p>
                    <p><span translate>State</span></p>
                    <p><span translate>Country</span></p>
                    <p><span translate>Zip Code</span></p>
                    <p><span translate>Address</span></p>
                  </div>
                  <div class="pl-2 mt-3">
                    <p>
                      <span>&nbsp;: {{ coachlist.belt.color }}</span>
                    </p>
                    <p>
                      <span>&nbsp;: {{ coachlist.city }}</span>
                    </p>
                    <p>
                      <span>&nbsp;: {{ coachlist.state_code }}</span>
                    </p>
                    <p>
                      <span>&nbsp;: {{ coachlist.country_code }}</span>
                    </p>
                    <p>
                      <span>&nbsp;: {{ coachlist.zip_code }}</span>
                    </p>
                    <p>
                      <span>&nbsp;: {{ coachlist.address }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <app-admin-attendance></app-admin-attendance>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="row mt-4 clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                  <div class="body">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="d-flex">{{ 'Show' | translate}}
                          <select
                            [(ngModel)]="itemPerPage"
                            name="selection-datatable_length"
                            aria-controls="selection-datatable"
                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                            <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                              {{ pageCountObj }}
                            </option>
                          </select>
                          {{ 'entries' | translate}}
                        </span>
                      </div>
                      <div class="col-md-9">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'Search' | translate }}"
                            [(ngModel)]="searchText"
                            name="searchText" />
                          <div class="input-group-append" *ngIf="searchText">
                            <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                              >Clear</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th translate>Name and Fees</th>
                          <th translate>Class</th>
                          <th translate>Maximum of Registration</th>
                          <th translate>Start Date - End Date</th>
                          <th translate>Repeat options</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let event of eventlist
                              | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                              | filter: searchText;
                            let i = index
                          ">
                          <td>{{ event.name }}</td>
                          <td>
                            <div *ngFor="let class of event.eventclass">
                              {{ class.name }}
                            </div>
                          </td>
                          <td>{{ event.max_registration }}</td>
                          <td>
                            {{ event.start_time | date: 'dd-MM-YYYY hh:mm a' }} -
                            {{ event.end_time | date: 'dd-MM-YYYY hh:mm a' }}
                          </td>
                          <td>{{ event.repeat }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-2">
                  <pagination-controls 
                    (pageChange)="pageChange($event)"
                    [previousLabel]="'Previous' | translate"
                    [nextLabel]="'Next' | translate"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab">
            <ul class="nav nav-pills py-3 pt-2 pl-4 border-bottom-0" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="pills-history-tab"
                  data-toggle="pill"
                  href="#pills-history"
                  role="tab"
                  aria-controls="pills-history"
                  aria-selected="true"
                  translate
                  ><i class="fas fa-history mr-2"></i>Payment</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="pills-withdraw-tab"
                  data-toggle="pill"
                  href="#pills-withdraw"
                  role="tab"
                  aria-controls="pills-withdraw"
                  aria-selected="true"
                  translate
                  ><i class="fas fa-history mr-2"></i>Withdraw History</a
                >
              </li>
            </ul>
            <div class="tab-content mt-0" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-history"
                role="tabpanel"
                aria-labelledby="pills-history-tab">
                <div class="row mt-4 clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card">
                      <div class="body">
                        <div class="row">
                          <div class="col-md-3">
                            <span class="d-flex">{{ 'Show' | translate}}
                              <select
                                [(ngModel)]="itemPerPage"
                                name="selection-datatable_length"
                                aria-controls="selection-datatable"
                                class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                  {{ pageCountObj }}
                                </option>
                              </select>
                              {{ 'entries' | translate}}
                            </span>
                          </div>
                          <div class="col-md-9">
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'Search' | translate }}"
                                [(ngModel)]="searchText"
                                name="searchText" />
                              <div class="input-group-append" *ngIf="searchText">
                                <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                  >Clear</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table table-hover mb-0">
                          <thead>
                            <tr>
                              <th translate>Class</th>
                              <th translate>Total Amount</th>
                              <th translate>Paid Amount</th>
                              <th translate>Balance Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ coachPayment.total.events }}</td>
                              <td>${{ coachPayment.total.total_amount || 0 }}</td>
                              <td>${{ coachPayment.list.paid_amount || 0 }}</td>
                              <td>${{ coachPayment.available.bal_amount || 0 }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="pills-withdraw" role="tabpanel" aria-labelledby="pills-withdraw-tab">
                <div class="row mt-4 clearfix">
                  <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="userrole == 'ADMIN'">
                    <div class="float-right mb-4 mr-4" data-toggle="modal" data-target="#exampleModal">
                      <a class="btn-sm auth-button" translate><i class="fas fa-plus mr-1"></i>Add Withdraw</a>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card">
                      <div class="table-responsive">
                        <table class="table table-hover mb-0">
                          <thead>
                            <tr>
                              <th translate>Class</th>
                              <th translate>Amount</th>
                              <th translate>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let payment of widrawpayment
                                  | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                                  | filter: searchText;
                                let i = index
                              ">
                              <td>
                                {{ payment.json_data.length }}
                              </td>
                              <td>R$ {{ payment.total_amount }}</td>
                              <td>{{ payment.created_date | date }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row mx-2">
                      <pagination-controls 
                        (pageChange)="pageChange($event)"
                        [previousLabel]="'Previous' | translate"
                        [nextLabel]="'Next' | translate"
                      ></pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  *ngIf="userrole == 'ADMIN'">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title payment-text-color-blue" id="exampleModalLabel">Payment Withdraw</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closepaymentModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="withdrawForm" (ngSubmit)="onSubmit()">
        <div class="modal-body">
          <div class="form-group">
            <label translate>Class<span class="text-danger">*</span></label>
            <ng-multiselect-dropdown
              placeholder="{{ 'Select Class' | translate }}"
              formControlName="event"
              [settings]="dropdownSettings"
              [data]="eventlist"
              [ngClass]="{ 'is-invalid': submitted && f.event.errors }">
            </ng-multiselect-dropdown>
            <div *ngIf="submitted && f.event.errors" class="invalid-feedback">
              <div *ngIf="f.event.errors.required" translate>Class is required</div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn auth-button">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
