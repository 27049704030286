<app-modal-root [title]="'ADD_A_SCHEDULE' | translate" [showCloseButton]="true" size="lg">
  <app-class-time-input
    (formValueChanged)="scheduleChanged($event)"
    [limitHeight]="true"
    [scheduleRepeated]="scheduleRepeated"></app-class-time-input>
  <ng-container slot="footer">
    <app-button
      icon="add"
      variant="primary"
      [iconAfterLabel]="true"
      [disabled]="isScheduleInvalid"
      (click)="submitSchedule()"
      >{{ 'ADD' | translate }}</app-button
    >
  </ng-container>
</app-modal-root>
