import { Component } from '@angular/core';

@Component({
  selector: 'app-fab-list',
  templateUrl: './fab-list.component.html',
  styleUrls: ['./fab-list.component.scss'],
})
export class FabListComponent {
  constructor() {}
}
