import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  eventlist:any=[]
  dashboardcount:object | any

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    this.Apiservice.getApi(endpoints.coachdashboard.path).subscribe(
      (data: any) => {
        this.dashboardcount=data
        this.eventlist=data.latestEvents
      },

      (err: any) => {
      }
    );
  }
}

