import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleInterface } from '../../../../../shared/interfaces/schedule.interface';

@Component({
  selector: 'app-add-schedule-modal',
  templateUrl: './add-schedule-modal.component.html',
  styleUrls: ['./add-schedule-modal.component.scss'],
})
export class AddScheduleModal implements OnInit {
  @Input() schedules: ScheduleInterface[] = [];
  @Output() clickevent = new EventEmitter<ScheduleInterface>();
  scheduleRepeated: boolean = false;

  isScheduleInvalid = true;
  currentSchedule: ScheduleInterface | undefined = undefined;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  scheduleChanged(schedule: ScheduleInterface) {
    let isRecurrenceWeekly = schedule.recurrence === 'weekly';

    for (const [key, value] of Object.entries(schedule)) {
      if (value === null && !(key === 'sequence' && isRecurrenceWeekly)) {
        this.isScheduleInvalid = true;
        this.currentSchedule = undefined;
        return;
      }
    }

    if (schedule.recurrence === 'monthly' && schedule.day === -1) {
      this.isScheduleInvalid = true;
      return;
    }

    this.scheduleRepeated = this.checkScheduleOverlap(schedule);
    this.isScheduleInvalid = this.scheduleRepeated;

    this.currentSchedule = this.isScheduleInvalid ? undefined : schedule;
  }

  checkScheduleOverlap(schedule: ScheduleInterface): boolean {
    return (
      this.schedules.filter((s) => {
        return (
          s.recurrence === schedule.recurrence &&
          s.sequence === schedule.sequence &&
          s.day === schedule.day &&
          schedule.start_time === s.start_time &&
          schedule.end_time === s.end_time
        );
      }).length > 0
    );
  }

  submitSchedule() {
    if (this.isScheduleInvalid || !this.currentSchedule) return;

    this.clickevent.emit(this.currentSchedule);
    this.activeModal.close();
  }
}
