import { Component, Input, OnInit } from '@angular/core';
import { ClassAvailability } from '../../state/events.interface';

@Component({
  selector: 'app-empty-events',
  templateUrl: './empty-events.component.html',
  styleUrls: ['./empty-events.component.scss'],
})
export class EmptyEventsComponent implements OnInit {
  @Input() status: ClassAvailability = 'open';

  @Input() hasFilters: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
