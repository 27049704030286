<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <ul class="nav nav-pills" id="nav-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                    aria-controls="pills-home" aria-selected="true" translate><i
                        class="fas fa-user-graduate mr-2"></i>Student</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                    aria-controls="pills-profile" aria-selected="false" translate><i
                        class="fas fa-chalkboard-teacher mr-2"></i>Coach</a>
            </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="block-header">
                    <div class="row clearfix">
                        <div class="card">
                            <div class="card-body">
                                <div class="row justify-content-center">
                                    <div class="col-md-6">
                                        <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <label class="mt-1" translate>Users<span
                                                        class="text-danger">*</span></label>
                                                <ng-select placeholder="{{'Users'|translate}}"
                                                    formControlName="to_id"
                                                    [ngClass]="{ 'is-invalid': submitted && f.to_id.errors }">
                                                    <ng-option *ngFor="let status of userlist" [value]="status.id">
                                                        {{status.first_name}} {{status.middle_name}} {{status.last_name}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && f.to_id.errors" class="invalid-feedback">
                                                    <div *ngIf="f.to_id.errors.required" translate>User is
                                                        required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label translate>Message</label>
                                                <textarea class="form-control" formControlName="message"  [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                                <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                                    <div *ngIf="f.message.errors.required" translate>Message is
                                                        required</div>
                                                </div>
                                            </div>
                                            <button type="submit" class="btn btn-dark float-right auth-button mt-4"
                                                translate>Save</button>
                                            <!-- <button type="submit"
                                                class="btn btn-dark float-right auth-button mt-4 mr-2">Update</button> -->
                                            <a [routerLink]="['/admin/messages']"
                                                class="btn btn-dark float-right mr-2 mt-4 text-white text-white"
                                                translate>Cancel</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="block-header">
                    <div class="row clearfix">
                        <div class="card">
                            <div class="card-body">
                                <div class="row justify-content-center">
                                    <div class="col-md-6">
                                        <form [formGroup]="coachForm" (ngSubmit)="onSubmit()">
                                            <div class="form-group">
                                                <label class="mt-1" translate>Coach Name<span
                                                        class="text-danger">*</span></label>
                                                <ng-select placeholder="{{'Coach Name'|translate}}"
                                                    formControlName="coach"
                                                    [ngClass]="{ 'is-invalid': submitted && f.coach.errors }">
                                                    <ng-option *ngFor="let status of coach" [value]="status.key_name">
                                                        {{status.key_value}}</ng-option>
                                                </ng-select>
                                                <div *ngIf="submitted && f.coach.errors" class="invalid-feedback">
                                                    <div *ngIf="f.coach.errors.required" translate>Coach Name is
                                                        required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label translate>Description</label>
                                                <textarea class="form-control" formControlName="description"></textarea>
                                            </div>
                                            <button type="submit"
                                                class="btn btn-dark float-right auth-button mt-4">Save</button>
                                            <a [routerLink]="['/admin/messages']"
                                                class="btn btn-dark float-right mr-2 mt-4 text-white text-white"
                                                translate>Cancel</a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>