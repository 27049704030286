<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Video Category</h2>
                </div>
                <div class="col-md-12">
                  <div class="float-right">
                      <a class="btn-sm auth-button" [routerLink]="['/admin/video-category/add-video-category']" translate><i class="fas fa-plus mr-1" translate></i>New</a>
                  </div>
                </div>
            </div>
            <div class="row table mt-4">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="pb-3">
                        <ul class="nav nav-pills" id="nav-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                    role="tab" aria-controls="pills-home" aria-selected="true" translate><i
                                        class="fas fa-lock-open"></i>
                                    Active</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                                    role="tab" aria-controls="pills-profile" aria-selected="false" translate><i
                                        class="fas fa-lock"></i>
                                    In-active</a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-assignee"
                                    role="tab" aria-controls="pills-assignee" aria-selected="false" translate><i
                                        class="fas fa-lock"></i>
                                    Assignee Class</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tab-content mt-0" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="row clearfix">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <span class="d-flex">{{ 'Show' | translate}}
                                                        <select [(ngModel)]="itemPerPage"
                                                            name="selection-datatable_length"
                                                            aria-controls="selection-datatable"
                                                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                            <option *ngFor="let pageCountObj of pagecounts"
                                                                [value]="pageCountObj">
                                                                {{pageCountObj}}</option>
                                                        </select> {{ 'entries' | translate}}
                                                    </span>
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="{{'Search'|translate}}"
                                                            [(ngModel)]="searchText" name="searchText">
                                                        <div class="input-group-append" *ngIf="searchText">
                                                            <span class="input-group-text" id="search-mail"
                                                                (click)="resetsearch()" translate>Clear</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th translate>Title</th>
                                                        <th translate>Default category</th>
                                                        <!-- <th translate>Active</th> -->
                                                        <th translate>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let class of classlist | paginate: { itemsPerPage: itemPerPage, currentPage: page, id:'active' } | filter: searchText; let i = index ">
                                                        <td translate>{{class.section}}</td>
                                                        <td translate>
                                                          <ng-container *ngIf="class.default_section==1"><span class="btn btn-defaults btn-sm"><i class="fas fa-check"></i></span></ng-container>
                                                          <ng-container *ngIf="class.default_section==0"><span class="btn btn-defaults btn-sm"><i class="fas fa-times"></i></span></ng-container>
                                                        </td>
                                                        <!-- <td translate>
                                                          <ng-container *ngIf="class.assign_status==1"><span class="btn btn-defaults btn-sm"><i class="fas fa-check"></i></span></ng-container>
                                                          <ng-container *ngIf="class.assign_status==0"><span class="btn btn-defaults btn-sm"><i class="fas fa-times"></i></span></ng-container>
                                                        </td> -->
                                                        <td>
                                                            <a [ngClass]="class.default_section==1 ? 'showicon':'hideicon'" class="btn btn-default btn-sm" title="{{ 'Edit' | translate }}" [routerLink]="['/admin/video-category/edit-video-category/'+class.id]"><i class="fas fa-pencil-alt"></i></a>
                                                            <a [ngClass]="class.default_section==1 ? 'showicon':'hideicon'" class="btn btn-default btn-sm ml-1" (click)="deleteRow(i, class )" title="{{ 'Delete' | translate }}"><i class="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="classlist==''" class="text-center nodata_found" colspan="10"><b>No Data Found</b></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <pagination-controls 
                                        id="active" 
                                        (pageChange)="pageChange($event)"
                                        [previousLabel]="'Previous' | translate"
                                        [nextLabel]="'Next' | translate"
                                    ></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div class="row clearfix">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="body">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <span class="d-flex">{{ 'Show' | translate}}
                                                        <select [(ngModel)]="itemPerPage"
                                                            name="selection-datatable_length"
                                                            aria-controls="selection-datatable"
                                                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                            <option *ngFor="let pageCountObj of pagecounts"
                                                                [value]="pageCountObj">
                                                                {{pageCountObj}}</option>
                                                        </select> {{ 'entries' | translate}}
                                                    </span>
                                                </div>
                                                <div class="col-md-9">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="{{'Search'|translate}}"
                                                            [(ngModel)]="searchText" name="searchText">
                                                        <div class="input-group-append" *ngIf="searchText">
                                                            <span class="input-group-text" id="search-mail"
                                                                (click)="resetsearch()" translate>Clear</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table table-hover mb-0">
                                                <thead>
                                                    <tr>
                                                        <th translate>Title</th>
                                                        <th translate>Default category</th>
                                                        <!-- <th translate>In-active</th> -->
                                                        <th translate>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let class of Inactivelist | paginate: { itemsPerPage: itemPerPage, currentPage: pagenext, id:'inactive' } | filter: searchText; let i = index ">
                                                        <td translate>{{class.section}}</td>
                                                        <td translate>
                                                          <ng-container *ngIf="class.default_section==1"><span class="btn btn-defaults btn-sm"><i class="fas fa-check"></i></span></ng-container>
                                                          <ng-container *ngIf="class.default_section==0"><span class="btn btn-defaults btn-sm"><i class="fas fa-times"></i></span></ng-container>
                                                        </td>
                                                        <!-- <td translate>
                                                          <ng-container *ngIf="class.assign_status==1"><span class="btn btn-defaults btn-sm"><i class="fas fa-check"></i></span></ng-container>
                                                          <ng-container *ngIf="class.assign_status==0"><span class="btn btn-defaults btn-sm"><i class="fas fa-times"></i></span></ng-container>
                                                        </td> -->
                                                        <td>
                                                            <a [ngClass]="class.default_section==1 ? 'showicon':'hideicon'" class="btn btn-default btn-sm" title="{{ 'Edit' | translate }}" [routerLink]="['/admin/video-category/edit-video-category/'+class.id]"> <i class="fas fa-pencil-alt"></i></a>
                                                            <a [ngClass]="class.default_section==1 ? 'showicon':'hideicon'" class="btn btn-default btn-sm ml-1" (click)="deleteInActiveRow(i, class )" title="{{ 'Delete' | translate }}"> <i class="fas fa-trash-alt"></i></a>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="Inactivelist==''" class="text-center nodata_found"><b>No Data Found</b></tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <pagination-controls 
                                        id="inactive" 
                                        (pageChange)="pagechange($event)"
                                        [previousLabel]="'Previous' | translate"
                                        [nextLabel]="'Next' | translate"
                                  ></pagination-controls>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="pills-assignee" role="tabpanel"
                            aria-labelledby="pills-assignee-tab">
                            <div class="row clearfix">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="body">
                                            <div class="row">
                                                <div class="col-lg-3 col-md-4 col-sm-4">
                                                    <span class="d-flex">{{ 'Show' | translate}}
                                                        <select [(ngModel)]="itemPerPage"
                                                            name="selection-datatable_length"
                                                            aria-controls="selection-datatable"
                                                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                                            <option *ngFor="let pageCountObj of pagecounts"
                                                                [value]="pageCountObj">{{pageCountObj}}</option>
                                                        </select>
                                                        {{ 'entries' | translate}}
                                                    </span>
                                                </div>
                                                <div class="col-lg-9 col-md-8 col-sm-8">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            placeholder="{{'Search'|translate}}"
                                                            [(ngModel)]="searchText" name="searchText">
                                                        <div class="input-group-append" *ngIf="searchText">
                                                            <span class="input-group-text" id="search-mail"
                                                                (click)="resetsearch()" translate>Clear</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form [formGroup]="ClassForm" (ngSubmit)="onSubmit()">
                                            <div class="table-responsive">
                                                <table class="table table-hover mb-0" formArrayName="data">
                                                    <thead>

                                                        <tr>
                                                            <th translate>Status</th>
                                                            <th translate>Class ID</th>
                                                            <th translate>Class name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let class of this.ClassForm.get('data')['controls']; let i = index"
                                                            [formGroupName]="i">
                                                            <td>
                                                                <input type="checkbox" formControlName="status"
                                                                    [value]="1" [(ngModel)]="assignclass[i].assigned"
                                                                    class="checkbox-control"
                                                                    *ngIf="assignclass[i].assigned==1" />
                                                                <input type="checkbox" formControlName="status"
                                                                    class="checkbox-control"
                                                                    *ngIf="assignclass[i].assigned==0" />
                                                            </td>
                                                            <td translate>{{assignclass[i].id}}</td>
                                                            <td translate>{{assignclass[i].name}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button type="submit" class="btn-sm float-right auth-button m-2"
                                                    translate>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
