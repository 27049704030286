
<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 translate>Dashboard</h2>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="card">
                        <div class="body">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="card dash-body" style="background: #ff5c33;">
                                        <div class="body ">
                                            <div class="row text-white align-items-center">
                                                <div class="col-md-6 text-center border-right">
                                                    <i class="far fa-edit"></i>
                                                    <p translate>Last Payment Date</p>
                                                </div>
                                                <div class="col-md-6 text-center">
                                                    <h4 *ngIf="dashboardcount.lastDate">{{dashboardcount.lastDate?.transaction_date | date:'dd-MM-YYYY'}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="card  dash-body" style="background: #4da6ff;">
                                        <div class="body">
                                            <div class="row text-white align-items-center">
                                                <div class="col-md-6 text-center border-right">
                                                    <i class="far fa-edit"></i>
                                                    <p translate>Next Payment Date</p>
                                                </div>
                                                <div class="col-md-6 text-center">
                                                    <h4 *ngIf="dashboardcount.nextDate">{{dashboardcount.nextDate |date:'dd-MM-YYYY'}}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="header">
                        <h2 class="dashboard-h2 mb-2" translate>Attendance Overview</h2>
                    </div>
                        <app-bar-charts></app-bar-charts>
                </div>
            </div>
            <div class="row clearfix">
                <div class="col-12 card-section">
                    <div class="header">
                        <h2 class="dashboard-h2 mb-2" translate>Recent Messages</h2>
                    </div>
                    <div class="card-body bgwhite">
                        <div class="box-border p-4">
                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th translate>Name</th>
                                            <th translate>Date</th>
                                            <th translate>Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let message of messages">
                                            <td>{{message.from_user.first_name}} {{message.from_user.last_name}}</td>
                                            <td>{{message.created_at | date:'dd-MM-YYYY'}}</td>
                                            <td>{{message.message}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
