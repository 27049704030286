import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../../core/config/endpoints';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.css']
})
export class ViewEventComponent implements OnInit {
  listevents: any = [];
  liststudent:any=[]
  eventid:any
  filterForm: FormGroup | any;
  settings: any = [];
  classData: any = [];
  coach: any = [];
  searchText: any;
  submitted = false;
  isLoading: boolean = false;
  schoolname:any


  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private route:ActivatedRoute,
    private formBuilder: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.settings = this.AuthService.setting;
    this.classData = this.settings.data.filter((t: any) => t.group == 'class');
    this.coach = this.settings.data.filter((t: any) => t.group == 'coach');

    this.eventid=this.route.params
    this.Apiservice.getApi(endpoints.eventdetails.path+"?id="+this.eventid._value.id).subscribe(
      (data: any) => {
        this.schoolname=this.AuthService.schoolname
        this.listevents = data.eventDetails;
        this.liststudent=data.eventDetails.event_register;
      },(err:any)=>{
    });
    this.filterForm = this.formBuilder.group({
      selectedRange: ['', Validators.required],
    });
  }
  get f() {
    return this.filterForm.controls;
  }

  onSubmit() {
    this.submitted = true;
  }
  resetsearch() {
    this.searchText = '';
  }
}
