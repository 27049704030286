import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

type UserFields = 'email' | 'password';
type FormErrors = { [u in UserFields]: string };
@Component({
  selector: 'app-404-Error',
  templateUrl: './404-Error.component.html',
  styleUrls: ['./404-Error.component.scss'],
})
export class ErrorComponent implements OnInit {
  loginForm: FormGroup | any;
  submitted: any = false;
  success: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public route: Router,
    public api: ApiService,
    public toaster: ToastrService,
    public auth: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
        ]),
      ],
      password: ['', Validators.required],
    });
  }
  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.api.postApi('user/login', this.loginForm.value).subscribe(
      (msg) => {
        this.toaster.success(this.translate.instant('Login success!'), this.translate.instant('Welcome back'));
        this.success = true;
        // this.auth.setAuth(msg)
      },
      (err) => {
        this.toaster.error(this.translate.instant('Login Error!'), this.translate.instant('Try again'));
        if (err != 'The given data was invalid.') this.success = true;
      }
    );
  }
}
