<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5">
        <div class="auth-form p-4 p-md-5">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group d-flex justify-content-center">
              <a class="navbar-brand text-center" [routerLink]="['/auth/login']">
                <img src="../../../../assets/img/tatami-logo.png" width="220" class="" alt="" />
                <h3 class="text-center" translate>Have an account?</h3>
              </a>
            </div>
            <div class="form-group">
              <label translate>Email<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Enter Email' | translate }}"
                formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                required />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required" translate>Email is required</div>
                <div *ngIf="f.email.errors.pattern" translate>Email is invalid</div>
              </div>
            </div>
            <div class="form-group">
              <label translate>Password<span class="text-danger">*</span></label>
              <input
                type="password"
                class="form-control"
                placeholder="{{'Enter Password' | translate}}"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                required />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required" translate>Password is required</div>
              </div>
            </div>
            <div class="form-group d-md-flex">
              <div class="w-50">
                <label class="checkbox-wrap checkbox-primary forgot-color"
                  >{{ 'Remember Me' | translate}}
                  <input type="checkbox" checked="checked" />
                  <span class="checkmark"></span>
                </label>
                <!-- <label class="checkbox-wrap checkbox-primary">{{ 'Remember Me' | translate}}
                                    <input type="checkbox"  checked="checked">
                                    <span class="checkmark"></span>
                                </label> -->
              </div>
              <div class="w-50 text-md-right">
                <i class="fa fa-lock pr-1 forgot-color"></i>
                <a [routerLink]="['/auth/forgotPassword']" class="forgot-color" translate>Forgot Password</a>
              </div>
            </div>
            <div class="form-group">
              <button type="submit" [disabled]="isLoading" class="btn btn-dark btn-lg btn-block auth-button">
                <ng-container *ngIf="!isLoading">{{ 'LOGIN' | translate }}</ng-container>
                <ng-container *ngIf="isLoading">{{ 'Loading...' | translate }}</ng-container>
              </button>
            </div>
            <div class="text-center">
              <span class="">
                <a [routerLink]="['/auth/school-register']" href="javascript:void(0)" class="back-login" translate
                  >Register</a
                ></span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
