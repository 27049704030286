import { Component, OnInit } from '@angular/core';

import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-view-coach',
  templateUrl: './view-coach.component.html',
  styleUrls: ['./view-coach.component.css']
})
export class ViewCoachComponent implements OnInit {
  
  constructor(
  ) {}

  ngOnInit(): void {
    

  }

}
