<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-5">
                <div class="auth-form p-4 p-md-5">
                    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                        <div class="form-group d-flex justify-content-center">
                            <a class="navbar-brand" [routerLink]="['/auth/login']">
                                <!-- <img src="../../../../assets/img/school-manage.png" width="180" class="" alt=""> -->
                                <h3 class="text-center">Reset Password</h3>
                            </a>
                        </div>
                        <div class="form-group">
                            <label>Email<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" formControlName="email" placeholder="Enter email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern">Enter valid email</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Email Verification Code<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="token"
                                placeholder="Enter Email Verification Code"
                                [ngClass]="{ 'is-invalid': submitted && f.token.errors }" required>
                            <div *ngIf="submitted && f.token.errors" class="invalid-feedback">
                                <div *ngIf="f.token.errors.required">Email Verification Code is required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Password<span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Enter Password" minlength="8"
                                maxlength="20" formControlName="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" required>
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                                <div *ngIf="f.password.errors?.['minlength']">Password must be at least 8 characters.
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Confirm Password<span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="Enter Confirmation Password"
                                minlength="8" maxlength="20" formControlName="password_confirmation"
                                [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" required>
                            <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                                <div *ngIf="f.password_confirmation.errors.required">Confirm Password is required</div>
                                <div *ngIf="f.password_confirmation.errors?.['minlength']">Password must be at least 8
                                    characters.</div>
                            </div>
                        </div>

                        <button type="submit" [disabled]="isLoading" class="btn btn-dark btn-lg btn-block auth-button">
                            <ng-container *ngIf="!isLoading">CONTINUE</ng-container>
                            <ng-container *ngIf="isLoading">Loading ...</ng-container>
                        </button>
                        <div class="text-center mt-2">
                            <span> <a [routerLink]="['/auth/login']" href="javascript:void(0)">Back to login</a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>