<button
  type="button"
  class="c-choice-chip"
  [class.c-choice-chip--selected]="selected"
  [class.c-choice-chip--separated]="separated"
  [class.c-choice-chip--disabled]="disabled"
  [class.c-choice-chip--tooltip]="!!tooltip"
  [class.c-choice-chip--radio]="isRadioButton"
  (click)="onClick()"
  [disabled]="disabled">
  <div class="c-choice-chip__label">
    <div class="c-choice-chip__label--mobile">
      <span>{{ mobileLabel || label }}</span>
      <span class="c-choice-chip__label__counter" *ngIf="showCounter">{{ counter }}</span>
    </div>
    <div class="c-choice-chip__label--desktop">
      <span>{{ label }}</span>
      <span class="c-choice-chip__label__counter" *ngIf="showCounter">({{ counter }})</span>
      <ion-icon *ngIf="!!tooltip" name="information-circle" class="c-choice-chip__label__icon"></ion-icon>
    </div>
  </div>
  <span *ngIf="!!tooltip" class="c-choice-chip__tooltip t-text--sm" (click)="$event.stopPropagation()">{{
    tooltip
  }}</span>
</button>
