<main class="e-events-page">
  <section id="main-content">
    <!-- Header -->
    <div class="e-events-page_header">
      <header class="c-page-header u-display--flex u-flex-align--center u-flex-justify--space-between">
        <div
          class="c-page-header_filter u-display--flex u-flex-align--center"
          (click)="handleFilterHeaderAction(!isMobile)">
          <ion-icon class="c-page-header__icon" name="search-outline"></ion-icon>
          <h2 class="c-page-header__title u-margin-bottom--none">{{ headerTitle | translate }}</h2>
        </div>
        <app-button variant="primary" icon="add" (click)="goToCreatePage()">
          {{ 'NEW_CLASS' | translate }}
        </app-button>
      </header>

      <!-- Search box -->
      <app-search-class
        [showFilter]="showSearchFields"
        [isMobile]="isMobile"
        (onToggleShow)="handleFilterHeaderAction()"
        (filters)="onFilterUpdate($event)"></app-search-class>
    </div>

    <!-- Content -->
    <div class="e-events-page__content-box">
      <div class="e-events-page__content-box__content">
        <app-chip-list (chipSelected)="handleChipSelected($event)">
          <app-choice-chip
            value="open"
            [selected]="true"
            [label]="'EVENTS_PAGE.OPEN_CLASSES' | translate"
            [mobileLabel]="'EVENTS_PAGE.OPEN' | translate"
            [counter]="filteredClasses?.open?.length || 0"></app-choice-chip>
          <app-choice-chip
            value="upcoming"
            [label]="'EVENTS_PAGE.UPCOMING_CLASSES' | translate"
            [mobileLabel]="'EVENTS_PAGE.UPCOMING' | translate"
            [counter]="filteredClasses?.upcoming?.length || 0"></app-choice-chip>
          <app-choice-chip
            value="closed"
            [label]="'EVENTS_PAGE.CLOSED_CLASSES' | translate"
            [mobileLabel]="'EVENTS_PAGE.CLOSED' | translate"
            [tooltip]="'EVENTS_PAGE.CLOSED_CLASSES_TOOLTIP' | translate"
            [counter]="filteredClasses?.closed?.length || 0"></app-choice-chip>
        </app-chip-list>

        <!-- Classes list -->
        <ng-container *ngIf="!isLoading; else loadingState">
          <ng-container [ngSwitch]="availability">
            <div *ngSwitchCase="'open'">
              <app-events-list
                [classes]="filteredClasses?.open"
                [programs]="programList"
                [isLoading]="isLoading"
                [hasFilters]="hasSearch"
                status="open"
                (classUpdated)="fetchClasses()"></app-events-list>
            </div>

            <div *ngSwitchCase="'upcoming'">
              <app-events-list
                [classes]="filteredClasses?.upcoming"
                [programs]="programList"
                [isLoading]="isLoading"
                [hasFilters]="hasSearch"
                status="upcoming"
                (classUpdated)="fetchClasses()"></app-events-list>
            </div>

            <div *ngSwitchCase="'closed'">
              <app-events-list
                [classes]="filteredClasses?.closed"
                [programs]="programList"
                [isLoading]="isLoading"
                [hasFilters]="hasSearch"
                status="closed"
                (classUpdated)="fetchClasses()"></app-events-list>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </section>
</main>

<ng-template #loadingState>
  <div class="e-loading">
    <div class="e-loading__divisor"></div>
    <div class="e-loading__card"></div>
    <div class="e-loading__card"></div>
    <div class="e-loading__card"></div>
  </div>
</ng-template>
