<main class="e-add-program-page">
  <div id="main-content">
    <div class="e-add-program-page__header">
      <div class="row justify-content-center">
        <div class="e-add-program-page__content-box col-12 col-md-10 col-lg-8">
          <div class="e-add-program-page__content-box__content">
            <div class="col-12">
              <form [formGroup]="classForm" (ngSubmit)="onSubmit()">
                <div class="c-page-header__divisor">
                  <div>
                    <div class="c-page-header__divisor__title">
                      <span>{{
                        routerid ? ('ADD_PROGRAM.EDIT_TITLE' | translate) : ('ADD_PROGRAM.TITLE' | translate)
                      }}</span>
                    </div>
                    <div class="c-page-header__divisor__subtitle">
                      <span>{{
                        routerid ? ('ADD_PROGRAM.EDIT_SUBTITLE' | translate) : ('ADD_PROGRAM.SUBTITLE' | translate)
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <app-field-input
                      formControlName="name"
                      type="text"
                      maxlength="20"
                      class="mt-3"
                      [isRequired]="true"
                      [clearButton]="true"
                      [label]="'ADD_PROGRAM.NAME' | translate"
                      [placeholder]="'ADD_PROGRAM.NAME' | translate">
                    </app-field-input>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <label class="c-field__label">{{ 'ADD_PROGRAM.COLOR' | translate }}</label>
                    <div class="d-flex flex-wrap">
                      <div class="e-color-picker mr-2" *ngFor="let color of colors">
                        <input
                          type="radio"
                          [value]="color.value"
                          formControlName="color"
                          class="e-color-picker__input" />
                        <label
                          (click)="setColor(color)"
                          [for]="color.value"
                          [ngStyle]="{
                            'background-color': color.background,
                            color: color.foreground
                          }"
                          class="e-color-picker__label"
                          >{{ color.label }}</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end mt-5">
                  <app-button
                    variant="secondary"
                    class="mr-3"
                    [isSubmit]="true"
                    [disabledStyleOnly]="true"
                    [routerLink]="['/admin/programs']"
                    >{{ 'ADMIN_ACTIONS.BACK' | translate }}</app-button
                  >
                  <app-button
                    icon="checkmark"
                    [iconAfterLabel]="true"
                    variant="primary"
                    [isSubmit]="true"
                    [loading]="loading"
                    [disabled]="classForm.invalid"
                    [disabledStyleOnly]="true"
                    >{{
                      routerid ? ('ADMIN_ACTIONS.SAVE' | translate) : ('ADMIN_ACTIONS.SUBMIT' | translate)
                    }}</app-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
