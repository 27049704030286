import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-messages',
  templateUrl: './add-messages.component.html',
  styleUrls: ['./add-messages.component.css'],
})
export class AddMessagesComponent implements OnInit {
  messageForm: FormGroup | any;
  coachForm:FormGroup | any
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;
  settings: any = [];
  userlist: any = [];

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.Apiservice.getApi(endpoints.listusers.path).subscribe((data: any) => {
      
      this.userlist = data.data;
    });

    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;

    this.messageForm = this.formBuilder.group({
      to_id: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.coachForm= this.formBuilder.group({
      coach: ['', Validators.required],
      description: ['', Validators.required],
    });
  }
  get f() {
    return this.messageForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.messageForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.createmessage.path,
      this.messageForm.value
    ).subscribe(
      (data: any) => {
        this.toaster.success(this.translate.instant('Message sent Successfully'));
        this.router.navigate(['/admin/messages']);
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
}
