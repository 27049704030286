<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header row">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>View Student <span>({{studentlist.first_name}}
              {{studentlist.middle_name}}
              {{studentlist.last_name}})</span> </h2>

        </div>
      </div>
      <div class="col-md-12">
        <div class="float-right mb-4">
          <a class="btn-sm auth-button " [routerLink]="['/admin/student']"
            translate><i class="fas fa-bars mr-1"></i>All</a>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="card profile-header">
        <ul class="nav nav-pills py-3 pt-2 pl-4" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-student-tab" data-toggle="pill"
              href="#pills-student" role="tab" aria-controls="pills-student"
              aria-selected="true" translate><i
                class="fas fa-user mr-2"></i>Student Details</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link " id="pills-pdf-tab" data-toggle="pill"
              href="#pills-pdf" role="tab" aria-controls="pills-pdf"
              aria-selected="true" translate><i
                class="fas fa-file-pdf mr-2"></i>Contract Wayer</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link " id="pills-home-tab" data-toggle="pill"
              href="#pills-home" role="tab" aria-controls="pills-home"
              aria-selected="true" translate><i
                class="fas fa-history mr-2"></i>Attendance History</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-payment-tab" data-toggle="pill"
              href="#pills-payment" role="tab" aria-controls="pills-payment"
              aria-selected="false" translate><i
                class="fas fa-credit-card mr-2"></i>Payment Method</a>
          </li>
        </ul>
        <div class="tab-content mt-0" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-student"
            role="tabpanel" aria-labelledby="pills-student-tab">
            <div class="row" style="padding: 30px 10px;">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="body text-center">
                  <img src="{{studentlist.photo || 'assets/images/user.png'}}"
                    class="rounded-circle" alt="">
                  <h5 class="mt-3 mb-3"><strong>{{studentlist.first_name}}
                      {{studentlist.middle_name}}
                      {{studentlist.last_name}}</strong></h5>
                  <p><i
                      class="fas fa-phone-alt">&nbsp;:</i>{{studentlist.phone}}
                    {{studentlist.phonenumber}}</p>
                  <p><i class="fas fa-envelope">&nbsp;:</i>{{studentlist.email}}
                  </p>
                  <p><i class="fas fa-calendar">&nbsp;:</i>{{studentlist.dob}}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12  view-studentsection">
                <div class="d-flex pt-2" style="flex-direction: initial;">
                  <div class="view-student pr-2 mt-3">
                    <p><span translate>Belt</span></p>
                    <p><span translate>City</span></p>
                    <p><span translate>State</span></p>
                    <p><span translate>Country</span></p>
                    <p><span translate>Zip Code</span></p>
                    <p><span translate>Address</span></p>
                    <p><span translate>Membership Expires</span></p>
                    <p>&nbsp;</p>
                  </div>
                  <div class="pl-2 mt-3">
                    <p><span>&nbsp;: {{studentlist.belt.color}}</span></p>
                    <p><span>&nbsp;: {{studentlist.city}}</span></p>
                    <p><span>&nbsp;: {{studentlist.state_code}}</span></p>
                    <p><span>&nbsp;: {{studentlist.country_code}}</span></p>
                    <p><span>&nbsp;: {{studentlist.zip_code}}</span></p>
                    <p><span>&nbsp;: {{studentlist.address}}</span></p>
                    <p *ngIf="hasMembership && !pausedMembership"><span>&nbsp;: {{studentlist.expiry_date | date:'yyyy-MM-dd' }}</span></p>
                    <p *ngIf="hasMembership && pausedMembership"><span>&nbsp;: Membership Paused</span></p>
                      <button *ngIf="hasMembership && !pausedMembership" type="button" class="btn btn-dark auth-button"
                        (click)="confirmPauseMembership(studentlist.id)" translate>
                        Pause Membership
                      </button>
                      <button *ngIf="hasMembership && pausedMembership" type="button" class="btn btn-dark auth-button"
                        (click)="confirmResumeMembership(studentlist.id)" translate>
                        Resume Membership
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-pdf" role="tabpanel"
            aria-labelledby="pills-pdf-tab">
            <iframe
              *ngIf="pdffile.changingThisBreaksApplicationSecurity != null"
              width="100%" height="400px" id="myFrame" [src]="pdffile"></iframe>
            <div *ngIf="pdffile.changingThisBreaksApplicationSecurity == null"
              class="datanoterr">Data Not Found</div>
          </div>
          <div class="tab-pane fade  " id="pills-home" role="tabpanel"
            aria-labelledby="pills-home-tab">
            <app-admin-attendance></app-admin-attendance>

          </div>
          <div class="tab-pane fade " id="pills-payment" role="tabpanel"
            aria-labelledby="pills-payment-tab">
            <div class="row mt-4 clearfix">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                  <div class="body">
                    <div class="row">
                      <div class="col-md-3">
                        <span class="d-flex">{{ 'Show' | translate}}
                          <select [(ngModel)]="itemPerPage"
                            name="selection-datatable_length"
                            aria-controls="selection-datatable"
                            class="custom-select custom-select-sm form-control form-control-sm w-auto">
                            <option *ngFor="let pageCountObj of pagecounts"
                              [value]="pageCountObj">
                              {{pageCountObj}}</option>
                          </select> {{ 'entries' | translate}}
                        </span>
                      </div>
                      <div class="col-md-9">
                        <div class="input-group">
                          <input type="text" class="form-control"
                            placeholder="{{'Search'| translate}}"
                            [(ngModel)]="searchText" name="searchText">
                          <div class="input-group-append" *ngIf="searchText">
                            <span class="input-group-text" id="search-mail"
                              (click)="resetsearch()" translate>Clear</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table table-hover mb-0">
                      <thead>
                        <tr>
                          <th translate>Transaction Id</th>
                          <th translate>Transaction Date</th>
                          <th translate>Packages</th>
                          <th translate>Amount</th>
                          <th translate>Payment status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let payment of listpayment | paginate: { itemsPerPage: itemPerPage, currentPage: page } | filter: searchText; let i = index">
                          <td>{{payment.transaction_id}}</td>
                          <td>{{payment.transaction_date }}</td>
                          <td *ngIf="payment.package">
                            {{payment.package.plan_name}}</td>
                          <td>R$ {{payment.amount}}</td>
                          <td>{{payment.payment_status}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mx-2">
                  <pagination-controls
                    (pageChange)="pageChange($event)"
                    [previousLabel]="'Previous' | translate"
                    [nextLabel]="'Next' | translate"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
