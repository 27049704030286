import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup | any;
  submitted = false;
  isLoading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    public auth: AuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$'),
        ]),
      ],
      token: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    });
  }

  get f() {
    return this.resetForm.controls;
  }

  onSubmit() {
    let loginemail = this.resetForm.value.email.toLowerCase();
    this.resetForm.controls['email'].setValue(loginemail);

    if (this.resetForm.valid) {
      if (
        this.resetForm.value.password !=
        this.resetForm.value.password_confirmation
      ) {
        this.toaster.error(this.translate.instant('The password confirmation does not match.'));
        return;
      }
      this.isLoading = true;
      this.submitted = false;
      this.Apiservice.postApi(endpoints.resetPassword.path, {
        email: this.resetForm.get('email').value,
        token: this.resetForm.get('token').value,
        password: this.resetForm.get('password').value,
        password_confirmation: this.resetForm.get('password_confirmation')
          .value,
      }).subscribe(
        (data) => {
          this.isLoading = false;
          this.toaster.success(this.translate.instant('Password has changed Successfully!'));
          this.router.navigate(['auth/login']);
        },
        (error) => {
          this.isLoading = false;
          this.toaster.error(this.translate.instant('Something went wrong!'));
        }
      );
    } else {
      this.isLoading = false;
      this.submitted = true;
    }
  }
}
