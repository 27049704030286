import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { setEntities, withEntities } from '@ngneat/elf-entities';
import {
  selectCurrentPageEntities,
  selectPaginationData,
  setCurrentPage,
  setPage,
  updatePaginationData,
  withPagination,
} from '@ngneat/elf-pagination';
import { StudentEntity, StudentResponse } from './student.interface';

interface StudentState {
  filter: 'active' | 'archived';
  numActive: number | null;
  numArchived: number | null;
}

const initialState = {
  filter: 'active' as 'active',
  numActive: null,
  numArchived: null,
};

export const store = createStore(
  { name: 'tatami-students' },
  withProps<StudentState>(initialState),
  withPagination(),
  withEntities<StudentEntity, 'user_id'>({ idKey: 'user_id' })
);

@Injectable({ providedIn: 'root' })
export class StudentRepository {
  currentPage$ = store.pipe(selectCurrentPageEntities());
  pageData$ = store.pipe(selectPaginationData());

  numArchived$ = store.pipe(select(({ numArchived }) => numArchived));
  numActive$ = store.pipe(select(({ numActive }) => numActive));

  setFilter(filter: 'active' | 'archived', num: number | null) {
    if (filter === 'active') {
      store.update(setProps({ filter, numActive: num }));
    } else {
      store.update(setProps({ filter, numArchived: num }));
    }
  }

  updateNumArchived(num: number | null) {
    store.update(setProps({ numArchived: num }));
  }

  updateNumActive(num: number | null) {
    store.update(setProps({ numActive: num }));
  }

  reset() {
    store.update(setEntities([]));
  }

  setEmptyPage(page: number) {
    store.update(setPage(page, []));
  }

  addPage(response: StudentResponse) {
    store.update(
      setEntities(response.data),
      updatePaginationData({
        currentPage: response.meta.current_page,
        perPage: response.meta.per_page,
        total: response.meta.total,
        lastPage: response.meta.last_page,
      }),
      setPage(
        response.meta.current_page,
        response.data.map((c) => c.user_id)
      ),
      setCurrentPage(response.meta.current_page)
    );
  }
}
