<div class="row clearfix">
  <div class="col-md-12">
    <!-- <h3 *ngIf="!date" class="texth3">{{myDate | date:'YYYY-MM-dd'}} to  {{myDate | date:'YYYY-MM-dd'}}</h3> -->
    <h3 *ngIf="!date" class="texth3" translate>All Classes</h3>
    <h3 *ngIf="date" class="texth3">{{ date }}</h3>
  </div>
</div>
<div class="row clearfix mt-3">
  <div class="col-md-12">
    <div class="container card p-3">
      <div *ngIf="dataInput.length > 0">
        <div
          class="card-split rorborder"
          [ngClass]="{ activemenu: event.id == eventactive }"
          *ngFor="let event of dataInput; let i = index">
          <div class="row nth-class-ng no-gutters" [routerLink]="['/student/schedule/view/' + event.id]">
            <div class="col-log-12 col-md-12 col-sm-12 col-12 text-center card-tophead-bg">
              <h5 class="title-card-name mb-0 py-1">{{ event.name }}</h5>
            </div>
          </div>
          <div class="row clearfix no-gutters p-2 rorborders card-bg-color mb-0" (click)="eventdetails(event)">
            <div class="col-lg-3 col-md-3 col-sm-3 col-3" [routerLink]="['/student/schedule/view/' + event.id]">
              <img src="assets/images/user.png" class="img-person" alt="User Profile Picture" />
            </div>
            <div class="col-lg-9 col-md-9">
              <div class="calendar-pro-text">
                <!-- <p [routerLink]="['/student/schedule/view/'+event.id]">{{event.start_time | date:'dd-MM-YYY hh:mm a'}} - {{event.end_time | date:'dd-MM-YYY hh:mm a' }}</p> -->
                <p [routerLink]="['/student/schedule/view/' + event.id]">
                  {{ event?.start_date }}, {{ event?.end_time }}- {{ event?.start_date }}, {{ event?.end_time }}
                </p>
                <div class="calender-event-main">
                  <div
                    class="calander-event-name"
                    data-toggle="modal"
                    data-target="#nameModal"
                    (click)="coachdetails(event)">
                    <p class="textbold" *ngIf="event.coach">
                      {{ event?.coach.first_name }} {{ event?.coach.middle_name }} {{ event?.coach.last_name }}
                    </p>
                  </div>
                  <div
                    class="calander-event-icons"
                    data-toggle="modal"
                    data-target="#viewModal"
                    (click)="singleevent(event)">
                    <i class="fas fa-eye"></i>
                  </div>
                </div>
                <div class="calender-event-mains text-right" [routerLink]="['/student/schedule/view/' + event.id]">
                  <!-- <div class="calander-new-name">
                    <h5>{{event.name}}</h5>
                  </div> -->
                  <div class="calander-new-icons">
                    <h6>{{ event.event_register.length }}/{{ event.max_registration }}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 *ngIf="dataInput.length == 0" class="text-center nodata_found" translate>No data available</h5>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="viewModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="eventModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-color-blue" id="eventModalLabel" translate>Class Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ eventDetails }}
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="nameModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="profileModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="profileModalLabel">Coach Profile</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="coachDetails">
        <h3 class="text-center">
          {{ coachDetails.first_name }} {{ coachDetails.middle_name }} {{ coachDetails.last_name }}
        </h3>
        <p>
          Phone:<span>&nbsp; {{ coachDetails.phone }} {{ coachDetails.phonenumber }}</span>
        </p>
        <p>
          Email:<span>&nbsp;{{ coachDetails.email }}</span>
        </p>
        <p>
          Birthday:<span>&nbsp;{{ coachDetails.dob }}</span>
        </p>
        <p>
          Belt:<span>&nbsp;{{ coachDetails.belt }}</span>
        </p>
        <p>
          Address:<span>&nbsp;{{ coachDetails.address }}</span>
        </p>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
  </div>
</div>
