import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-leftmenu',
  templateUrl: './leftmenu.component.html',
  styleUrls: ['./leftmenu.component.scss'],
})
export class LeftmenuComponent implements OnInit {
  photo:any
  constructor(
    private router: Router,
    @Inject(AppComponent) private app: AppComponent,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {
    this.photo=this.authservice.photo
  }

  logOut() {
    this.authservice.logOut();
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('toggle_menu_active')) {
      body.classList.remove('toggle_menu_active');
    } else {
      body.classList.add('toggle_menu_active');
    }
  }

  cToggoleMenu() {
    const body = document
      .getElementsByTagName('body')[0]
      .classList.remove('offcanvas-active');
    //document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }
}
