import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-class',
  templateUrl: './add-class.component.html',
  styleUrls: ['./add-class.component.css'],
})
export class AddClassComponent implements OnInit {
  classForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot.data['message'];
    if (this.routerid) {
      this.classForm = this.formBuilder.group({
        name: [this.data.name, Validators.required],
      });
    } else {
      this.classForm = this.formBuilder.group({
        name: ['', Validators.required],
      });
    }
  }
  get f() {
    return this.classForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.classForm.invalid) {
      return;
    }
    if (!this.routerid) {
      this.Apiservice.postApi(
        endpoints.addsettingclass.path,
        this.classForm.value
      ).subscribe(
        (data: any) => {
          
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Class added Successfully'));
          this.router.navigate(['/superadmin/class']);
        },
        (err: any) => {
          
          this.toaster.error(this.translate.instant('Error while loading Class'));
        }
      );
    } else {
      this.Apiservice.postApi(
        endpoints.updatesettingclasss.path + '?id=' + this.routerid,
        this.classForm.value
      ).subscribe(
        (data: any) => {
          
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Class updated Successfully'));
          this.router.navigate(['/superadmin/class']);
        },
        (err: any) => {
          
          this.toaster.error(this.translate.instant('Error while loading Class'));
        }
      );
    }
  }
}
