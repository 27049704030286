<app-modal-root size="sm" class="e-confirmation-modal">
  <ng-container slot="header">
    <div class="c-modal_header_icon">
      <ion-icon
        [name]="action === 'open' ? 'lock-open' : 'lock-closed'"
        [style.background-color]="'#8F9098'"></ion-icon>
    </div>
  </ng-container>
  <div class="e-confirmation-modal_content">
    <span
      class="e-confirmation-modal__confirm"
      [innerHTML]="
        (action === 'open' ? 'OPEN_CLASS_CONFIRMATION' : 'CLOSE_CLASS_CONFIRMATION')
          | translate: { className: class?.name }
      ">
    </span>
    <span class="e-confirmation-modal__details">{{ getDetails() }}</span>
  </div>
  <ng-container slot="footer">
    <app-button variant="secondary" (click)="dismiss()">{{ 'No' | translate }}</app-button>
    <app-button variant="primary" (click)="confirm()">{{ 'Yes' | translate }}</app-button>
  </ng-container>
</app-modal-root>
