<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-10 col-md-10 col-sm-6 col-8">
          <h2 class="mainh2" translate>{{ schoolname }} <span translate>Class</span></h2>
        </div>
      </div>
      <form [formGroup]="filterform" (ngSubmit)="onSubmit()">
        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-4">
            <button type="submit" class="btn-sm auth-button submit-newbtn" translate>Search</button>
            <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>Clear</button>
          </div>
        </div>
      </form>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-8 col-sm-12 col-12 m-auto">
          <app-calander-leftmenu
            (newItemEvent)="enentdata($event)"
            [date]="selectdate"
            [dataInput]="filterData"></app-calander-leftmenu>
        </div>
        <!-- <div class="col-lg-8 col-md-8 col-sm-12 col-12">
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <ul class="nav nav-pills" id="nav-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-home-tab" data-toggle="pill"
                          href="#pills-home" role="tab" aria-controls="pills-home"
                          aria-selected="true" translate>Student List</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-note-tab" data-toggle="pill"
                          href="#pills-note" role="tab" aria-controls="pills-note"
                          aria-selected="false" translate>Notes</a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a class="nav-link" id="pills-video-tab" data-toggle="pill"
                          href="#pills-video" role="tab" aria-controls="pills-video"
                          aria-selected="false" translate>Videos</a>
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                        aria-labelledby="pills-home-tab">
                      <div class="card">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12" id="content">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="input-group mb-1 my-4">
                                <input type="text" class="form-control "
                                    placeholder="Search" [(ngModel)]="searchText"
                                    name="searchText">
                                <div class="input-group-append" *ngIf="searchText">
                                  <span class="input-group-text" id="search-mail"
                                      (click)="resetsearch()" translate>Clear</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn auth-button float-right my-4 mr-4"
                                    (click)="Registration()"
                                    *ngIf="eventregister.length>0 && !regstudent || !regstudent"
                                    translate>Registration</button>
                              </div>
                            </div>
                            <h5 *ngIf="studentlist.length==0 && !activedata" class="text-center py-4" translate>No data available</h5>
                            <div class="row clearfix mt-4 mb-4" *ngIf="studentlist.length>0">
                              <div class="col-lg-3 col-md-4 col-sm-4 col-6"
                                  *ngFor="let student of studentlist | filter: searchText; let i = index">
                                <div class="calendar-list mb-4">
                                  <div class="check-uncheck">
                                    <img src="assets/images/user.png"
                                        class="img-person"
                                        alt="User Profile Picture">
                                  </div>
                                  <button class="btn inputcheck1 mr-2 "
                                      *ngIf="eventregister.length>0 && regstudent && student.id==regstudent.student_id.id">
                                      <i class="fas fa-registered"></i>
                                  </button>
                                  <span *ngFor="let status of eventregister">
                                    <button  *ngIf="status.student_id.id==student.id &&status.status==1 "
                                    class="btn inputcheck1 btngreen">
                                    <i class="fas fa-check"></i>
                                    </button>
                                    <button *ngIf="status.student_id.id==student.id && status.status==0 "
                                        class="btn inputcheck1 btnred">
                                        <i class="fas fa-times"></i>
                                    </button>
                                  </span>

                                  <p>{{student.first_name}} {{student.middle_name}}
                                      {{student.last_name}} <span  *ngIf="student.id==userid" style="color: #8d458b;" translate="">(You)</span></p>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-note" role="tabpanel"
                        aria-labelledby="pills-note-tab">
                      <div class="card">
                        <h5 *ngIf="eventnotes.length==0 && !activedata" class="text-center py-4" translate>No data available</h5>
                        <div class="row clearfix mt-4 p-3" *ngIf="eventnotes.length>0 && activedata">
                          <div class="col-lg-10 col-md-10 col-sm-10 m-auto" *ngFor="let notes of eventnotes">
                            <div class="box-border mb-2 p-3">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <p class="float-right"><strong>{{notes.created_at | date:'dd-MM-YYYY hh:mm'}}</strong></p>
                                </div>

                              </div>
                              <p >{{notes.message}}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-video" role="tabpanel"
                        aria-labelledby="pills-video-tab">
                      <div class="card">
                        <div class="row clearfix mt-4 p-3">
                          <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
                            <div class="video-borders mb-2 px-3 py-2">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <p class="mb-0 d-flex align-items-center"><i class="fab fa-youtube fa-3x mr-2 color-toutube-bg"></i>Link</p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
