import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import * as moment from 'moment';
import {
  entitiesPropsFactory,
  selectAllEntities,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import {
  BeltEntity,
  ClassEntity,
  ClassesItem,
  CoachEntity,
  EventsFilter,
  ProgramEntity,
  StatusId,
} from './events.interface';

interface EventsState {
  filter: EventsFilter;
  statusIds: StatusId | undefined;
}

const initialState = {
  filter: {
    coach_id: '',
    name: '',
    startDate: '',
    endDate: '',
    program_id: '',
  },
  statusIds: undefined,
};

const { programEntitiesRef, withProgramEntities } = entitiesPropsFactory('program');
const { coachEntitiesRef, withCoachEntities } = entitiesPropsFactory('coach');
const { beltEntitiesRef, withBeltEntities } = entitiesPropsFactory('belt');

export const store = createStore(
  { name: 'events' },
  withProps<EventsState>(initialState),
  withEntities<ClassEntity, 'class_id'>({ idKey: 'class_id' }),
  withCoachEntities<CoachEntity, 'user_id'>({ idKey: 'user_id' }),
  withProgramEntities<ProgramEntity, 'program_id'>({ idKey: 'program_id' }),
  withBeltEntities<BeltEntity, 'belt_id'>({ idKey: 'belt_id' })
);

@Injectable({ providedIn: 'root' })
export class EventsRepository {
  programs$ = store.pipe(selectAllEntities({ ref: programEntitiesRef }));

  coaches$ = store.pipe(selectAllEntities({ ref: coachEntitiesRef }));

  belts$ = store.pipe(selectAllEntities({ ref: beltEntitiesRef }));

  contentStatus$ = store.pipe(select((state) => state.statusIds));

  allClasses$ = store.pipe(selectAllEntities());

  filters$ = store.pipe(select((state) => state.filter));

  constructor() {
    persistState(store, {
      key: 'admin-events',
      storage: localStorageStrategy,
    });
  }

  resetFilter() {
    store.update((state) => ({ ...state, filter: initialState.filter }));
  }

  updateFilter(selectedFilter: EventsFilter) {
    store.update((state) => ({ ...state, filter: selectedFilter }));
  }

  update(resourcesData: Partial<EventsState>): void {
    store.update((state) => ({ ...state, ...resourcesData }));
  }

  setEvents(payload: ClassesItem[]) {
    const classList = this.buildClassesArray(payload);
    store.update(setEntities(classList));
  }

  updateEvent(payload: ClassEntity) {
    const { start_date, end_date, manually_closed_at } = payload;
    if (manually_closed_at) {
      payload.availability = 'closed';
    } else {
      if (moment(end_date).isSameOrBefore(moment())) {
        payload.availability = 'closed';
      } else if (moment(start_date).isSameOrBefore(moment())) {
        payload.availability = 'open';
      } else {
        payload.availability = 'upcoming';
      }
    }

    store.update(upsertEntities(payload));
  }

  setPrograms(payload: ProgramEntity[]) {
    store.update(setEntities(payload, { ref: programEntitiesRef }));
  }

  updateProgram(payload: ProgramEntity) {
    store.update(upsertEntities(payload, { ref: programEntitiesRef }));
  }

  getProgramById(programId: string) {
    return store.pipe(selectEntity(programId, { ref: programEntitiesRef }));
  }

  setCoaches(payload: CoachEntity[]) {
    store.update(setEntities(payload, { ref: coachEntitiesRef }));
  }

  updateCoach(payload: CoachEntity) {
    store.update(upsertEntities(payload, { ref: coachEntitiesRef }));
  }

  getCoachById(coachId: string) {
    return store.pipe(selectEntity(coachId, { ref: coachEntitiesRef }));
  }

  setBelts(payload: BeltEntity[]) {
    store.update(setEntities(payload, { ref: beltEntitiesRef }));
  }

  updateBelt(payload: BeltEntity) {
    store.update(upsertEntities(payload, { ref: beltEntitiesRef }));
  }

  getBeltById(beltId: string) {
    return store.pipe(selectEntity(beltId, { ref: beltEntitiesRef }));
  }

  /** Receives the API payload of type ClassesItem[] and converts to a single array with all classes elements.
   * Conversion with purpose to make updates easier
   */
  private buildClassesArray(payload: ClassesItem[]): ClassEntity[] {
    let classesArray: ClassEntity[] = [];
    const statusId: StatusId = {
      open: [],
      upcoming: [],
      closed: [],
    };

    payload.forEach((category) => {
      const availability = category.availability;

      category.programs.forEach((categoryItem) => {
        const program = {
          program_id: categoryItem.program_id,
          name: categoryItem.name,
        };

        categoryItem.classes.forEach((item) => {
          const modifiedItem = { ...item, program, availability };
          classesArray.push(modifiedItem);

          // add to statusIds
          statusId[category.availability].push(item.class_id);
        });
      });
    });

    store.update((state) => ({ ...state, statusIds: statusId }));
    return classesArray;
  }
}
