import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ScheduleInterface } from '../../interfaces/schedule.interface';
import { SelectOption } from '../field-select/field-select.interfaces';

@Component({
  selector: 'app-class-time-input',
  templateUrl: './class-time-input.component.html',
  styleUrls: ['./class-time-input.component.scss'],
})
export class ClassTimeInputComponent implements OnInit, OnDestroy {
  @Input() schedule: ScheduleInterface | undefined = undefined;
  @Input() showRemoveButton: boolean = false;
  @Input() limitHeight: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() scheduleRepeated: boolean = false;
  @Output() removeButtonClicked = new EventEmitter();
  @Output() formValueChanged = new EventEmitter<ScheduleInterface>();

  form!: FormGroup;
  formSubscription$: Subscription = new Subscription();
  hasSequence = false;

  recurrenceOptions: SelectOption[] = [];
  sequenceOptions: SelectOption[] = [];
  dayOptionsWeekly: SelectOption[] = [];
  dayOptionsMonthly: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.recurrenceOptions = this.populateOptions(['Weekly', 'Monthly']);
    this.sequenceOptions = this.populateOptions(['first', 'second', 'third', 'fourth', 'last'], true, 1);
    this.dayOptionsWeekly = this.populateOptions(
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      true
    );

    this.dayOptionsMonthly = [
      {
        id: '-1',
        text: this.translateService.instant('DAY'),
      },
      ...this.dayOptionsWeekly,
    ];

    this.form = this.formBuilder.group({
      recurrence: [this.schedule ? this.schedule.recurrence : 'weekly', Validators.required],
      sequence: [
        this.schedule && this.schedule.sequence ? String(this.schedule.sequence) : '1',
        Validators.required,
      ],
      day: [this.schedule ? String(this.schedule.day) : '1', Validators.required],
      start_time: [
        this.schedule && this.schedule.start_time
          ? moment(this.schedule.start_time, 'HH:mm:ss').format('hh:mm A')
          : null,
        Validators.required,
      ],
      end_time: [
        this.schedule && this.schedule.end_time
          ? moment(this.schedule.end_time, 'HH:mm:ss').format('hh:mm A')
          : null,
        Validators.required,
      ],
    });

    this.formSubscription$ = this.form.valueChanges.subscribe((value) => {
      this.hasSequence = value.recurrence === 'monthly';

      value.sequence = this.hasSequence ? value.sequence : null;
      value.day = !this.hasSequence && value.day === '-1' ? '1' : value.day;

      if (this.isStartTimeAfterEndTime()) {
        this.form.get('end_time')?.reset();
        return;
      }

      const output: ScheduleInterface = {
        recurrence: value.recurrence,
        sequence: value.sequence ? Number(value.sequence) : null,
        day: Number(value.day),
        start_time: value.start_time ? this.convertTo24Hour(value.start_time) : null,
        end_time: value.end_time ? this.convertTo24Hour(value.end_time) : null,
      };

      this.formValueChanged.emit(output);
    });
  }

  ngOnDestroy(): void {
    this.formSubscription$.unsubscribe();
  }

  onRemoveButtonClick() {
    this.removeButtonClicked.emit();
  }

  convertTo24Hour(initialTime: string) {
    return moment(initialTime, ['h:mm A']).format('HH:mm:ss');
  }

  populateOptions(values: string[], hasNumericId: boolean = false, initialIndex: number = 0) {
    return values.map((el: string, idx: number): SelectOption => {
      return {
        id: hasNumericId ? String(idx + initialIndex) : el.toLowerCase(),
        text: this.translateService.instant(el.toUpperCase()),
      };
    });
  }

  isStartTimeAfterEndTime() {
    const startTime = this.form.get('start_time')?.value;
    const endTime = this.form.get('end_time')?.value;
    return startTime && endTime && moment(startTime, 'hh:mm A').isAfter(moment(endTime, 'hh:mm A'));
  }
}
