import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../services/auth.service';
import { endpoints } from 'src/app/core/config/endpoints';
import  html2canvas from 'html2canvas';

@Component({
  selector: 'app-student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.css']
})
export class StudentAttendanceComponent implements OnInit {
  @ViewChild('screen') screen: ElementRef | any;
  @ViewChild('downloadLink') downloadLink: ElementRef | any;

  attendance:any
  listattendance:any=[]
  userrole:any

  constructor(
    private Apiservice: ApiService,
    private authservice: AuthService,
  ) { }

  ngOnInit(): void {
    this.userrole=this.authservice.userrole
    if(this.userrole=='STUDENT'){
      this.Apiservice.getApi(endpoints.attendance.path).subscribe(
        (data: any) => {
          this.attendance=data
          this.listattendance= data.date_filter.filter((s:any)=>s.events!=null);
        }
      );
    }else if(this.userrole=='COACH'){
      this.Apiservice.getApi(endpoints.coachattendance.path).subscribe(
        (data: any) => {
          this.attendance=data
          this.listattendance = data.date_filter;
        }
      );
    }

  }
  dateFilter(event:any){
    if(this.userrole=='STUDENT'){
      this.Apiservice.getApi(endpoints.attendance.path+"?date="+event.target.value).subscribe(
        (data: any) => {
          this.listattendance= data.date_filter.filter((s:any)=>s.events!=null);
        }
      );
    }else if(this.userrole=='COACH'){
      this.Apiservice.getApi(endpoints.coachattendance.path+"?date="+event.target.value).subscribe(
        (data: any) => {
          this.listattendance= data.date_filter;
        }
      );
    }


  }
  Captureimage(){
    html2canvas(this.screen.nativeElement).then((canvas:any) => {
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'marble-diagram.png';
      this.downloadLink.nativeElement.click();
    });
  }
}


