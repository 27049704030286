import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ICountry, Timezones } from 'country-state-city/dist/lib/interface';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';
import { CountryService } from '../../../../shared/services/countrycitystate';

@Component({
  selector: 'app-add-school',
  templateUrl: './add-school.component.html',
  styleUrls: ['./add-school.component.css'],
})
export class AddSchoolComponent implements OnInit {
  schoolForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;
  settings: any = [];
  package: any = [];
  autorenew: boolean = false;
  childvalue = 0;
  countryCodeList: any = [];
  maxDigit: any;
  minDigit: any;
  timezone: any;
  timezones: Timezones[] = [];
  minDate: string = new Date().toISOString().slice(0, 10);

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private translate: TranslateService,
    private countryService: CountryService
  ) {
    this.countryService.getCountries().forEach((element: ICountry) => {
      if (element.timezones) {
        this.timezones = [...this.timezones, ...element.timezones];
      }
    });
  }

  ngOnInit(): void {
    this.settings = this.AuthService.setting;
    this.Apiservice.getApi(endpoints.packageslist.path).subscribe((data: any) => {
      this.package = data.data.filter((s: any) => s.status == 1);
    });

    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot?.data?.message;
    if (this.routerid) {
      if (this.data.auto_renew == '1') {
        this.data.auto_renew = true;
      } else if (this.data.auto_renew == '0') {
        this.data.auto_renew = false;
      }

      this.schoolForm = this.formBuilder.group({
        photo: [null],
        school: [this.data.name, [Validators.required]],
        responsible: [this.data.responsible, [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_first_name: [
          this.data.admin.first_name,
          [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")],
        ],
        admin_middle_name: [this.data.admin.middle_name, [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_last_name: [
          this.data.admin.last_name,
          [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")],
        ],
        admin_email: [
          this.data.admin.email,
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
          ]),
        ],
        email: [
          this.data.email,
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
          ]),
        ],
        phone: [this.data.phone, Validators.required],
        phonenumber: [this.data.phonenumber, [Validators.required]],
        subscriptionenddate: [this.data.subscription_end_date, Validators.required],
        package: [this.data.package, Validators.required],
        auto_renew: [this.data.auto_renew],
        address: [this.data?.address || '', [Validators.required, this.addressValidator.bind(this)]],
        countryCode: [this.data.country_code, Validators.required],
        stateCode: [this.data.state_code, Validators.required],
        city: [this.data.city, Validators.required],
        timezone: [this.data.timezone, Validators.required],
        zip_code: [this.data.zip_code, Validators.required],
      });
    } else {
      this.schoolForm = this.formBuilder.group({
        photo: [''],
        school: ['', Validators.required],
        responsible: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_first_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_last_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        admin_email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
          ]),
        ],
        phone: ['+55', Validators.required],
        phonenumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
        subscriptionenddate: ['', Validators.required],
        package: [null, Validators.required],
        auto_renew: [false],
        address: ['', Validators.required],
        countryCode: [null, Validators.required],
        stateCode: [null, Validators.required],
        city: [null, Validators.required],
        timezone: [null, Validators.required],
        zip_code: [null, Validators.required],
      });
    }
    if (this.schoolForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.schoolForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
  }
  get f() {
    return this.schoolForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.AuthService.packageid = this.schoolForm.value.package;
    Object.keys(this.schoolForm.controls).forEach((field) => {
      const control = this.schoolForm.get(field);
      control.markAsTouched();
    });
    if (this.schoolForm.invalid) {
      return;
    }
    if (this.schoolForm.value.auto_renew == true) {
      this.schoolForm.controls['auto_renew'].setValue(1);
    } else if (this.schoolForm.value.auto_renew == false) {
      this.schoolForm.controls['auto_renew'].setValue(0);
    }
    let staffemail = this.schoolForm.value.email.toLowerCase();
    this.schoolForm.controls['email'].setValue(staffemail);
    const formData = this.schoolForm.getRawValue();

    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.addSchool.path, formData).subscribe({
        next: (data: any) => {
          this.toaster.success(this.translate.instant('SCHOOL_ADDED_SUCCESSFULLY'));
          this.router.navigate(['/superadmin/payments/add-payment/' + data.school.admin.id]);
        },
        error: (err: any) => {
          this.toaster.error(err.error?.message ?? this.translate.instant('SCHOOL_ADD_FAILED'));
          console.log(err);
        },
      });
    } else {
      this.Apiservice.postApi(endpoints.updateSchool.path + '?id=' + this.routerid, formData).subscribe({
        next: (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('SCHOOL_UPDATED_SUCCESSFULLY'));
          this.router.navigate(['/superadmin/school']);
        },
        error: (err: any) => {
          this.toaster.error(err.error?.message ?? this.translate.instant('SCHOOL_ADD_FAILED'));
          console.log(err);
        },
      });
    }
  }
  Countrychange(event: any) {
    this.schoolForm.controls['countryCode'].setValue(event.countryCode);
    this.schoolForm.controls['stateCode'].setValue(event.stateCode);
    this.schoolForm.controls['city'].setValue(event.city);
    this.schoolForm.controls['zip_code'].setValue(event.zip_code);

    this.schoolForm.controls['address'].updateValueAndValidity();
  }
  addressValidator(control: AbstractControl): ValidationErrors | null {
    const countryCode = this.schoolForm?.get('countryCode')?.value;
    const address = control.value;

    if (!address) return null;

    if (countryCode === 'BR') {
      const hasNumber = /\d/.test(address);
      const hasStreetName = /(rua|avenida|av\.|alameda|travessa|rodovia|r\.|av|al|trv|rdv)/i.test(address);

      if (!hasNumber || !hasStreetName) {
        return { invalidBrazilAddress: true };
      }
    }

    if (countryCode === 'US') {
      const usAddressPattern = /^\d+\s+[a-zA-Z]+/;
      if (!usAddressPattern.test(address)) {
        return { invalidUsAddress: true };
      }
    }

    return null;
  }

  clearAddressErrors() {
    if (this.schoolForm.get('address')?.errors) {
      this.schoolForm.get('address')?.markAsUntouched();
    }
  }

  getAddressErrorMessage(): string {
    const errors = this.schoolForm.get('address')?.errors;
    if (!errors) return '';

    if (errors['required']) {
      return this.translate.instant('FORM_ADD_SCHOOL.ADDRESS_REQUIRED');
    }

    const countryCode = this.schoolForm.get('countryCode')?.value;
    if (countryCode === 'BR' && errors['invalidBrazilAddress']) {
      return this.translate.instant('FORM_ADD_SCHOOL.INVALID_BRAZIL_ADDRESS');
    }

    if (countryCode === 'US' && errors['invalidUsAddress']) {
      return this.translate.instant('FORM_ADD_SCHOOL.INVALID_US_ADDRESS');
    }

    return '';
  }
  onFileSelected(event: any) {
    let type = event.target?.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file: File = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
          this.schoolForm.patchValue({
            photo: reader.result,
          });
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }

  conutyphone(event: any) {
    this.schoolForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }

  onImageError(event: any) {
    event.target.src = 'assets/images/group.svg';
  }

  selectTimezone(event: any) {
    this.timezone = event;
    this.schoolForm.controls['timezone'].setValue(this.timezone);
  }

  validateSubscriptionEndDate(e: any) {
    if (e.target.value < e.target.min) {
      e.target.value = e.target.min;
    }
  }
}
