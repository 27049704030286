import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  rangeForm: FormGroup | any;
  schoolForm: FormGroup | any;
  isTouchUIActivated = false;
  submitted = false;
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  selectedFile: File | any = null;
  name: any;
  generalForm: FormGroup | any;
  securityForm: FormGroup | any;
  userForm: FormGroup | any;
  settings: any = [];
  school: any = [];

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    public formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    this.Apiservice.getApi(endpoints.schoolList.path).subscribe((data: any) => {
      this.school = data.filter((s: any) => s.status == 1);
    });

    this.rangeForm = this.formBuilder.group({
      start: [''],
      end: [''],
      school: [null, Validators.required],
    });
    this.schoolForm = this.formBuilder.group({
      start: [''],
      end: [''],
      school: [null, Validators.required],
    });
  }

  get f() {
    return this.rangeForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.stafflist.path,
      this.rangeForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
      },
      (err: any) => {
      }
    );
  }

  get ff() {
    return this.schoolForm.controls;
  }

  schoolSubmit() {
    this.submit = true;
    if (this.schoolForm.invalid) {
      return;
    }
  }

  resetsearch() {
    this.searchText = '';
  }

  pageChange(event: any) {
    this.page = event;
  }
}
