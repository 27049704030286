import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
})
export class AddUserComponent implements OnInit {
  userForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  userid: any;
  routerid: any;
  settings: any = [];
  position: any = [];
  countryCodeList: any = [];
  maxDigit: any;
  minDigit: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userid = this.route.params;
    this.routerid = this.userid._value.id;
    this.data = this.route.snapshot.data?.message?.data;

    if (!this.routerid) {
      this.userForm = this.formBuilder.group({
        photo: [''],
        first_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        last_name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: [
          '',
          [
            Validators.compose([
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
            ]),
          ],
        ],
        phone: ['+55', Validators.required],
        phonenumber: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
        address: ['', Validators.required],
        countryCode: [null, Validators.required],
        stateCode: [null, Validators.required],
        city: [null, Validators.required],
        zip_code: [null, Validators.required],
        gender: [null, Validators.required],
      });
    } else {
      this.userForm = this.formBuilder.group({
        photo: [''],
        first_name: [this.data.first_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        last_name: [this.data.last_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: [
          this.data.email,
          [
            Validators.compose([
              Validators.required,
              Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
            ]),
          ],
        ],
        phone: [this.data.phone, Validators.required],
        phonenumber: [this.data.phonenumber, [Validators.required, Validators.pattern('[- +()0-9]+')]],
        address: [this.data.address, Validators.required],
        countryCode: [this.data.country_code, Validators.required],
        stateCode: [this.data.state_code, Validators.required],
        city: [this.data.city, Validators.required],
        zip_code: [this.data.zip_code, Validators.required],
        gender: [this.data.gender, Validators.required],
      });
      if (this.data.middle_name != null) {
        this.userForm.controls['middle_name'].setValue(this.data.middle_name);
      }
    }
    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    if (this.userForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.userForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }
  get f() {
    return this.userForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.userForm.invalid) {
      return;
    }
    if (!this.routerid) {
      if (this.userForm.value.password != this.userForm.value.password_confirmation) {
        this.toaster.error(this.translate.instant('The password confirmation does not match.'));
        return;
      }
    }
    let useremail = this.userForm.value.email.toLowerCase();
    this.userForm.controls['email'].setValue(useremail);
    const formData = new FormData();
    formData.append('photo', this.userForm.get('photo').value);
    let formfile = [
      'first_name',
      'middle_name',
      'last_name',
      'email',
      'phone',
      'phonenumber',
      'address',
      'countryCode',
      'stateCode',
      'city',
      'zip_code',
      'gender',
    ];
    formfile.forEach((name: string) => {
      formData.append(name, this.userForm.get(name).value);
    });
    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.adduserprofile.path, formData).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('User Added Successfully'));
          this.router.navigate(['/superadmin/profile']);
        },
        (err: any) => {
          if (err.error.errors) {
            this.toaster.error(err.error.errors.email || err.error.errors.phonenumber);
          } else {
            this.toaster.error(err.error.message);
          }
        }
      );
    } else {
      this.Apiservice.postApi(endpoints.updateuserprofile.path + '?id=' + this.routerid, formData).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('User Updated Successfully'));
          this.router.navigate(['/superadmin/profile']);
        },
        (err: any) => {
          this.toaster.error(err.error.message);
        }
      );
    }
  }
  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.userForm.patchValue({
          photo: file,
        });
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  Countrychange(event: any) {
    this.userForm.controls['countryCode'].setValue(event.countryCode);
    this.userForm.controls['stateCode'].setValue(event.stateCode);
    this.userForm.controls['city'].setValue(event.city);
    this.userForm.controls['zip_code'].setValue(event.zip_code);
  }
  conutyphone(event: any) {
    this.userForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }
}
