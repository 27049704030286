import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AdminModule } from './modules/admin/admin.module';
import { ErrorComponent } from './modules/auth/404-Error/404-Error.component';
import { AuthModule } from './modules/auth/auth.module';
import { CoachModule } from './modules/coach/coach.module';
import { StudentModule } from './modules/student/student.module';
import { SuperAdminModule } from './modules/superadmin/superadmin.module';
import { AdminAuthguardGuard } from './shared/services/admin-authguard.guard';
import { CoachAuthguard } from './shared/services/coach-authguard.guard';
import { PublicAuthguardGuard } from './shared/services/public-authguard.guard';
import { StudentAuthguardGuard } from './shared/services/student-authguard.guard';
import { SuperAdminAuthGuard } from './shared/services/superadmin-authguard.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [PublicAuthguardGuard],
  },
  {
    path: 'superadmin',
    loadChildren: () => import('./modules/superadmin/superadmin.module').then((m) => m.SuperAdminModule),
    canActivate: [SuperAdminAuthGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminAuthguardGuard],
  },
  {
    path: 'coach',
    loadChildren: () => import('./modules/coach/coach.module').then((m) => m.CoachModule),
    canActivate: [CoachAuthguard],
  },
  {
    path: 'student',
    loadChildren: () => import('./modules/student/student.module').then((m) => m.StudentModule),
    canActivate: [StudentAuthguardGuard],
  },
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { title: '404 Error' },
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    AuthModule,
    AdminModule,
    StudentModule,
    CoachModule,
    SuperAdminModule,
  ],
  exports: [RouterModule],
  providers: [
    PublicAuthguardGuard,
    AdminAuthguardGuard,
    StudentAuthguardGuard,
    CoachAuthguard,
    SuperAdminAuthGuard,
  ],
})
export class AppRoutingModule {}
