<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-lg-8 col-md-8 col-sm-8 col-7">
          <h2 class="mainh2">{{ schoolname }} <span translate>Class</span></h2>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-5">
          <div class="float-right mb-4">
            <a class="btn-sm auth-button" [routerLink]="['/superadmin/school']" translate
              ><i class="fas fa-bars mr-1"></i>All</a
            >
          </div>
        </div>
      </div>
      <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
        <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12">
            <app-datepicker></app-datepicker>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-4 col-4">
            <button class="btn-sm auth-button submit-newbtn" type="submit" translate>Search</button>
          </div>
        </div>
      </form>

      <div class="row mt-4">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="row clearfix mt-4">
            <div class="col-md-12">
              <div class="container card mt-1 p-4 mt-4">
                <div class="row clearfix rorborder mb-1 mt-4">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src="assets/images/user.png" class="img-person" alt="User Profile Picture" />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-5 col-6">
                    <div class="calendar-pro-text">
                      <p>
                        {{ listevents.start_time | date: 'short' }} - {{ listevents.end_time | date: 'short' }}
                      </p>
                      <p class="textbold mt-2x">
                        {{ listevents.coach.first_name }} {{ listevents.coach.middle_name }}
                        {{ listevents.coach.last_name }}
                      </p>
                      <h5>{{ listevents.name }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 col-12">
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <ul class="nav nav-pills" id="nav-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        translate
                        >Checked In</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab">
                      <div class="card mt-4">
                        <div class="row">
                          <div class="col-md-12" id="content">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="input-group newsearchbox mb-1 mt-4">
                                <input
                                  type="text"
                                  class="form-control marright"
                                  placeholder="Search"
                                  [(ngModel)]="searchText"
                                  name="searchText" />
                                <div class="input-group-append" *ngIf="searchText">
                                  <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                    >Clear</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="auth-form mt-4 p-1">
                              <div class="row clearfix mt-4 mb-4">
                                <div class="col-lg-3 col-md-3 col-sm-4 col-6" *ngFor="let student of liststudent">
                                  <div class="calendar-list mb-4">
                                    <div class="check-uncheck">
                                      <span
                                        ><img
                                          src="assets/images/user.png"
                                          class="img-person"
                                          alt="User Profile Picture" />
                                        <button
                                          class="btn auth-button1 inputcheck1 btngreen"
                                          *ngIf="student.student_id.status == 1">
                                          <i class="fas fa-check"></i>
                                        </button>
                                        <button
                                          class="btn auth-button1 inputcheck1 btnred"
                                          *ngIf="student.student_id.status == 0">
                                          <i class="fas fa-times"></i>
                                        </button>
                                      </span>
                                    </div>
                                    <p>
                                      {{ student.student_id.first_name }}
                                      {{ student.student_id.middle_name }}
                                      {{ student.student_id.last_name }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
