<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 *ngIf="!routerid" translate>Add Packages</h2>
                    <h2 *ngIf="routerid" translate>Edit Packages</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <a class="btn-sm auth-button " [routerLink]="['/superadmin/packages']" translate><i
                                class="fas fa-bars mr-1"></i>All</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="packageform" (ngSubmit)="onSubmit()">
                        <div class="form-group ">
                            <label class="mt-1" translate>Amount<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Amount' | translate}}" maxlength="10"
                                formControlName="price" [ngClass]="{ 'is-invalid': submitted && f.price.errors }" />
                            <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                                <div *ngIf="f.price.errors.required">Amount is required</div>
                                <div *ngIf="f.price.errors.pattern">Amount should be number</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Plan Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Plan Name' | translate}}"
                                formControlName="plan_name" maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.plan_name.errors }" />
                            <div *ngIf="submitted && f.plan_name.errors" class="invalid-feedback">
                                <div *ngIf="f.plan_name.errors.required" translate>Plan Name is required</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <label class="mt-1" translate>Valid Days<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Valid Days' | translate}}"
                                formControlName="valid_date" maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.valid_date.errors }" />
                            <div *ngIf="submitted && f.valid_date.errors" class="invalid-feedback">
                                <div *ngIf="f.valid_date.errors.required" translate>Valid Days is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label translate>Description</label>
                            <textarea class="form-control" formControlName="description" maxlength="25"></textarea>
                        </div>
                        <button type="submit" *ngIf="!routerid" class="btn btn-dark float-right auth-button mt-4"
                            translate>Save</button>
                        <button type="submit" *ngIf="routerid" class="btn btn-dark float-right auth-button mt-4"
                            translate>Update</button>
                        <a [routerLink]="['/superadmin/packages']" class="btn btn-dark float-right mr-2 mt-4 text-white"
                            translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>