<app-header></app-header>
<div class="e-belt-page">
  <section id="main-content">
    <div class="e-belt-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title" translate>Belt</h2>
        <app-button icon="add" [routerLink]="['/admin/belt/add-belt']">{{
          'BELT_PAGE.NEW_BELT' | translate
        }}</app-button>
      </header>
    </div>

    <div class="e-belt-page__content-box">
      <div class="e-belt-page__content-box__content">
        <div class="card mb-4">
          <div class="mb-4 e-belt-page__content-header">
            <!--      <app-field-input
              [(ngModel)]="searchText"
              type="text"
              class="e-belt-page__search-input"
              [clearButton]="true"
              [placeholder]="'Search' | translate">
            </app-field-input> -->
            <div class="d-flex">
              <app-chip-list class="u-animation-fade-in e-belt-page__chip-list" (chipSelected)="setFilter($event)">
                <app-choice-chip
                  value="active"
                  [selected]="true"
                  [label]="'BELT_PAGE.ACTIVE' | translate"
                  [mobileLabel]="'BELT_PAGE.ACTIVE' | translate"
                  [counter]="activeBeltList.length">
                </app-choice-chip>
                <app-choice-chip
                  value="inactive"
                  [label]="'BELT_PAGE.ARCHIVED' | translate"
                  [mobileLabel]="'BELT_PAGE.ARCHIVED' | translate"
                  [counter]="inactiveBeltList.length">
                </app-choice-chip>
              </app-chip-list>
            </div>
          </div>

          <div class="table-responsive e-belt-page__table">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th translate>BELT_PAGE.BELT_NAME</th>
                  <th translate>BELT_PAGE.ORDER</th>
                  <th translate>BELT_PAGE.MINIMAL_NUMBER_OF_CLASSES</th>
                  <th translate>BELT_PAGE.NUMBER_OF_STUDENTS</th>
                  <th class="text-center" translate>BELT_PAGE.ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="isLoading">
                  <td colspan="5" class="text-center pb-3 pt-3">
                    <div class="spinner-border" role="status">
                      <span class="sr-only" translate>Loading</span>
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="!isLoading && displayBeltList.length === 0">
                  <tr>
                    <td *ngIf="!failedToLoad" colspan="5" class="text-center" translate>BELT_PAGE.NO_BELTS</td>
                    <td *ngIf="failedToLoad" colspan="5" class="text-center">
                      <ion-icon name="alert-circle-outline"></ion-icon>
                      {{ 'BELT_PAGE.ERROR_MESSAGE' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <tr
                  class="e-belt-page__table-item"
                  (click)="editBelt(belt.belt_id)"
                  *ngFor="let belt of displayBeltList | filter: searchText; let i = index">
                  <td>{{ belt.name }}</td>
                  <td>{{ belt.order }}</td>
                  <td>{{ belt.minimum_attendance }}</td>
                  <td>{{ belt.users_count }}</td>
                  <td>
                    <div class="d-flex w-100 justify-content-center">
                      <app-button
                        class="c-class-card__action-button mr-2"
                        icon="pencil"
                        [iconOnly]="true"
                        variant="untoggled"
                        [routerLink]="['/admin/belt/edit-belt/' + belt.belt_id]">
                      </app-button>
                      <app-button
                        class="c-class-card__action-button"
                        [icon]="viewMode === 'active' ? 'archive-outline' : 'power'"
                        [iconOnly]="true"
                        variant="untoggled"
                        [loading]="!!togglingMap[belt.belt_id]"
                        (click)="toggleActive($event, belt)">
                      </app-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
