<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6 col-lg-5">
                <div class="auth-form p-4 p-md-5">
                    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
                        <div class="form-group d-flex justify-content-center">
                            <a class="navbar-brand" [routerLink]="['/auth/login']">
                                <img src="../../../../assets/img/tatami-logo.png" width="220" class="" alt="">
                                <h3 class="text-center" translate>Forgot Password</h3>
                            </a>
                        </div>
                        <div class="form-group">
                            <label translate>Email<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" formControlName="email" placeholder="{{ 'Enter Email' | translate}}"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required" translate>Email is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" [disabled]="isLoading"
                                class="btn btn-purple btn-lg btn-block auth-button">
                                <ng-container *ngIf="!isLoading">{{ 'SEND' | translate }}</ng-container>
                                <ng-container *ngIf="isLoading">{{ 'Loading...' | translate }}</ng-container>
                            </button>
                        </div>
                        <div class="text-center">
                            <span class=""> <a [routerLink]="['/auth/login']" href="javascript:void(0)" class="back-login" translate>Back to login</a></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>