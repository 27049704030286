import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BeltHistoryResponse,
  StudentCheckins,
  StudentCheckinsDashboard,
  StudentData,
  StudentResponse,
} from './student.interface';
import { StudentRepository } from './student.repository';

@Injectable({ providedIn: 'root' })
export class StudentService {
  constructor(
    private http: HttpClient,
    private studentRepository: StudentRepository
  ) {}

  getStudent(id: string): Observable<StudentData> {
    return this.http.get<StudentData>(`${environment.BACKEND_BASE_URL}admin/students/${id}`);
  }

  getStudents(
    params: { search?: string; active?: boolean; page?: number; per_page?: number } = { active: true }
  ): Observable<StudentResponse> {
    this.studentRepository.reset();

    return this.http
      .get<StudentResponse>(`${environment.BACKEND_BASE_URL}admin/students`, {
        params,
      })
      .pipe(
        tap((response) => {
          this.studentRepository.addPage(response);
          if (params.active !== undefined) {
            this.studentRepository.setFilter(params.active ? 'active' : 'archived', response.meta.total);
          }
        })
      );
  }

  getNumArchived(): Observable<StudentResponse> {
    return this.http
      .get<StudentResponse>(`${environment.BACKEND_BASE_URL}admin/students`, { params: { active: false } })
      .pipe(
        tap((response) => {
          this.studentRepository.updateNumArchived(response.meta.total);
        })
      );
  }

  patchStudent(user_id: string, data: Partial<StudentData>): Observable<StudentData> {
    return this.http.patch<StudentData>(`${environment.BACKEND_BASE_URL}admin/students/${user_id}`, data);
  }

  postStudent(data: Partial<StudentData>): Observable<StudentData> {
    return this.http.post<StudentData>(`${environment.BACKEND_BASE_URL}admin/students`, data);
  }
  getNumActive(): Observable<StudentResponse> {
    return this.http
      .get<StudentResponse>(`${environment.BACKEND_BASE_URL}admin/students`, { params: { active: true } })
      .pipe(
        tap((response) => {
          this.studentRepository.updateNumActive(response.meta.total);
        })
      );
  }

  getStudentCheckins(user_id: string, params: { page?: number } = {}): Observable<StudentCheckins> {
    return this.http.get<StudentCheckins>(`${environment.BACKEND_BASE_URL}admin/students/${user_id}/checkins`, {
      params,
    });
  }

  getStudentCheckinsDashboard(user_id: string): Observable<StudentCheckinsDashboard> {
    return this.http.get<StudentCheckinsDashboard>(
      `${environment.BACKEND_BASE_URL}admin/students/${user_id}/checkins/dashboard`
    );
  }

  getBeltHistory(user_id: string) {
    return this.http.get<BeltHistoryResponse[]>(
      `${environment.BACKEND_BASE_URL}admin/students/${user_id}/belt-history`
    );
  }
}
