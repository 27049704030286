<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 *ngIf="!routerid" translate>Add School</h2>
          <h2 *ngIf="routerid" translate>Edit School</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="float-right mb-4">
            <a class="btn-sm auth-button" [routerLink]="['/superadmin/school']" translate
              ><i class="fas fa-bars mr-1"></i>All</a
            >
          </div>
        </div>
      </div>
      <div class="row clearfix mt-4">
        <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto">
          <form *ngIf="schoolForm" [formGroup]="schoolForm">
            <div class="body text-center">
              <label for="profileImage">
                <img
                  [src]="data.photo"
                  class="rounded-circle"
                  id="profilePicPreview"
                  (error)="onImageError($event)"
                  alt="" />
              </label>
              <input
                type="file"
                accept="image/*"
                id="profileImage"
                name="photo"
                style="display: none"
                (change)="onFileSelected($event)" />
            </div>
            <div class="form-group">
              <label class="mt-1" translate>School Name<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'School' | translate }}"
                maxlength="150"
                formControlName="school"
                [ngClass]="{ 'is-invalid': submitted && f.school.errors }" />
              <div *ngIf="submitted && f.school.errors" class="invalid-feedback">
                <div *ngIf="f.school.errors.required" translate>Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <label class="mt-1" translate>Responsible<span class="text-danger" translate>*</span></label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Responsible' | translate }}"
                formControlName="responsible"
                minlength="2"
                maxlength="200"
                [ngClass]="{ 'is-invalid': submitted && f.responsible.errors }" />
              <div *ngIf="submitted && f.responsible.errors" class="invalid-feedback">
                <div *ngIf="f.responsible.errors.required" translate>Name is required</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label class="mt-1 texinput" translate>Phone Number<span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <ng-select
                        formControlName="phone"
                        (change)="conutyphone($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <ng-option *ngFor="let status of countryCodeList" [value]="status">
                          {{ status.code }}
                        </ng-option>
                      </ng-select>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="(00) 00000-0000"
                      mask=" {{ minDigit }} || {{ maxDigit }}"
                      [validation]="false"
                      formControlName="phonenumber"
                      [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" />
                    <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                      <div *ngIf="f.phonenumber.errors.required" translate>Phone Number is required</div>
                      <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile should be 10 character long
                                        </div>
                                        <div *ngIf="f.phonenumber.errors.pattern">Only Numbers are allowed</div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <div class="form-group">
                    <label class="mt-1" translate>Email<span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Email' | translate }}"
                      maxlength="64"
                      formControlName="email"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required" translate>Email is required</div>
                      <div *ngIf="f.email.errors.pattern" translate>Enter valid email</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="mt-1" translate>Packages<span class="text-danger">*</span></label>
              <ng-select
                placeholder="{{ 'Select Package' | translate }}"
                formControlName="package"
                [ngClass]="{ 'is-invalid': submitted && f.package.errors }">
                <ng-option *ngFor="let status of package" [value]="status.id">{{ status.plan_name }} </ng-option>
              </ng-select>

              <div *ngIf="submitted && f.package.errors" class="invalid-feedback">
                <div *ngIf="f.package.errors.required" translate>Packages is required</div>
              </div>
            </div>
            <div class="form-group">
              <label class="mt-1" translate>Auto Renew</label>
              <div class="custom-control custom-switch">
                <input type="checkbox" class="checkbox" id="customSwitches" formControlName="auto_renew" />
                <label class="toggle" for="customSwitches"></label>
              </div>
            </div>
            <div class="form-group">
              <label class="mt-1" translate>Subscription End Date<span class="text-danger">*</span></label>
              <input
                type="date"
                min="{{ minDate }}"
                max="9999-12-31"
                class="form-control"
                placeholder="{{ 'subscription End Date' | translate }}"
                formControlName="subscriptionenddate"
                (blur)="validateSubscriptionEndDate($event)"
                [ngClass]="{ 'is-invalid': submitted && f.subscriptionenddate.errors }" />
              <div *ngIf="submitted && f.subscriptionenddate.errors" class="invalid-feedback">
                <div *ngIf="f.subscriptionenddate.errors.required" translate>Subscription Date is required</div>
              </div>
            </div>
            <!-- <div class="form-group">
                            <label translate>Photo</label>
                            <input type="file" class="form-control" name="photo" (change)="onFileSelected($event)" />
                        </div> -->
            <app-country-state (CountryChanged)="Countrychange($event)" [countryForm]="schoolForm">
            </app-country-state>
            <div class="form-group">
              <label translate>TIMEZONE<span class="text-danger">*</span></label>
              <ng-select
                placeholder="{{ 'SELECT_TIMEZONE' | translate }}"
                formControlName="timezone"
                (change)="selectTimezone($event)"
                [ngClass]="{ 'is-invalid': f.timezone.errors }">
                <ng-option *ngFor="let timezone of timezones" [value]="timezone.zoneName">{{
                  timezone.zoneName + ' (' + timezone.gmtOffsetName + ')'
                }}</ng-option>
              </ng-select>
              <div *ngIf="f.timezone.errors" class="invalid-feedback">
                <div *ngIf="f.timezone.errors.required" translate>TIMEZONE_REQUIRED</div>
              </div>
            </div>
            <div class="form-group">
              <label class="mt-1" translate>Address<span class="text-danger">*</span></label>
              <textarea
                type="textarea"
                class="form-control"
                placeholder="{{ 'Address' | translate }}"
                maxlength="250"
                formControlName="address"
                [ngClass]="{ 'is-invalid': (submitted || f.address.touched) && f.address.errors }"
                (focus)="clearAddressErrors()"
                cols="30"
                rows="3"></textarea>

              <div *ngIf="(submitted || f.address.touched) && f.address.errors" class="invalid-feedback">
                <div>{{ getAddressErrorMessage() }}</div>
              </div>
            </div>
            <hr *ngIf="!routerid" />
            <div class="row" *ngIf="!routerid">
              <h5 translate>Administrator's Account</h5>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label class="mt-1" translate>First Name<span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'First Name' | translate }}"
                    formControlName="admin_first_name"
                    minlength="2"
                    maxlength="64"
                    [ngClass]="{ 'is-invalid': submitted && f.admin_first_name.errors }" />
                  <div *ngIf="submitted && f.admin_first_name.errors" class="invalid-feedback">
                    <div *ngIf="f.admin_first_name.errors.required" translate>First Name is required</div>
                    <div *ngIf="f.admin_first_name.errors.pattern" translate>Only Letters are allowed</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label class="mt-1" translate>Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'Middle Name' | translate }}"
                    formControlName="admin_middle_name"
                    maxlength="64"
                    [ngClass]="{ 'is-invalid': submitted && f.admin_middle_name.errors }" />
                  <div *ngIf="submitted && f.admin_middle_name.errors" class="invalid-feedback">
                    <div *ngIf="f.admin_middle_name.errors.pattern" translate>Only Letters are allowed</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <label class="mt-1" translate>Last Name<span class="text-danger">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="{{ 'Last Name' | translate }}"
                    formControlName="admin_last_name"
                    maxlength="64"
                    [ngClass]="{ 'is-invalid': submitted && f.admin_last_name.errors }" />
                  <div *ngIf="submitted && f.admin_last_name.errors" class="invalid-feedback">
                    <div *ngIf="f.admin_last_name.errors.required" translate>Last Name is required</div>
                    <div *ngIf="f.admin_last_name.errors.pattern" translate>Only Letters are allowed</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="form-group">
                  <div class="form-group">
                    <label class="mt-1" translate>Email<span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'Email' | translate }}"
                      maxlength="64"
                      formControlName="admin_email"
                      [ngClass]="{ 'is-invalid': submitted && f.admin_email.errors }" />
                    <div *ngIf="submitted && f.admin_email.errors" class="invalid-feedback">
                      <div *ngIf="f.admin_email.errors.required" translate>Email is required</div>
                      <div *ngIf="f.admin_email.errors.pattern" translate>Enter valid email</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              *ngIf="!routerid"
              class="btn btn-dark float-right auth-button mt-4"
              translate
              (click)="onSubmit()">
              Save
            </button>
            <button
              type="submit"
              *ngIf="routerid"
              class="btn btn-dark float-right auth-button mt-4"
              translate
              (click)="onSubmit()">
              Update
            </button>
            <a
              [routerLink]="['/superadmin/school']"
              class="btn btn-dark float-right mr-2 mt-4 text-white"
              translate
              >Cancel</a
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
