import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-belt',
  templateUrl: './belt.component.html',
  styleUrls: ['./belt.component.css']
})
export class BeltComponent implements OnInit {
  rangeForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  settings: any = [];
  beltlist:any=[]

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private AuthService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.settings = this.AuthService.setting;
    
    this.Apiservice.getApi(endpoints.listbelt.path).subscribe(
      (data: any) => {
        this.beltlist=data.data
        this.showMsg = true;
      },
      (err: any) => {
      }
    );
    this.rangeForm = this.formBuilder.group({
      daterange: [''],
    });
  }
  get f() {
    return this.rangeForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.stafflist.path,
      this.rangeForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
      },
      (err: any) => {
      }
    );
  }

  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
