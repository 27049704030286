import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgChartsModule } from 'ng2-charts';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { SharedModule } from 'src/app/shared/shared.module';
import { AttendanceComponent } from './attendance/attendance.component';
import { BeltComponent } from './belt/belt.component';
import { CalanderLeftmenuComponent } from './calendar/calander-leftmenu/calander-leftmenu.component';
import { CalendarComponent } from './calendar/calendar.component';
import { IframeDialogComponent } from './calendar/iframe-dialog/iframe-dialog.component';
import { ViewScheduleComponent } from './calendar/view-schedule/view-schedule.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './filter.pipe';
import { HeaderComponent } from './header/header.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { AddPaymentsComponent } from './payments/add-payments/add-payments.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { SafePipe } from './safe.pipe';
import { SettingsComponent } from './settings/settings.component';
import { StudentRoutingModule } from './student-routing.module';
import { VideoLibraryComponent } from './video-library/video-library.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    StudentRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CollapseModule.forRoot(),
    NgxPaginationModule,
    HttpClientModule,
    NgChartsModule,
    NgCircleProgressModule.forRoot({
      animationDuration: 100,
    }),
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatNativeDateModule,
    NgSelectModule,
    SharedModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    MatExpansionModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    NgxSlickJsModule.forRoot({
      links: {
        jquery: 'https://code.jquery.com/jquery-3.4.0.min.js',
        slickJs: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),

    MatProgressSpinnerModule,
  ],
  declarations: [
    StudentRoutingModule.components,
    DashboardComponent,
    HeaderComponent,
    LeftmenuComponent,
    CalendarComponent,
    AttendanceComponent,
    ProfileComponent,
    SettingsComponent,
    ReportComponent,
    CalanderLeftmenuComponent,
    MessagesComponent,
    FilterPipe,
    PaymentsComponent,
    AddPaymentsComponent,
    BeltComponent,
    NotificationComponent,
    ViewScheduleComponent,
    IframeDialogComponent,
    SafePipe,
    VideoLibraryComponent,
  ],
  providers: [],
})
export class StudentModule {}
