<app-modal-root
  [title]="'ADD_NEW_COACH_MODAL.TITLE' | translate"
  [showFooterDivisor]="true"
  [showCloseButton]="true"
  headerCss="u-margin-bottom--none"
  modalCss="u-overflow--auto">
  <form
    [formGroup]="form"
    (keydown.enter)="submit()"
    class="u-display--flex u-flex-direction--column u-flex-gap--xl u-padding-bottom--xs">
    <app-field-input
      formControlName="name"
      type="text"
      class="u-flex--full"
      [label]="'ADD_NEW_COACH_MODAL.NAME_LABEL' | translate"
      [placeholder]="'ADD_NEW_COACH_MODAL.NAME_PLACEHOLDER' | translate"
      [isRequired]="true"></app-field-input>

    <div>
      <label
        class="c-field__label"
        [class.c-field__label--invalid]="!form.get('gender')?.pristine && form.get('gender')?.errors?.required"
        >{{ 'ADD_NEW_COACH_MODAL.GENDER_LABEL' | translate }}</label
      >
      <app-chip-list (chipSelected)="handleGenderChipSelected($event)">
        <app-choice-chip
          value="male"
          [label]="'ADD_NEW_COACH_MODAL.GENDER_OPTIONS.MALE' | translate"></app-choice-chip>
        <app-choice-chip
          value="female"
          [label]="'ADD_NEW_COACH_MODAL.GENDER_OPTIONS.FEMALE' | translate"></app-choice-chip>
        <app-choice-chip
          value="other"
          [label]="'ADD_NEW_COACH_MODAL.GENDER_OPTIONS.OTHER' | translate"></app-choice-chip>
      </app-chip-list>
      <span
        *ngIf="!form.get('gender')?.pristine && form.get('gender')?.errors?.required"
        class="c-field__icon__helper u-color--orange-300">
        {{ 'FORM_ERRORS.FIELD_REQUIRED' | translate }}
      </span>
    </div>

    <app-field-input
      formControlName="email"
      type="email"
      class="u-flex--full"
      [label]="'ADD_NEW_COACH_MODAL.EMAIL_LABEL' | translate"
      [placeholder]="'ADD_NEW_COACH_MODAL.EMAIL_PLACEHOLDER' | translate"
      [isRequired]="true"></app-field-input>

    <div>
      <div class="c-field">
        <label class="c-field__label"> {{ 'Phone Number' | translate }} <span>*</span> </label>
      </div>

      <div class="input-group">
        <div class="input-group-prepend e-form-phone">
          <ng-select
            formControlName="phone_code"
            class="e-form-phone e-form-phone__code"
            (change)="conutyphone($event)"
            [ngClass]="{ 'is-invalid': form.get('phone_code')?.errors && form.get('phone_code')?.dirty }">
            <ng-option *ngFor="let status of countryCodeList" [value]="status">
              {{ status.code }}
            </ng-option>
          </ng-select>
        </div>
        <input
          type="text"
          class="form-control e-form-phone"
          [placeholder]="minDigit"
          mask=" {{ minDigit }} || {{ maxDigit }}"
          [validation]="false"
          formControlName="phone_number"
          [ngClass]="{ 'is-invalid': form.get('phone_number')?.errors && form.get('phone_number')?.dirty }" />
        <div *ngIf="form.get('phone_number')?.errors" class="invalid-feedback">
          <div *ngIf="form.get('phone_number')?.errors.required" translate>Phone Number is required</div>
          <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile should be 10 character long
                </div>
                <div *ngIf="f.phonenumber.errors.pattern">Only Numbers are allowed</div> -->
        </div>
      </div>
    </div>

    <app-datepicker
      labelClass="c-field__label"
      inputClass="c-field__input e-class-search__input u-margin-bottom--none"
      variant="date"
      [isRequired]="true"
      [calendarLabel]="'ADD_NEW_COACH_MODAL.DATE_OF_BIRTH_LABEL' | translate"
      [calendarPlaceholder]="'ADD_NEW_COACH_MODAL.DATE_OF_BIRTH_PLACEHOLDER' | translate"
      [showDropdowns]="true"
      [maxDate]="maxDate"
      [helperText]="'CALENDAR.DATE_FIELD_INPUT_FORMAT' | translate"
      (OnDate)="onDateChange($event)"></app-datepicker>

    <app-field-select
      class="u-flex--full"
      formControlName="belt"
      [label]="'ADD_NEW_COACH_MODAL.BELT_LABEL' | translate"
      [options]="beltOptions"
      [placeholder]="'ADD_NEW_COACH_MODAL.BELT_PLACEHOLDER' | translate"
      [allowSearch]="true"
      [isRequired]="true"></app-field-select>

    <div class="u-display--flex u-flex-gap--xl" [class.u-flex-direction--column]="isMobile">
      <app-field-number
        formControlName="zip_code"
        class="u-flex--full"
        type="zip"
        [label]="'ADD_NEW_COACH_MODAL.ZIP_CODE_LABEL' | translate"
        [placeholder]="'ADD_NEW_COACH_MODAL.ZIP_CODE_PLACEHOLDER' | translate"
        [isRequired]="true"></app-field-number>

      <app-field-select
        class="u-flex--full"
        formControlName="country_code"
        [label]="'ADD_NEW_COACH_MODAL.COUNTRY_LABEL' | translate"
        [options]="countryOptions"
        [placeholder]="'ADD_NEW_COACH_MODAL.COUNTRY_PLACEHOLDER' | translate"
        [allowSearch]="true"
        [isRequired]="true"></app-field-select>
    </div>

    <div class="u-display--flex u-flex-gap--xl" [class.u-flex-direction--column]="isMobile">
      <app-field-select
        class="u-flex--full"
        formControlName="state_code"
        [label]="'ADD_NEW_COACH_MODAL.STATE_LABEL' | translate"
        [options]="stateOptions"
        [placeholder]="'ADD_NEW_COACH_MODAL.STATE_PLACEHOLDER' | translate"
        [allowSearch]="true"
        [isRequired]="true"></app-field-select>

      <app-field-select
        class="u-flex--full"
        formControlName="city"
        [label]="'ADD_NEW_COACH_MODAL.CITY_LABEL' | translate"
        [options]="cityOptions"
        [placeholder]="'ADD_NEW_COACH_MODAL.CITY_PLACEHOLDER' | translate"
        [allowSearch]="true"
        [isRequired]="true"></app-field-select>
    </div>

    <app-field-input
      formControlName="address"
      class="u-flex--full"
      [label]="'ADD_NEW_COACH_MODAL.ADDRESS_LABEL' | translate"
      [placeholder]="'ADD_NEW_COACH_MODAL.ADDRESS_PLACEHOLDER' | translate"
      [isRequired]="true"></app-field-input>
  </form>
  <ng-container slot="footer">
    <app-button
      icon="checkmark"
      [iconAfterLabel]="true"
      variant="primary"
      [isSubmit]="true"
      [disabled]="form.invalid"
      (click)="submit()"
      [loading]="isLoading"
      >{{ 'ADD_NEW_COACH_MODAL.SUBMIT_BUTTON' | translate }}</app-button
    >
  </ng-container>
</app-modal-root>
