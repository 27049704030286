import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ClassEntity } from '../../../modules/admin/classes/state/events.interface';
import { ClassesService } from '../../services/classes.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModal {
  @Input() class: ClassEntity | undefined;
  @Input() action: 'open' | 'close' = 'open';
  @Output() clickevent = new EventEmitter<'confirm' | 'dismiss'>();
  currentLang: string;

  constructor(
    private classesService: ClassesService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal
  ) {
    const locale = localStorage.getItem('language') || 'pt-br';

    this.currentLang = 'pt-BR';

    if (locale === 'es') {
      this.currentLang = 'es-ES';
    } else if (locale === 'en') {
      this.currentLang = 'en-US';
    }
  }

  dismiss() {
    this.clickevent.emit('dismiss');
    this.activeModal.dismiss();
  }
  confirm() {
    this.clickevent.emit('confirm');
    this.activeModal.close();
  }

  getDetails(): string {
    if (!this.class) return '';

    if (this.action === 'close') return this.translate.instant('CLOSE_CLASS_MESSAGE');

    const classStatus = this.classesService.handleClassStatus(this.class);

    switch (classStatus) {
      case 'upcoming':
        return this.translate.instant('OPEN_CLASS_AUTOMATICALLY_MESSAGE', {
          date: this.formatDate(this.class.start_date),
        });
      case 'closed_automatically':
        return this.translate.instant('OPEN_CLASS_CLOSED_AUTOMATICALLY_MESSAGE', {
          date: this.formatDate(this.class.end_date),
        });
      case 'closed_manually':
        return this.translate.instant('OPEN_CLASS_CLOSED_MANUALLY_MESSAGE', {
          date: this.class.manually_closed_at ? this.formatDate(this.class.manually_closed_at) : '',
        });
      default:
        return '';
    }
  }

  private formatDate(date: string) {
    return moment(date).locale(this.currentLang).format('MMM Do, YYYY');
  }
}
