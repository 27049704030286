import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ScheduleInterface } from '../../../../../shared/interfaces/schedule.interface';
import { Schedules, SummaryDetails } from '../../state/events.interface';
import { AddScheduleModal } from '../add-schedule-modal/add-schedule-modal.component';

export interface ScheduleFormType {
  schedules: FormControlTyped<Schedules[]>;
  start_date: FormControlTyped<Date>;
  end_date: FormControlTyped<Date>;
}

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
})
export class ScheduleFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() groupName!: string;
  @Input() summaryDetails: SummaryDetails | undefined;
  @Input() shouldLoadSchedule: boolean = false;

  showAddScheduleModal: boolean = false;
  scheduleLoaded: boolean = false;
  scheduleForm!: FormGroup;
  weeklySchedules: ScheduleInterface[] = [];
  monthlySchedules: ScheduleInterface[] = [];

  scheduleAddedSubscription$: Subscription | undefined = undefined;
  formChangedSubscription$: Subscription | undefined = undefined;

  get isSchedulesEmpty(): boolean {
    return this.weeklySchedules.length === 0 && this.monthlySchedules.length === 0;
  }

  constructor(
    private rootFormGroup: FormGroupDirective,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.scheduleForm = this.rootFormGroup.control.get(this.groupName) as FormGroupTyped<ScheduleFormType>;
    this.scheduleForm.controls['schedules'].setValidators(this.validateSchedules.bind(this));
    this.scheduleForm.controls['start_date'].setValidators(this.validateStartDate.bind(this));
    this.scheduleForm.controls['end_date'].setValidators(this.validateEndDate.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shouldLoadSchedule?.currentValue) this.loadSchedule();
  }

  ngOnDestroy(): void {
    this.scheduleAddedSubscription$?.unsubscribe();
    this.formChangedSubscription$?.unsubscribe();
  }

  loadSchedule() {
    const scheduleList: ScheduleInterface[] = this.scheduleForm.value.schedules;
    this.weeklySchedules = scheduleList.filter((schedule) => schedule.recurrence === 'weekly');
    this.monthlySchedules = scheduleList.filter((schedule) => schedule.recurrence === 'monthly');

    this.scheduleLoaded = true;
  }

  onDateChange(dateEv: any, type: 'start' | 'end') {
    this.scheduleForm.controls[type === 'start' ? 'start_date' : 'end_date'].setValue(
      dateEv?.startDate ? moment(dateEv.startDate).format('YYYY-MM-DD') : null
    );
  }

  onEndDateClear() {
    this.scheduleForm.patchValue({ end_date: null });
  }

  scheduleAdded() {
    const modalRef = this.modalService.open(AddScheduleModal, {
      centered: true,
      size: 'lg',
      modalDialogClass: 'c-modal_custom',
    });

    modalRef.componentInstance.schedules = this.weeklySchedules.concat(this.monthlySchedules);

    this.scheduleAddedSubscription$ = modalRef.componentInstance.clickevent.subscribe(
      (schedule: ScheduleInterface) => {
        if (schedule.recurrence === 'weekly') {
          this.weeklySchedules.push(schedule);
        } else {
          this.monthlySchedules.push(schedule);
        }
        this.patchFormValue();
      }
    );
  }

  scheduleValueChanged(id: number, recurrence: 'weekly' | 'monthly', schedule: ScheduleInterface) {
    const sourceSchedules = recurrence === 'weekly' ? this.weeklySchedules : this.monthlySchedules;
    const targetSchedules = recurrence === 'weekly' ? this.monthlySchedules : this.weeklySchedules;

    if (schedule.recurrence !== recurrence) {
      sourceSchedules.splice(id, 1);
      targetSchedules.push(schedule);
      return;
    }

    sourceSchedules[id].recurrence = schedule.recurrence;
    sourceSchedules[id].sequence = schedule.sequence;
    sourceSchedules[id].day = schedule.day;
    sourceSchedules[id].start_time = schedule.start_time;
    sourceSchedules[id].end_time = schedule.end_time;

    this.patchFormValue();
  }

  checkScheduleOverlap(schedule: ScheduleInterface) {
    const schedules = this.weeklySchedules.concat(this.monthlySchedules);
    const overlap =
      schedules.filter((s) => {
        return (
          s.recurrence === schedule.recurrence &&
          s.sequence === schedule.sequence &&
          s.day === schedule.day &&
          schedule.start_time === s.start_time &&
          schedule.end_time === s.end_time
        );
      }).length > 1;

    return overlap;
  }

  removeSchedule(index: number, recurrence: 'weekly' | 'monthly') {
    if (recurrence === 'weekly') {
      this.weeklySchedules.splice(index, 1);
    } else {
      this.monthlySchedules.splice(index, 1);
    }

    this.patchFormValue();
  }

  patchFormValue() {
    const schedules = this.weeklySchedules.concat(this.monthlySchedules);
    this.scheduleForm.patchValue({
      schedules: schedules,
    });
  }

  getMomentDate(type: 'start' | 'end' | 'today'): moment.Moment | null {
    if (type === 'today') return moment();

    const formDate = type === 'start' ? this.scheduleForm.value.start_date : this.scheduleForm.value.end_date;

    return formDate ? moment(formDate) : null;
  }

  validateSchedules(control: AbstractControl): ValidationErrors | null {
    const schedules = control.value as ScheduleInterface[];
    const uniqueSchedules = new Set();

    for (const schedule of schedules) {
      const scheduleString = JSON.stringify(schedule);
      if (uniqueSchedules.has(scheduleString)) {
        return { duplicateSchedules: true };
      } else {
        uniqueSchedules.add(scheduleString);
      }
    }

    return null;
  }

  validateStartDate(control: AbstractControl): ValidationErrors | null {
    const startDate = moment(control.value);
    const endDate = this.scheduleForm.value.end_date ? moment(this.scheduleForm.value.end_date) : null;

    const isValid =
      startDate.isValid() &&
      startDate.isSameOrAfter(moment(), 'day') &&
      (!endDate || startDate.isSameOrBefore(endDate, 'day'));
    return isValid ? null : { invalidStartDate: true };
  }

  validateEndDate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const startDate = moment(this.scheduleForm.value.start_date);
    const endDate = moment(control.value);

    const isValid = endDate.isValid() && endDate.isSameOrAfter(startDate, 'day');
    return isValid ? null : { invalidStartDate: true };
  }

  getTodayDate(): moment.Moment {
    return moment();
  }
}
