import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipSelection } from '../../interfaces/chip-selection.interface';

@Component({
  selector: 'app-choice-chip[value]',
  templateUrl: './choice-chip.component.html',
  styleUrls: ['./choice-chip.component.scss'],
})
export class ChoiceChipComponent implements OnInit {
  @Input() selected: boolean = false;
  @Input() separated: boolean = false;
  @Input() value!: string;
  @Input() label: string = '';
  @Input() mobileLabel: string = '';
  @Input() disabled: boolean = false;
  @Input() isRadioButton: boolean = false;
  @Input() tooltip: string = '';
  @Input() counter: number | null = null;

  @Output() chipSelectionChanged = new EventEmitter<ChipSelection>();

  get showCounter(): boolean {
    return this.counter !== null && this.counter >= 0;
  }

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.selected = true;

    this.chipSelectionChanged.emit({
      value: this.value,
      tooltip: this.tooltip,
    });
  }
}
