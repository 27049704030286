<!-- <div class="card">
    <div class="row clearfix mt-4">
        <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
            <form [formGroup]="messageForm" (ngSubmit)="NotesSubmit()">
                <div class="form-group">
                    <label translate>Message<span class="text-danger">*</span></label>
                    <textarea class="form-control" formControlName="message"  [ngClass]="{ 'is-invalid': submitted && ff.message.errors }" ></textarea>
                    <div *ngIf="submitted && ff.message.errors" class="invalid-feedback">
                        <div *ngIf="ff.message.errors.required" translate>Message is required</div>
                    </div>
                </div>
                <button type="submit" class="btn btn-dark float-right auth-button my-4 mr-2" translate>Submit</button>
            </form>
        </div>
    </div>
</div> -->

<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title head-color-blue" id="exampleModalLabel" translate>Notes</h5>
        <button type="button" class="close" data-dismiss="modal"  aria-label="Close" (click)="Close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row clearfix mt-4">
            <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
                <form [formGroup]="messageForm" (ngSubmit)="NotesSubmit()">
                    <div class="form-group">
                        <label translate>Message<span class="text-danger">*</span></label>
                        <textarea class="form-control" formControlName="message"  [ngClass]="{ 'is-invalid': submitted && ff.message.errors }" ></textarea>
                        <div *ngIf="submitted && ff.message.errors" class="invalid-feedback">
                            <div *ngIf="ff.message.errors.required" translate>Message is required</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-dark float-right auth-button my-4 mr-2" translate>Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>
