import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('currentUser')
      ? JSON.parse(localStorage.getItem('currentUser') || '')['token']
      : '';
    const language = localStorage.getItem('language') || 'pt-br';

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Accept-Language': `${language}`,
      },
    });

    return next.handle(request);
  }
}
