import { AbstractControl, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class AddressValidator {
  /**
   * Validates an address based on country code
   * @param address The address to validate
   * @param countryCode The country code to determine validation rules
   * @returns ValidationErrors or null if valid
   */
  static validate(address: string | null | undefined, countryCode: string | null): ValidationErrors | null {
    const trimmedAddress = address?.trim() || '';

    if (!trimmedAddress) return { required: true };

    if (countryCode === 'BR') {
      const brazilPattern = /^(.+?),\s*(\d+)\s*(.*?)$/;
      const isValidFormat = brazilPattern.test(trimmedAddress);
      const hasValidLogradouro = /(^|\s)(rua|avenida|av\.?|alameda|travessa|rodovia|r\.|al\.)/i.test(
        trimmedAddress
      );

      if (!isValidFormat || !hasValidLogradouro) {
        return { invalidBrazilAddress: true };
      }
    }

    if (countryCode === 'US') {
      const usPattern = /^\d+\s+[a-zA-Z0-9\s]+(?:\s(?:Apt|Unit|Suite)\s*[a-zA-Z0-9#-]+)?$/;
      if (!usPattern.test(trimmedAddress)) {
        return { invalidUsAddress: true };
      }
    }

    return null;
  }

  /**
   * Creates a form validator function using the validate method
   * @param getCountryCode Function that returns the current country code
   */
  static createValidator(getCountryCode: () => string | null) {
    return (control: AbstractControl): ValidationErrors | null => {
      return AddressValidator.validate(control.value, getCountryCode());
    };
  }

  /**
   * Returns the appropriate error message based on validation errors
   */
  static getErrorMessage(
    errors: ValidationErrors | null | undefined,
    countryCode: string | null,
    translate: TranslateService
  ): string {
    if (!errors) return '';

    if (errors['required']) {
      return translate.instant('FORM_ADD_STUDENT.ADDRESS_REQUIRED');
    }

    if (countryCode === 'BR' && errors['invalidBrazilAddress']) {
      return translate.instant('FORM_ADD_STUDENT.INVALID_BRAZIL_ADDRESS');
    }

    if (countryCode === 'US' && errors['invalidUsAddress']) {
      return translate.instant('FORM_ADD_STUDENT.INVALID_US_ADDRESS');
    }

    return '';
  }
}
