import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Resolve,
} from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/core/config/endpoints';

@Injectable({
  providedIn: 'root',
})
export class CoachProfileResolver implements Resolve<any> {
  constructor(
    private AuthService: AuthService,
    private Apiservice: ApiService
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      this.Apiservice.getApi(endpoints.editcoachprofile.path).subscribe(
        (data) => {
          resolve(data);
        }
      );
    });
  }
}
