<form [formGroup]="countryForm" (ngSubmit)="(countryForm.form.valid)">
  <div class="row mt-3">
    <div class="col-lg-6 col-md-6 col-sm-6">
      <app-field-select
        class="u-flex--full"
        formControlName="country_code"
        [label]="'Country' | translate"
        [options]="countries"
        [placeholder]="'Select Country' | translate"
        [allowSearch]="true"
        (click)="countryInteraction = true"
        [isRequired]="true"></app-field-select>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <app-field-select
        *ngIf="states && states.length > 0; else emptyStates"
        class="u-flex--full"
        formControlName="state_code"
        [label]="'State' | translate"
        [options]="states"
        [placeholder]="'Select State' | translate"
        [allowSearch]="true"
        (click)="userInteracted($event)"
        [isRequired]="true"></app-field-select>
      <ng-template #emptyStates>
        <app-field-input
          formControlName="state_code"
          type="text"
          maxlength="200"
          class="u-flex--full"
          [isRequired]="true"
          [clearButton]="true"
          [label]="'State' | translate"
          [placeholder]="'Enter State' | translate"></app-field-input>
      </ng-template>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 mt-3">
      <app-field-select
        *ngIf="cities && cities.length > 0; else emptyCities"
        class="u-flex--full"
        formControlName="city"
        [label]="'City' | translate"
        [options]="cities"
        [placeholder]="'Select City' | translate"
        [allowSearch]="true"
        [isRequired]="true"></app-field-select>
      <ng-template #emptyCities>
        <app-field-input
          formControlName="city"
          type="text"
          maxlength="200"
          class="u-flex--full"
          [isRequired]="true"
          [clearButton]="true"
          [label]="'City' | translate"
          [placeholder]="'Enter City' | translate"></app-field-input>
      </ng-template>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-6 mt-3">
      <app-field-input
        formControlName="zip_code"
        type="text"
        maxlength="12"
        class="u-flex--full"
        [isRequired]="true"
        [clearButton]="true"
        [label]="'Zip Code' | translate"
        [placeholder]="'Zip Code' | translate"></app-field-input>
    </div>
  </div>
</form>
