<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title head-color-blue" id="exampleModalLabel" translate>Add Video Section</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row clearfix mt-4">
      <div class="col-lg-10 col-md-10 col-sm-10 m-auto">
        <form [formGroup]="classForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label class="mt-1" translate>Title<span class="text-danger">*</span></label>
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'Title' | translate }}"
              formControlName="section"
              [ngClass]="{ 'is-invalid': submitted && f.section.errors }" />
            <div *ngIf="submitted && f.section.errors" class="invalid-feedback" translate>
              <div *ngIf="f.section.errors.required" translate>Title is required</div>
            </div>
          </div>
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="customCheck1"
                formControlName="assign_status"
                [value]="1" />
              <label class="custom-control-label" for="customCheck1" translate="">Assign Status</label>
            </div>
          </div>
          <!-- <div class="form-group ">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="default_section" [value]="1">
                    <label class="custom-control-label" for="customCheck2" translate="">Default Catetgory</label>
                </div>
              </div> -->
          <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="!this.routerid" translate>
            Save
          </button>
          <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="this.routerid" translate>
            Update
          </button>
          <a (click)="Close()" class="btn btn-dark float-right mr-2 mt-4 text-white" translate>Cancel</a>
        </form>
      </div>
    </div>
  </div>
</div>
