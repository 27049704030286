<div class="c-field">
  <label *ngIf="label" class="c-field__label" [class.c-field__label--disabled]="isDisabled">
    {{ label }}
    <span *ngIf="isRequired">*</span>
  </label>

  <div
    class="btn-group u-display--flex u-flex-direction--column u-width--100"
    dropdown
    #dropdown="bs-dropdown"
    triggers="focus click"
    [autoClose]="true">
    <button
      *ngIf="!allowSearch"
      id="button-basic"
      dropdownToggle
      type="button"
      class="c-field__select e-custom-select u-text-align--left"
      [class.c-field__select--no-arrow]="!showArrow"
      [class.c-field__select--disabled]="isDisabled"
      aria-controls="dropdown-basic">
      <p
        *ngIf="!itemSelected"
        class="e-custom-select__placeholder"
        [class.e-custom-select__placeholder--disabled]="isDisabled">
        {{ placeholder }}
      </p>
      <p
        *ngIf="itemSelected"
        class="e-custom-select__value"
        [class.e-custom-select__value--disabled]="isDisabled"
        [class.e-custom-select__value--initial]="isInitialValue && highlightInitialValue"
        [class.e-custom-select__value--lg]="largeText">
        {{ itemSelected.text }}
        <span class="u-color--gray-500" *ngIf="showAmount">({{ itemSelected.amount }})</span>
      </p>
    </button>
    <div *ngIf="allowSearch" class="u-width--100 u-display--flex u-flex-align--center e-custom-select_container">
      <div
        class="e-custom-select--searchable"
        [class.e-custom-select--searchable--initial]="itemSelected && isInitialValue && highlightInitialValue">
        <input
          dropdownToggle
          type="text"
          [(ngModel)]="searchTerm"
          placeholder="{{ itemSelected?.text && isInitialValue ? itemSelected?.text : placeholder }}"
          value="{{ itemSelected?.text }}"
          class="c-field__select e-custom-select u-text-align--left"
          [class.c-field__input--invalid]="
            ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
          "
          [class.c-field__input--disabled]="isDisabled"
          [class.e-custom-select__placeholder]="!itemSelected"
          [class.e-custom-select__value]="itemSelected"
          [class.e-custom-select__value--lg]="largeText"
          [class.c-field__select--no-arrow]="searchTerm.length > 0"
          [required]="isRequired"
          aria-controls="dropdown-basic"
          autocomplete="disabled"
          (input)="markAsTouchedAndDirty()"
          (blur)="onBlur()" />
        <span class="u-color--gray-500" *ngIf="!!itemSelected && showAmount">({{ itemSelected.amount }})</span>
      </div>
      <ion-icon
        *ngIf="searchTerm"
        name="close"
        class="c-field__icon u-color--gray-500"
        [class.u-color--orange-300]="
          ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
        "
        (click)="clearSelection()"></ion-icon>
    </div>

    <span *ngIf="helperText" class="c-field__icon__helper">{{ helperText }}</span>
    <div
      class="c-field__icon__helper u-display--flex u-flex-direction--column"
      *ngIf="ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.errors">
      <span *ngIf="ngControl.control?.errors?.required" class="u-color--orange-300">{{
        'FORM_ERRORS.FIELD_REQUIRED' | translate
      }}</span>
    </div>

    <ul
      id="dropdown-basic"
      *dropdownMenu
      class="dropdown-menu e-custom-select_dropdown"
      [class.small-height]="smallHeight"
      role="menu"
      aria-labelledby="button-basic">
      <li *ngIf="!!placeholder" class="e-custom-select_dropdown__item u-color--gray-500">
        {{ placeholder }}
      </li>
      <li
        role="menuitem"
        class="e-custom-select_dropdown__item"
        [class.e-custom-select_dropdown__item--lg]="largeText"
        (mousedown)="onSelect(option)"
        *ngFor="let option of filteredOptions(); let idx = index">
        {{ option?.text }}
        <span class="u-color--gray-500" *ngIf="showAmount">({{ option?.amount }})</span>
      </li>
    </ul>
  </div>
</div>
