import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('showHide', [
      transition(':enter', [style({ opacity: 0 }), animate('150ms ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent implements OnInit {
  @Input() showCloseButton: boolean = false;
  @Input() title: string | undefined = undefined;
  @Input() headerCss: string | undefined = undefined;
  @Input() show: boolean = false;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() showFooterDivisor: boolean = false;
  @Output() showChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleModal() {
    this.show = !this.show;
    this.showChange.emit(this.show);
  }
}
