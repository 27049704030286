<app-modal-root [title]="'ADD_NEW_PROGRAM' | translate" [showFooterDivisor]="true" [showCloseButton]="true">
  <form [formGroup]="form" (keydown.enter)="submit()">
    <app-field-input
      formControlName="name"
      type="text"
      class="u-flex--full"
      [clearButton]="true"
      [label]="'Name' | translate"
      [placeholder]="'Program name' | translate"
      [isRequired]="true"></app-field-input>
  </form>

  <ng-container slot="footer">
    <app-button
      icon="checkmark"
      [iconAfterLabel]="true"
      [isSubmit]="true"
      variant="primary"
      [disabled]="form.invalid"
      (click)="submit()"
      [loading]="isLoading">
      {{ 'ADD_PROGRAM' | translate }}
    </app-button>
  </ng-container>
</app-modal-root>
