<div class="c-modal" *ngIf="show" @showHide>
  <span class="c-modal__overlay" (click)="toggleModal()"></span>
  <div class="c-modal_body" [class.c-modal_body--sm]="size === 'sm'" [class.c-modal_body--lg]="size === 'lg'">
    <header class="c-modal_header {{ headerCss }}">
      <span *ngIf="title; else customHeader" class="c-modal_header__title">{{ title }}</span>
      <div *ngIf="showCloseButton" class="c-modal_header__close" (click)="toggleModal()">
        <ion-icon class="c-modal_header__close-icon" name="close"></ion-icon>
      </div>
    </header>
    <div class="c-modal_content">
      <ng-content></ng-content>
    </div>
    <footer class="c-modal_footer">
      <div class="c-modal_footer__divisor" *ngIf="showFooterDivisor"></div>
      <div class="c-modal_footer_buttons">
        <ng-content select="[slot=footer]"></ng-content>
      </div>
    </footer>
  </div>
</div>
<ng-template #customHeader>
  <ng-content select="[slot=header]"></ng-content>
</ng-template>
