import { Component, Input } from '@angular/core';
import { SummaryDetails } from '../../state/events.interface';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent {
  @Input() details: SummaryDetails | undefined;
}
