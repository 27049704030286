import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SelectOption } from 'src/app/shared/components/field-select/field-select.interfaces';
import { HasUnsavedChanges } from 'src/app/shared/guard/unsaved.guard';
import { CountryService } from 'src/app/shared/services/countrycitystate';
import Swal from 'sweetalert2';
import { Belt, SchoolDetails } from '../../admin.interface';
import { AdminService } from '../../admin.service';
import { StudentData } from '../state/student.interface';
import { StudentService } from '../state/student.service';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss'],
})
export class AddStudentComponent implements OnInit, HasUnsavedChanges {
  studentForm: FormGroup | any;
  submitted = false;
  loading = false;
  data: StudentData | undefined;
  userid: any;
  routerid: any;
  beltOptions: SelectOption[] = [];
  countryCodeList: any = [];
  maxDigit: any;
  minDigit: any;

  schoolDetails: SchoolDetails | undefined;

  maxDate: moment.Moment = moment();
  minDate: moment.Moment = moment('1924-01-01');
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;

  constructor(
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    private translate: TranslateService,
    private studentService: StudentService,
    private adminService: AdminService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.adminService.getBeltList().subscribe((data: Belt[]) => {});

    this.userid = this.route.params;
    this.routerid = this.userid._value.id;

    this.data = this.route.snapshot.data['message'];

    this.beltOptions = this.route.snapshot.data['message'].belts
      ? this.route.snapshot.data['message'].belts
          .filter((s: Belt) => s.active)
          .map((s: Belt) => ({
            id: s.belt_id,
            text: s.name,
          }))
      : [];

    if (!this.routerid) {
      this.studentForm = this.formBuilder.group({
        photo: [''],
        file: [''],
        name: ['', [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: ['', Validators.email],
        phone_code: ['+1', Validators.required],
        phone_number: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
        dob: ['', Validators.required],
        belt_id: [null, Validators.required],
        gender: [null, Validators.required],
        country_code: ['', Validators.required],
        state_code: ['', Validators.required],
        city: ['', Validators.required],
        zip_code: ['', Validators.required],
        address: ['', Validators.required],
        password: ['', Validators.required],
        password_confirmation: ['', Validators.required],
      });

      this.studentForm.get('password')?.setValidators([Validators.required, this.passwordValidator.bind(this)]);

      this.studentForm
        .get('password_confirmation')
        ?.setValidators([Validators.required, this.passwordMatchValidator.bind(this)]);

      this.adminService.getSchoolDetails().subscribe((data: SchoolDetails) => {
        this.schoolDetails = data;

        this.studentForm.controls['country_code'].setValue(this.schoolDetails.country_code);
        this.studentForm.controls['state_code'].setValue(this.schoolDetails.state_code);
        this.studentForm.controls['city'].setValue(this.schoolDetails.city);
      });
    } else if (this.data) {
      this.studentForm = this.formBuilder.group({
        photo: [''],
        name: [this.data?.name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
        email: [this.data.email, Validators.required],
        phone_code: [this.data.phone_code, Validators.required],
        phone_number: [this.data.phone_number, [Validators.required]],
        dob: [this.data.dob, Validators.required],
        belt_id: [this.data.belt_id, Validators.required],
        gender: [this.data.gender, Validators.required],
        address: [this.data.address, Validators.required],
        country_code: [this.data.country_code, Validators.required],
        state_code: [this.data.state_code, Validators.required],
        city: [this.data.city, Validators.required],
        zip_code: [this.data.zip_code, Validators.required],
      });
      this.startDate = moment(this.data.dob);
      this.endDate = moment(this.data.dob);
    }

    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });

    this.updatePhoneMask();
  }
  get f() {
    return this.studentForm.controls;
  }

  onSubmit() {
    if (this.studentForm.invalid) {
      this.toaster.warning(this.translate.instant('FORM.SUBMIT_INVALID_FORM'));
      for (const key in this.studentForm.controls) {
        this.studentForm.controls[key].markAsDirty();
        this.studentForm.controls[key].markAsTouched();
      }
      return;
    }

    if (this.studentForm.invalid || this.loading) {
      return;
    }
    this.loading = true;

    // make sure it is lowercase
    let studentemail = this.studentForm.value.email.toLowerCase();
    this.studentForm.controls['email'].setValue(studentemail);

    // Convert dob from yyyymmdd format to yyyy-mm-dd format
    let dob = this.studentForm.value.dob;
    if (dob && /^\d{8}$/.test(dob)) {
      dob = `${dob.substring(0, 4)}-${dob.substring(4, 6)}-${dob.substring(6, 8)}`;
      this.studentForm.controls['dob'].setValue(dob);
    }

    if (!this.routerid) {
      this.studentForm.controls['country_code'].markAsTouched();
      this.studentForm.controls['state_code'].markAsTouched();
      this.studentForm.controls['city'].markAsTouched();
    }

    // only submit data that was changed by the user
    const requestData: Partial<StudentData> = {};
    for (const control in this.studentForm.controls) {
      if (this.studentForm.controls[control].touched) {
        requestData[control as keyof StudentData] = this.studentForm.controls[control].value;
      }
    }

    if (requestData.country_code) {
      const country = this.countryService.getCountryByCode(requestData.country_code)?.name;
      requestData.country = country;
    }

    if (requestData.state_code) {
      const state = this.countryService.getStateByCode(requestData.state_code)?.name;
      requestData.state = state;
    }

    if (!this.routerid) {
      this.studentService.postStudent(requestData).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.studentForm.reset();
          this.toaster.success(this.translate.instant('Student Added Successfully'));
          this.router.navigate(['/admin/student']);
        },
        error: (err: any) => {
          this.loading = false;
          if (err.error.errors) {
            this.toaster.error(err.error.errors.email || err.error.errors.phonenumber);
          } else {
            this.toaster.error(err.error.message);
          }
        },
      });
    }
    if (this.routerid) {
      this.studentService.patchStudent(this.routerid, requestData).subscribe({
        next: (data: any) => {
          this.loading = false;
          this.studentForm.reset();
          this.toaster.success(this.translate.instant('Student updated Successfully'));
          this.router.navigate(['/admin/student']);
        },
        error: (err: any) => {
          this.loading = false;
          if (err.error.errors) {
            this.toaster.error(err.error.errors.email || err.error.errors.phonenumber);
          } else {
            this.toaster.error(err.error.message);
          }
        },
      });
    }
  }

  countryChange(event: any) {
    this.studentForm.controls['country_code'].setValue(event.country_code);
    this.studentForm.controls['state_code'].setValue(event.state_code);
    this.studentForm.controls['city'].setValue(event.city);
    this.studentForm.controls['zip_code'].setValue(event.zip_code);
  }

  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      const file = <File>event.target.files[0];
      this.studentForm.patchValue({
        photo: file,
      });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
      };
    } else {
      Swal.fire({
        text: 'Image should be acceot only .jpg,.png',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: 'Yes',
      });
      return;
    }
  }
  onSelected(event: any) {
    let type = event.target.files[0].type;

    if (type == 'application/pdf') {
      const file = <File>event.target.files[0];
      this.studentForm.patchValue({
        file: file,
      });
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }

  onDateChange(ev: { startDate: moment.Moment; endDate: moment.Moment }) {
    if (ev.startDate && ev.startDate.format) {
      const dobControl = this.studentForm.controls['dob'];
      dobControl.setValue(ev.startDate.format('YYYY-MM-DD'));
      dobControl.markAsDirty();
      dobControl.markAsTouched();
    }
  }

  conutyphone(event: any) {
    this.studentForm.controls['phone_code'].setValue(event.code);
    this.updatePhoneMask();
  }

  updatePhoneMask() {
    if (this.studentForm.value.phone_code == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.studentForm.value.phone_code == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '0000000000';
      this.minDigit = '0000000000';
    }
  }

  hasUnsavedChanges(): boolean {
    return this.studentForm.touched;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    if (!this.studentForm) {
      return null;
    }
    const password = this.studentForm.get('password');
    const confirmPassword = control;

    if (password && confirmPassword && password.value !== confirmPassword.value) {
      return { passwordMismatch: true };
    }

    return null;
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return null;
    }

    const hasMinLength = value.length >= 8;
    const hasLetters = /[a-zA-Z]/.test(value);
    const hasMixedCase = /[a-z]/.test(value) && /[A-Z]/.test(value);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const hasNumbers = /[0-9]/.test(value);

    const passwordValid = hasMinLength && hasLetters && hasMixedCase && hasSymbols && hasNumbers;

    return !passwordValid ? { weakPassword: true } : null;
  }
}
