<nav class="navbar navbar-fixed-top m-0 w-100">
    <div class="container-fluid">
        <div class="navbar-left">
            <div class="navbar-btn">
                <button type="button" (click)="mToggoleMenu()" class="btn-toggle-offcanvas"><i
                        class="fa fa-align-left"></i></button>
            </div>
        </div>

        <div class="navbar-right">
            <div id="navbar-menu">
                <ul class="nav navbar-nav">
                    <li class="dropdown">
                        <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                            <i class="fas fa-envelope icon-color-palete"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-list dropdown-menu-right pb-0">
                            <li *ngFor="let msg of msglist">
                                <div class="image-section p-2">
                                    <div class="message-name-date border p-2">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <h6 class="message-text-col" *ngIf="msg.user!=null" >{{msg.label}}</h6>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                                <p class="float-right">{{msg.created_at | date:'dd-MM-YYY'}}</p>
                                            </div>
                                            
                                        </div>
                                        <p >{{msg.message}} </p>
                                    </div>
                                </div>
                            </li>
                            <button class="auth-button w-100" [routerLink]="['/student/messages']" translate>View All</button>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <a href="javascript:void(0);" class="dropdown-toggle icon-menu" data-toggle="dropdown">
                            <i class="far fa-bell icon-color-palete"></i>
                        </a>
                        <ul class="dropdown-menu dropdown-list dropdown-menu-right pb-0">
                            <button class="auth-button w-100"  [routerLink]="['/student/notification']" translate>View All</button>
                        </ul>
                    </li>
                    <div class="dropdown d-inline">
                        <a class="icon-menu" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                            aria-expanded="false">
                            <i class="fas fa-globe icon-color-palete"></i>
                        </a>
                        <div class="dropdown-menu language-dropdown-menu mt-3" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('en')">English</a>
                            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('es')">Spanish</a>
                            <a class="dropdown-item language-dropdown-items" (click)="useLanguage('pt-br')">Brazilian portuguese</a>
                        </div>
                    </div>
                    <li class="hidden-xs">
                        <a *ngIf="!isFullScreen" href="javascript:void(0);" (click)="openfullScreen()"
                            id="btnFullscreen" class="icon-menu"><i class="fa fa-arrows-alt icon-color-palete"></i></a>
                        <a *ngIf="isFullScreen" href="javascript:void(0);" (click)="closeFullScreen()"
                            id="btnFullscreen" class="icon-menu"><i class="fa fa-arrows-alt icon-color-palete"></i></a>
                    </li>
                    <li><a (click)="logOut()" href="javascript:void(0)" class="icon-menu"><i
                                class="fa fa-power-off icon-color-palete"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</nav>

<app-leftmenu></app-leftmenu>