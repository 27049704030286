// field-select.component.ts
import { Component, ElementRef, Input, OnInit, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { SelectOption } from './field-select.interfaces';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options: SelectOption[] = [];

  @Input() label: string = '';

  @Input() placeholder: string = '';

  @Input() showArrow: boolean = true;

  @Input() smallHeight: boolean = false;

  @Input() centralizeSelection: boolean = false;

  @Input() largeText: boolean = false;

  @Input() showAmount: boolean = false;

  @Input() highlightInitialValue = true;

  @Input() allowSearch: boolean = false;

  @Input() isRequired: boolean = false;

  @Input() helperText: string | undefined = undefined;

  itemSelected: SelectOption | undefined = undefined;

  isInitialValue = false;

  searchTerm: string = '';

  value: string | undefined = undefined;

  @ViewChild('toggleButton') toggleButton: ElementRef | null = null;

  @ViewChild('menu') menu: ElementRef | null = null;

  onChange: (_: any) => void = () => {};
  onTouched: () => void = () => {};

  get isDisabled() {
    return this.ngControl.control?.disabled;
  }

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.isInitialValue = !!this.itemSelected;

    const { control } = this.ngControl;

    if (!control) return;

    let validators = this.getValidators();
    validators = control?.validator ? [control.validator, ...validators] : this.getValidators();

    control.setValidators(validators);
    control.updateValueAndValidity();
  }

  writeValue(value: string): void {
    this.setItemSelected(value);
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateChanges() {
    this.onChange(this.value);
  }

  clearSelection(): void {
    this.itemSelected = undefined;
    this.value = undefined;
    this.searchTerm = '';
    this.updateChanges();
  }

  onSelect(selectedOption: SelectOption): void {
    this.itemSelected = selectedOption;
    this.value = selectedOption.id;
    this.searchTerm = selectedOption.text;
    this.isInitialValue = false;
    this.markAsTouchedAndDirty();
    this.updateChanges();
  }

  onBlur() {
    if (this.searchTerm.toLocaleLowerCase() === this.itemSelected?.text.toLocaleLowerCase()) return;

    this.clearSelection();
  }

  filteredOptions(): SelectOption[] {
    return this.allowSearch
      ? this.options.filter((option) => option.text.toLowerCase().includes(this.searchTerm.toLowerCase()))
      : this.options;
  }

  markAsTouchedAndDirty() {
    this.ngControl.control?.markAsTouched();
    this.ngControl.control?.markAsDirty();
  }

  private setItemSelected(value: string) {
    if (!value) {
      this.clearSelection();
      return;
    }

    this.itemSelected = this.options.find((el) => el.id === value);
  }

  private getValidators(): ValidatorFn[] {
    const validators = [];

    if (this.isRequired) {
      validators.push(Validators.required);
    }

    return validators;
  }
}
