import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;

  name: any;
  securityForm: FormGroup | any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private AuthService:AuthService
  ) {}

  ngOnInit(): void {
    this.securityForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
    });
  }

  get ff() {
    return this.securityForm.controls;
  }

  onPassSubmit() {
    this.submit = true;

    if (this.securityForm.invalid) {
      return;
    }
    this.Apiservice.postApi(
      endpoints.changePassword.path,
      this.securityForm.value
    ).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(data.message);
        this.AuthService.logOut()
      },
      (err: any) => {
        this.toaster.error(err.error.message || err.error);
      }
    );
  }
}

