<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row clearfixs my-4 no-gutters d-flex justify-content-between align-items-center">
            <p class="mb-0 breadcrumbs-style">{{ schoolname }} Classes <span>></span> {{ categoryName }}</p>
            <a class="btn-sm auth-button ml-2 float-right" [routerLink]="['/student/schedule']"
              ><i class="fas fa-angle-left mr-1"></i>Back</a
            >
          </div>
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <ul class="nav nav-pills" id="nav-tab" role="tablist">
                    <!-- <li class="nav-item" role="presentation">
                      <a class="nav-link active" id="pills-video-tab" data-toggle="pill"
                          href="#pills-video" role="tab" aria-controls="pills-video"
                          aria-selected="true" translate>Videos</a>
                    </li> -->
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                        translate
                        >Student List</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-note-tab"
                        data-toggle="pill"
                        href="#pills-note"
                        role="tab"
                        aria-controls="pills-note"
                        aria-selected="false"
                        translate
                        >Notes</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="tab-content" id="pills-tabContent">
                    <!-- <div class="tab-pane fade show active" id="pills-video" role="tabpanel"
                        aria-labelledby="pills-video-tab">
                      <div class="card">
                        <h5 *ngIf="videoSection.length==0" class="text-center py-4" translate>No data available</h5>
                        <div class="row clearfix mt-4 p-3" *ngIf="videoSection.length > 0">
                          <div class="col-lg-9 col-md-10 col-sm-10 m-auto">
                            <div class="row mb-3">
                              <div class="col-lg-12 col-md-12 col-sm-12 accord-head-bgcolor">
                                <h6 class="mb-0 py-3 text-center text-white" translate>Guidelines and Questions</h6>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st">
                                <p class="py-2 mb-0" translate>Check out the guidelines and questions about training, techniques and periodization.</p>
                                <p class="py-2" translate>New all platform workouts will have all the techniques on this page. So if you want to watch only the guidelines regarding your training.</p>
                              </div>


                            </div>
                            <mat-accordion class="example-headers-align">
                              <mat-expansion-panel *ngFor="let section of videoSection; let i = index " [expanded]="i === 0" (opened)="setStep(i)"  hideToggle>
                                <mat-expansion-panel-header *ngIf="section?.section_id?.length > 0" >
                                  <mat-panel-title>
                                    <div class="row card no-gutters category-card-style">
                                      <div class="video-borderss px-2 py-2">
                                        <div class="row">
                                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-between">
                                            <p class="mb-0 d-flex align-items-center"><b>{{section.section}}</b></p>
                                            <p class="mb-0" translate><b>{{section?.section_id?.length}} videos</b></p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="row">
                                  <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st text-center" *ngIf="section?.section_id?.length == 0">
                                    <p class="mb-0 py-2 mt-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                                  </div>
                                </div>
                                <ng-container *ngFor="let list of section.section_id; let i = index ">
                                  <div class="video-borders mb-2 px-3 py-1" (click)="openDialog(section,list,i)" *ngIf="list?.section_id?.length > 0">
                                    <div class="row d-flex align-items-center">
                                      <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-lg-between align-items-center">
                                        <p class="mb-0 d-flex align-items-center"><i class="fab fa-youtube fa-3x mr-2 color-toutube-bg"></i> <b><span class="title-video-eclipsedot" title="{{list.title}}">{{list.title}}</span></b></p>
                                      </div>
                                      <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-block">
                                        <p class="mb-0 descript-eclipse" title="{{list.description}}">{{list.description}}</p>
                                      </div>
                                    </div>
                                    <div class="row" *ngIf="list?.section_id?.length == 0">
                                      <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <p class="mb-0 mt-2 py-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </mat-expansion-panel>
                            </mat-accordion>
                          </div>

                        </div>
                      </div>
                    </div> -->
                    <!-- <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st text-center" *ngIf="section?.section_id?.length == 0">
                      <p class="mb-0 py-2 mt-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                    </div> -->
                    <div
                      class="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab">
                      <div class="card">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12" id="content">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div class="input-group mb-1 my-4">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Search"
                                  [(ngModel)]="searchText"
                                  name="searchText" />
                                <div class="input-group-append" *ngIf="searchText">
                                  <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                    >Clear</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <button
                                  class="btn auth-button float-right my-4 mr-4"
                                  (click)="Registration()"
                                  *ngIf="(eventregister.length > 0 && !regstudent) || !regstudent"
                                  translate>
                                  Registration
                                </button>
                              </div>
                            </div>
                            <h5 *ngIf="studentlist.length == 0" class="text-center py-4" translate>
                              No data available
                            </h5>
                            <div class="row clearfix mt-4 mb-4" *ngIf="studentlist.length > 0">
                              <div
                                class="col-lg-3 col-md-4 col-sm-4 col-6"
                                *ngFor="let student of studentlist | filter: searchText; let i = index">
                                <div class="calendar-list mb-4">
                                  <div class="check-uncheck">
                                    <img
                                      src="assets/images/user.png"
                                      class="img-person"
                                      alt="User Profile Picture" />
                                  </div>
                                  <button
                                    class="btn inputcheck1 mr-2"
                                    *ngIf="
                                      eventregister.length > 0 &&
                                      regstudent &&
                                      student.id == regstudent.student_id.id
                                    ">
                                    <i class="fas fa-registered"></i>
                                  </button>
                                  <span *ngFor="let status of eventregister">
                                    <button
                                      *ngIf="status.student_id.id == student.id && status.status == 1"
                                      class="btn inputcheck1 btngreen">
                                      <i class="fas fa-check"></i>
                                    </button>
                                    <button
                                      *ngIf="status.student_id.id == student.id && status.status == 0"
                                      class="btn inputcheck1 btnred">
                                      <i class="fas fa-times"></i>
                                    </button>
                                  </span>

                                  <p>
                                    {{ student.first_name }} {{ student.middle_name }} {{ student.last_name }}
                                    <span *ngIf="student.id == userid" style="color: #8d458b" translate=""
                                      >(You)</span
                                    >
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="pills-note" role="tabpanel" aria-labelledby="pills-note-tab">
                      <div class="card">
                        <h5 *ngIf="eventnotes.length == 0" class="text-center py-4" translate>
                          No data available
                        </h5>
                        <div class="row clearfix mt-4 p-3" *ngIf="eventnotes.length > 0">
                          <div class="col-lg-10 col-md-10 col-sm-10 m-auto" *ngFor="let notes of eventnotes">
                            <div class="box-border mb-2 p-3">
                              <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                  <p class="float-right">
                                    <strong>{{ notes.created_at | date: 'dd-MM-YYYY hh:mm' }}</strong>
                                  </p>
                                </div>
                              </div>
                              <p>{{ notes.message }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
