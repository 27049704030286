import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from '../../../../core/config/endpoints';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-video-category',
  templateUrl: './add-video-category.component.html',
  styleUrls: ['./add-video-category.component.css']
})
export class AddVideoCategoryComponent implements OnInit {

  classForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.schoolid = this.route.params;
    this.routerid = this.schoolid._value.id;
    this.data = this.route.snapshot.data['message'];
    if (this.routerid) {
      this.classForm = this.formBuilder.group({
        section: [this.data.section, Validators.required],
        assign_status: [this.data.assign_status],
        default_section:[0]
      });
      if(this.data.assign_status=='0'){
        this.classForm.controls['assign_status'].setValue(false)
      }else if(this.data.assign_status=='1'){
        this.classForm.controls['assign_status'].setValue(true)
      }
      if(this.data.default_section=='0'){
        this.classForm.controls['default_section'].setValue(false)
      }else if(this.data.default_section=='1'){
        this.classForm.controls['default_section'].setValue(true)
      }
    } else {
      this.classForm = this.formBuilder.group({
        section: ['', Validators.required],
        assign_status: [0],
        default_section:[0]
      });
    }
  }
  get f() {
    return this.classForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.classForm.invalid) {
      return;
    }
    if(this.classForm.value.assign_status==true){
      this.classForm.controls['assign_status'].setValue(1)
    }else if(this.classForm.value.assign_status==false){
      this.classForm.controls['assign_status'].setValue(0)
    }
    if(this.classForm.value.default_section==true){
      this.classForm.controls['default_section'].setValue(1)
    }else if(this.classForm.value.default_section==false){
      this.classForm.controls['default_section'].setValue(0)
    }
    if (!this.routerid) {
      this.Apiservice.postApi(endpoints.createVideoSection.path,this.classForm.value).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Video Category added Successfully'));
          this.router.navigate(['/superadmin/video-category']);
        },
        (err: any) => {
          this.toaster.error(this.translate.instant('Error while loading Video Category'));
        }
      );
    } else {
      this.Apiservice.postApi(endpoints.updateVideoSection.path + '?id=' + this.routerid,
        this.classForm.value
      ).subscribe(
        (data: any) => {
          this.showMsg = true;
          this.toaster.success(this.translate.instant('Video Category updated Successfully'));
          this.router.navigate(['/superadmin/video-category']);
        },
        (err: any) => {
          this.toaster.error(this.translate.instant('Error while loading Video Category'));
        }
      );
    }
  }

}
