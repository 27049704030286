<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <div class="block-header">
      <div class="row mt-4">
        <div class="card">
          <ul class="nav nav-pills py-3 pt-2 pl-4" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="pills-student-tab"
                data-toggle="pill"
                href="#pills-student"
                role="tab"
                aria-controls="pills-student"
                aria-selected="true"
                translate
                ><i class="fas fa-user mr-2"></i>Profile</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                translate
                ><i class="fas fa-history mr-2"></i>Attendance History</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                translate
                ><i class="fas fa-user-graduate mr-2"></i>Class</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-payment-tab"
                data-toggle="pill"
                href="#pills-payment"
                role="tab"
                aria-controls="pills-payment"
                aria-selected="false"
                translate
                ><i class="fas fa-credit-card mr-2"></i>Payment</a
              >
            </li>
          </ul>

          <div class="tab-content mt-0" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-student"
              role="tabpanel"
              aria-labelledby="pills-student-tab">
              <form [formGroup]="generalForm" (ngSubmit)="onSubmit()">
                <div class="card-body">
                  <div class="row mt-4">
                    <div class="col-lg-3">
                      <div class="body text-center">
                        <label for="profileImage">
                          <img
                            src="{{ data.photo || 'assets/images/user.png' }}"
                            class="rounded-circle"
                            id="profilePicPreview"
                            onerror="this.onerror=null;this.src='assets/images/img_avatar.png';"
                            alt="" />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="profileImage"
                          name="photo"
                          style="display: none"
                          (change)="onFileSelected($event)" />
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>First Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'First Name' | translate }}"
                              formControlName="first_name"
                              minlength="2"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                              <div *ngIf="f.first_name.errors.required" translate>First Name is required</div>
                              <div *ngIf="f.first_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>Middle Name</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Middle Name' | translate }}"
                              formControlName="middle_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.middle_name.errors }" />
                            <div *ngIf="submitted && f.middle_name.errors" class="invalid-feedback">
                              <div *ngIf="f.middle_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Last Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Last Name' | translate }}"
                              formControlName="last_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                              <div *ngIf="f.last_name.errors.required" translate>Last Name is required</div>
                              <div *ngIf="f.last_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Gender<span class="text-danger">*</span></label>
                            <ng-select
                              placeholder="{{ 'Select Gender' | translate }}"
                              formControlName="gender"
                              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                              <ng-option value="male">Male </ng-option>
                              <ng-option value="female">Female </ng-option>
                              <ng-option value="others">Others </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                              <div *ngIf="f.gender.errors.required" translate>Gender is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Email<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              maxlength="64"
                              placeholder="{{ 'Email' | translate }}"
                              formControlName="email"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required" translate>Email is required</div>
                              <div *ngIf="f.email.errors.pattern" translate>Enter valid email</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2 texinput" translate
                              >Phone Number<span class="text-danger">*</span></label
                            >
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <ng-select
                                  formControlName="phone"
                                  (change)="conutyphone($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                  <ng-option *ngFor="let status of countryCodeList" [value]="status.code"
                                    >{{ status.code }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                formControlName="phonenumber"
                                mask=" {{ minDigit }} || {{ maxDigit }}"
                                [validation]="false"
                                placeholder="(00) 00000-0000"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" />
                              <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required" translate>Phone Number is required</div>
                                <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile
                                                                    should be 10
                                                                    character long
                                                                </div>
                                                                <div *ngIf="f.phonenumber.errors.pattern">Only Numbers
                                                                    are
                                                                    allowed</div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>DOB<span class="text-danger">*</span></label>
                            <input
                              placeholder="{{ 'DOB' | translate }}"
                              type="date"
                              max="9999-12-31"
                              class="form-control"
                              formControlName="dob"
                              [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" />
                            <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                              <div *ngIf="f.dob.errors.required" translate>DOB is required</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Belt<span class="text-danger">*</span></label>
                            <ng-select
                              placeholder="{{ 'Select Belt Color' | translate }}"
                              formControlName="belt"
                              [readonly]="true"
                              [ngClass]="{ 'is-invalid': submitted && f.belt.errors }">
                              <ng-option *ngFor="let status of belt" [value]="status.id">
                                {{ status.color }}
                              </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.belt.errors" class="invalid-feedback">
                              <div *ngIf="f.belt.errors.required" translate>Belt is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Address<span class="text-danger">*</span></label>
                            <textarea
                              placeholder="{{ 'Address' | translate }}"
                              type="text"
                              class="form-control"
                              formControlName="address"
                              maxlength="250"
                              [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required" translate>Address is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <app-country-state
                        (CountryChanged)="Countrychange($event)"
                        [countryForm]="generalForm"></app-country-state>
                      <div class="row"></div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-dark float-right auth-button mr-3 mb-4" translate>
                  Save
                </button>
              </form>
            </div>
            <div class="tab-pane fade show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <app-student-attendance></app-student-attendance>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <div class="row mt-4 clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Name</th>
                            <th translate>Program</th>
                            <th translate>Maximum of Registration</th>
                            <th translate class="text-center">Start Date - End Date</th>
                            <th translate>Repeat options</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let event of eventlist
                                | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                                | filter: searchText;
                              let i = index
                            ">
                            <td>{{ event.name }}</td>
                            <td>
                              <div *ngFor="let class of event.eventclass">
                                {{ class.name }}
                              </div>
                            </td>
                            <td>{{ event.max_registration }}</td>
                            <td>
                              {{ event.start_time | date: 'dd-MM-YYYY hh:mm aa' }} -
                              {{ event.end_time | date: 'dd-MM-YYYY hh:mm aa' }}
                            </td>
                            <td>{{ event.repeat }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-2">
                    <pagination-controls 
                      (pageChange)="pageChange($event)"
                      [previousLabel]="'Previous' | translate"
                      [nextLabel]="'Next' | translate"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-payment-tab">
              <div class="row mt-4 clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="card">
                    <div class="body">
                      <div class="row">
                        <div class="col-md-3">
                          <span class="d-flex">{{ 'Show' | translate}}
                            <select
                              [(ngModel)]="itemPerPage"
                              name="selection-datatable_length"
                              aria-controls="selection-datatable"
                              class="custom-select custom-select-sm form-control form-control-sm w-auto">
                              <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                {{ pageCountObj }}
                              </option>
                            </select>
                            {{ 'entries' | translate}}
                          </span>
                        </div>
                        <div class="col-md-9">
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Search' | translate }}"
                              [(ngModel)]="searchText"
                              name="searchText" />
                            <div class="input-group-append" *ngIf="searchText">
                              <span class="input-group-text" id="search-mail" (click)="resetsearch()" translate
                                >Clear</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive">
                      <table class="table table-hover mb-0">
                        <thead>
                          <tr>
                            <th translate>Class</th>
                            <th translate>Total Amount</th>
                            <th translate>Paid Amount</th>
                            <th translate>Balance Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ listpayment.total.events }}</td>
                            <td>R$ {{ listpayment.total.total_amount || 0 }}</td>
                            <td>R$ {{ listpayment.list.paid_amount || 0 }}</td>
                            <td>R$ {{ listpayment.available.bal_amount || 0 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row mx-2">
                    <pagination-controls 
                      (pageChange)="pageChange($event)"
                      [previousLabel]="'Previous' | translate"
                      [nextLabel]="'Next' | translate"
                    ></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
