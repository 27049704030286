import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-root',
  templateUrl: './modal-root.component.html',
  styleUrls: ['./modal-root.component.scss'],
})
export class ModalRoot implements OnInit {
  @Input() showCloseButton: boolean = false;
  @Input() title: string | undefined;
  @Input() modalCss: string | undefined;
  @Input() headerCss: string | undefined;
  @Input() contentCss: string | undefined;
  @Input() footerCss: string | undefined;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() showFooterDivisor: boolean = false;

  constructor(public modalService: NgbActiveModal) {}

  ngOnInit(): void {}

  dismiss() {
    this.modalService.close();
  }
}
