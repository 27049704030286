import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  path: any;
  pageCounts = [50, 100, 200, 300, 400];
  constructor(private httpClient: HttpClient) {
    this.path = environment.BACKEND_BASE_URL;
  }

  getApi(path: any, query: any = {}): Observable<any> {
    path = this.path + path;
    return this.httpClient.get(path, query);
  }
  deleteApi(path: any, query: any = {}) {
    path = this.path + path;
    return this.httpClient.delete(path, query);
  }
  postApi(path: any, query: any = {}) {
    path = this.path + path;
    return this.httpClient.post(path, query);
  }
  setFormControl(keys: any, values: any) {
    const formData = new FormData();
    keys.forEach((name: string) => {
      formData.append(name, values[name]);
    });
    return formData;
  }
}
