import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notifications',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
  readnotify: any = [];
  unreadnotify: any = [];
  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.notifyList();
  }
  notifyList() {
    this.Apiservice.getApi(endpoints.listnotify.path).subscribe((data: any) => {
      this.readnotify = data.notificationlist;
    });
    this.Apiservice.getApi(endpoints.unreadnotify.path).subscribe((data: any) => {
      this.unreadnotify = data.unread;
    });
  }

  deletenotify(i: number, notify: any) {
    Swal.fire({
      title: this.translate.instant('Delete'),
      text: this.translate.instant('Do you want to Delete ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.deleteApi(endpoints.deletenotify.path + '?id=' + notify).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.notifyList();
          },
          (err) => {
            Swal.fire({
              title: err.error.message,
              icon: 'error',
            });
          }
        );
      }
    });
  }
}
