import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, finalize, skip } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { StudentEntity } from './state/student.interface';
import { StudentRepository } from './state/student.repository';
import { StudentService } from './state/student.service';

interface SearchParams {
  search?: string;
  active?: boolean;
  page?: number;
}

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class StudentComponent implements OnInit {
  page: any;

  searchText = new FormControl('');
  isSearchActive = false;
  filterByActive = true;
  isLoading = false;

  students$ = this.studentRepository.currentPage$;
  pageData$ = this.studentRepository.pageData$;

  numArchived$ = this.studentRepository.numArchived$;
  numActive$ = this.studentRepository.numActive$;

  errorMessage = null;

  lastParams: SearchParams | undefined;

  // id of the student that is being archived or unarchived
  performingActionOnId: string | undefined;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    public formBuilder: FormBuilder,
    public modalService: NgbModal,
    private translate: TranslateService,
    private toastr: ToastrService,
    private studentService: StudentService,
    private studentRepository: StudentRepository,
    private router: Router
  ) {}

  ngOnInit(): void {
    // skips first as the value emits when the component is initialized
    this.searchText.valueChanges.pipe(debounceTime(300), skip(1)).subscribe((value) => {
      this.search(value);
    });

    this.getStudent();

    // updates the number of archived and active students
    this.updateNumArchived();
  }

  getStudent(params?: SearchParams) {
    this.lastParams = params;

    this.isLoading = true;
    this.studentService
      .getStudents(params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.errorMessage = null;
        },
        error: (error) => {
          this.errorMessage = error;
        },
      });
  }

  toggleActive(ev: any, student: StudentEntity) {
    const title = student.active
      ? this.translate.instant('STUDENT_PAGE.ARCHIVE_TITLE')
      : this.translate.instant('STUDENT_PAGE.UNARCHIVE_TITLE');
    const text = student.active
      ? this.translate.instant('STUDENT_PAGE.ARCHIVE_TEXT')
      : this.translate.instant('STUDENT_PAGE.UNARCHIVE_TEXT');

    ev.stopPropagation();

    Swal.fire({
      title,
      text,
      icon: 'info',
      customClass: 'c-swal-modal',
      showCancelButton: true,
      confirmButtonColor: '#2F69B0',
      cancelButtonColor: '#F1F8FF',
      reverseButtons: true,
      confirmButtonText: student.active
        ? this.translate.instant('STUDENT_PAGE.SET_ARCHIVED_BTN')
        : this.translate.instant('STUDENT_PAGE.SET_ACTIVE_BTN'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.performingActionOnId = student.user_id;
        this.studentService.patchStudent(student.user_id, { active: !student.active }).subscribe({
          next: (data: { active: boolean }) => {
            this.performingActionOnId = undefined;
            const message =
              data.active === true
                ? this.translate.instant('STUDENT_PAGE.SET_ACTIVE_SUCCESS')
                : this.translate.instant('STUDENT_PAGE.SET_ARCHIVED_SUCCESS');

            this.toastr.success(message);

            this.getStudent(this.lastParams);

            if (this.lastParams?.active === false) {
              this.updateNumActive();
            } else {
              this.updateNumArchived();
            }
          },
          error: (err) => {
            this.performingActionOnId = undefined;
            Swal.fire({
              title: err,
              icon: 'error',
            });
          },
        });
      }
    });
  }

  search(value: string) {
    // needs to re-do the filter in case search is empty
    const filterValue = !this.filterByActive ? { active: false } : { active: true };
    const searchParams = value ? { search: value } : filterValue;
    this.isSearchActive = !!value;

    this.getStudent(searchParams);
  }

  setFilter(value: 'active' | 'archived') {
    this.filterByActive = value !== 'archived';
    this.search('');
  }

  pageChange(event: any) {
    this.page = event;
    this.getStudent({ page: event, active: this.filterByActive });
  }

  navigateToUser(id: string) {
    this.router.navigate(['/admin/student/view-student/' + id]);
  }

  navigateToEdit(ev: any, id: string) {
    ev.stopPropagation();
    this.router.navigate(['/admin/student/edit-student/' + id]);
  }

  updateNumArchived() {
    this.studentService.getNumArchived().subscribe();
  }

  updateNumActive() {
    this.studentService.getNumActive().subscribe();
  }
}
