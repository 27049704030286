<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Dashboard</h2>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="card">
            <div class="body">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="card dash-body" style="background: #ff5c33">
                    <div class="body">
                      <div class="row text-white align-items-center">
                        <div class="col-md-6 text-center border-right">
                          <i class="far fa-flag"></i>
                          <p translate>Total Classes / Month</p>
                        </div>
                        <div class="col-md-6 text-center">
                          <h4 *ngIf="dashboardcount.events">{{ dashboardcount.events.count || 0 }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="card dash-body" style="background: #4da6ff">
                    <div class="body">
                      <div class="row text-white align-items-center">
                        <div class="col-md-6 text-center border-right">
                          <i class="far fa-folder"></i>
                          <p translate>Total Payment / Month</p>
                        </div>
                        <div class="col-md-6 text-center">
                          <h4 *ngIf="dashboardcount.payment">{{ dashboardcount.payment.total_amount || 0 }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="header">
            <h2 class="dashboard-h2 mb-2" translate>Classes Overview</h2>
          </div>
          <app-bar-charts></app-bar-charts>
        </div>
      </div>

      <div class="row clearfix">
        <div class="col-12 card-section">
          <div class="header">
            <h2 class="dashboard-h2 mb-2" translate>Recent Classes</h2>
          </div>
          <div class="card-body bgwhite">
            <div class="box-border p-4">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th translate>Class Name</th>
                      <th translate>Program</th>
                      <th translate>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let event of eventlist">
                      <td>{{ event.name }}</td>
                      <td>
                        <span *ngFor="let class of event.eventclass" class="d-block">{{ class.name }}</span>
                      </td>
                      <td>{{ event.start_time | date: 'dd-MM-YYYY hh:mm' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
