import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.css'],
})
export class CoachComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;

  rangeForm: FormGroup | any;
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page:any;
  pagenext:any
  itemPerPage = 50;
  coachlist: any = [];
  inacticelist: any = [];
  searchText: any;
  pagecounts: any;
  settings: any = [];
  coach: any = [];
  submitted: boolean = false;

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {} 
  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.getUsers();
    this.rangeForm = this.formBuilder.group({
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
    });
  }
  getUsers() {
    this.Apiservice.getApi(endpoints.coachlist.path).subscribe(
      (data: any) => {
        this.coachlist = data.coach.filter((s: any) => s.status == 1);
        this.inacticelist = data.coach.filter((s: any) => s.status == 0);
        
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  private convert(str:any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  OnSelect(event:any){
    if(event?.startDate){
      let startdate=this.convert(event.startDate._d)
      let enddate=this.convert(event.endDate._d)
      this.rangeForm.controls['start_time'].setValue(startdate)
      this.rangeForm.controls['end_time'].setValue(enddate)
    }
  }
  deleteRow(i: any, staff: any) {
    Swal.fire({
      title: this.translate.instant('Inactive'),
      text: this.translate.instant('Do you want to Inactive ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deletecoach.path + '?id=' + staff.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.getUsers();
          },
          (err) => {

            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  InactiveRow(i: any, staff: any) {
    Swal.fire({
      title: this.translate.instant('Active'),
      text: this.translate.instant('Do you want to Active ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText:this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.activecoach.path + '?id=' + staff.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.getUsers();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }
  get f() {
    return this.rangeForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.rangeForm.invalid) {
      this.toaster.error(this.translate.instant('Select a from and to date'))
      return;
    }
    this.Apiservice.getApi(endpoints.coachlist.path+"?start_time="+this.rangeForm.value.start_time+"&end_time="+this.rangeForm.value.end_time).subscribe(
      (data: any) => {
        this.coachlist = data.coach.filter((s: any) => s.status == 1);
        this.inacticelist = data.coach.filter((s: any) => s.status == 0);
        this.toaster.success(this.translate.instant('Successfully'))
        this.rangeForm.reset()
      },
      (err: any) => {
        this.toaster.error(err.error.message)
      }
    );
  }
  clear(){
    this.daterangepicker.clear()
    this.rangeForm.reset()
    this.getUsers()
  }

  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  pagechange(event: any) {
    this.pagenext = event;
  }
}
