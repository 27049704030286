<app-header></app-header>
<div class="e-program-page">
  <section id="main-content">
    <div class="e-program-page__header">
      <header class="c-page-header">
        <h2 class="c-page-header__title">{{ 'PROGRAM_PAGE.TITLE' | translate }}</h2>
        <app-button icon="add" [routerLink]="['/admin/programs/add-program']">
          {{ 'PROGRAM_PAGE.NEW_PROGRAM' | translate }}
        </app-button>
      </header>
    </div>

    <div class="e-program-page__content-box">
      <div class="e-program-page__content-box__content">
        <div class="card mb-4">
          <div class="mb-4 e-program-page__content-header">
            <div class="d-flex">
              <app-chip-list
                class="u-animation-fade-in e-program-page__chip-list"
                (chipSelected)="setFilter($event)">
                <app-choice-chip
                  value="active"
                  [selected]="true"
                  [label]="'PROGRAM_PAGE.ACTIVE' | translate"
                  [mobileLabel]="'PROGRAM_PAGE.ACTIVE' | translate"
                  [counter]="isLoading ? null : activeProgramList.length">
                </app-choice-chip>
                <app-choice-chip
                  value="archived"
                  [label]="'PROGRAM_PAGE.ARCHIVED' | translate"
                  [mobileLabel]="'PROGRAM_PAGE.ARCHIVED' | translate"
                  [counter]="isLoading ? null : inactiveProgramList.length">
                </app-choice-chip>
              </app-chip-list>
            </div>
          </div>

          <div class="table-responsive e-program-page__table">
            <table class="table table-hover mb-0">
              <thead>
                <tr>
                  <th translate>PROGRAM_PAGE.PROGRAM_NAME</th>
                  <th translate class="e-program-page__table-item__color-header">PROGRAM_PAGE.COLOR</th>
                  <th class="text-center" translate>PROGRAM_PAGE.ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="isLoading">
                  <td colspan="3" class="text-center pb-3 pt-3">
                    <div class="spinner-border" role="status">
                      <span class="sr-only" translate>Loading</span>
                    </div>
                  </td>
                </tr>
                <ng-container *ngIf="!isLoading && displayProgramList.length === 0">
                  <tr>
                    <td *ngIf="!failedToLoad" colspan="3" class="text-center">
                      {{ 'PROGRAM_PAGE.NO_PROGRAMS' | translate }}
                    </td>
                    <td *ngIf="failedToLoad" colspan="3" class="text-center">
                      <ion-icon name="alert-circle-outline"></ion-icon>
                      {{ 'PROGRAM_PAGE.ERROR_MESSAGE' | translate }}
                    </td>
                  </tr>
                </ng-container>
                <tr
                  class="e-program-page__table-item"
                  (click)="editProgram(program.program_id)"
                  *ngFor="let program of displayProgramList">
                  <td>{{ program.name }}</td>
                  <td class="e-program-page__table-item__color" [style.background-color]="program.colors.bg"></td>
                  <td>
                    <div class="d-flex w-100 justify-content-center">
                      <app-button
                        class="c-class-card__action-button mr-2"
                        icon="pencil"
                        [iconOnly]="true"
                        variant="untoggled"
                        [routerLink]="['/admin/programs/edit-program/' + program.program_id]">
                      </app-button>
                      <app-button
                        class="c-class-card__action-button"
                        [icon]="viewMode === 'active' ? 'archive-outline' : 'power'"
                        [iconOnly]="true"
                        variant="untoggled"
                        [loading]="!!togglingMap[program.program_id]"
                        (click)="toggleActive($event, program)">
                      </app-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
