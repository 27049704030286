import { City, Country, State } from 'country-state-city';

import { Injectable } from '@angular/core';

@Injectable()
export class CountryService {
  getCountries() {
    return Country.getAllCountries();
  }

  getCountryByCode(countryCode: string) {
    return Country.getCountryByCode(countryCode);
  }

  getStateByCode(stateCode: string) {
    return State.getStateByCode(stateCode);
  }

  getStatesByCountry(countryShotName: string) {
    return State.getStatesOfCountry(countryShotName);
  }

  getCitiesByState(country: string, state: string) {
    return City.getCitiesOfState(country, state);
  }
}
