import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule } from 'ngx-ui-loader';

import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxEditorModule } from 'ngx-editor';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
import { RollbarService, rollbarFactory } from './core/services/rollbar.service';
import { ErrorComponent } from './modules/auth/404-Error/404-Error.component';
import { ErrorInterceptor } from './shared/guard/ErrorInterceptor';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { AdminGuard } from './shared/guard/admin.guard';
import { JwtInterceptor } from './shared/guard/httpInterceptor';
import { AuthService } from './shared/services/auth.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent, ErrorComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      timeOut: 8000,
      closeButton: true,
    }),
    BsDatepickerModule.forRoot(),
    NgxPaginationModule,
    MatNativeDateModule,
    NgChartsModule,
    NgxEditorModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
  ],
  providers: [
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    AuthService,
    AdminGuard,
    SecureInnerPagesGuard,
    BsDatepickerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
