import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.css'],
})
export class ViewStudentComponent implements OnInit {
  studentlist: any;
  listpayment: any = [];
  routeparam: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  pdffile: any;
  pausedMembership: boolean = false;
  hasMembership: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private Apiservice: ApiService,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
    public toaster: ToastrService,
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.routeparam = this.route.params;
    this.studentlist = this.route.snapshot.data?.message?.data;
    this.pdffile = this.sanitizer.bypassSecurityTrustResourceUrl(this.studentlist.file);
    this.Apiservice.getApi(endpoints.listpayment.path + '?user_id=' + this.routeparam._value.id).subscribe(
      (data: any) => {
        console.log(data.response)
        this.listpayment = data.response.payments;
        this.hasMembership = data.response.has_membership;
        this.pausedMembership = data.response.paused_membership;
      }
    );
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }

  confirmPauseMembership(studentId: string) {
    Swal.fire({
      title: this.translate.instant('Pause Membership'),
      text: this.translate.instant('Are you sure you want to pause the membership for this student?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.pauseMembership(studentId);
      }
    });
  }

  confirmResumeMembership(studentId: string) {
    Swal.fire({
      title: this.translate.instant('Resume Membership'),
      text: this.translate.instant('Are you sure you want to resume the membership for this student?'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.resumeMembership(studentId);
      }
    });
  }

  pauseMembership(studentId: string) {
    console.log(studentId)

    if (studentId) {
      this.Apiservice.postApi(endpoints.pauseMembership.path, {student_id: studentId}).subscribe(
        (data: any) => {
          console.log(data);

          this.pausedMembership = true;

          this.toaster.success(this.translate.instant(data.message));

        },
        (err: any) => {
          this.toaster.error(this.translate.instant(err.error.message));
        }
      );
    }
  }

  resumeMembership(studentId: string) {
    console.log(studentId)

    if (studentId) {
      this.Apiservice.postApi(endpoints.resumeMembership.path, {student_id: studentId}).subscribe(
        (data: any) => {
          console.log(data);

          this.pausedMembership = false;

          this.toaster.success(this.translate.instant(data.message));

        },
        (err: any) => {
          this.toaster.error(this.translate.instant(err.error.message));
        }
      );
    }
  }
}
