<app-header></app-header>

<div id="main-content">
  <div class="container-fluid">
    <!-- Page header section  -->
    <ul class="nav nav-pills" id="nav-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          [ngClass]="{ active: password != true }"
          id="pills-home-tab"
          data-toggle="pill"
          href="#pills-home"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
          translate
          ><i class="fas fa-home mr-2"></i>General</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          [ngClass]="{ active: password == true }"
          id="pills-profile-tab"
          data-toggle="pill"
          href="#pills-profile"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
          translate
          ><i class="fas fa-lock mr-2"></i>Security</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="pills-users-tab"
          data-toggle="pill"
          href="#pills-users"
          role="tab"
          aria-controls="pills-users"
          aria-selected="false"
          translate
          ><i class="fas fa-user-plus mr-2"></i>Users</a
        >
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show"
        [ngClass]="{ active: password != true }"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab">
        <div class="block-header">
          <div class="row clearfix">
            <div class="card">
              <form [formGroup]="generalForm">
                <div class="card-body">
                  <div class="row mt-4">
                    <div class="col-lg-3">
                      <div class="body text-center">
                        <label for="profileImage">
                          <img
                            src="{{ data.photo || 'assets/images/user.png' }}"
                            class="rounded-circle"
                            id="profilePicPreview"
                            onerror="this.onerror=null;this.src='assets/images/img_avatar.png';"
                            alt="" />
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          id="profileImage"
                          name="photo"
                          style="display: none"
                          (change)="onFileSelected($event)" />
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>First Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'First Name' | translate }}"
                              formControlName="first_name"
                              minlength="2"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                              <div *ngIf="f.first_name.errors.required" translate>First Name is required</div>
                              <div *ngIf="f.first_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>Middle Name</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Middle Name' | translate }}"
                              formControlName="middle_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.middle_name.errors }" />
                            <div *ngIf="submitted && f.middle_name.errors" class="invalid-feedback">
                              <div *ngIf="f.middle_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1" translate>Last Name<span class="text-danger">*</span></label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="{{ 'Last Name' | translate }}"
                              formControlName="last_name"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                              <div *ngIf="f.last_name.errors.required" translate>Last Name is required</div>
                              <div *ngIf="f.last_name.errors.pattern" translate>Only Letters are allowed</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-1 texinput" translate
                              >Phone Number<span class="text-danger">*</span></label
                            >
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <ng-select
                                  formControlName="phone"
                                  (change)="conutyphone($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                                  <ng-option *ngFor="let status of countryCodeList" [value]="status"
                                    >{{ status.code }}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <input
                                type="text"
                                placeholder="(00) 00000-0000"
                                mask=" {{ minDigit }} || {{ maxDigit }}"
                                [validation]="false"
                                class="form-control"
                                formControlName="phonenumber"
                                [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" />
                              <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                                <div *ngIf="f.phonenumber.errors.required" translate>Phone Number is required</div>
                                <!-- <div *ngIf="f.phonenumber.errors.minlength">Mobile
                                                                    should be 10 character long
                                                                </div>
                                                                <div *ngIf="f.phonenumber.errors.pattern">Only Numbers
                                                                    are allowed</div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Email<span class="text-danger">*</span></label>
                            <input
                              placeholder="{{ 'Email' | translate }}"
                              type="text"
                              class="form-control"
                              formControlName="email"
                              maxlength="64"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required" translate>Email is required</div>
                              <div *ngIf="f.email.errors.pattern" translate>Enter valid email</div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>DOB<span class="text-danger">*</span></label>
                            <input
                              type="date"
                              max="9999-12-31"
                              placeholder="{{ 'DOB' | translate }}"
                              class="form-control"
                              formControlName="dob"
                              [ngClass]="{ 'is-invalid': submitted && f.dob.errors }" />
                            <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                              <div *ngIf="f.dob.errors.required" translate>DOB is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Gender<span class="text-danger">*</span></label>
                            <ng-select
                              placeholder="{{ 'Select Gender' | translate }}"
                              formControlName="gender"
                              [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                              <ng-option value="male">Male </ng-option>
                              <ng-option value="female">Female </ng-option>
                              <ng-option value="others">Others </ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                              <div *ngIf="f.gender.errors.required" translate>Gender is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <app-country-state
                        (CountryChanged)="Countrychange($event)"
                        [countryForm]="generalForm"></app-country-state>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label class="mt-2" translate>Address<span class="text-danger">*</span></label>
                            <textarea
                              placeholder="{{ 'Address' | translate }}"
                              type="text"
                              class="form-control"
                              formControlName="address"
                              maxlength="250"
                              [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required" translate>Address is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button (click)="onSubmit()" class="btn btn-dark float-right auth-button mr-3 mb-4" translate>
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        [ngClass]="{ active: password == true }"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab">
        <div class="block-header">
          <app-change-password></app-change-password>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-users" role="tabpanel" aria-labelledby="pills-users-tab">
        <div class="block-header">
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="float-right">
                <a class="btn-sm auth-button" [routerLink]="'/superadmin/profile/add-user/'" translate
                  ><i class="fas fa-plus mr-1"></i>New</a
                >
              </div>
            </div>
            <div class="row mt-4 table">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pb-3">
                  <ul class="nav nav-pills" id="nav-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-active-tab"
                        data-toggle="pill"
                        href="#pills-active"
                        role="tab"
                        aria-controls="pills-active"
                        aria-selected="true"
                        translate
                        ><i class="fas fa-lock-open pr-2"></i>Active</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-inactive-tab"
                        data-toggle="pill"
                        href="#pills-inactive"
                        role="tab"
                        aria-controls="pills-inactive"
                        aria-selected="false"
                        translate
                        ><i class="fas fa-lock pr-2"></i>In-active</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="tab-content mt-0" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-active"
                    role="tabpanel"
                    aria-labelledby="pills-active-tab">
                    <div class="row clearfix">
                      <div class="col-12">
                        <div class="card">
                          <div class="body">
                            <div class="row">
                              <div class="col-md-3">
                                <span class="d-flex">{{ 'Show' | translate}}
                                  <select
                                    [(ngModel)]="itemPerPage"
                                    name="selection-datatable_length"
                                    aria-controls="selection-datatable"
                                    class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                    <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                      {{ pageCountObj }}
                                    </option>
                                  </select>
                                  {{ 'entries' | translate}}
                                </span>
                              </div>
                              <div class="col-md-9">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="{{ 'Search' | translate }}"
                                    [(ngModel)]="searchText"
                                    name="searchText" />
                                  <div class="input-group-append" *ngIf="searchText">
                                    <span
                                      class="input-group-text"
                                      id="search-mail"
                                      (click)="resetsearch()"
                                      translate
                                      >Clear</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-hover mb-0">
                              <thead>
                                <tr>
                                  <th translate>Name</th>
                                  <th translate>Phone</th>
                                  <th translate>Email</th>
                                  <th translate>Gender</th>
                                  <th translate>Address</th>
                                  <th translate>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let list of userlist
                                      | paginate: { itemsPerPage: itemPerPage, currentPage: page, id: 'active' }
                                      | filter: searchText;
                                    let i = index
                                  ">
                                  <td class="multi_lines_text">
                                    {{ list.first_name }}
                                    {{ list.middle_name }}
                                    {{ list.last_name }}
                                  </td>
                                  <td>
                                    {{ list.phone }} {{ list.phonenumber | slice: 0 : 3 }}-{{
                                      list.phonenumber | slice: 3 : 6
                                    }}-{{ list.phonenumber | slice: 6 }}
                                  </td>
                                  <td class="multi_lines_text">{{ list.email }}</td>
                                  <td>{{ list.gender }}</td>
                                  <td class="multi_lines_text">{{ list.address }}</td>
                                  <td>
                                    <a
                                      [routerLink]="'/superadmin/profile/edit-user/' + list.id"
                                      class="btn btn-default btn-sm ml-1"
                                      ><i class="fas fa-pencil-alt"></i
                                    ></a>
                                    <a
                                      (click)="deleteRow(i, list)"
                                      class="btn btn-default btn-sm ml-1"
                                      title="{{ 'Delete' | translate }}"
                                      ><i class="fas fa-trash-alt"></i
                                    ></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <pagination-controls 
                          id="active" 
                          (pageChange)="pageChange($event)"
                          [previousLabel]="'Previous' | translate"
                          [nextLabel]="'Next' | translate"
                        ></pagination-controls>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-inactive"
                    role="tabpanel"
                    aria-labelledby="pills-inactive-tab">
                    <div class="row clearfix">
                      <div class="col-12">
                        <div class="card">
                          <div class="body">
                            <div class="row">
                              <div class="col-md-3">
                                <span class="d-flex">{{ 'Show' | translate}}
                                  <select
                                    [(ngModel)]="itemPerPage"
                                    name="selection-datatable_length"
                                    aria-controls="selection-datatable"
                                    class="custom-select custom-select-sm form-control form-control-sm w-auto">
                                    <option *ngFor="let pageCountObj of pagecounts" [value]="pageCountObj">
                                      {{ pageCountObj }}
                                    </option>
                                  </select>
                                  {{ 'entries' | translate}}
                                </span>
                              </div>
                              <div class="col-md-9">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="{{ 'Search' | translate }}"
                                    [(ngModel)]="searchText"
                                    name="searchText" />
                                  <div class="input-group-append" *ngIf="searchText">
                                    <span
                                      class="input-group-text"
                                      id="search-mail"
                                      (click)="resetsearch()"
                                      translate
                                      >Clear</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="table-responsive">
                            <table class="table table-hover mb-0">
                              <thead>
                                <tr>
                                  <th translate>Name</th>
                                  <th translate>Phone</th>
                                  <th translate>Email</th>
                                  <th translate>Gender</th>
                                  <th translate>Address</th>
                                  <th translate>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let list of Inactiveuser
                                      | paginate
                                        : { itemsPerPage: itemPerPage, currentPage: pagenext, id: 'inactive' }
                                      | filter: searchText;
                                    let i = index
                                  ">
                                  <td>{{ list.first_name }} {{ list.middle_name }} {{ list.last_name }}</td>
                                  <td>
                                    {{ list.phone }} {{ list.phonenumber | slice: 0 : 3 }}-{{
                                      list.phonenumber | slice: 3 : 6
                                    }}-{{ list.phonenumber | slice: 6 }}
                                  </td>
                                  <td>{{ list.email }}</td>
                                  <td>{{ list.gender }}</td>
                                  <td>{{ list.address }}</td>
                                  <td>
                                    <a
                                      [routerLink]="'/superadmin/profile/edit-user/' + list.id"
                                      class="btn btn-default btn-sm ml-1"
                                      ><i class="fas fa-pencil-alt"></i
                                    ></a>
                                    <a
                                      (click)="deleteActiveRow(i, list)"
                                      class="btn btn-default btn-sm ml-1"
                                      title="{{ 'Delete' | translate }}"
                                      ><i class="fas fa-trash-alt"></i
                                    ></a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <pagination-controls 
                          id="inactive" 
                          (pageChange)="pagechange($event)"
                          [previousLabel]="'Previous' | translate"
                          [nextLabel]="'Next' | translate"
                        ></pagination-controls>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
