<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header" >
      <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row clearfixs my-4 no-gutters d-flex justify-content-between align-items-center">
            <p class="mb-0 breadcrumbs-style" translate>Video Library</p>
          </div>
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12 mb-4">
                  <div class="card">
                    <h5 *ngIf="videoSection?.length==0" class="text-center py-4" translate>No data available</h5>
                    <div class="row clearfix mt-4 p-3" *ngIf="videoSection?.length > 0">
                      <div class="col-lg-9 col-md-10 col-sm-10 m-auto">
                        <div class="row mb-3">
                          <div class="col-lg-12 col-md-12 col-sm-12 accord-head-bgcolor">
                            <h6 class="mb-0 py-3 text-center text-white" translate>Guidelines and Questions</h6>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st">
                            <p class="py-2 mb-0" translate>Check out the guidelines and questions about training, techniques and periodization.</p>
                            <p class="py-2" translate>New all platform workouts will have all the techniques on this page. So if you want to watch only the guidelines regarding your training.</p>
                          </div>
                          <!-- <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st text-center" *ngIf="section?.section_id?.length == 0">
                            <p class="mb-0 py-2 mt-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                          </div> -->
                        </div>
                        <mat-accordion class="example-headers-align">
                          <mat-expansion-panel *ngFor="let section of videoSection; let i = index " [expanded]="i === 0" (opened)="setStep(i)"  hideToggle>
                            <mat-expansion-panel-header *ngIf="section?.section_id?.length > 0" >
                              <mat-panel-title>
                                <div class="row card no-gutters category-card-style">
                                  <div class="video-borderss px-2 py-2">
                                    <div class="row">
                                      <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-between">
                                        <p class="mb-0 d-flex align-items-center"><b>{{section.section}}</b></p>
                                        <p class="mb-0" translate><b>{{section?.section_id?.length}} videos</b></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="row">
                              <div class="col-lg-12 col-md-12 col-sm-12 video-body-text-st text-center" *ngIf="section?.section_id?.length == 0">
                                <p class="mb-0 py-2 mt-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                              </div>
                            </div>
                            <ng-container *ngFor="let list of section.section_id; let i = index ">
                              <div class="video-borders mb-2 px-3 py-1" (click)="openDialog(section,list,i)">
                                <div class="row d-flex align-items-center">
                                  <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-lg-between align-items-center">
                                    <p class="mb-0 d-flex align-items-center"><i class="fab fa-youtube fa-3x mr-2 color-toutube-bg"></i> <b><span class="title-video-eclipsedot" title="{{list.title}}">{{list.title}}</span></b></p>
                                  </div>
                                  <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-block">
                                    <p class="mb-0 descript-eclipse" title="{{list.description}}">{{list.description}}</p>
                                  </div>
                                </div>
                                <div class="row" *ngIf="list?.section_id?.length == 0">
                                  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                    <p class="mb-0 mt-2 py-2 no-video-bg-clr"><b translate>No Videos Available</b></p>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
