import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export class ZipCodeValidator {
  static createValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const countryControl = control.parent?.get('country_code');
      if (!countryControl || !countryControl.value) {
        return null;
      }

      const zipCode = control.value;
      if (!zipCode) {
        return null;
      }

      const countryCode = countryControl.value;

      if (countryCode === 'BR') {
        const brazilPattern = /^\d{5}-?\d{3}$/;
        if (!brazilPattern.test(zipCode)) {
          return { invalidZipCode: 'BR' };
        }
      }

      if (countryCode === 'US') {
        const usPattern = /^\d{5}(-\d{4})?$/;
        if (!usPattern.test(zipCode)) {
          return { invalidZipCode: 'US' };
        }
      }
      return null;
    };
  }

  static getErrorMessage(errors: ValidationErrors | null, translate: TranslateService): string {
    if (!errors || !errors['invalidZipCode']) return '';

    const countryCode = errors['invalidZipCode'];
    return translate.instant(`ZIPCODE.${countryCode}`);
  }
}
