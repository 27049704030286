<app-header></app-header>
<div id="main-content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row clearfix">
        <div class="col-xl-12 col-md-12 col-sm-12">
          <h2 translate>Payments</h2>
        </div>
      </div>
      <form [formGroup]="rangeForm" (ngSubmit)="onSubmit()">
        <div class="row for-calender">
          <div class="col-lg-4 col-md-6 col-sm-6 col-12">
            <app-datepicker (OnDate)="OnSelect($event)"></app-datepicker>
          </div>

          <div class="col-lg-2 col-md-3 col-sm-3 col-4">
            <button class="btn-sm auth-button submit-newbtn new-btnclass" translate>Search</button>
            <button class="btn-sm btn-outline ml-2" type="button" (click)="clear()" translate>Clear</button>
          </div>
        </div>
      </form>
      <div class="row mt-4 auth-form p-4">
        <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <h5 class="wallet-text" ><span translate>Wallet</span> : <span>1,200</span></h5>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <button class="btn auth-button float-right" translate>
                        Credit
                    </button>
                    <button class="btn auth-buttonnew float-right" translate>
                        Debit
                    </button>
                </div> -->
        <div class="table-responsive mt-4">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th translate>Class</th>
                <th translate>Total Amount</th>
                <th translate>Paid Amount</th>
                <th translate>Balance Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ listPayment.total.events }}</td>
                <td>R$ {{ listPayment.total.total_amount || 0 }}</td>
                <td>R$ {{ listPayment.list.paid_amount || 0 }}</td>
                <td>R$ {{ listPayment.available.bal_amount || 0 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
