import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { DatePickerComponent } from 'src/app/shared/components/date-picker/date-picker.component';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  @ViewChild(DatePickerComponent, { static: true }) daterangepicker: DatePickerComponent | any;

  rangeForm: FormGroup | any;
  submitted = false;
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  selectedFile: File | any = null;
  countryCodeList: any = [];
  searchText: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  listpayment: any = [];
  maxDigit: any;
  minDigit: any;

  name: any;
  generalForm: FormGroup | any;
  securityForm: FormGroup | any;
  password: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.password = this.AuthService.firstpassword;
    setTimeout(() => {
      this.AuthService.firstpassword = false;
    }, 3000);
    this.data = this.route.snapshot.data?.message?.data;

    this.generalForm = this.formBuilder.group({
      photo: [''],
      first_name: [this.data.first_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      last_name: [this.data.last_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      school: [this.data.school.name, Validators.required],
      email: [
        this.data.email,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
        ]),
      ],
      phone: [this.data.phone, Validators.required],
      phonenumber: [this.data.phonenumber, [Validators.required]],
      dob: [this.data.dob, Validators.required],
      address: [this.data.address, Validators.required],
      countryCode: [this.data.country_code, Validators.required],
      stateCode: [this.data.state_code, Validators.required],
      city: [this.data.city, Validators.required],
      zip_code: [this.data.zip_code, Validators.required],
      gender: [this.data.gender, Validators.required],
    });
    this.securityForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
        ]),
      ],
    });
    if (this.data.middle_name != null) {
      this.generalForm.controls['middle_name'].setValue(this.data.middle_name);
    }
    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    this.pagecounts = this.Apiservice.pageCounts;
    this.paymentList();
    this.rangeForm = this.formBuilder.group({
      start_time: [''],
      end_time: [''],
    });
    if (this.generalForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.generalForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }

  get f() {
    return this.generalForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.generalForm.invalid) {
      return;
    }
    this.AuthService.schoolname = this.generalForm.value.school;
    const formData = new FormData();
    formData.append('photo', this.generalForm.get('photo').value);
    let formfile = [
      'first_name',
      'middle_name',
      'last_name',
      'email',
      'phone',
      'phonenumber',
      'dob',
      'address',
      'countryCode',
      'stateCode',
      'city',
      'zip_code',
      'school',
      'gender',
    ];
    formfile.forEach((name: string) => {
      formData.append(name, this.generalForm.get(name).value);
    });
    this.Apiservice.postApi(endpoints.updateprofile.path, formData).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Admin profile updated Successfully'));
        this.router.navigate(['/admin/dashboard']);
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Profile'));
      }
    );
  }

  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.generalForm.patchValue({
          photo: file,
        });
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  Countrychange(event: any) {
    this.generalForm.controls['countryCode'].setValue(event.countryCode);
    this.generalForm.controls['stateCode'].setValue(event.stateCode);
    this.generalForm.controls['city'].setValue(event.city);
    this.generalForm.controls['zip_code'].setValue(event.zip_code);
  }
  paymentList() {
    this.Apiservice.getApi(endpoints.listpayment.path).subscribe((data: any) => {
      this.listpayment = data.response.payments;
    });
  }
  private convert(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }
  OnSelect(event: any) {
    if (event?.startDate) {
      let startdate = this.convert(event.startDate._d);
      let enddate = this.convert(event.endDate._d);
      this.rangeForm.controls['start_time'].setValue(startdate);
      this.rangeForm.controls['end_time'].setValue(enddate);
    }
  }

  onDateSubmit() {
    this.submit = true;
    if (this.rangeForm.invalid) {
      this.toaster.error(this.translate.instant('Select a from and to date to submit'));
      return;
    }

    this.Apiservice.getApi(
      endpoints.listpayment.path +
        '?start_time=' +
        this.rangeForm.value.start_time +
        '&end_time=' +
        this.rangeForm.value.end_time
    ).subscribe(
      (data: any) => {
        this.listpayment = data.response.data;
      },
      (err: any) => {}
    );
  }
  clear() {
    this.daterangepicker.clear();
    this.rangeForm.reset();
    this.paymentList();
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  conutyphone(event: any) {
    console.log(event);

    this.generalForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }
}
