import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChoiceChipComponent } from './components/choice-chip/choice-chip.component';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgPaymentCardModule } from 'ng-payment-card';
import { NgChartsModule } from 'ng2-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxSlickJsModule } from 'ngx-slickjs';

import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AddPaymentComponent } from './components/add-payment/add-payment.component';
import { AdminAttendanceComponent } from './components/admin-attendance/admin-attendance.component';
import { AdminPaymentComponent } from './components/admin-payment/admin-payment.component';
import { StudentAttendanceComponent } from './components/attendance/student-attendance.component';
import { BarChartsComponent } from './components/bar-charts/bar-charts.component';
import { ButtonComponent } from './components/button/button.component';
import { CancelModal } from './components/cancel-modal/cancel-modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { ClassCardComponent } from './components/class-card/class-card.component';
import { ClassTimeInputComponent } from './components/class-time-input/class-time-input.component';
import { CoachDetailsComponent } from './components/coach-details/coach-details.component';
import { ConfirmationModal } from './components/confirmation-modal/confirmation-modal.component';
import { CountryStateCityComponent } from './components/country-state-city/country-state-city.component';
import { CountryStateComponent } from './components/country-state/country-state.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DivisorComponent } from './components/divisor/divisor.component';
import { FabListComponent } from './components/fab-list/fab-list.component';
import { FabComponent } from './components/fab/fab.component';
import { FieldInputComponent } from './components/field-input/field-input.component';
import { FieldNumberComponent } from './components/field-number/field-number.component';
import { FieldSelectComponent } from './components/field-select/field-select.component';
import { ModalRoot } from './components/modal-root/modal-root.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotesComponent } from './components/notes/notes.component';
import { NotificationComponent } from './components/notification/notification.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RecurrenceComponent } from './components/recurrence/recurrence.component';
import { SearchClassComponent } from './components/search-class/search-class.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { VideoIframeDialogComponent } from './components/video-iframe-dialog/video-iframe-dialog.component';
import { CustomInputNumberDirective } from './directives/custom-input-number.directive';
import { FocusNextDirective } from './directives/focus-next.directive';
import { FilterPipe } from './filter.pipe';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { SafePipe } from './safe.pipe';
import { ClassesService } from './services/classes.service';

@Injectable({ providedIn: 'root' })
export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    const path = `/assets/i18n/${lang}.json`;
    return this.http.get(`${path}?v=` + new Date().getTime());
  }
}

@NgModule({
  declarations: [
    NotesComponent,
    AddPaymentComponent,
    AdminPaymentComponent,
    DatePickerComponent,
    BarChartsComponent,
    CountryStateComponent,
    CountryStateCityComponent,
    ChatComponent,
    FilterPipe,
    ChangePasswordComponent,
    StudentAttendanceComponent,
    CoachDetailsComponent,
    NotificationComponent,
    AdminAttendanceComponent,
    VideoIframeDialogComponent,
    SafePipe,
    RecurrenceComponent,
    ChipListComponent,
    ChoiceChipComponent,
    ButtonComponent,
    SearchClassComponent,
    FieldSelectComponent,
    ClassCardComponent,
    FabListComponent,
    FabComponent,
    DivisorComponent,
    FieldInputComponent,
    FieldNumberComponent,
    ClassTimeInputComponent,
    ModalComponent,
    RadioGroupComponent,
    SpinnerComponent,
    ModalRoot,
    CancelModal,
    ConfirmationModal,
    CustomInputNumberDirective,
    FocusNextDirective,
  ],

  exports: [
    FocusNextDirective,
    RecurrenceComponent,
    NotesComponent,
    AddPaymentComponent,
    DatePickerComponent,
    BarChartsComponent,
    AdminPaymentComponent,
    CountryStateComponent,
    CountryStateCityComponent,
    ChatComponent,
    ChangePasswordComponent,
    StudentAttendanceComponent,
    CoachDetailsComponent,
    NotificationComponent,
    AdminAttendanceComponent,
    VideoIframeDialogComponent,
    TranslateModule,
    ReactiveFormsModule,
    ChipListComponent,
    ChoiceChipComponent,
    SearchClassComponent,
    FieldSelectComponent,
    ClassCardComponent,
    ButtonComponent,
    FabListComponent,
    FabComponent,
    DivisorComponent,
    ClassTimeInputComponent,
    FieldInputComponent,
    FieldNumberComponent,
    ModalComponent,
    RadioGroupComponent,
    ModalRoot,
    CancelModal,
    ConfirmationModal,
    CustomInputNumberDirective,
  ],

  imports: [
    MatRadioModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgSelectModule,
    NgChartsModule,
    PerfectScrollbarModule,
    NgxDaterangepickerMd.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPaginationModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    YouTubePlayerModule,
    NgxMaskModule.forRoot(),
    NgCircleProgressModule.forRoot({
      animationDuration: 100,
    }),
    NgxSlickJsModule.forRoot({
      links: {
        jquery: 'https://code.jquery.com/jquery-3.4.0.min.js',
        slickJs: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss: 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),
    NgPaymentCardModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: CustomLoader, deps: [HttpClient] },
    }),
    NgxMaterialTimepickerModule,
    BsDropdownModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    BsDropdownConfig,
    ClassesService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
