<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <!-- Page header section  -->
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-xl-12 col-md-12 col-sm-12">
                    <h2 *ngIf="!this.routerid" translate>Add Video Category</h2>
                    <h2 *ngIf="this.routerid" translate>Edit Video Category</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="float-right mb-4">
                        <a class="btn-sm auth-button " [routerLink]="['/admin/video-category']" translate><i
                                class="fas fa-bars mr-1"></i>View All</a>
                    </div>
                </div>
            </div>
            <div class="row clearfix mt-4">
                <div class="col-lg-8 col-md-8 col-sm-8 p-5 auth-form" style="margin: auto;">
                    <form [formGroup]="classForm" (ngSubmit)="onSubmit()">
                        <div class="form-group ">
                            <label class="mt-1" translate>Title<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="{{'Title'|translate}}"
                                formControlName="section" [ngClass]="{ 'is-invalid': submitted && f.section.errors }" />
                            <div *ngIf="submitted && f.section.errors" class="invalid-feedback" translate>
                                <div *ngIf="f.section.errors.required" translate>Title is required</div>
                            </div>
                        </div>
                        <div class="form-group ">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="assign_status" [value]="1">
                                <label class="custom-control-label" for="customCheck1" translate="">Assign Status</label>
                            </div>
                        </div>
                        <!-- <div class="form-group ">
                          <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="default_section" [value]="1">
                              <label class="custom-control-label" for="customCheck2" translate="">Default Catetgory</label>
                          </div>
                        </div> -->
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="!this.routerid"
                            translate>Save</button>
                        <button type="submit" class="btn btn-dark float-right auth-button mt-4" *ngIf="this.routerid"
                            translate>Update</button>
                        <a [routerLink]="['/admin/video-category']" class="btn btn-dark float-right mr-2 mt-4 text-white"
                            translate>Cancel</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
