<div class="c-field">
  <label *ngIf="label" class="c-field__label" [class.c-field__label--disabled]="isDisabled">
    {{ label }}
    <span *ngIf="isRequired">*</span>
  </label>

  <div class="c-field_container">
    <input
      [type]="type === 'tel' ? 'tel' : 'number'"
      customInputNumber
      [formControl]="ngControl.control"
      [value]="value"
      [placeholder]="placeholder"
      [required]="isRequired"
      [min]="min"
      [max]="max"
      class="c-field__input"
      [class.c-field__input--invalid]="
        ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
      "
      [class.u-text-align--center]="centerContent"
      [class.c-field__input--no-arrow]="!numericArrows"
      [class.c-field__input--disabled]="isDisabled"
      (input)="setValue($event)"
      autocomplete="disabled"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="handleArrows($event)" />

    <ion-icon
      *ngIf="clearButton && value"
      name="close"
      class="c-field__icon-delete c-field--number"
      [class.u-color--orange-300]="
        ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
      "
      [class.c-field--focused]="isFocused"
      [class.c-field--disabled]="isDisabled"
      (click)="clearInput($event)"></ion-icon>
  </div>

  <span *ngIf="helperText" class="c-field__icon__helper">{{ helperText }}</span>
  <div
    class="c-field__icon__helper u-display--flex u-flex-direction--column"
    *ngIf="ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.errors">
    <span *ngIf="ngControl.control?.errors?.required" class="u-color--orange-300">{{
      'FORM_ERRORS.FIELD_REQUIRED' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.min" class="u-color--orange-300">
      {{ 'FORM_ERRORS.MIN_NUMBER' | translate }} {{ ngControl.control?.errors?.min.min }}
    </span>
    <span *ngIf="ngControl.control?.errors?.max" class="u-color--orange-300">
      {{ 'FORM_ERRORS.MAX_NUMBER' | translate }} {{ ngControl.control?.errors?.max.max }}
    </span>
    <span *ngIf="ngControl.control?.errors?.pattern" class="u-color--orange-300">{{
      'FORM_ERRORS.INVALID_FORMAT' | translate
    }}</span>
  </div>
</div>
