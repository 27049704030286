import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.css'],
})
export class PackagesComponent implements OnInit {
  isTouchUIActivated = false;
  loading = false;
  success: boolean = false;
  searchText: any;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  liststaff: any = [];
  ActiveStatus: any = [];
  submitted: boolean = false;
  currency: string = 'USD';

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    public formBuilder: FormBuilder,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.packagesList();
  }

  packagesList() {
    this.Apiservice.getApi(endpoints.packagesadminlist.path).subscribe(
      (data: any) => {
        console.log(data)
        this.ActiveStatus = data.response.data.packages.filter((s: any) => s.status == 1);
        this.currency = data.response.data.school.default_currency.toUpperCase();
      }
    );
  }
  deleteActiveRow(i: number, school: any) {
    Swal.fire({
      title: this.translate.instant('Delete'),
      text: this.translate.instant('Do you want to Delete ?'),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#8d448b',
      cancelButtonColor: '#343a40',
      confirmButtonText: this.translate.instant('Yes'),
      cancelButtonText: this.translate.instant('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.Apiservice.getApi(
          endpoints.deleteadminpackages.path + '?id=' + school.id
        ).subscribe(
          (data: any) => {
            Swal.fire({
              title: data.message,
              icon: 'success',
            });
            this.packagesList();
          },
          (err) => {
            Swal.fire({
              title: err,
              icon: 'error',
            });
          }
        );
      }
    });
  }

  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
}
