import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.services';
import Swal from 'sweetalert2';
import { endpoints } from '../../../../core/config/endpoints';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-seo-settings',
  templateUrl: './seo-settings.component.html',
  styleUrls: ['./seo-settings.component.css'],
})
export class SeoSettingsComponent implements OnInit {
  schoolForm: FormGroup | any;
  submitted = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  schoolid: any;
  routerid: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private AuthService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.data = this.route.snapshot.data?.message?.data;
    this.schoolForm = this.formBuilder.group({
      logo: [this.data.logo],
      fav_icon: [this.data.fav_icon],
      title: [this.data.title, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      meta_tag: [this.data.meta_tag, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      description: [this.data.description],
    });
  }
  get f() {
    return this.schoolForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.schoolForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('logo', this.schoolForm.get('logo').value);
    formData.append('fav_icon', this.schoolForm.get('fav_icon').value);
    let formfile = ['title', 'meta_tag', 'description'];
    formfile.forEach((name: string) => {
      formData.append(name, this.schoolForm.get(name).value);
    });
    this.Apiservice.postApi(endpoints.updateseo.path + '?id=' + this.data.id, formData).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('SEO updated successfully'));
        this.router.navigate(['/superadmin/seosettings']);
        this.data = this.route.snapshot.data?.message?.data;
      },
      (err: any) => {
        this.toaster.error(this.translate.instant('Error while loading SEO'));
      }
    );
  }

  LogoSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.schoolForm.patchValue({
          logo: file,
        });
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
      });
      return;
    }
  }
  faviconSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.schoolForm.patchValue({
          fav_icon: file,
        });
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
      });
      return;
    }
  }
}
