<form *ngIf="form" [formGroup]="form" class="e-class-time-input">
  <div class="e-class-time-input_line u-flex--half">
    <div class="e-class-time-input_field">
      <app-field-select
        formControlName="recurrence"
        [showArrow]="false"
        [centralizeSelection]="true"
        [largeText]="true"
        [smallHeight]="limitHeight"
        [options]="recurrenceOptions"
        [highlightInitialValue]="!showRemoveButton"></app-field-select>
    </div>
    <span class="e-class-time-input__text">{{ 'EVERY' | translate }}</span>
  </div>
  <div class="e-class-time-input_line u-flex--full">
    <div *ngIf="hasSequence" class="e-class-time-input_field">
      <app-field-select
        formControlName="sequence"
        [showArrow]="false"
        [centralizeSelection]="true"
        [largeText]="true"
        [smallHeight]="limitHeight"
        [options]="sequenceOptions"
        [highlightInitialValue]="!showRemoveButton"></app-field-select>
    </div>
    <div class="e-class-time-input_field">
      <app-field-select
        formControlName="day"
        [showArrow]="false"
        [centralizeSelection]="true"
        [largeText]="true"
        [options]="hasSequence ? dayOptionsMonthly : dayOptionsWeekly"
        [highlightInitialValue]="!showRemoveButton"></app-field-select>
    </div>
    <span class="e-class-time-input__text">{{ 'EVENTS_PAGE.FROM' | translate }}</span>
  </div>
  <div class="e-class-time-input_line u-flex--full">
    <div class="e-class-time-input_field">
      <input
        [ngxTimepicker]="startTimepicker"
        class="e-class-time-input__input"
        formControlName="start_time"
        [placeholder]="'START' | translate" />
      <ngx-material-timepicker
        #startTimepicker
        [confirmBtnTmpl]="okButton"
        [cancelBtnTmpl]="cancelButton"></ngx-material-timepicker>
    </div>
    <span class="e-class-time-input__text">{{ 'EVENTS_PAGE.TO' | translate }}</span>
    <div class="e-class-time-input_field">
      <input
        [ngxTimepicker]="endTimepicker"
        class="e-class-time-input__input"
        formControlName="end_time"
        [placeholder]="'END' | translate"
        [min]="form.value.start_time" />
      <ngx-material-timepicker
        #endTimepicker
        [confirmBtnTmpl]="okButton"
        [cancelBtnTmpl]="cancelButton"></ngx-material-timepicker>
    </div>
    <div *ngIf="showRemoveButton">
      <app-button variant="tertiary" icon="close-outline" (click)="onRemoveButtonClick()"></app-button>
    </div>
  </div>
</form>
<span *ngIf="scheduleRepeated" class="u-color--orange-300 u-display--block u-margin-top--md">{{
  'FORM_ERRORS.SCHEDULE_REPEATED' | translate
}}</span>
<ng-template #okButton>
  <app-button class="c-class-card__menu-button" variant="primary">{{ 'Ok' }}</app-button>
</ng-template>
<ng-template #cancelButton>
  <app-button class="c-class-card__menu-button" variant="secondary">{{ 'Cancel' | translate }}</app-button>
</ng-template>
