<div class="card">
    <div class="body">
        <div class="row">
            <div class="col-lg-12 col-md-12 barfor-div">
                <!-- <canvas class="forbar" baseChart [datasets]="barChartData" [options]="barChartOptions"
                    [plugins]="barChartPlugins" [type]="barChartType">
                </canvas> -->
                <canvas baseChart 
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [type]="barChartType">
            </canvas>
            </div>
        </div>
    </div>
</div>
