import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-coach',
  templateUrl: './view-coach.component.html',
  styleUrls: ['./view-coach.component.css']
})
export class ViewCoachComponent implements OnInit {
 
  constructor(
    
    ) {}

  ngOnInit(): void {
    
  }

}
