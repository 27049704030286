<div class="c-modal_body c-modal_body--{{ size }} {{ modalCss }}">
  <header class="c-modal_header {{ headerCss }}">
    <span *ngIf="title; else customHeader" class="c-modal_header__title">{{ title }}</span>
    <div *ngIf="showCloseButton" class="c-modal_header__close" (click)="modalService.close()">
      <ion-icon class="c-modal_header__close-icon" name="close"></ion-icon>
    </div>
  </header>

  <div class="c-modal_content {{ contentCss }}">
    <ng-content></ng-content>
  </div>

  <footer class="c-modal_footer {{ footerCss }}">
    <div class="c-modal_footer__divisor" *ngIf="showFooterDivisor"></div>
    <div class="c-modal_footer_buttons">
      <ng-content select="[slot=footer]"></ng-content>
    </div>
  </footer>
</div>

<ng-template #customHeader>
  <ng-content select="[slot=header]"></ng-content>
</ng-template>
