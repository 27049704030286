import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgChartsModule } from 'ng2-charts';
import { NgxCaptureModule } from 'ngx-capture';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SharedModule } from 'src/app/shared/shared.module';
import { AttendanceComponent } from './attendance/attendance.component';
import { CalanderLeftmenuComponent } from './calendar/calander-leftmenu/calander-leftmenu.component';
import { CalendarComponent } from './calendar/calendar.component';
import { IframeDialogComponent } from './calendar/iframe-dialog/iframe-dialog.component';
import { ViewScheduleComponent } from './calendar/view-schedule/view-schedule.component';
import { CoachRoutingModule } from './coach-routing.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './filter.pipe';
import { HeaderComponent } from './header/header.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { MessagesComponent } from './message/message.component';
import { NotificationComponent } from './notification/notification.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { SafePipe } from './safe.pipe';
import { SettingsComponent } from './settings/settings.component';
import { VideoLibraryComponent } from './video-library/video-library.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    CoachRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgCircleProgressModule.forRoot({
      animationDuration: 100,
    }),
    NgChartsModule,
    MatCardModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatNativeDateModule,
    NgxPaginationModule,
    OverlayModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    FontAwesomeModule,
    NgxCaptureModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    SharedModule,
    MatExpansionModule,
    NgxMaskModule.forRoot(),

    MatProgressSpinnerModule,
  ],
  declarations: [
    CoachRoutingModule.components,
    DashboardComponent,
    HeaderComponent,
    LeftmenuComponent,
    CalendarComponent,
    CalanderLeftmenuComponent,
    ConfirmDialogComponent,
    ReportComponent,
    SettingsComponent,
    PaymentsComponent,
    AttendanceComponent,
    ProfileComponent,
    FilterPipe,
    MessagesComponent,
    NotificationComponent,
    ViewScheduleComponent,
    SafePipe,
    IframeDialogComponent,
    VideoLibraryComponent,
  ],

  providers: [],
})
export class CoachModule {}
