import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ModalDismissReasons,
  NgbActiveModal,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from '../../../../shared/services/auth.service';
import { NotesComponent } from 'src/app/shared/components/notes/notes.component';
import { endpoints } from 'src/app/core/config/endpoints';

@Component({
  selector: 'app-calander-leftmenu',
  templateUrl: './calander-leftmenu.component.html',
  styleUrls: ['./calander-leftmenu.component.css'],
})
export class CalanderLeftmenuComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() dataInput:any;
  @Input() date:any;

  datePicker: boolean = false;
  settings: any = [];
  classData: any = [];
  classes: any = [];
  listclass: any = [];
  activeicon: boolean = false;
  eventactive:any
  myDate:any

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public modalService: NgbModal
  ) {
    this.myDate = new Date();
      
  }

  ngOnInit(): void {
  }
 
  cToggoleMenu() {
    const body = document
      .getElementsByTagName('body')[0]
      .classList.remove('offcanvas-active');
  }
  datepickerCalendar() {
    this.datePicker = !this.datePicker;
  }
  eventdetails(event: any) {
    this.eventactive=event.id
    this.newItemEvent.emit(event);
  }
  notesOpen(){
    const modalRef = this.modalService.open(NotesComponent, {
      size: 'lg',
    });
  }
}
