import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[customInputNumber]',
})
export class CustomInputNumberDirective {
  private regexPattern: RegExp = /^[0-9\s.+\\-]*$/;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;
    const isValid = this.regexPattern.test(inputValue);

    if (!isValid) {
      // If the input value does not match the regex pattern, prevent the default behavior
      event.preventDefault();
      // Optionally, you can also reset the input value to remove the invalid characters
      inputElement.value = inputValue.replace(new RegExp(`[^0-9\\s.+\\-]`, 'g'), '');
      return;
    } else return;
  }
}
