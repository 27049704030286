export interface RadioGroupItem {
  id: string;
  label: string;
  disabled: boolean;
  checked: boolean;
}

export const ConvertToRadioOption = (
  options: any[],
  toId: string,
  toLabel: string,
  toDisabled?: string | undefined
): RadioGroupItem[] => {
  let optionsArray: RadioGroupItem[] = [];
  options.forEach((el) => {
    optionsArray.push({
      id: el[toId],
      label: el[toLabel],
      disabled: toDisabled ? el[toDisabled] : false,
      checked: false,
    });
  });
  return optionsArray;
};
