<div class="p-4  mt-4"  #screen>
    <div class="row" >
        <div class="col-lg-4 col-md-4 col-sm-6">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 ">
                    <h4 translate>CHECK-IN ACTIVITY</h4>
                    <p><span translate>Member since</span> {{attendance?.member_since}}</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <h5 translate>YEAR TO DATE</h5>
                    <p *ngIf="attendance?.year">{{attendance?.year?.year}}</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <h5 translate>ALL TIME</h5>
                    <p>{{attendance?.total}}</p>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h5 translate>BEST MONTH</h5>
                    <p *ngIf="attendance?.bestMonth">{{attendance?.bestMonth.month_name}}</p>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-6" >
            <circle-progress [percent]="attendance?.currentWeek" [radius]="80" [backgroundPadding]="5"
                [outerStrokeWidth]="10" [innerStrokeWidth]="10" [outerStrokeColor]="'#8d448b'"
                [innerStrokeColor]="'#e7e8ea'" [animation]="true" [showInnerStroke]="true" [showZeroOuterStroke]="true"
                [showUnits]=false [showSubtitle]=false [renderOnClick]="false" [showTitle]="true" [showSubtitle]="true"
                [space]="-10" [title]="'This Week'" [subtitle]="attendance?.currentWeek" [subtitleFontSize]="'28'"
                [clockwise]="false"></circle-progress>
            <circle-progress [percent]="attendance?.currentMonth" [radius]="80" [backgroundPadding]="5"
                [outerStrokeWidth]="10" [innerStrokeWidth]="10" [outerStrokeColor]="'#8d448b'"
                [innerStrokeColor]="'#e7e8ea'" [animation]="true" [showInnerStroke]="true" [showZeroOuterStroke]="true"
                [showUnits]=false [showSubtitle]=false [renderOnClick]="false" [showTitle]="true" [showSubtitle]="true"
                [space]="-10" [title]="'This Month'" [subtitle]="attendance?.currentMonth" [subtitleFontSize]="'28'"
                [clockwise]="false"></circle-progress>

        </div>

    </div>
    <div class="row mt-4" >
        <div class="col-lg-8 col-md-8 col-sm-6">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <input type="date" max="9999-12-31" class="form-control" (change)=dateFilter($event) />
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 mt-4 mt-md-0">
                    <h5 translate>MONTHLY TOTAL</h5>
                    <p>{{attendance?.currentMonth}}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <h5 translate>MOST VISITED DAY</h5>
                    <p *ngIf="attendance?.mostVisitedDay">{{attendance?.mostVisitedDay.date}}</p>
                </div>
            </div>

        </div>
        <!-- <div class="col-lg-4 col-md-4 col-sm-6">
            <button class="btn auth-button float-right" (click)="Captureimage()" translate><i class="fas fa-share-alt mr-1"></i>Share my  activity</button>

        </div> -->
        <div class="col-lg-12 col-md-12 col-sm-12 mt-4" >
            <div class="box-border p-4">
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr>
                                <th>DD/MM/YYYY</th>
                                <th translate>NAME OF THE CLASS CHECKED IN</th>
                                <th translate>TIME OF THE CLASS</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="userrole=='STUDENT'">
                            <tr *ngFor="let event of listattendance">
                                <td>{{event.events.start_time | date:'dd-MM-YYYY'}}</td>
                                <td>{{event.events.name}}</td>
                                <td>{{event.events.start_time | date:'hh:mm a'}} - {{event.events.end_time | date:'hh:mm a'}}</td>
                            </tr>

                        </tbody>
                        <tbody *ngIf="userrole=='COACH'">
                            <tr *ngFor="let event of listattendance">
                                <td>{{event.start_time | date:'dd-MM-YYYY'}}</td>
                                <td>{{event.name}}</td>
                                <td>{{event.start_time | date:'hh:mm a'}} - {{event.end_time |date:'hh:mm a'}}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="download" class="mt-4">
    <a #downloadLink></a>
</div>
