<div class="c-card e-summary-card">
  <div class="c-card_content">
    <div class="c-card_content_info">
      <img
        class="c-card_content_info__img"
        [src]="details?.coachImage ?? 'assets/images/placeholder-class.png'"
        alt="coach picture" />
      <div class="c-card__text u-display--flex u-flex-direction--column">
        <span class="c-card__text--title">{{ details?.className }}</span>
        <span class="c-card__text--subtitle">
          {{ details?.coachName }}
        </span>
      </div>
    </div>
  </div>
  <div class="c-card_footer">
    <div class="c-card_footer_tag-container">
      <span class="c-card_footer__tag">{{ details?.programName }} </span>
    </div>
  </div>
</div>
