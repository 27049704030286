<div class="row clearfix">
  <div class="col-md-12">
    <!-- <h3 *ngIf="!date" class="texth3">{{myDate | date:'YYYY-MM-dd'}} to  {{myDate | date:'YYYY-MM-dd'}}</h3> -->
    <h3 *ngIf="!date" class="texth3" translate>All Classes</h3>
    <h3 *ngIf="date" class="texth3">{{ date }}</h3>
  </div>
</div>

<div class="row clearfix mt-3">
  <div class="col-md-12">
    <div class="container card p-3">
      <div *ngIf="dataInput.length > 0">
        <div
          class="card-split rorborder"
          [ngClass]="{ activemenu: event.id == eventactive }"
          *ngFor="let event of dataInput; let i = index">
          <div class="row nth-class-ng no-gutters" [routerLink]="['/coach/schedule/view/' + event.id]">
            <div class="col-log-12 col-md-12 col-sm-12 col-12 text-center card-tophead-bg">
              <h5 class="title-card-name mb-0 py-1">{{ event.name }}</h5>
            </div>
          </div>
          <div class="row clearfix no-gutters p-2 rorborders mb-1">
            <div class="col-lg-3 col-md-3 col-sm-3 col-3" [routerLink]="['/coach/schedule/view/' + event.id]">
              <img src="assets/images/user.png" class="img-person" alt="User Profile Picture" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-6">
              <div class="calendar-pro-text" [routerLink]="['/coach/schedule/view/' + event.id]">
                <p>
                  {{ event?.start_date }}, {{ event?.end_time }}- {{ event?.start_date }}, {{ event?.end_time }}
                </p>
                <!-- <p class="textbold" *ngFor="let class of listclass">{{class.name}}</p> -->
                <div class="calender-event-main">
                  <div class="calander-event-name">
                    <p class="textbold">
                      {{ event.coach.first_name }} {{ event.coach.middle_name }} {{ event.coach.last_name }}
                    </p>
                  </div>
                </div>
                <!-- <div class="calender-event-main">
                                    <div class="calander-new-name">
                                        <h5>{{event.name}}</h5>
                                    </div>
                                    <div class="calander-new-icons">
                                        <h6> {{event.event_register.length}}/{{event.max_registration}}</h6>
                                    </div>
                                </div> -->
              </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-3">
              <div class="calanderlistview align-right">
                <a class="btn btn-default btn-sm" title="{{ 'Notes' | translate }}" (click)="notesOpen()">
                  <i class="fas fa-envelope"></i
                ></a>
                <div class="calander-new-icons" [routerLink]="['/coach/schedule/view/' + event.id]">
                  <h6>{{ event.event_register.length }}/{{ event.max_registration }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h5 *ngIf="dataInput.length == 0" class="text-center" translate>No data available</h5>
    </div>
  </div>
</div>
