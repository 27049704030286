import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  submitted = false;
  submit = false;
  loading = false;
  success: boolean = false;
  error = '';
  random = '';
  showMsg: boolean = false;
  data: any;
  countryCodeList: any = [];
  settings: any = [];
  belt: any = [];
  name: any;
  generalForm: FormGroup | any;
  eventlist: any = [];
  listpayment: any = [];
  page: any;
  pagecounts: any;
  itemPerPage = 50;
  searchText: any;
  maxDigit: any;
  minDigit: any;

  constructor(
    public formBuilder: FormBuilder,
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.pagecounts = this.Apiservice.pageCounts;
    this.Apiservice.getApi(endpoints.coachbelt.path).subscribe((data: any) => {
      this.belt = data.data.filter((s: any) => s.status == 1);
    });
    this.data = this.route.snapshot.data?.message?.data;
    this.generalForm = this.formBuilder.group({
      photo: [''],
      first_name: [this.data.first_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      middle_name: ['', [Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      last_name: [this.data.last_name, [Validators.required, Validators.pattern("^[A-Za-zÀ-ÖØ-öø-ÿ '_-]+$")]],
      email: [
        this.data.email,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,9}$'),
        ]),
      ],
      phone: [this.data.phone, Validators.required],
      phonenumber: [this.data.phonenumber, [Validators.required, Validators.pattern('[- +()0-9]+')]],
      belt: [this.data.belt.color, Validators.required],
      dob: [this.data.dob, Validators.required],
      address: [this.data.address, Validators.required],
      countryCode: [this.data.country_code, Validators.required],
      stateCode: [this.data.state_code, Validators.required],
      city: [this.data.city, Validators.required],
      zip_code: [this.data.zip_code, Validators.required],
      gender: [this.data.gender, Validators.required],
    });
    if (this.data.middle_name != null) {
      this.generalForm.controls['middle_name'].setValue(this.data.middle_name);
    }
    this._httpClient.get('assets/countrycode.json').subscribe((data: any) => {
      this.countryCodeList = data.countryCodeList;
    });
    this.Apiservice.getApi(endpoints.coacheventlist.path).subscribe((data: any) => {
      this.eventlist = data.results;
    });
    this.Apiservice.getApi(endpoints.coachpayment.path + '?coach=' + this.AuthService.userid).subscribe(
      (data: any) => {
        this.listpayment = data;
      }
    );
    if (this.generalForm.value.phone == '+1') {
      this.maxDigit = '(000)-000-0000';
      this.minDigit = '(000)-000-0000';
    } else if (this.generalForm.value.phone == '+55') {
      this.maxDigit = '(00) 00000-0000';
      this.minDigit = '(00) 0000-0000';
    } else {
      this.maxDigit = '(000)-000-0000-00';
      this.minDigit = '(000)-000-00';
    }
  }

  get f() {
    return this.generalForm.controls;
  }
  onSubmit() {
    this.submitted = true;

    if (this.generalForm.invalid) {
      return;
    }
    const formData = new FormData();
    formData.append('photo', this.generalForm.get('photo').value);
    let formfile = [
      'first_name',
      'middle_name',
      'last_name',
      'email',
      'phone',
      'phonenumber',
      'belt',
      'dob',
      'address',
      'countryCode',
      'stateCode',
      'city',
      'zip_code',
      'gender',
    ];
    formfile.forEach((name: string) => {
      formData.append(name, this.generalForm.get(name).value);
    });
    this.Apiservice.postApi(endpoints.updatecoachprofile.path, formData).subscribe(
      (data: any) => {
        this.showMsg = true;
        this.toaster.success(this.translate.instant('Coach updated Successfully'));
        this.router.navigate(['/coach/dashboard']);
      },
      (data: any) => {
        this.toaster.error(this.translate.instant('Error while loading Profile'));
      }
    );
  }
  onFileSelected(event: any) {
    let type = event.target.files[0].type;
    if (type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.generalForm.patchValue({
          photo: file,
        });
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          this.loading = false;
          this.element.nativeElement.querySelector('#profilePicPreview').src = reader.result;
        };
      }
    } else {
      Swal.fire({
        text: this.translate.instant('Image should be acceot only .jpg,.png'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#8d448b',
        cancelButtonColor: '#343a40',
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('Cancel'),
      });
      return;
    }
  }
  Countrychange(event: any) {
    this.generalForm.controls['countryCode'].setValue(event.countryCode);
    this.generalForm.controls['stateCode'].setValue(event.stateCode);
    this.generalForm.controls['city'].setValue(event.city);
    this.generalForm.controls['zip_code'].setValue(event.zip_code);
  }
  resetsearch() {
    this.searchText = '';
  }
  pageChange(event: any) {
    this.page = event;
  }
  conutyphone(event: any) {
    this.generalForm.controls['phone'].setValue(event.code);
    this.maxDigit = event.maxDigit || '(000)-000-0000-00';
    this.minDigit = event.minDigit || '(000)-000-00';
  }
}
