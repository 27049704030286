<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">

            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 ">
                    <div class="ClassListActive">
                        <ul class="nav nav-pills d-block" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
                                    role="tab" aria-controls="pills-home" aria-selected="true" translate><i
                                        class="fas fa-school mr-2" ></i>Attendance</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 ">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-12" id="content" #content>
                                        <div class="body">
                                            <div>
                                                    <div class=" card-section">
                                                        <div class="header">
                                                            <h2 class="dashboard-h2 mb-2" translate>Attendance Overview</h2>
                                                        </div>
                                                        <app-bar-charts></app-bar-charts>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>