<app-header></app-header>

<div id="main-content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row clearfix">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h2 translate>Setting</h2>
                </div>
            </div>
            <app-change-password></app-change-password >
        </div>
    </div>
</div>