<form *ngIf="scheduleForm" [formGroup]="scheduleForm" class="e-schedule-form">
  <app-summary-card class="e-schedule-form__summary" [details]="summaryDetails"></app-summary-card>
  <div *ngIf="isSchedulesEmpty" class="e-schedule-form_input-container">
    <div class="e-schedule-form_input-container__title">
      <span>{{ 'ADD_SCHEDULE_TO_PROCEED' | translate }}</span>
    </div>
  </div>
  <div class="e-schedule-form_input-container">
    <app-button
      [variant]="isSchedulesEmpty ? 'primary' : 'secondary'"
      icon="add"
      [iconAfterLabel]="true"
      (click)="scheduleAdded()">
      {{ 'ADD_A_SCHEDULE' | translate }}</app-button
    >
  </div>
  <div class="e-schedule-form_input-container">
    <div class="e-schedule-form_divisor" *ngIf="weeklySchedules.length > 0">
      <span class="e-schedule-form_divisor__text">{{ 'WEEKLY' | translate }}</span>
      <div class="e-schedule-form_divisor__line"></div>
    </div>
    <app-class-time-input
      class="u-margin-bottom--md"
      *ngFor="let schedule of weeklySchedules; index as idx"
      [showRemoveButton]="true"
      [schedule]="schedule"
      [scheduleRepeated]="checkScheduleOverlap(schedule)"
      (removeButtonClicked)="removeSchedule(idx, 'weekly')"
      (formValueChanged)="scheduleValueChanged(idx, 'weekly', $event)"></app-class-time-input>
    <div class="e-schedule-form_divisor" *ngIf="monthlySchedules.length > 0">
      <span class="e-schedule-form_divisor__text">{{ 'MONTHLY' | translate }}</span>
      <div class="e-schedule-form_divisor__line"></div>
    </div>
    <app-class-time-input
      class="u-margin-bottom--md"
      *ngFor="let schedule of monthlySchedules; index as idx"
      [showRemoveButton]="true"
      [schedule]="schedule"
      (removeButtonClicked)="removeSchedule(idx, 'monthly')"
      (formValueChanged)="scheduleValueChanged(idx, 'monthly', $event)"></app-class-time-input>
  </div>
  <div class="e-schedule-form_divisor">
    <div class="e-schedule-form_divisor__line"></div>
  </div>
  <div class="e-schedule-form-line">
    <div class="e-schedule-form_input-container">
      <div class="e-schedule-form_input-container__title">
        <span>{{ 'CLASS_FREQUENCY_RANGE' | translate }}</span>
      </div>
      <div class="e-schedule-form_input-container__subtitle">
        <span>{{ 'CLASS_SCHEDULE_REPEAT' | translate }}</span>
      </div>
    </div>
    <ion-icon name="information-circle" class="e-schedule-form__icon"> </ion-icon>
    <span class="e-schedule-form__tooltip t-text--sm" (click)="$event.stopPropagation()">{{
      'ENDING_BLANK_TOOLTIP' | translate
    }}</span>
  </div>
  <div class="e-schedule-form-line">
    <div class="e-schedule-form_input-container">
      <div class="c-field">
        <app-datepicker
          labelClass="c-field__label"
          inputClass="c-field__input u-margin-bottom--none"
          [calendarLabel]="('EVENTS_PAGE.STARTING_ON' | translate) + '*'"
          variant="date"
          [startDate]="scheduleForm.value?.start_date"
          [minDate]="getMomentDate('today')"
          [maxDate]="getMomentDate('end')"
          [isRequired]="true"
          (OnDate)="onDateChange($event, 'start')"></app-datepicker>
      </div>
    </div>
    <div class="e-schedule-form_input-container">
      <app-datepicker
        labelClass="c-field__label"
        inputClass="c-field__input u-margin-bottom--none"
        [calendarLabel]="'ENDING_ON' | translate"
        [calendarPlaceholder]="'INDEFINITELY' | translate"
        variant="date"
        [clearDate]="true"
        [startDate]="scheduleForm.value?.end_date"
        [minDate]="getMomentDate('start')"
        (OnDate)="onDateChange($event, 'end')"
        (onClear)="onEndDateClear()"></app-datepicker>
    </div>
  </div>
</form>
