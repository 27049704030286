import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { endpoints } from 'src/app/core/config/endpoints';
import { ApiService } from '../../services/api.services';
import { AuthService } from './../../../shared/services/auth.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css'],
})
export class NotesComponent implements OnInit {
  studentList: any = [];
  submitted: boolean = false;
  @Input() classId: string | undefined;
  @Input() messageForm: FormGroup | any;

  constructor(
    private Apiservice: ApiService,
    private AuthService: AuthService,
    public formBuilder: FormBuilder,
    public toaster: ToastrService,
    public modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.Apiservice.getApi(endpoints.studentlist.path).subscribe((data: any) => {
      this.studentList = data.filter((s: any) => s.status == 1);
    });
    this.messageForm = this.formBuilder.group({
      event_id: ['', Validators.required],
      message: ['', Validators.required],
    });
  }
  get ff() {
    return this.messageForm.controls;
  }
  NotesSubmit() {
    this.messageForm.controls['event_id'].setValue(this.classId || this.AuthService.eventid);
    this.submitted = true;
    if (this.messageForm.invalid) {
      return;
    }
    this.Apiservice.postApi(endpoints.createnotes.path, this.messageForm.value).subscribe(
      (data: any) => {
        this.toaster.success(data.message);
        this.modalService.dismissAll();
      },
      (err: any) => {
        this.toaster.error(err.error.message);
      }
    );
  }
  Close() {
    this.modalService.dismissAll();
  }
}
