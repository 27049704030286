import { ControlContainer, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgChartsModule } from 'ng2-charts';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddClassComponent } from './class/add-class/add-class.component';
import { ClassComponent } from './class/class.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './filter.pipe';
import { HeaderComponent } from './header/header.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { AddMessagesComponent } from './messages/add-messages/add-messages.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { PackagesComponent } from './packages/packages.component';
import { AddPaymentComponent } from './payments/add-payment/add-payment.component';
import { PaymentsComponent } from './payments/payments.component';
import { AddUserComponent } from './profile/add-user/add-user.component';
import { ProfileComponent } from './profile/profile.component';
import { ReportComponent } from './report/report.component';
import { AddSchoolComponent } from './school/add-school/add-school.component';
import { SchoolComponent } from './school/school.component';
import { ViewCoachComponent } from './school/view-coach/view-coach.component';
import { ViewEventComponent } from './school/view-event/view-event.component';
import { ViewSchoolComponent } from './school/view-school/view-school.component';
import { AddRolesComponent } from './settings/roles/add-roles/add-roles.component';
import { RolesComponent } from './settings/roles/roles.component';
import { SeoSettingsComponent } from './settings/seo-settings/seo-settings.component';
import { SettingsComponent } from './settings/settings.component';
import { AddStaffpositionsComponent } from './settings/staff-positions/add-staffpositions/add-staffpositions.component';
import { StaffPositionsComponent } from './settings/staff-positions/staff-positions.component';
import { SuperAdminRoutingModule } from './superadmin-routing.module';
import { AddVideoCategoryComponent } from './video-category/add-video-category/add-video-category.component';
import { VideoCategoryComponent } from './video-category/video-category.component';
import { VideoLibraryComponent } from './video-library/video-library.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    SuperAdminRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    NgChartsModule,
    CollapseModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatCardModule,
    MatNativeDateModule,
    MatIconModule,
    NgxDaterangepickerMd.forRoot(),
    NgSelectModule,
    NgxMaskModule.forRoot(),
    SharedModule,
    MatExpansionModule,
    MatSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  declarations: [
    SuperAdminRoutingModule.components,
    LeftmenuComponent,
    DashboardComponent,
    HeaderComponent,
    SchoolComponent,
    AddSchoolComponent,
    FilterPipe,
    ViewSchoolComponent,
    ProfileComponent,
    ClassComponent,
    AddClassComponent,
    ReportComponent,
    SettingsComponent,
    StaffPositionsComponent,
    RolesComponent,
    AddRolesComponent,
    AddStaffpositionsComponent,
    SeoSettingsComponent,
    PaymentsComponent,
    AddPaymentComponent,
    PackagesComponent,
    AddPackagesComponent,
    AddMessagesComponent,
    MessagesComponent,
    AddUserComponent,
    ViewCoachComponent,
    ViewEventComponent,
    NotificationComponent,
    VideoCategoryComponent,
    AddVideoCategoryComponent,
    VideoLibraryComponent,
  ],

  providers: [{ provide: ControlContainer, useExisting: NgForm }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SuperAdminModule {}
