import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() size: 'lg' | 'sm' | 'icon' | 'default' = 'default';

  @Input() variant: 'primary' | 'secondary' | 'tertiary' | 'untoggled' | 'gray' | 'disabled' | 'orange' =
    'primary';

  @Input() icon: string | null = null;

  @Input() iconOnly: boolean = false;

  @Input() isSubmit: boolean = false;

  @Input() loading: boolean = false;

  @Input() disabled: boolean = false;

  @Input() disabledStyleOnly = false;

  @Input() iconAfterLabel = false;

  @Input() hideIconOnMobile = false;
}
