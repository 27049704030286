<div class="c-field">
  <label *ngIf="label" class="c-field__label" [class.c-field__label--disabled]="isDisabled">
    {{ label }}
    <span *ngIf="isRequired">*</span>
  </label>

  <div class="c-field_container">
    <input
      appFocusNext
      [mask]="mask"
      [type]="type"
      [formControl]="ngControl.control"
      [placeholder]="placeholder"
      [required]="isRequired"
      class="c-field__input"
      [class.c-field__input--invalid]="
        ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
      "
      [class.u-text-align--center]="centerContent"
      [class.c-field__input--disabled]="isDisabled"
      (input)="setValue($event)"
      (focus)="onFocus()"
      (blur)="onBlur()" />

    <ion-icon
      *ngIf="clearButton && value"
      name="close"
      class="c-field__icon-delete"
      [class.c-field--number]="type === 'number'"
      [class.u-color--orange-300]="
        ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.invalid
      "
      [class.c-field--focused]="isFocused"
      [class.c-field--disabled]="isDisabled"
      (click)="clearInput($event)"></ion-icon>
  </div>

  <span *ngIf="helperText" class="c-field__icon__helper">{{ helperText }}</span>
  <div
    class="c-field__icon__helper u-display--flex u-flex-direction--column"
    *ngIf="ngControl.control?.touched && ngControl.control?.dirty && ngControl.control?.errors">
    <span *ngIf="ngControl.control?.errors?.required" class="u-color--orange-300">{{
      'FORM_ERRORS.FIELD_REQUIRED' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.minlength" class="u-color--orange-300">
      {{ 'FORM_ERRORS.MIN_LENGTH' | translate }} {{ ngControl.control?.errors?.minlength.requiredLength }}
    </span>
    <span *ngIf="ngControl.control?.errors?.maxlength" class="u-color--orange-300">
      {{ 'FORM_ERRORS.MAX_LENGTH' | translate }} {{ ngControl.control?.errors?.maxlength.requiredLength }}
    </span>
    <span *ngIf="ngControl.control?.errors?.email" class="u-color--orange-300">{{
      'FORM_ERRORS.INVALID_EMAIL' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.pattern" class="u-color--orange-300">{{
      'FORM_ERRORS.INVALID_FORMAT' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.passwordMismatch" class="u-color--orange-300">{{
      'FORM_ERRORS.PASSWORD_MISMATCH' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.invalidDate" class="u-color--orange-300">{{
      'FORM_ERRORS.INVALID_DATE' | translate
    }}</span>
    <span *ngIf="ngControl.control?.errors?.weakPassword" class="u-color--orange-300">{{
      'FORM_ERRORS.WEAK_PASSWORD' | translate
    }}</span>
  </div>
</div>
