import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ChartConfiguration, ChartData, ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Component, OnInit, ViewChild } from '@angular/core';

import { ApiService } from 'src/app/shared/services/api.services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BaseChartDirective } from 'ng2-charts';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { endpoints } from '../../../core/config/endpoints';

@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-charts.component.html',
  styleUrls: ['./bar-charts.component.css']
})
export class BarChartsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  eventoverview:any=[]
  showHeader:any
  checkin:any=[]
  checkout:any=[]

  constructor(
    private Apiservice: ApiService,
    public toaster: ToastrService,
    private _httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private AuthService: AuthService,
    private translate: TranslateService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url == "/coach/dashboard") {
          this.showHeader = "coach";
        } else if (this.router.url == "/student/dashboard") {
          this.showHeader = "student";
        } 
      }
    });
  }

ngOnInit(): void {
  
  if(this.showHeader=='coach' ){
    this.Apiservice.getApi(endpoints.coachdashboard.path).subscribe(
      (data: any) => {
        let obj=data.data
        this.eventoverview= Object.keys(obj).map(key => obj[key]);
        this.barChartData=[
          {
            data: this.eventoverview,label:this.translate.instant('Total schedule'), backgroundColor:'pink'
          }
        ]
      },

      (err: any) => {
      }
    );
  }else if(this.showHeader=='student'){
    this.Apiservice.getApi(endpoints.studentdashboard.path).subscribe(
      (data: any) => {
        let obj=data.events
        let checkinobj=data.checkIn
        let checkoutobj=data.checkOut
        this.eventoverview= Object.keys(obj).map(key => obj[key]);
        this.checkin= Object.keys(checkinobj).map(key => checkinobj[key]);
        this.checkout= Object.keys(checkoutobj).map(key => checkoutobj[key]);
        this.barChartData=[
          {
            data: this.eventoverview,label:this.translate.instant('Total Events'), backgroundColor:'blue'
          },
          {
            data: this.checkin,label:this.translate.instant('CheckedIn'), backgroundColor:'green'
          },
          {
            data: this.checkout,label:this.translate.instant('CheckedOut'), backgroundColor:'red'
          }
        ]
      },

      (err: any) => {
      }
    );
  }
 
};
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
        x: {},
        y: {
          // display: true,
          min: 1,
          max: 20,
          ticks: {
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value ;
            },
          },
        },
      },
    
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartLabels = [
    this.translate.instant('Jan'),
    this.translate.instant('Feb'),
    this.translate.instant('Mar'),
    this.translate.instant('Apr'),
    this.translate.instant('May'),
    this.translate.instant('Jun'),
    this.translate.instant('Jul'),
    this.translate.instant('Aug'),
    this.translate.instant('Sep'),
    this.translate.instant('Oct'),
    this.translate.instant('Nov'),
    this.translate.instant('Dec'),
  ];

  public barChartData: ChartDataset[] = [
    
  ]
}
